const batchEntities = [
  'disciplines',
  'units',
  'service_packages',
  'areas',
  'subareas',
  'request_statuses',
  'inspect_statuses',
  'progress_statuses',
  'progress_service_statuses',
  'estimate_statuses',
  'formulas',
  'employees',
  'variable_types',
  'datasheet_categories',
  'field_settings'
]

// eslint-disable-next-line import/prefer-default-export
export { batchEntities }
