/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { formulaCategory } from '/src/models/concerns/formula_categories'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import useFormModal from'/src/ui/core/popups/form_modal'
import useHistoryPopupFields from './history_popup_fields'

export default function HistoryPopup({ parentDataItem, historyDataItem,
                                       open, onClose, level }) {
  const formTitleDefault =  I18n.t(`history_popup.form_create_${level}_title`)
  const modalTitleDefault = I18n.t('history_popup.modal_create_title')
  const [formTitle, setFormTitle] = useState(formTitleDefault)
  const [modalTitle, setModalTitle] = useState(modalTitleDefault)
  const [formulas, setFormulas] = useState()
  const { status, responseData, fetchAPI } = useFetchAPI('formulas')
  const formSubtitle = I18n.t('history_popup.form_subtitle')
  const historyPopupFieldsProps = {
    parentDataItem,
    historyDataItem,
    level,
    formulas
  }
  const [dataItem, sections] = useHistoryPopupFields(historyPopupFieldsProps)

  const model = {
    name: I18n.t('history_popup.singular'),
    paramName: `${level}_history`,
    route: `${level}_histories`
  }

  const percentageText = I18n.t('history_popup.percentage')

  useEffect(() => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: {
          eav_template_id: parentDataItem.eav_template_id,
          category: formulaCategory.drop
        }
      }
    }

    fetchAPI(params)
  }, [parentDataItem])

  useEffect(() => {
    if (status !== 'SUCCESS') return
    const formulasObj = {}
    responseData.data.forEach((formula) => {
      const fields = formula.fields.map((field) => ({ ...field, editable: true }))
      formulasObj[formula.id] = { ...formula, entity: level, fields }
    })
    setFormulas(formulasObj)
  }, [status, responseData])

  useEffect(() => {
    if (historyDataItem && historyDataItem.id) {
      setFormTitle(I18n.t(`history_popup.form_edit_${level}_title`))
      setModalTitle(I18n.t('history_popup.modal_edit_title'))
    } else {
      setFormTitle(formTitleDefault)
      setModalTitle(modalTitleDefault)
    }
  }, [historyDataItem])

  const progressType = (progressBy) => {
    return progressBy === percentageText ? 'accumulated_progress' : 'daily_quantity'
  }

  const validateForm = (item) => {
    const fields = [
      progressType(item.progress_by),
      'crew_size',
      'crews',
      'date',
      `${level}_id`,
      'working_hours'
    ]

    const validated = fields.every((field) => item[field])

    if (!validated)
      notifyError({
        title: I18n.t('history_popup.form_error_title'),
        body: I18n.t('history_popup.form_error_body'),
        closeTimeout: 5
      })

    return validated
  }

  // eslint-disable-next-line max-params
  const saveForm = (e, button, sendForm, dataItemToSave) => {
    const progressBy = progressType(dataItemToSave.progress_by)

    const item = {
      [progressBy]: dataItemToSave[progressBy],
      progress_by: dataItemToSave.progress_by,
      crew_size: dataItemToSave.crew_size,
      crews: dataItemToSave.crews,
      date: dataItemToSave.date,
      [`${level}_id`]: dataItemToSave[`${level}_id`],
      working_hours: dataItemToSave.working_hours,
      comment: dataItemToSave.comment,
      drop_formula_id: dataItemToSave.drop_formula_id,
      drop_formula_variables: dataItemToSave.drop_formula_variables
    }

    if (validateForm(item)) sendForm(item)
  }

  const buttons = [
    {
      id: 1,
      text: I18n.t('actions.cancel'),
      cancel: true,
      class: 'cancel-button'
    },
    {
      id: 2,
      text: I18n.t('actions.save'),
      onClick: saveForm,
      class: 'action-button'
    }
  ]

  const modalProps = { modalTitle, formTitle, formSubtitle, sections, buttons, onClose,
                       dataItem, model, sectionable: false, width: 50, height: 75, validateForm }

  const [openModal, renderModal] = useFormModal(modalProps)

  useEffect(() => { if (open) openModal() }, [open])

  return renderModal()
}

HistoryPopup.propTypes = {
  parentDataItem: PropTypes.oneOfType([PropTypes.object]),
  historyDataItem: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  level: PropTypes.string.isRequired
}

HistoryPopup.defaultProps = {
  parentDataItem: {},
  historyDataItem: {}
}
