// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class OrderModel {
  name = I18n.t('orders.title')

  singularName = 'order'

  paramName = 'order'

  templateType = 'order'

  route = 'orders'
}
