import { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { singularize, pluralize } from 'inflected'
import useSyncBatch from '/src/hooks/api/sync_batch'

/**
 * Fetches all the parent items for the desired item
 *
 * @param item - Array of items (The items used to fetch it's parents)
 * @param parents - Array of Strings (The parents that will be fetched)
 * @return parentItems - Object - The parent of items with their fields
 *
 * Ex:
 * > useWatchGrandparentsBatch([{ id: 1, scope_id: 1, ... }], ['scope', 'estimate.request'])
 * {
 *   'scope': [{ id: 1, description: 'My Scope' }],
 *   'request': [{ id: 2, reason: 'My Reason', test_integer: 666 }]
 * }
 */
export default function useWatchGrandparentsBatch(items, parentModels) {
  const [batch, setBatch] = useState([])

  const grandparents = useMemo(() =>
    parentModels.filter((parent) => parent.includes('.')),
    [parentModels]
  )

  const entities = useMemo(() => {
    if (!grandparents || !grandparents.length) return {}

    const entitiesKeys = {}
    grandparents.forEach((grandparent) => {
      const [entityKey, parentKey] = grandparent.split(".")
      if (!items[entityKey]) return

      const ids = Object.values(items[entityKey]).map((item) =>  item[`${parentKey}_id`]).filter(Number)
      const uniques = [...new Set(ids)]

      const query = { where: { id: uniques } }

      const get = (() => {
        return batch[parentKey]
      })

      const set = ((newValue) => {
        setBatch((oldValues) => {
          const newValues = {}
          newValues[parentKey] = newValue

          return { ...oldValues, ...newValues }
        })
      })

      entitiesKeys[pluralize(parentKey)] = { get, set, query }
    })

    return entitiesKeys
  }, [items, grandparents])

  const { loading: grandparentsLoading } = useSyncBatch(entities, true)

  return { batch, grandparentsLoading }
}
