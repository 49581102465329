import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { RiMoneyDollarBoxFill } from 'react-icons/ri'
import I18n from '/src/utils/translations'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import ActionToolbar from '/src/ui/core/toolbar/action_toolbar'

// eslint-disable-next-line max-lines-per-function
export default function EstimateFormButtons({ estimate }) {
  const [approveButtonClicked, setApproveButtonClicked] = useState(false)
  const { status, responseData, fetchAPI, loading } = useFetchAPI('estimates')
  const [showConfirmation, renderConfirmation] = useConfirmationModal()

  useEffect(() => {
    if (loading) dispatch(BusEvents.SHOW_LOADING_DIALOG)
    else dispatch(BusEvents.HIDE_DIALOG)
    if (status === 'SUCCESS') {
      notifySuccess(I18n.t('estimate.estimate_success'))
      dispatch(BusEvents.FORM_FINISHED)
    }
  }, [status, responseData, loading])

  const approveEstimate = () => {
    const params = {
      requestAction: 'UPDATE',
      httpAction: 'put',
      resourceId: estimate.id,
      additionalResource: { path: 'approve' }
    }

    fetchAPI(params)
  }

  const handleApproveClick = () => {
    dispatch(BusEvents.FORM_SUBMIT)
    setApproveButtonClicked(true)
  }

  const showConfirmationModal = () => {
    const confirmationParams = {
      title: I18n.t('estimate.confirmation.title'),
      description: I18n.t('estimate.confirmation.description'),
      actionButtonText: I18n.t('estimate.confirmation.confirm'),
      cancelButtonText: I18n.t('estimate.confirmation.cancel'),
      onSuccess: () => handleApproveClick,
    }

    showConfirmation(confirmationParams)
  }

  useBus(
    BusEvents.FORM_SUBMITTED,
    () => {
      approveButtonClicked ? approveEstimate() : dispatch(BusEvents.FORM_FINISHED)
    }, [approveButtonClicked])

  return (
    <div>
      {renderConfirmation()}
      <ActionToolbar
        className='form-buttons-estimate'
        cancelText={I18n.t('estimate.save_draft')}
        onCancel={() => dispatch(BusEvents.FORM_SUBMIT)}
        actionIcon={<RiMoneyDollarBoxFill />}
        actionText={I18n.t('estimate.accept')}
        onAction={() => showConfirmationModal()}
      />
    </div>
  )
}

EstimateFormButtons.propTypes = {
  estimate: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
}

EstimateFormButtons.defaultProps = {
  estimate: undefined
}
