import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import InputNumeric from './input_numeric'

export default function InputDecimal({ inputProps }) {
  const [project] = useStore('project')

  return <InputNumeric format={`n${project.number_of_digits}`} inputProps={inputProps} />
}

InputDecimal.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired
}
