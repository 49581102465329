import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import UserAvatar from '/src/ui/core/menus/user_avatar'
import Breadcrumb from '/src/ui/core/menus/breadcrumb'
import EnvironmentSwitch from '/src/ui/core/menus/environment_switch'
import TopMenu from '/src/ui/core/header/top_menu'
import '/src/static/css/core/menus/header.css'

export default function Header({ showProjectSwitcher }) {
  const [globalSubproject] = useStore('subproject')

  return (
    <div className="header-bar">
      <div className="header">
        <TopMenu />
        <UserAvatar />
      </div>
      <div
        className="breadcrumb-project-switch"

      >
        <Breadcrumb />
        {showProjectSwitcher && (
          <div className="center-container-header">
            <EnvironmentSwitch />
          </div>
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
showProjectSwitcher: PropTypes.bool
}

Header.defaultProps = {
  showProjectSwitcher: true
}
