import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { objectPop } from '/src/utils/object'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import TrackBlock from '/src/ui/domain/requests/track_block'
import useFetchById from '/src/hooks/api/fetch_by_id'
import FlexibleActionButtons from '/src/ui/core/buttons/flexible_action_buttons'
import '/src/static/css/core/blocks/track_block.css'

// eslint-disable-next-line max-lines-per-function
export default function ProgressServicesTrackBlock({ dataItem, contextMenuItems }) {
  const { contract_service: contractService } = dataItem
  const request = useFetchById('requests', dataItem.request.id)
  const [items, mainItem] = objectPop(contextMenuItems, 'history')
  const showMainItem = mainItem && mainItem.visible(dataItem)

  const buttons = (items && mainItem) ? (
    <FlexibleActionButtons
      mainItem={showMainItem && mainItem}
      dataItem={dataItem}
      items={Object.values(items).map((menuItem) => ({ ...menuItem, dataItem }))}
    />
  ) : null

  return !request ? <ThreeDotsLoader /> : (
    <TrackBlock detail={request} actions={buttons}>
      <div className="progress-track-block-children">
        <div className="summary-item">
          {`${I18n.t('progress_services.service')} ${dataItem.number}`}
        </div>
      </div>
      {contractService && (
        <div>
          <div className="summary-label">
            {I18n.t('progress_services.contract_service')}
          </div>
          <div className="summary-value">
            {contractService.description}
          </div>
        </div>
      )}
    </TrackBlock>
  )
}

ProgressServicesTrackBlock.propTypes = {
  dataItem: PropTypes.shape({
    contract_service: PropTypes.shape({
      description: PropTypes.string,
    }),
    request: PropTypes.shape({
      id: PropTypes.number,
    }),
    number: PropTypes.number,
  }).isRequired,
  contextMenuItems: PropTypes.oneOfType([PropTypes.object])
}

ProgressServicesTrackBlock.defaultProps = {
  contextMenuItems: []
}
