/* eslint-disable import/prefer-default-export */
import { filterVisibleOnWeb as filterColumn } from './eav_column'

export const filterVisibleOnWeb = (sections = []) => {
  return sections.map((section) => {
    const newSection = section
    newSection.eav_columns = section.eav_columns ? filterColumn(section.eav_columns) : []
    return newSection
  })
}
