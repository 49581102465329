import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout'
import SubRequestPanelBarTitle from '/src/ui/domain/sub_requests/sub_request_panel_bar_title'
import SubScopesList from '/src/ui/domain/sub_requests/sub_scopes_list'

// eslint-disable-next-line max-lines-per-function
export default function SubRequestItem({ subRequest, loadData, scopes, templateId }) {
  const [status, setStatus] = useState()

  return (
    <PanelBar>
      <PanelBarItem
        key={subRequest.id}
        title={<SubRequestPanelBarTitle subRequest={subRequest} status={status} />}
        className={status}
      >
        <SubScopesList
          templateId={templateId}
          subRequest={subRequest}
          parentScopes={scopes}
          onApiResponse={setStatus}
          onDataChanged={() => loadData()}
        />
      </PanelBarItem>
    </PanelBar>
  )
}

SubRequestItem.propTypes = {
  subRequest:  PropTypes.oneOfType([PropTypes.object]).isRequired,
  scopes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      quantity: PropTypes.number,
      remaining_quantity: PropTypes.number,
      team_target_hours: PropTypes.number
    })
  ).isRequired,
  loadData: PropTypes.func.isRequired,
  templateId: PropTypes.number
}

SubRequestItem.defaultProps = {
  templateId: null
}
