/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { notifyWarning } from '/src/ui/core/dialogs/notifications'
import InputControlledFormula from './input_controlled_formula'
import I18n from '/src/utils/translations'
import '/src/static/css/inputs.css'


export default function InputFormula({ inputProps })  {
  const {
    className,
    title,
    formula,
    formula_result: value,
  } = inputProps

  useEffect(() => {
    if (formula) return
    const msg = I18n.t('notification.bad_formula_setup', { title })
    notifyWarning({ body: msg, closeTimeout: 10 })
  }, [])

  const inputClass = () => {
    const classes = ['form-input-text']
    if (className) classes.push(className)
    return classes.join(' ')
  }

  const label = <div className="result-label">{I18n.t('form.inputs.formula.result')}</div>
  return (
    <InputControlledFormula
      inputProps={{ ...inputProps, className: inputClass(), label, value }}
    />
  )
}

InputFormula.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    formula: PropTypes.oneOfType([PropTypes.object]),
    control: PropTypes.oneOfType([PropTypes.object]),
    onChange: PropTypes.func,
    parentItems: PropTypes.oneOfType([PropTypes.object]),
    dataItem: PropTypes.oneOfType([PropTypes.object])
  }).isRequired
}
