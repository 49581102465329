import { useState, useEffect } from 'react'
import useSyncBatch from '/src/hooks/api/sync_batch'

/**
 * Fetch the Formulas Services through batch
 * Fetch Formulas Services for the provided template (eavTemplateId)
 */
export default function useFormulasServices(eavTemplateId, shouldFetch) {
  const [getBatch, setBatch] = useState()
  const [formulasServices, setFormulasServices] = useState(null)

  const batchedEntities = {}

  if (shouldFetch) {
    batchedEntities.formulas_services = {
      get: getBatch,
      set: setBatch,
      query: { where: { eav_template_id: eavTemplateId } }
    }
  }

  const { loading:formulasLoading } = useSyncBatch(batchedEntities)

  const getTemplateFormulas = () => {
    const formulasResponse = batchedEntities.formulas_services.get
    const templateFormulas = {}

    if (formulasResponse && Object.keys(formulasResponse).length > 0) {
      Object.values(formulasResponse).forEach((formula) => {
        templateFormulas[formula.eav_template_id] = formula
      })
    }

    return templateFormulas
  }

  // Build formulasServices based on useSyncBatch result
  useEffect(() => {
    if (!shouldFetch) return
    if (formulasLoading) return

    const templateFormulas = getTemplateFormulas()

    setFormulasServices(templateFormulas[eavTemplateId])
  }, [shouldFetch, formulasLoading, eavTemplateId])

  return formulasServices
}
