/* eslint-disable max-lines-per-function */
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import RequestModel from '/src/models/request'
import useFormModal from'/src/ui/core/popups/form_modal'
import useScheduleRequestPopupSections from './schedule_request_popup_sections'

export default function ScheduleRequestPopup({ dataItem }) {
  const [data, setData, sections] = useScheduleRequestPopupSections()
  const model = new RequestModel()
  model.columns = undefined
  const formTitle = I18n.t('requests.schedule_form.title')
  const formSubtitle = I18n.t('requests.schedule_form.subtitle')
  const modalTitle = I18n.t('actions.schedule')
  const buttons = [
    {
      id: 1,
      text: I18n.t('actions.cancel'),
      cancel: true,
      class: 'cancel-button'
    },
    {
      id: 2,
      text: I18n.t('actions.schedule'),
      submit: true,
      class: 'action-button'
    }
  ]

  const dates = {
    request: { start: dataItem.request_start_date, end: dataItem.request_end_date },
    scope: { start: dataItem.scope_start_date, end: dataItem.scope_end_date },
    progress: { start: dataItem.progress_start_date, end: dataItem.progress_end_date }
  }
  const scheduledItem = { ...dataItem, ...dates }

  const filterDataAttributes = (formData) => {
    const allowedAttributes = [ 'request_end_date', 'request_start_date', 'request_responsible_id',
                              'scope_end_date', 'scope_start_date', 'scope_responsible_id',
                              'progress_end_date', 'progress_start_date', 'progress_responsible_id']

    return Object.fromEntries(Object.entries(formData).filter(([key]) => { 
      return allowedAttributes.includes(key)
    }))
  }

  const modalProps = { modalTitle, formTitle, formSubtitle, label: data.id,
    sections, buttons, dataItem: data, model, filterDataAttributes }
  const [openModal, renderModal] = useFormModal(modalProps)

  useEffect(() => { if (Object.values(dataItem).length) setData(scheduledItem) }, [dataItem])

  useEffect(() => { if (Object.values(data).length) openModal() }, [data])

  return renderModal()
}

ScheduleRequestPopup.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
}

ScheduleRequestPopup.defaultProps = {
  dataItem: {},
}
