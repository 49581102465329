import React from 'react'
import { TiVendorMicrosoft } from 'react-icons/ti'
import I18n from '/src/utils/translations.js'

export default function KaeferLoginButton() {
  return (
    <div className="login-kaefer-wrapper">
      <div className="login-kaefer">
        <span className="ms-logo"><TiVendorMicrosoft /></span>
        <span className="continue-kaefer">{I18n.t('login.continue_mykaefer')}</span>
      </div>
    </div>
  )
}
