/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Layout from '/src/ui/core/layouts/layout'
import useModelController from '/src/hooks/model_controller'
import ScopeForm from '/src/ui/domain/scopes/scope_form'
import { useQueryParam, NumberParam, StringParam, BooleanParam } from 'use-query-params'
import { buildBackURL } from '/src/utils/url'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

export default function ScopeController({ match }) {
  const history = useHistory()
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const [itemTemplateId] = useQueryParam('item_template_id', NumberParam)
  const [selectItemId] = useQueryParam('select_item_id', NumberParam)
  const [selectServiceId] = useQueryParam('select_service_id', NumberParam)
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [filterByRequest] = useQueryParam('filtered_by_request', BooleanParam)

  const backURLConfig = () => ({
    eavTemplateId: itemTemplateId || templateId,
    selectItemId,
    selectServiceId,
    requestId: filterByRequest !== undefined ? filterByRequest && requestId : requestId
  })

  const { dataItem } = useModelController({
    modelType: 'scopes',
    dataItemId: match.params.id,
    onBack: () => history.push(`/scopes?${buildBackURL(backURLConfig())}`),
    onFinish: () => history.push(`/scopes?${buildBackURL(backURLConfig())}`),
  })

  useBus(
    BusEvents.FORM_BACK_BUTTON_CLICKED,
    () => {},
    [history]
  )

  const shouldShow = () => {
    switch (match.params.collection) {
      case 'edit':
      case 'duplicate':
        return dataItem !== null
      default:
        return true
    }
  }

  return (
    <Layout key={dataItem} showProjectSwitcher={false}>
      {shouldShow() && (
        <ScopeForm
          dataItem={dataItem}
          templateId={templateId}
          requestId={filterByRequest ? requestId : undefined}
          type={match.params.collection}
        />
      )}
    </Layout>
  )
}

ScopeController.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number
    })
  })
}

ScopeController.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined
    })
  })
}
