import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import RequiredMark from '/src/ui/core/alerts/required_mark'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

// eslint-disable-next-line max-lines-per-function
export default function Field({ column, renderColumn }) {
  const { description, title, required, visible_on_web: visible,
          type, fieldClass, elementBefore, elementAfter } = column
  const shouldHide = (visible === false && !required)
  const noHeightTypes = ['datasheet_filter', 'multiple_datasheet_filter']

  const renderedColumn = renderColumn(column)

  const sectionClasses = classNames({
    'section-field': true,
    'dont-grow': column.dontExpand,
    [column.columnSize]: true,
    [fieldClass]: fieldClass,
    'invisible': shouldHide,
    'column-height': !noHeightTypes.includes(type)
  })

  return renderedColumn !== null && (
    <React.Fragment>
      {elementBefore}
      <div className={sectionClasses}>
        {title && (
          <label className="column-label" htmlFor={description}>
            <CustomTooltip position='auto'>
              <div title={title} className="column-label-title">
                <RequiredMark required={required} />
                {title}
              </div>
            </CustomTooltip>
            {column.subtitle && (
              <div className="column-label-subtitle">
                {column.subtitle}
              </div>
            )}
          </label>
        )}
        {renderedColumn}
      </div>
      {elementAfter}
    </React.Fragment>
  )
}

Field.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    required: PropTypes.bool,
    visible_on_web: PropTypes.bool,
    dontExpand: PropTypes.bool,
    columnSize: PropTypes.string,
    type: PropTypes.string,
    fieldClass: PropTypes.string,
    elementBefore: PropTypes.element,
    elementAfter: PropTypes.element,
  }).isRequired,
  renderColumn: PropTypes.func.isRequired
}
