/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Layout from '/src/ui/core/layouts/layout'
import useModelController from '/src/hooks/model_controller'
import ProgressForm from '/src/ui/domain/progresses/progress_form'
import { useQueryParam, NumberParam } from 'use-query-params'
import { buildBackURL } from '/src/utils/url'

export default function ProgressFormPage({ match }) {
  const history = useHistory()
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [selectItemId] = useQueryParam('select_item_id', NumberParam)
  const [selectServiceId] = useQueryParam('select_service_id', NumberParam)

  const backURLConfig = () => ({
    eavTemplateId: templateId,
    selectItemId,
    selectServiceId,
    requestId
  })

  const { dataItem } = useModelController({
    modelType: 'progresses',
    dataItemId: match.params.id,
    onBack: () => history.push(`/performances?${buildBackURL(backURLConfig())}`),
    onFinish: () => history.push(`/performances?${buildBackURL(backURLConfig())}`),
  })

  const shouldShow = () => {
    switch (match.params.collection) {
      case 'edit':
      case 'duplicate':
        return dataItem !== null
      default:
        return true
    }
  }

  return (
    <Layout key={dataItem} showProjectSwitcher={false}>
      {shouldShow() ? (
        <ProgressForm
          dataItem={dataItem}
          templateId={templateId}
          requestId={requestId}
          type={match.params.collection}
        />
      ) : <React.Fragment />}
    </Layout>
  )
}

ProgressFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number
    })
  })
}

ProgressFormPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined
    })
  })
}
