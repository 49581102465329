// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class InspectionModel {
  name = I18n.t('inspections.title')

  singularName = I18n.t('inspections.description')

  paramName = 'inspection'

  templateType = 'inspection'

  route = 'inspections'

  flexible = true

  staticColumns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('inspections.id'),
      hideOnGrid: true,
      hideOnForm: true,
      width: 120,
      type: 'integer'
    },
    {
      id: 2,
      description: 'request_id',
      title: I18n.t('requests.id'),
      hideOnForm: true,
      width: 150,
      type: 'integer'
    },
    {
      id: 3,
      description: 'request',
      title: I18n.t('requests.id'),
      foreignKey: 'requests',
      foreignAttribute: 'request_id',
      hideOnGrid: true,
      orderOnForm: 1,
      required: true,
      searchFields: ['id', 'reason', 'comments'],
      searchExtraQuery: { where: {
        '[request_statuses][i18n_id]': [
          'registered',
          'confirmed',
          'in_scoping',
          'authorization_pending',
          'in_planning',
          'in_performance',
          'on_hold',
          'done'
        ]
      } },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'comments'],
      textField: 'reason',
      type: 'search'
    },
    {
      id: 4,
      description: 'inspect_status',
      title: I18n.t('inspections.inspect_status_id'),
      sortable: false,
      filterable: true,
      hideOnForm: true,
      width: 200,
      type: 'text'
    },
    {
      id: 5,
      description: 'inspect_date',
      orderOnForm: 3,
      title: I18n.t('inspections.inspect_date'),
      width: 200,
      type: 'date'
    },
    {
      id: 6,
      description: 'number',
      title: '#',
      hideOnForm: true,
      width: 100,
      type: 'string'
    },
    {
      id: 7,
      description: 'reason',
      queryField: 'request.reason',
      title: I18n.t('requests.reason'),
      hideOnForm: true,
      width: 200,
      type: 'string'
    },
    {
      id: 8,
      description: 'comments',
      queryField: 'request.comments',
      title: I18n.t('requests.comments'),
      hideOnForm: true,
      width: 200,
      type: 'string'
    },
    {
      id: 10,
      description: 'responsible',
      field: 'responsible.full_name',
      onSortField: 'responsible_id',
      title: I18n.t('inspections.responsible_id'),
      foreignKey: 'employees',
      foreignAttribute: 'responsible_id',
      required: true,
      filterable: true,
      orderOnForm: 4,
      searchFields: ['name', 'last_name'],
      searchExtraQuery: {
        where: { '[work_positions][operational]': 1, is_inspection_responsible: 1 }
      },
      searchRoute: 'employees',
      textDisplayFields: ['full_name'],
      type: 'search'
    },
    {
      id: 11,
      description: 'inspected_type',
      title: I18n.t('inspections.module'),
      orderOnForm: 2,
      filterable: false,
      width: 200,
      type: 'string'
    },
    {
      id: 12,
      description: 'recurrency_uuid',
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'string'
    },
    {
      id: 13,
      description: 'eav_template_id',
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'integer'
    }
  ]

  constructor(inspectedFormatter) {
    this.columns = this.staticColumns
    const inspectedType = this.columns.find((e) => e.description === 'inspected_type')
    inspectedType.formatter = inspectedFormatter
  }
}
