import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/buttons/dotted_button.css'

export default function DottedButton({ className, title, onClick, icon }) {
  return (
    <button 
      type="button"
      className={className || 'dotted-button'}
      onClick={onClick}
    >
      <div className='content'>
        {icon}
        <div className='dotted-button-title'>
          {title}
        </div>
      </div>
    </button>
  )
}

DottedButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.element
}

DottedButton.defaultProps = {
  className: undefined,
  onClick: () => {},
  icon: undefined
}
