import { useCallback, useEffect, useState } from 'react'
import useSyncBatch from '/src/hooks/api/sync_batch'
import useFetchAPI from '/src/hooks/api/fetch_api'

export default function useSearchDatasheetFilters(templateId) {
  const [datasheetFilters, setDatasheetFilters] = useState({})
  const [datasheetColumns, setDatasheetColumns] = useState({})

  const queryFilters =  { where: { eav_columns: { eav_template_id: templateId } } }
  const batchedEntities = {
    eav_datasheet_filters: {
      get: datasheetFilters,
      set: setDatasheetFilters,
      key: 'eav_column_id',
      query: queryFilters
    }
  }

  useSyncBatch(batchedEntities)

  const { status, responseData, fetchAPI } = useFetchAPI('eav_columns')

  useEffect(() => {
    if (!datasheetFilters || Object.values(datasheetFilters).length === 0) return

    const queryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: Object.values(datasheetFilters).map((filter) => filter.datasheet_column_id) } }
    }

    fetchAPI(queryParams)
  }, [datasheetFilters])


  useEffect(() => {
    if (status !== 'SUCCESS') return
    const eavColumns = responseData.data
    const newDatasheetColumns = {}
    eavColumns.forEach((value) => newDatasheetColumns[value.id] = value)
    setDatasheetColumns(newDatasheetColumns)
  }, [status, responseData])

  const isDatasheetFilterSingle = useCallback((column) => {
    if (!datasheetFilters[column.id]) return null

    const datasheetTemplateId = datasheetFilters[column.id].datasheet_template_id
    const sameTemplate = Object.values(datasheetFilters).filter(
      (filter) =>
        filter.eav_column_id !== column.id && 
        filter.datasheet_template_id === datasheetTemplateId
    )
    const sameTag = Object.values(datasheetFilters).filter(
      (c) => c.id !== column.id && c.metadata === column.metadata
    )

    return sameTemplate.length === 0 || sameTag.length === 0
  }, [datasheetFilters])

  return [datasheetFilters, datasheetColumns, isDatasheetFilterSingle]
}
