import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import ProgressesTrackBlock from './progresses_track_block'
import '/src/static/css/model_side_panel.css'

export default function ProgressesSidePanel({ dataItem, onClose, sections,
                                              contextMenuItems, sidePanelFields  }) {
  const [blocks, setBlocks] = useState()
  const sectionBlocks = useSectionBlock({ sections, dataItem })

  useEffect(() => {
    if (!dataItem) onClose()
    else if (!dataItem.request_id) setBlocks([...sectionBlocks])
    else setBlocks([buildSummary(), ...sectionBlocks])
  }, [dataItem, sidePanelFields])

  const buildSummary = () => ({
    component: (
      <ProgressesTrackBlock
        dataItem={dataItem}
        contextMenuItems={contextMenuItems}
        fields={sidePanelFields}
      />
    ),
    title: '',
    type: 'track',
    key: 'scope-track-block'
  })

  return (
    <div className="model-side-panel">
      {dataItem && (
        <BlocksSidePanel
          title={I18n.t('progresses.progresses_detail.side_panel_title')}
          closable
          onClose={onClose}
          blocks={blocks}
        />
      )}
    </div>
  )
}

ProgressesSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.shape({
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    cancel: PropTypes.oneOfType([PropTypes.object]).isRequired,
  }).isRequired,
  sidePanelFields: PropTypes.oneOfType([PropTypes.object])
}

ProgressesSidePanel.defaultProps = {
  dataItem: undefined
}
