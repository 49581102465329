import { useDeleteStoreValue } from 'react-context-hook'
import useCookie from '/src/hooks/cookie'
import useClearBatchEntities from '/src/hooks/clear_batch_entities'

export default function useLogout() {
  const removeUser = useDeleteStoreValue('user')
  const removeUserRole = useDeleteStoreValue('user_role')
  const [, ,removeCookie] = useCookie('authentication_token')
  const clearBatchEntities = useClearBatchEntities()

  return () => {
    removeCookie()
    removeUser()
    removeUserRole()
    clearBatchEntities()
  }
}
