import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import Section from '/src/ui/core/layouts/section'
import FieldsGroup from '/src/ui/core/layouts/fields_group'
import '/src/static/css/core/layouts/sectioned_panel.css'

export default function SectionedPanel({ sections, enumerable, hideable,
                                         renderColumn, sectionable, display }) {

  const [sectionComponents, setSectionComponents] = useState(<ThreeDotsLoader />)

  const isSectionVisible = (columns) => {
    const visibleColumns = columns.filter((column) => {
      if (column.type === 'datasheet_filter')
        return column.filters.some((f) => f.visible_on_web)
      if (column.column_type && column.column_type.description &&
          (column.column_type.description === 'drop_formula_id' ||
          column.column_type.description === 'drop_formula_variables'))
        return false
      return column.required || column.visible_on_web
    })

    return visibleColumns.length > 0
  }

  const renderSection = (section, index) => {
    const columns = section.eav_columns || section.columns
    const body = <FieldsGroup columns={columns} renderColumn={renderColumn} key={section.id} />

    const shouldDisplay = display || isSectionVisible(columns)

    dispatch({
      type: BusEvents.FORM_SECTION_DISPLAY_UPDATE,
      payload: { ...section, shouldDisplay }
    })

    return (
      <Section
        index={section.index || index + 2}
        title={section.title}
        finished={section.finished}
        body={body}
        enumerable={enumerable}
        hideable={hideable}
        sectionable={sectionable}
        display={shouldDisplay}
        id={section.id}
        key={section.id}
      />
    )
  }

  useEffect(() => {
    if (sections.length === 0) return
    const sortedSections = sections.sort((sec1, sec2) => sec1.position - sec2.position)
    setSectionComponents(sortedSections.map((section, index) => renderSection(section, index)))
  }, [sections])

  return (
    <React.Fragment>
      {sectionComponents}
    </React.Fragment>
  )
}

SectionedPanel.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string
  })).isRequired,
  enumerable: PropTypes.bool,
  hideable: PropTypes.bool,
  sectionable: PropTypes.bool,
  renderColumn: PropTypes.func,
  display: PropTypes.bool
}

SectionedPanel.defaultProps = {
  sectionable: true,
  enumerable: true,
  hideable: true,
  display: undefined
}
