import React from 'react'
import { useStore } from 'react-context-hook'
import { MdPrint } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { formatCurrency, formatNumberWithPrecision } from '/src/utils/project_formatter'
import ContentLoader from '/src/ui/core/loaders/content_loader'
import '/src/static/css/domain/estimates/estimate.css'

export default function EstimateSidePanel({ estimate }) {
  const [project] = useStore('project')

  const getLoader = () => <ContentLoader width='20%' height={15} />

  const toggleMonetaryValueAndLoader = (key) => {
    let currencyValue = 0

    if(!estimate) return getLoader()

    if(estimate[key]) currencyValue = estimate[key]
    return formatCurrency(currencyValue, project)
  }

  const toggleHourValueAndLoader = (key) => {
    let timeValue = 0

    if(!estimate) return getLoader()

    if(estimate[key]) timeValue = estimate[key]
    return `${formatNumberWithPrecision(timeValue, project)} h`
  }

  const totalPrice = () => {
    let price = 0

    if(!estimate) return <ContentLoader width='40%' height={15} />

    if (estimate.estimate_cost) price = estimate.estimate_cost
    return formatCurrency(price, project)
  }

  return (
    <div className="estimate-side-panel">
      <div className="header">
        <div className="title">{I18n.t('estimate.estimate_details')}</div>
        <div className="print-icon"><MdPrint /></div>
      </div>
      <div className="body">
        <div className="price-items">
          <div className="description">{I18n.t('estimate.norm_hours')}</div>
          <div className="price">
            {toggleHourValueAndLoader('total_norm_hours')}
          </div>
        </div>
        <div className="price-items">
          <div className="description">{I18n.t('estimate.team_target_hours')}</div>
          <div className="price">
            {toggleHourValueAndLoader('total_team_target_hours')}
          </div>
        </div>
        <div className="price-items">
          <div className="description">{I18n.t('estimate.budget_hours')}</div>
          <div className="price">
            {toggleHourValueAndLoader('total_budget_target_hours')}
          </div>
        </div>
        <div className="price-items">
          <div className="description">{I18n.t('estimate.application')}</div>
          <div className="price">
            {toggleMonetaryValueAndLoader('total_application_price')}
          </div>
        </div>
        <div className="price-items">
          <div className="description">{I18n.t('estimate.material')}</div>
          <div className="price">
            {toggleMonetaryValueAndLoader('total_materials_price')}
          </div>
        </div>
        <div className="price-items">
          <div className="description">{I18n.t('estimate.equipment')}</div>
          <div className="price">
            {toggleMonetaryValueAndLoader('total_equipment_price')}
          </div>
        </div>
        <div className="price-items">
          <div className="description">{I18n.t('estimate.others')}</div>
          <div className="price">
            {toggleMonetaryValueAndLoader('total_other_price')}
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="description">{I18n.t('estimate.total_request')}</div>
        <div className="total-price">
          {totalPrice()}
        </div>
      </div>
    </div>
  )
}

EstimateSidePanel.propTypes = {
  estimate: PropTypes.shape({
    total_application_price: PropTypes.number,
    total_budget_target_hours: PropTypes.number,
    total_equipment_price: PropTypes.number,
    total_materials_price: PropTypes.number,
    total_norm_hours: PropTypes.number,
    total_other_price: PropTypes.number,
    total_team_target_hours: PropTypes.number,
  })
}

EstimateSidePanel.defaultProps = {
  estimate: undefined
}
