import React from 'react'
import PropTypes from 'prop-types'
import { MdPersonOutline, MdInput, MdInfoOutline } from 'react-icons/md'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { useHistory } from 'react-router-dom'

export default function UserMenu({ user, logout, onVersionClick }) {
  const history = useHistory()
  
  return (
    <div className="user-menu-wrapper">
      <div className="user-info">
        <div className="username">
          <CustomTooltip maxLength={25}>{user.name}</CustomTooltip>
        </div>
        <div className="user-email">
          <CustomTooltip maxLength={25}>{user.email}</CustomTooltip>
        </div>
      </div>
      <div className="user-menu-list">
        <button type="button" onClick={() => history.push(`/users/${user.id}/edit`)} className="user-menu-item">
          <MdPersonOutline />
          {I18n.t('user_menu.profile')}
        </button>
        <button
          type="button"
          className="user-menu-item"
          data-testid="user-about-version-item"
          onClick={() => onVersionClick(true)}
        >
          <MdInfoOutline />
          {I18n.t('user_menu.about_version')}
        </button>
        <button
          type="button"
          className="user-menu-item"
          data-testid="user-menu-item"
          onClick={() => logout()}
        >
          <MdInput />
          {I18n.t('user_menu.logout')}
        </button>
      </div>
    </div>
  )
}

UserMenu.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number
  }),
  logout: PropTypes.func.isRequired,
  onVersionClick: PropTypes.func.isRequired
}

UserMenu.defaultProps = {
  user: { name: '', email: '', id: null }
}
