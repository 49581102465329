import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import PlanningChart from './planning_chart'
import PlanningChartBlockBullet from './planning_chart_block_bullet'
import '/src/static/css/core/grid/grid.css'

export default function PlanningChartBlock({ dataItem, project }) {
  const unit = dataItem.contract_service.unit.description
  const targetQuantity = formatNumberWithPrecision(dataItem.quantity, project)
  const actualQuantity = formatNumberWithPrecision(
    dataItem.progress_service_summary.actual_quantity,
    project
  )

  return (
    <div className="planning-chart-block">
      <div className="bullets-group">
        <PlanningChartBlockBullet
          name={I18n.t('progress_services.planning_chart.actual')}
          key={I18n.t('progress_services.planning_chart.actual')}
          value={actualQuantity}
          iconClassName="green-dot"
          nameAppend={unit}
        />
        <PlanningChartBlockBullet
          name={I18n.t('progress_services.planning_chart.target')}
          key={I18n.t('progress_services.planning_chart.target')}
          value={targetQuantity}
          iconClassName="blue-dot"
          nameAppend={unit}
        />
      </div>
      <PlanningChart dataItem={dataItem} unit={unit} project={project} />
    </div>
  )
}

PlanningChartBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  project: PropTypes.oneOfType([PropTypes.object])
}

PlanningChartBlock.defaultProps = {
  project: {}
}
