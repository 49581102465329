/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { GridColumn as Column } from '@progress/kendo-react-grid'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFieldSettings from '/src/hooks/field_settings'
import useFormulasServices from '/src/hooks/formulas_services'
import { eavColumnToKendoType } from '/src/models/concerns/eav_column'
import I18n from '/src/utils/translations'
import usePaging from '/src/ui/core/grid/paging_hook'
import useFiltering from '/src/ui/core/grid/filtering_hook'
import useSorting from '/src/ui/core/grid/sorting_hook'
import useRowsSelection from '/src/ui/core/grid/rows_selection_hook'
import CellContentLoader from '/src/ui/core/grid/cell_content_loader'
import CustomizeCell from '/src/ui/core/grid/customize_cell'
import ColumnFooterTitle from '/src/ui/core/grid/column_footer_title'
import ColumnFooterCell from '/src/ui/core/grid/column_footer_cell'
import SimpleGridHeader from '/src/ui/core/grid/simple_grid_header'
import SimpleGridLabels from '/src/ui/core/grid/simple_grid_labels'
import SimpleGridContent from '/src/ui/core/grid/simple_grid_content'
import { ColumnMenu } from '/src/ui/core/grid/column_menu'
import GridRowContextMenu from '/src/ui/core/grid/grid_row_context_menu'
import useGridCRUD from '/src/ui/core/grid/grid_hook'
import useGridSettings from '/src/ui/core/grid/grid_settings'
import { fieldWidthByType } from '/src/utils/constants/fields'
import { getGridPageSize, setGridPageSize } from '/src/utils/store/grid_page_size'
import useColumnOrder from '/src/ui/core/grid/column_order'
import '/src/static/css/core/grid/grid.css'

// eslint-disable-next-line max-lines-per-function
export default function SimpleGrid({ model, pageSize, filter, sortable, sort, columnCellFactory,
                                     templateId, loadFlexColumns, contextMenuItems, gridTitle,
                                     icons, labels, selectedItem, selecting, onRowClick,
                                     selectFiltering, onFilterUpdate, additionalEntities,
                                     onRowRender, onDataSource, onGridColumns, animatePopup }) {

  const [formattedGridColumns, setFormattedGridColumns] = useState()
  const [dataSource, setDataSource] = useState({ data: [], total: 0 })
  const [selectedId, setSelectedId] = useState(0)
  const [actualPageSize, setActualPageSize] = useState(getGridPageSize() || pageSize)
  const scrollPosition = useRef()

  const {
    getColumnsWithUpdatedSettings,
    setHiddenColumns,
    setColumnWidth,
    setColumnsOrder,
    getSorting,
    setSorting
  } = useGridSettings({ templateId })

  const [status, setStatus] = useState('loading')
  const { paging, onPageChange } = usePaging(actualPageSize)
  const {
    filtering,
    onFilterChange,
    hasGridFilters,
    clearColumnFilter,
    dynamicFilters
  } = useFiltering(filter, onFilterUpdate, dataSource.total, templateId)
  const initialSorting = getSorting() || sort
  const { sorting, onSortChange } = useSorting(initialSorting)
  const [columnOrder, onColumnReorder] = useColumnOrder()
  const { onSelectChange, getHeaderSelectionColumn } = useRowsSelection(setDataSource)

  const useGridProps = { model, paging, filtering, sorting, templateId,
                         flexible: loadFlexColumns, additionalEntities }
  const { loading, responseData, flexibleColumnsData, errors, read } = useGridCRUD(useGridProps)
  const [gridColumns, setGridColumns] = useState(getColumnsWithUpdatedSettings([...model.columns, ...flexibleColumnsData]))

  const formulasServices = useFormulasServices(templateId, true)
  const fieldSettings = useFieldSettings(templateId, ['hide_on_grid'])

  const gridWrapper = useRef(null)

  // Removes the row select when the side panel is closed
  useBus(BusEvents.SIDE_PANEL_CLOSED, () => { setSelectedId(0) }, [setSelectedId])

  // Read data again when BusEvent.RELOAD_GRID is triggered
  useBus(BusEvents.RELOAD_GRID, () => read(), [read])

  useEffect(() => {
    setSorting(sorting)
  }, [sorting])

  // sets data source as soon as the grid data is returned from the server
  useEffect(() => {
    if (loading || loading === undefined || errors) return
    setDataSource(responseData)

    if (hasGridFilters() || responseData.data.length > 0) {
      setStatus('loaded')
      return
    }

    if (!templateId) {
      setStatus('noTemplate')
      return
    }

    setStatus('empty')
  }, [loading, responseData, dataSource])

  // embeds flexible column on grid columns array as soon as the
  // flexible columns data is returned from the server
  useEffect(() => {
    if (loadFlexColumns && flexibleColumnsData.length > 0) {
      setGridColumns(getColumnsWithUpdatedSettings([...model.columns, ...flexibleColumnsData]))
    }
  }, [flexibleColumnsData])

  // Recover scroll position
  useEffect(() => {
    if (selectedId) return

    const contentDiv = document.getElementsByClassName('k-grid-content')[0]

    if (contentDiv && contentDiv.scrollTo && scrollPosition.current)
      contentDiv.scrollTo(contentDiv.scrollLeft, scrollPosition.current)
  }, [selectedId])

  // creates columns components based on the grid data and flexible columns
  useEffect(() => {
    if (!gridColumns) return
    let formattedColumns = []
    formattedColumns = addActionsColumn(formattedColumns)
    formattedColumns = addModelColumns(formattedColumns)
    formattedColumns = addSelectionColumns(formattedColumns)
    formattedColumns = addFooterColumns(formattedColumns)

    setFormattedGridColumns(formattedColumns)
    if (onDataSource) onDataSource({ ...dataSource, loading })
    if (onGridColumns) onGridColumns(gridColumns)
  }, [gridColumns, dataSource, loading, formulasServices, onRowClick])

  useEffect(() => {
    setSelectedId(selectedItem ? selectedItem.id : undefined)
  }, [selectedItem])

  const addActionsColumn = (formattedColumns) => {
    if (!contextMenuItems) return formattedColumns
    const actionsColumn = [<Column key='action' field='' width={60} sortable={false} />]
    if (['progress', 'progress_service'].includes(model.paramName))
      actionsColumn.push(<Column key='action' field='' width={60} sortable={false} />)
    formattedColumns.splice(0, 0, [...actionsColumn])
    return formattedColumns
  }

  const saveScrollPosition = () => {
    const position = document.getElementsByClassName('k-grid-content')[0].scrollTop
    scrollPosition.current = position
  }

  const resetScrollPosition = () => {
    const topContentDiv = document.getElementsByClassName('k-grid-content')[0]
    const subContentDiv = document.getElementsByClassName('k-grid-content')[1]

    if (topContentDiv && topContentDiv.scrollTo) topContentDiv.scrollTo(0, 0)
    if (subContentDiv && subContentDiv.scrollTo) subContentDiv.scrollTo(0, 0)
  }

  const onColumnsSubmit = (columnsState) => {
    setHiddenColumns(columnsState)

    const updatedColumns = getColumnsWithUpdatedSettings(columnsState)
    setGridColumns(updatedColumns)
  }

  const onColumnResize = (event) => {
    const { end, index, newWidth } = event
    if (!end) return

    const orderedColumns = event.columns.sort((a, b) => { return a.orderIndex - b.orderIndex })
    const columnField = orderedColumns[index].field

    const column = gridColumns.find((col) => col.field === columnField || col.description === columnField)

    setColumnWidth(column.description, newWidth)
    setGridColumns((old) => getColumnsWithUpdatedSettings(old))
  }

  const onChangePageSize = (size) => {
    setGridPageSize(size)
    setActualPageSize(size)
    resetScrollPosition()
  }

  const addModelColumns = (formattedColumns) => {
    const pickColumns = []
    gridColumns.forEach((column, index) => {
      const setting = fieldSettings[column.foreignAttribute] || fieldSettings[column.description]
      const columnVisible = formulasServices ? formulasServices[`${column.description}_visible`] : true
      const notVisible = columnVisible !== undefined ? !columnVisible : false

      if (notVisible || column.hideOnGrid || (setting && setting.hide_on_grid)) return
      pickColumns.push(buildColumnComponent(column, index))
    })

    formattedColumns.push(pickColumns)
    return formattedColumns
  }

  const addSelectionColumns = (formattedColumns) => {
    if (!selecting) return formattedColumns

    const i = contextMenuItems ? 1 : 0
    const selectionColumn = getHeaderSelectionColumn(dataSource)

    formattedColumns.splice(i, 0, selectionColumn)
    return formattedColumns
  }

  const addFooterColumns = (formattedColumns) => {
    if (!model.footer) return formattedColumns

    const i = contextMenuItems ? 1 : 0
    const selectedCount = dataSource.data.filter((dataItem) => dataItem.row_selected).length
    const totalColumn = (
      <Column
        key='selectedCount'
        field=''
        width={1}
        sortable={selecting}
        footerCell={() => <ColumnFooterTitle selected={selectedCount} />}
      />
    )

    formattedColumns.splice(i, 0, totalColumn)
    return formattedColumns
  }

  const onGridRowClick = (e) => {
    if (loading) return

    if (!selectedId) saveScrollPosition()

    if (onRowClick) onRowClick(e)
    else setSelectedId((prevSelectedId) => (
      prevSelectedId === e.dataItem.id ? undefined : e.dataItem.id
    ))
  }

  const customizedCell = (column) => {
    const cellContendLoader = () => <CellContentLoader />
    const customizeCell = (props) => (
      <CustomizeCell
        cell={props}
        column={column}
        columnCellFactory={columnCellFactory}
        columns={gridColumns}
        onClick={onGridRowClick}
      />
    )

    return loading ? cellContendLoader : customizeCell
  }

  const buildColumnComponent = (column, index) => {
    // TODO: This is a PROVISORY SOLUTION.
    // we must refactor the codebase to have a SINGLE POSSIBLE VALUE to
    // the <Column component field state.
    const field = column.field || column.description

    // TODO: This is necessary just to disable the "Is not equal" filter operator
    // in a date column with datetime values. This feature will be fully implemented
    // in a future user story
    const columnType = (column.column_type) ? column.column_type.description : column.type
    const columnIsDateTime = columnType === 'datetime'

    return (
      !column.hide && (
        <Column
          key={column.description}
          field={field}
          title={column.title}
          sortable={column.sortable}
          orderIndex={column.orderIndex || index + 1}
          width={column.width || fieldWidthByType(columnType)}
          cell={customizedCell(column)}
          filter={eavColumnToKendoType(column)}
          columnMenu={column.filterable === false ? undefined : (props) => (
            <ColumnMenu
              {...props}
              flexible={column.flexible}
              columnIsDateTime={columnIsDateTime}
              columns={gridColumns}
              onColumnsSubmit={(columnsState) => onColumnsSubmit(columnsState)}
            />
          )}
          footerCell={column.footer ? () => (
            <ColumnFooterCell
              column={column.description}
              filtered={selecting}
              data={dataSource.data}
            />
          ) : null}
        />
      )
    )
  }

  const rowRender = (trElement, dataItem) => {
    let props
    if (onRowRender) props = onRowRender(trElement, dataItem, onGridRowClick)
    const trProps = { ...trElement.props,  ...props }

    if (contextMenuItems) {
      const gridRowMenu = (
        <GridRowContextMenu
          key={`${dataItem.dataItem.id}-context-menu`}
          dataItem={dataItem.dataItem}
          data={dataSource.data}
          items={contextMenuItems}
        />
      )

      trElement.props.children.splice(0, 1, gridRowMenu)
    }

    if (selecting) {
      if (selectedId === dataItem.dataItem.id) {
        trProps.style = {
          backgroundColor: '#EEF7FF',
          border: '2px solid #2474E8',
          color: '#2474E8'
        }
      }
    }

    return React.cloneElement(trElement, { ...trProps }, trElement.props.children)
  }

  return (
    <div
      className='entity-grid-wrapper'
      ref={gridWrapper}
    >
      <SimpleGridHeader
        key='gridHeader'
        page={Math.ceil((paging.skip + paging.pageSize) / paging.pageSize)}
        take={paging.pageSize}
        total={Math.ceil(dataSource.total / paging.pageSize)}
        gridTitle={gridTitle || I18n.t('grid.all')}
        icons={icons}
        itemsQuantity={dataSource.total}
        onPageChange={(event, offset) => {
          onPageChange(event, offset)
          resetScrollPosition()
        }}
        onChangePageSize={onChangePageSize}
      >
        <SimpleGridLabels
          key='gridLabels'
          gridColumns={gridColumns}
          labels={labels}
          dynamicFilters={dynamicFilters}
          clearColumnFilter={clearColumnFilter}
        />
      </SimpleGridHeader>
      <SimpleGridContent
        status={status}
        model={model}
        animatePopup={animatePopup}
        gridWrapper={gridWrapper}
        selectedItem={selectedItem}
        selectedId={selectedId}
        selectFiltering={selectFiltering}
        dataSource={dataSource}
        gridColumns={gridColumns}
        formattedGridColumns={formattedGridColumns}
        rowRender={rowRender}
        dynamicFilters={dynamicFilters}
        onFilterChange={(e) => onFilterChange(e, gridColumns)}
        sortable={sortable}
        sorting={sorting}
        onColumnReorder={(e) => {
          const reorderedColumns = onColumnReorder(e, gridColumns)
          setColumnsOrder(reorderedColumns)
          setGridColumns(reorderedColumns)
        }}
        onSortChange={(e) => onSortChange(e, gridColumns)}
        onColumnResize={onColumnResize}
        onGridRowClick={onGridRowClick}
        onSelectionChange={(e) => onSelectChange(e, dataSource)}
      />
    </div>
  )
}

SimpleGrid.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageSize: PropTypes.number,
  filter: PropTypes.arrayOf(PropTypes.object),
  sortable: PropTypes.bool,
  sort: PropTypes.arrayOf(PropTypes.object),
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  selecting: PropTypes.bool,
  loadFlexColumns: PropTypes.bool,
  columnCellFactory: PropTypes.element,
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ),
  gridTitle: PropTypes.string,
  templateId: PropTypes.number,
  icons: PropTypes.oneOfType([PropTypes.array]),
  labels: PropTypes.oneOfType([PropTypes.array]),
  onRowClick: PropTypes.func,
  onRowRender: PropTypes.func,
  onDataSource: PropTypes.func,
  onGridColumns: PropTypes.func,
  selectFiltering: PropTypes.bool,
  onFilterUpdate: PropTypes.func,
  additionalEntities: PropTypes.arrayOf(PropTypes.shape({
    foreignKey: PropTypes.string.isRequired,
    query: PropTypes.string,
  })),
  animatePopup: PropTypes.bool
}

SimpleGrid.defaultProps = {
  pageSize: 30,
  filter: [],
  sortable: true,
  selectedItem: undefined,
  selecting: false,
  onFilterUpdate: undefined,
  loadFlexColumns: false,
  sort: [],
  columnCellFactory: null,
  contextMenuItems: null,
  gridTitle: undefined,
  templateId: undefined,
  icons: ['more'],
  labels: [],
  onRowClick: undefined,
  onRowRender: undefined,
  onDataSource: undefined,
  onGridColumns: undefined,
  selectFiltering: true,
  additionalEntities: [],
  animatePopup: true
}
