import React, { useRef, useState, useEffect } from 'react'
import useFetchAPI, { MAX_PAGE_SIZE } from '/src/hooks/api/fetch_api'
import { MdTraffic, MdCheckCircle, MdClose } from 'react-icons/md'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

const STATUS_BLACKLIST = ['confirmed', 'in_planning', 'deleting', 'on_hold']

export default function RequestStatusFilterIcon({ filter, onFilter }){
  const [popupOpened, setPopupOpened] = useState(false)
  const [subRequestFilter, setSubRequestFilter] = useState(false)
  const [statuses, setStatuses] = useState([])

  const getStatuses = useFetchAPI(`request_statuses`)

  const popupButtonRef = useRef(null)

  useEffect(() => {
    const statusesQueryParams = {
      httpAction: 'get',
      dataOptions: {
        paging: { skip: 0, pageSize: MAX_PAGE_SIZE }
      }
    }
    getStatuses.fetchAPI(statusesQueryParams)
  }, [])

  useEffect(() => {
    if (getStatuses.loading || getStatuses.errors || getStatuses.responseData.data.length == 0)
      return

    const statusList = getStatuses.responseData.data.map((s) => { return { ...s, filtered: false } })

    setStatuses(statusList)
  }, [getStatuses.loading, getStatuses.errors, getStatuses.responseData])

  const requestIdFiltered = () => {
    return filter && filter.length > 0 && filter.find((x) => x.column == 'id' && x.operator == 'eq')
  }

  const findFilter = (selectedStatus, status) => {
    if (selectedStatus.id === status.id) return true
    if (selectedStatus.i18n_id === 'confirmed' && status.i18n_id === 'in_scoping') return true
    if (selectedStatus.i18n_id === 'in_scoping' && status.i18n_id === 'confirmed') return true
    if (selectedStatus.i18n_id === 'in_planning' && status.i18n_id === 'in_performance') return true
    if (selectedStatus.i18n_id === 'in_performance' && status.i18n_id === 'in_planning') return true
    return false
  }

  const changeFilter = (status) => {
    if (!popupOpened) return

    setPopupOpened(false)

    const newStatuses = statuses.map((x) => x)

    const { filtered } = status
    newStatuses.filter((s) => findFilter(status, s)).forEach((s) => s.filtered = !filtered)

    const filters = statuses.filter((x) => x.filtered).map((s) => s.id)

    if (!filters) return

    const filterObject = { type: 'where_or', column: 'request_status_id', operator: 'eq', value: filters }
    let newFilter = []
    newFilter = removeFilter(filterObject)
    newFilter = filters.length > 0 ? addFilter(filterObject, newFilter) : newFilter
    onFilter(newFilter)
    setStatuses(newStatuses)
  }

  const changeSubRequestFilter = () => {
    if (!popupOpened) return

    setPopupOpened(false)

    if (!filter) return

    const filterObject = { type: 'where_or', column: 'parent_id', operator: 'eq', value: 'NOT_NULL' }
    const newFilter = subRequestFilter ? removeFilter(filterObject) : addFilter(filterObject)

    onFilter(newFilter)
    setSubRequestFilter(!subRequestFilter)
  }

  const removeFilter = ({ column, operator }) => {
    return filter.filter((x) => x.column != column || x.operator != operator)
  }

  const addFilter = (filterObject, source = filter) => {
    const newFilter = source.map((x) => x)
    newFilter.push(filterObject)
    return newFilter
  }

  const clearFilters = () => {
    setPopupOpened(false)
    setStatuses(statuses.map((s) => { return { ...s, filtered: false }}))
    onFilter([])
    setSubRequestFilter(false)
  }

  const allowedStatuses = statuses.filter((s) => !STATUS_BLACKLIST.includes(s.i18n_id))

  const description = (status) => {
    const statusTitle = I18n.t(`requests.statuses.${status.i18n_id}`)
    if (statusTitle.length <= 14) return statusTitle
    return `${statusTitle.substring(0, 14)}...`
  }

  const popupBody = (
    <React.Fragment>
      <div id="popup-request-filter" className="popup-request-filter">
        {allowedStatuses.map((status) => {
          const i18n = status.i18n_id || ''
          return (
            <div key={status.id}>
              <span
                id={`request-status-label-${i18n.replace('_', '-')}`}
                className={`label workflow-status ${i18n.replace('_', '-')}`}
                onClick={() => changeFilter(status)}
              >
                {description(status)}
                {status.filtered && <MdCheckCircle />}
              </span>
            </div>
          )
        })}
        <div>
          <span
            id="request-status-label-sub-request"
            className="label workflow-status sub-request"
            onClick={() => changeSubRequestFilter()}
          >
            {I18n.t(`requests.sub_request`)}
            {subRequestFilter && <MdCheckCircle />}
          </span>
        </div>
      </div>
      <div className="popup-line" />
      <button
        id="clear-filters"
        className="clear-filters"
        type="button"
        onClick={() => clearFilters()}
      >
        <MdClose />
        {I18n.t(`grid.clear_filters`)}
      </button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      {!requestIdFiltered() && (
        <div>
          <PopupAnchored
            body={popupBody}
            popupButtonRef={popupButtonRef}
            forceOpen={popupOpened}
            setForceOpen={setPopupOpened}
            onClick={() => setPopupOpened(!popupOpened)}
          >
            <button
              id="open-filter-popup-button"
              type="button"
              className={popupOpened ? 'btn-icon active': 'btn-icon'}
              ref={popupButtonRef}
            >
              <MdTraffic />
            </button>
          </PopupAnchored>
        </div>
      )}
    </React.Fragment>
  )
}

RequestStatusFilterIcon.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFilter: PropTypes.func.isRequired
}

RequestStatusFilterIcon.defaultProps = {
  filter: [],
  onFilter: () => {},
}
