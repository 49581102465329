import { isPresent } from '/src/utils/refinements'

const inspectedFormatter = (inspected, type) => {
  if (isPresent(inspected.number) && isPresent(inspected.eav_template) && isPresent(type)) {
    return `#${inspected.number} - ${type} - ${inspected.eav_template.title}`
  }
  if (isPresent(type)) return type
  return ''
}

export default inspectedFormatter
