import PropTypes from 'prop-types'

export default function Status({ status, noTemplate, loading, empty, children }) {
  switch(status) {
    case 'loading':
      return loading
    case 'noTemplate':
      return noTemplate
    case 'empty':
      return empty
    default:
      return children
  }
}

Status.propTypes = {
  status: PropTypes.string,
  noTemplate: PropTypes.oneOfType([
    PropTypes.element, PropTypes.node, PropTypes.string
  ]),
  loading: PropTypes.oneOfType([
    PropTypes.element, PropTypes.node, PropTypes.string
  ]).isRequired,
  empty: PropTypes.oneOfType([
    PropTypes.element, PropTypes.node, PropTypes.string
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.node, PropTypes.string
  ]).isRequired,
}

Status.defaultProps = {
  status: 'loading',
  noTemplate: undefined
}
