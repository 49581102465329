import { camelize, pluralize } from 'inflected'
import { sanitizeString } from '/src/utils/string'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import { isBlank } from '/src/utils/boolean_refinements'

const BATCH_ENTITY_EQUIVALENT = { responsibles: 'employees' }

/**
 * This custom hook will make it possible to filter grid columns
 * on foreign key (objects)
 *
 * @returns callback function to treat object filter
 */
export default function useObjectFiltering() {
  const batchedEntities = useEntitiesCache()
  const [locale] = useStore('language')

  const getEntityAndField = (searchFilter) => {
    const [entityName, fieldName] = searchFilter.field.split('.')
    const fKName = `${entityName}_id`
    return { entityName, fieldName, fKName }
  }

  const sanitizeStrings = (searchVal, entityVal) => ({
    searchValue: sanitizeString(searchVal),
    entityValue: sanitizeString(entityVal)
  })

  const batchedOrEquivalent = (entityName) => {
    if (batchedEntities[entityName]) return Object.values(batchedEntities[entityName])
    return Object.values(batchedEntities[BATCH_ENTITY_EQUIVALENT[entityName]])
  }

  const treatAllTypesStatusFilter = (gridFilter, comparisonFunc) => {
    const searchFilter = { ...gridFilter }
    const { field, value } = searchFilter
    const [entity] = field.split('_')
    const translations = { ...I18n.translations[locale] }
    const statusTranslations = Object.entries(translations[pluralize(entity)].statuses)
    const idValues = statusTranslations.reduce((ids, translation) => {
      const { searchValue, entityValue } = sanitizeStrings(value, translation[1])
      const entities = batchedOrEquivalent(camelize(pluralize(field), false))

      if (comparisonFunc(entityValue, searchValue)) {
        ids.push(entities.find((e) => e.i18n_id === translation[0]).id)
      }

      return ids
    }, [])


    searchFilter.operator = 'eq'
    searchFilter.field = `${field}_id`
    searchFilter.value = idValues

    return searchFilter
  }

  const treatObjectContainsFilter = (gridFilter) => {
    const searchFilter = { ...gridFilter }
    const { entityName, fieldName, fKName }  = getEntityAndField(searchFilter)
    const entities = batchedOrEquivalent(camelize(pluralize(entityName), false))

    const ids = entities.reduce((filtered, entity) => {
      const { searchValue, entityValue } = sanitizeStrings(searchFilter.value, entity[fieldName])
      if (entityValue.includes(searchValue)) filtered.push(entity.id)
      return filtered
    }, [])

    searchFilter.operator = 'eq'
    searchFilter.field = fKName
    searchFilter.value = ids.toString()

    return searchFilter
  }

  const treatObjectEqFilter = (gridFilter) => {
    const searchFilter = { ...gridFilter }
    const { entityName, fieldName, fKName }  = getEntityAndField(searchFilter)
    const entities = batchedOrEquivalent(camelize(pluralize(entityName), false))
    const matchSingleEntity = (entity) => {
      const { searchValue, entityValue } = sanitizeStrings(searchFilter.value, entity[fieldName])
      return searchValue === entityValue
    }
    const item = entities.find(matchSingleEntity)

    searchFilter.field = fKName
    searchFilter.value = item ? item.id : -1

    return searchFilter
  }

  const treatObjectNeqFilter = (gridFilter) => {
    const searchFilter = { ...gridFilter }
    const { entityName, fieldName, fKName }  = getEntityAndField(searchFilter)
    const entities = batchedOrEquivalent(camelize(pluralize(entityName), false))
    const ids = entities.reduce((filtered, entity) => {
      const { searchValue, entityValue } = sanitizeStrings(searchFilter.value, entity[fieldName])
      if (searchValue !== entityValue) filtered.push(entity.id)
      return filtered
    }, [])

    searchFilter.field = fKName
    searchFilter.operator = 'eq'
    searchFilter.value = ids.toString()

    return searchFilter
  }

  const treatGeneralObjectFilter = (gridFilter) => {
    let searchFilter = { ...gridFilter }
    const { operator } = searchFilter

    // eslint-disable-next-line default-case
    switch (operator) {
      case 'contains':
        searchFilter = treatObjectContainsFilter(searchFilter)
        break
      case 'eq':
        searchFilter = treatObjectEqFilter(searchFilter)
        break
      case 'neq':
        searchFilter = treatObjectNeqFilter(searchFilter)
        break
    }

    return searchFilter
  }

  const treatStatusFilter = (gridFilter) => {
    let searchFilter = { ...gridFilter }
    const { operator } = searchFilter
    let comparisonFunc

    // eslint-disable-next-line default-case
    switch (operator) {
      case 'contains':
        comparisonFunc = (entityValue, searchValue) => entityValue.includes(searchValue)
        break
      case 'eq':
        comparisonFunc = (entityValue, searchValue) => entityValue === searchValue
        break
      case 'neq':
        comparisonFunc = (entityValue, searchValue) => entityValue !== searchValue
        break
    }

    searchFilter = treatAllTypesStatusFilter(searchFilter, comparisonFunc)

    return searchFilter
  }

  const treatObjectFilter = (gridFilter, columns) => {
    let searchFilter = { ...gridFilter }
    const { field } = searchFilter
    const fieldColumn = columns.filter((column) => column.description === field)[0]
    const isFixedField = isBlank(fieldColumn && fieldColumn.eav_template_id)

    // if it is a dot-notation string (if it is a foreign key field)
    if (field.includes('.')) searchFilter = treatGeneralObjectFilter(searchFilter)
    else if (isFixedField) {
      if (field.includes('_status')) {
        searchFilter = treatStatusFilter(searchFilter)
      } else if (fieldColumn.queryField) {
        return {...searchFilter, field: fieldColumn.queryField}
      }
    } 

    return searchFilter
  }

  return treatObjectFilter
}
