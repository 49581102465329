import React from 'react'
import PropTypes from 'prop-types'
import { MdDelete, MdDeleteOutline } from 'react-icons/md'

export default function DeleteColumn({ toBeDeleted, onChange, header }) {
  const icon = toBeDeleted ? (
    <MdDelete size={22} color='#E63719' />
  ) : (
    <MdDeleteOutline size={22} color='#929EA5' />
  )

  const renderInput = () => (
    <div className='delete-input-container'>
      <input
        type='checkbox'
        className='delete-column-input'
        defaultChecked={toBeDeleted}
        onClick={onChange}
      />
      {icon}
    </div>
  )

  return header ? renderInput() : (
    <td>{renderInput()}</td>
)
}

DeleteColumn.propTypes = {
  toBeDeleted: PropTypes.bool,
  header: PropTypes.bool,
  onChange: PropTypes.func
}

DeleteColumn.defaultProps = {
  toBeDeleted: false,
  header: false,
  onChange: () => {}
}
