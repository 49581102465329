import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import PropTypes from 'prop-types'
import useModelController from '/src/hooks/model_controller'
import Layout from '/src/ui/core/layouts/layout'
import DatasheetForm from '/src/ui/domain/datasheets/datasheet_form'

export default function DatasheetFormPage({ match }) {
  const history = useHistory()

  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const [filterQuery] = useQueryParam('filter', StringParam)
  const datasheetCategory = (filterQuery && filterQuery.split(':')[0] === 'datasheet_category_id') && filterQuery

  const queryString = () => {
    const params = []
    params.push(`?eav_template_id=${templateId}`)
    if (datasheetCategory) params.push(`filter=${datasheetCategory}`)
    return params.join('&')
  }

  const { dataItem } = useModelController({
    modelType: 'datasheets',
    dataItemId: match.params.id,
    onBack: () => history.push(`/datasheets${queryString()}`),
    onFinish: () => history.push(`/datasheets${queryString()}`),
  })

  return (
    <Layout key={dataItem} showProjectSwitcher={false}>
      <DatasheetForm
        dataItem={dataItem || undefined}
        templateId={templateId}
        type={match.params.collection}
      />
    </Layout>
  )
}

DatasheetFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

DatasheetFormPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined
    })
  })
}
