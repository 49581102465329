import lodash from "lodash"
import { conditionalFormatMessage } from "/src/models/concerns/formula_result"
import { isBlank } from '/src/utils/boolean_refinements'

const fieldsToPreventItemChange = ['contract_service_id']

export const additionalFieldsToChangedItem = (changedField, newItem) => {
  const additionalFields = {}
  if (changedField === 'contract_service') {
    additionalFields.contract_service_id = newItem.contract_service.id
    additionalFields.service_price_id = newItem.contract_service.latest_price.id
  }
  return additionalFields
}

export const applyItemChange = (event, gridState, getDataItemKey) => {
  const { dataSource, newDataSource, setNewDataSource } = gridState
  const { dataItem, value, field } = event

  if (isBlank(field) || fieldsToPreventItemChange.includes(field)) return undefined

  dataItem[field] = value

  const newData = newDataSource.map((item) => {

    const key = getDataItemKey(item)
    if (item[key] === dataItem[key]) {
      const originalItem = dataSource.find((origItem) => origItem[key] === item[key])

      return {
        ...item,
        ...additionalFieldsToChangedItem(field, dataItem),
        [field]: value,
        dirty: !originalItem || originalItem[field] !== value,
        inError: undefined
      }
    }

    return item
  })

  setNewDataSource(newData)

  return newData
}

export const applyFormulaColumnsResults = (
  newDataItems, { setNewDataSource, formulaColumns }, getDataItemId
) => {
  const { pick } = lodash

  const newDataItemsMap = {}
  newDataItems.forEach((newDataItem) => {
    newDataItemsMap[getDataItemId(newDataItem)] = newDataItem
  })

  setNewDataSource((currentDataSource) => (
    currentDataSource.map((item) => ({
      ...item, ...pick(newDataItemsMap[getDataItemId(item)], formulaColumns)
    }))
  ))
}

const updateCellStructure = ({ cellStructure, itemId, formula, formulaResult }) => {
  const newCellStructure = cellStructure
  if (newCellStructure[`${itemId}`] === undefined)
    newCellStructure[`${itemId}`] = {}

  newCellStructure[`${itemId}`][formula.eav_column_id] = formulaResult
}

const cellStructureToUpdate = ({
  formula, newCellVisibilities, newCellMandatories, newCellConditionalFormats
}) => {
  if (formula.eav_column_field === 'required') return newCellMandatories
  if (formula.eav_column_field === 'conditional_format') return newCellConditionalFormats
  return newCellVisibilities
}

const parseFormulaResult = (formula, formulaResult) => {
  if (formula.eav_column_field !== 'conditional_format') return formulaResult
  return conditionalFormatMessage(formulaResult)
}

const applyControlFieldFormulaResults = (formulaResults, {
  cellVisibilities, cellMandatories, cellConditionalFormats, formulasControlFieldsById
}) => {
  if (!formulaResults || !formulasControlFieldsById) return

  const refVisibilities = cellVisibilities
  const newCellVisibilities = { ...refVisibilities.current }

  const refMandatories = cellMandatories
  const newCellMandatories = { ...refMandatories.current }

  const refConditionalFormats = cellConditionalFormats
  const newCellConditionalFormats = { ...refConditionalFormats.current }

  Object.entries(formulaResults).forEach(([resultKey, { status, response }]) => {
    const [itemId, formulaId] = resultKey.split('_')

    if (!formulaId || status !== 'success') return

    const formula = formulasControlFieldsById[formulaId]
    if (formula && formula.eav_column_field) {
      const cellStructure = cellStructureToUpdate({
        formula, newCellVisibilities, newCellMandatories, newCellConditionalFormats
      })
      const formulaResult = parseFormulaResult(formula, response)
      updateCellStructure({ cellStructure, itemId, formula, formulaResult })
    }
  })

  refVisibilities.current = newCellVisibilities
  refMandatories.current = newCellMandatories
  refConditionalFormats.current = newCellConditionalFormats
}

/*
  The purpose of this function is to call all the "applyFormula" functions,
  not just the applyFormulaColumnsResults. We need to apply the results on formula columns,
  then deal with visibility, mandatory and conditional format.
*/
export const applyFormulaResults = ({ newDataItems, formulaResults, gridState, getDataItemId }) => {
  applyControlFieldFormulaResults(formulaResults, gridState)
  applyFormulaColumnsResults(newDataItems, gridState, getDataItemId)
}
