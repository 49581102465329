import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import TrackBlock from '/src/ui/domain/requests/track_block'
import TrackBlockGeneralButtons from '/src/ui/core/buttons/track_block_general_buttons'
import getFirstStringOrFormula from '/src/ui/domain/eav_columns/get_first_string_or_formula'
import '/src/static/css/core/blocks/track_block.css'

export default function DatasheetsTrackBlock({ dataItem, contextMenuItems, columns }) {
  const buttons = (
    <TrackBlockGeneralButtons
      dataItem={dataItem}
      contextMenuItems={contextMenuItems}
    />
  )

  const commentColumn = getFirstStringOrFormula(columns)
  const comment = commentColumn && commentColumn.description

  const detail = { id: dataItem.id, comments: dataItem[comment] || '' }

  return (
    <TrackBlock idText={I18n.t('datasheets.id')} detail={detail} actions={buttons}>
      <div className="datasheet-track-block-children">
        <div className="summary-item">
          {`${I18n.t('datasheets.item')} ${dataItem.number}`}
        </div>
      </div>
    </TrackBlock>
  )
}

DatasheetsTrackBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  contextMenuItems: PropTypes.shape({
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    remove: PropTypes.oneOfType([PropTypes.object]).isRequired
  })
}

DatasheetsTrackBlock.defaultProps = {
  dataItem: undefined,
  contextMenuItems: {
    edit: {},
    duplicate: {},
    remove: {}
  },
}
