import React from 'react'
import PropTypes from 'prop-types'
import { MdSentimentSatisfied, MdSentimentDissatisfied, MdSentimentNeutral } from 'react-icons/md'
import PFQuantityProgressBar from '/src/ui/domain/progress_services/pf_quantity_progress_bar'
import PFHoursProgressBar from '/src/ui/domain/progress_services/pf_hours_progress_bar'
import I18n from '/src/utils/translations'
import { calculateServicePF, formatPF } from '/src/utils/productivity_factor'
import { formatQty } from '/src/ui/domain/progress_services/productivity_factor_block_helper'
import '/src/static/css/domain/progress_services/productivity_factor.css'
import { useStore } from 'react-context-hook'

export default function ProductivityFactorBlock({ dataItem }) {
  const [project] = useStore('project')

  const pf = calculateServicePF(dataItem)

  const classification = (pfValue) => {
    if (pfValue === I18n.t('fields.na')) return 'neutral'
    if (pfValue <= 1) return 'good'
    return 'bad'
  }

  const emoji = (pfValue) => {
    if (pfValue === I18n.t('fields.na')) return <MdSentimentNeutral size='1.5em' />
    if (pfValue <= 1) return <MdSentimentSatisfied size='1.5em' />
    return <MdSentimentDissatisfied size='1.5em' />
  }

  return (
    <React.Fragment>
      <div className={`pf-title-section-${classification(pf)}`}>
        <div className="pf-title">
          <span>{I18n.t('progress_services.pf')}</span>
          <div className="emoji">
            {emoji(pf)}
          </div>
        </div>
        <div className="pf-feedback">
          <span>{I18n.t(`progress_services.pf_doing_${classification(pf)}`)}</span>
          <span className="pf-value">{formatPF(pf, project)}</span>
        </div>
      </div>
      <div className="pf-quantity-section">
        <span>
          {`${I18n.t('progress_services.scoped_quantity')}: ${formatQty(dataItem, 'quantity')}`}
        </span>
      </div>
      <PFQuantityProgressBar dataItem={dataItem} />
      <PFHoursProgressBar dataItem={dataItem} />
    </React.Fragment>
  )
}

ProductivityFactorBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

ProductivityFactorBlock.defaultProps = {
  dataItem: {}
}
