import React from 'react'
import PropTypes from 'prop-types'
import InspectedColumn from '/src/ui/domain/inspections/inspected'
import RequestColumn from '/src/ui/domain/inspections/request'
import InspectionStatusLabel from '/src/ui/domain/inspections/inspection_status_label'
import ResponsibleBadge from '/src/ui/domain/inspections/responsible_badge'
import InspectionModel from '/src/models/inspection'
import FieldFactory from '/src/ui/core/fields/field_factory'
import IdCell from '/src/ui/core/grid/id_cell'

export default function InspectionCellFactory({ field, dataItem, value }) {

  const { columns } = new InspectionModel()

  const column = columns.find((c) => c.description === field || c.field === field)

  switch (field) {
    case 'request_id':
      return <IdCell id={dataItem[field]} />
    case 'reason':
    case 'comments':
      return <RequestColumn request={dataItem.request} field={field} />
    case 'inspected_type':
      return (
        <InspectedColumn inspected={dataItem.inspected} type={dataItem.inspected_type} />
      )
    case 'inspect_status':
      return <InspectionStatusLabel dataItem={dataItem} />
    case 'responsible.full_name':
      return <ResponsibleBadge fullName={value} />
    default:
      return (        
        <FieldFactory value={value || dataItem[field]} type={column ? column.type : 'text'} />
      )
  }
}

InspectionCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.shape({
    inspected: PropTypes.shape({}),
    inspected_type: PropTypes.string,
    request: PropTypes.shape({})
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape()
  ])
}

InspectionCellFactory.defaultProps = {
  field: '',
  dataItem: {},
  value: undefined
}
