import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import I18n from '/src/utils/translations'
import ActionToolbar from '/src/ui/core/toolbar/action_toolbar'

export default function FormButtons({ model, awaitFormSuccess }) {
  const [nextClicked, setNextClicked] = useState(false)

  const handleClick = () => {
    if (awaitFormSuccess) {
      dispatch(BusEvents.FORM_SUBMIT)
    } else {
      dispatch(BusEvents.FORM_SUBMITTED)
    }
  }

  useBus(
    BusEvents.STEPS_NEXT_BUTTON_CLICKED,
    () => {
      setNextClicked(true)
      handleClick()
    }, [handleClick, setNextClicked])

  useBus(
    BusEvents.FORM_SUBMITTED,
    () => {
      if(!nextClicked) dispatch(BusEvents.FORM_FINISHED)
    }, [nextClicked])

  return (
    <ActionToolbar
      className='form-buttons'
      cancelText={I18n.t('form.cancel')}
      onCancel={() => dispatch(BusEvents.FORM_DISCARD_CHANGES_CONFIRM)}
      actionText={I18n.t('actions.save_exit')}
      onAction={() => handleClick()}
    />
  )
}

FormButtons.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  awaitFormSuccess: PropTypes.bool
}

FormButtons.defaultProps = {
  awaitFormSuccess: false
}
