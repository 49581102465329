import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { titleize } from 'inflected'
import Header from '/src/ui/core/menus/header'
import Sidebar from '/src/ui/core/sidebar/sidebar'
import CenterContainer from '/src/ui/core/layouts/center_container'
import I18n from '/src/utils/translations'
import LoadingBatchEntities from '/src/ui/core/popups/loading_batch_entities'
import '/src/static/css/layout.css'

const translate = (title, param) => {
  if (title.includes('[missing')) return titleize(param)
  return title
}

export default function Layout({ children, showProjectSwitcher, showNewButton }) {
  const url = useParams()

  const title = ['DPMS']

  url.collection && title.push(translate(I18n.t(`collections.${url.collection}`), url.collection))
  url.resource && title.push(translate(I18n.t(`${url.resource}.title`), url.resource))
  url.id && title.push(`${url.id}`)

  document.title = title.join(' - ')

  return (
    <div className="body-wrapper">
      <LoadingBatchEntities />
      <Sidebar showNewButton={showNewButton} />
      <div className="vertical-wrapper">
        <Header showProjectSwitcher={showProjectSwitcher} />
        <CenterContainer>
          <React.Fragment>
            {children}
          </React.Fragment>
        </CenterContainer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  showProjectSwitcher: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  showNewButton: PropTypes.bool
}

Layout.defaultProps = {
  showProjectSwitcher: true,
  showNewButton: false
}
