/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { AiFillCalculator } from 'react-icons/ai'
import { useStore } from 'react-context-hook'
import { isPresent } from '/src/utils/boolean_refinements'
import { parseFormulaResult } from '/src/models/concerns/formula_result'
import '/src/static/css/inputs.css'

export default function InputControlledFormula({ inputProps })  {
  const { id, name, value, placeholder, className, label, isInteger } = inputProps
  const [globalProject] = useStore('project')

  return (
    <React.Fragment>
      <div className="formula-wrapper">
        {label}
        <input
          id={id}
          className={classNames('form-input-text', { [className]: className })}
          name={name}
          value={isPresent(value) ? parseFormulaResult(value, isInteger, globalProject) : ''}
          disabled
          placeholder={placeholder}
          type="text"
        />
        <button type="button" id="result-button">
          <AiFillCalculator />
        </button>
      </div>
    </React.Fragment>
  )
}

InputControlledFormula.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    label: PropTypes.element,
    isInteger: PropTypes.bool
  })
}

InputControlledFormula.defaultProps = {
  inputProps: {
    className: '',
    name: '',
    placeholder: '',
    value: null,
    label: null,
    isInteger: false
  }
}
