import React from 'react'
import PropTypes from 'prop-types'
import { Grid, GridNoRecords } from '@progress/kendo-react-grid'
import { PopupPropsContext } from '@progress/kendo-react-popup'
import I18n from '/src/utils/translations'
import GridEmptyState from '/src/ui/core/grid/grid_empty_state'
import GridLoader from '/src/ui/core/loaders/grid_loader'
import Status from '/src/ui/core/grid/status'
import filterOperators from '/src/ui/core/grid/filter_operators'
import { forceBooleanDefault } from '/src/utils/object'
import '/src/static/css/core/grid/grid.css'

const SELECTED_FIELD = 'row_selected'

export default function SimpleGridContent({
  status, model, animatePopup, gridWrapper, selectedItem, selectedId, selectFiltering, dataSource,
  formattedGridColumns, rowRender, dynamicFilters, onFilterChange, sortable, sorting,
  onColumnReorder, onSortChange, onColumnResize, onGridRowClick, onSelectionChange
}) {
  const { name, route } = model

  const handleDataSourceSelectedItems = (data) => data.map((item) => ({
    ...item,
    row_selected: item.row_selected
      ? forceBooleanDefault(item, SELECTED_FIELD)
      : item.id === selectedId
  }))

  const gridData = selectedItem && selectFiltering
    ? [forceBooleanDefault(selectedItem, SELECTED_FIELD)]
    : handleDataSourceSelectedItems(dataSource.data)

  return (
    <Status
      status={status}
      loading={<GridLoader />}
      empty={<GridEmptyState onCreate={null} modelName={name} modelRoute={route} />}
      noTemplate={<GridEmptyState modelName={`${name} template`} />}
    >
      <PopupPropsContext.Provider
        value={(props) => ({
          ... props, animate: animatePopup, appendTo: gridWrapper.current
        })}
      >
        <Grid
          data={gridData}
          className={dataSource.data.length === 0 ? 'no-records' : ''}
          rowRender={rowRender}
          total={dataSource.total}
          filter={dynamicFilters.filter}
          filterOperators={filterOperators}
          onFilterChange={onFilterChange}
          sortable={sortable}
          sort={sorting}
          reorderable
          resizable
          onColumnReorder={onColumnReorder}
          onSortChange={onSortChange}
          onColumnResize={onColumnResize}
          onRowClick={onGridRowClick}
          selectedField={SELECTED_FIELD}
          onSelectionChange={onSelectionChange}
        >
          <GridNoRecords>{I18n.t('grid.empty_after_filtering')}</GridNoRecords>
          {formattedGridColumns}
        </Grid>
      </PopupPropsContext.Provider>
    </Status>
  )
}

SimpleGridContent.propTypes = {
  status: PropTypes.string,
  model: PropTypes.object.isRequired,
  animatePopup: PropTypes.bool,
  gridWrapper: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  selectedId: PropTypes.number,
  selectFiltering: PropTypes.bool,
  dataSource: PropTypes.oneOfType([PropTypes.object]),
  formattedGridColumns: PropTypes.array,
  rowRender: PropTypes.func,
  dynamicFilters: PropTypes.oneOfType([PropTypes.object]),
  onFilterChange: PropTypes.func,
  sortable: PropTypes.bool,
  sorting: PropTypes.arrayOf(PropTypes.object),
  onColumnReorder: PropTypes.func,
  onSortChange: PropTypes.func,
  onColumnResize: PropTypes.func,
  onGridRowClick: PropTypes.func,
  onSelectionChange: PropTypes.func
}

SimpleGridContent.defaultProps = {
  status: 'loading',
  animatePopup: true,
  selectedItem: undefined,
  selectedId: undefined,
  selectFiltering: true,
  dataSource: { data: [], total: 0 },
  formattedGridColumns: [],
  rowRender: () => {},
  dynamicFilters: undefined,
  onFilterChange: () => {},
  sortable: true,
  sorting: [],
  onColumnReorder: () => {},
  onSortChange: () => {},
  onColumnResize: () => {},
  onGridRowClick: () => {},
  onSelectionChange: () => {}
}
