import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import { 
  formatPercentageWithPrecision,
  formatNumberWithPrecision
} from '/src/utils/project_formatter'
import '/src/static/css/core/grid/grid.css'

export default function ContributionBlock({ dataItem }) {
  const [project] = useStore('project')
  const [fields, setFields] = useState([])

  useEffect(() => {
    if (!dataItem) return

    const weight = formatPercentageWithPrecision(dataItem.weight, project.number_of_digits, project)
    const progress = dataItem.progress_service_summary &&
      formatPercentageWithPrecision(dataItem.progress_service_summary.actual_progress, project.number_of_digits, project)
    const factor = formatNumberWithPrecision(dataItem.factor, project)

    setFields([
      { value: weight, description: 'service_weight' },
      { value: progress, description: 'service_progress' },
      { value: factor, description: 'package_factor' },
    ])
  }, [dataItem, project])

  return (
    <div className="info-fields">
      {fields && fields.map((field) => (
        <div className="field" key={`field_${field.description}`}>
          <span>{I18n.t(`progress_services.${field.description}`)}</span>
          <span className="value">{field.value}</span>
        </div>
      ))}
    </div>
  )
}

ContributionBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

ContributionBlock.defaultProps = {
  dataItem: {}
}
