import React from 'react'
import PropTypes from 'prop-types'
import ColoredProgressBar from '/src/ui/core/progress_bars/colored_progress_bar'
import I18n from '/src/utils/translations'
import { progress, percentage, formatQty } from '/src/ui/domain/progress_services/productivity_factor_block_helper'

export default function PFQuantityProgressBar({ dataItem }) {
  return (
    <div className="pf-quantity-progress-section">
      <div className="pf-quantity-progress-title">
        {I18n.t('progress_services.actual_div_quantity')}
      </div>
      <div className="pf-quantity-progress-bar">
        <ColoredProgressBar
          progress={progress(dataItem, 'progress_service_summary.actual_quantity', 'quantity')}
        />
      </div>
      <div className="pf-quantity-progress-calculation">
        <div className="pf-quantity-progress-equation">
          <span className="pf-quantity-progress-actual">
            {formatQty(dataItem, 'progress_service_summary.actual_quantity')}
          </span>
          <span className="pf-quantity-progress-divided">
            {' / '}
          </span>
          <span className="pf-quantity-progress-scoped">
            {formatQty(dataItem, 'quantity')}
          </span>
        </div>
        <span className="pf-quantity-progress-value">
          {percentage(dataItem, 'progress_service_summary.actual_quantity', 'quantity')}
        </span>
      </div>
    </div>
  )
}

PFQuantityProgressBar.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

PFQuantityProgressBar.defaultProps = {
  dataItem: {}
}
