import React, { useState } from 'react'
import { MdMenu } from 'react-icons/md'
import TopMenuItem from '/src/ui/core/menus/top_menu_item'
import useWindowDimensions from '/src/utils/window_dimensions'
import items from '/src/ui/core/menus/top_menu'
import '/src/static/css/layout.css'

export default function TopMenu() {
  const { width } = useWindowDimensions()
  const menuItems = items().map((item) =>  <TopMenuItem item={item} key={item.name} /> )

  const [open, setOpen] = useState(false)

  if (width >= 768) {
    return <div className="header-menu">{menuItems}</div>
  }
  return (
    <>
      <button className="btn-icon" type="button" onClick={() => setOpen(!open)}><MdMenu /></button>
      <div className="header-menu-small popup-content k-popup k-child-animation-container" style={{ display: open ? 'block' : 'none' }}>
        {menuItems}
      </div>
    </>
  )
}
