/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import ProgressServicePackageableModel from '/src/models/progress_service_packageable'
import FormButtons from '/src/ui/core/forms/form_buttons'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import Form from '/src/ui/core/forms/form'
import useFetchSections from '/src/hooks/api/fetch_sections'
import I18n from '/src/utils/translations'

export default function ProgressServicePackageableForm({ progressId, templateId, disciplineId }) {
  const query = { query: { where: { discipline_id: disciplineId } } }
  const sections = useFetchSections(templateId)
  const progressServicePackageableModel = new ProgressServicePackageableModel(query)

  const type = 'new'

  return (
    <FormWrapper
      model={progressServicePackageableModel}
      type={type}
      backText={I18n.t('progress_services.back')}
    >
      <React.Fragment>
        <Form
          model={progressServicePackageableModel}
          sections={sections}
          includeOnForm={{
            progress_id: progressId,
            eav_template_id: templateId,
            discipline_id: disciplineId
          }}
          templateId={templateId}
          type={type}
        />
        <FormButtons model={progressServicePackageableModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

ProgressServicePackageableForm.propTypes = {
  progressId: PropTypes.number,
  templateId: PropTypes.number,
  disciplineId: PropTypes.number,
}

ProgressServicePackageableForm.defaultProps = {
  progressId: undefined,
  templateId: undefined,
  disciplineId: undefined
}
