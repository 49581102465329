/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'

/**
 * This custom hook implements a way of creating sections for schedule forms
 * @return [dataItem, setDataItem, sections]
*/
export default function useInspectionAcceptancePopupFields() {
  const [dataItem, setDataItem] = useState({})
  const [employees] = useStore('employees')
  const [sections, setSections] = useState([])

  const responsibleMetadata = () => {
    const employeesArray = Object.values(employees)
    return JSON.stringify(employeesArray)
  }

  const defaultDate = () => dataItem.inspect_date

  const defaultResponsible = () => {
    if (!dataItem) return null
    const employeesArray = Object.values(employees)
    const responsible = employeesArray.find((employee) => employee.id === dataItem.responsible_id)
    return responsible ? responsible.id : null
  }

  const onChange = (description, value) => {
    const item = { ...dataItem }
    item[description] = value
    setDataItem(item)
  }

  const updateSections = () => {
    const newSections = [
      {
        id: 1,
        eav_columns: [
          {
            id: 1,
            description: 'inspect_date',
            title: I18n.t('requests.schedule_form.date'),
            columnSize: 'large',
            onChange,
            type: 'date',
            required: true,
            default: defaultDate()
          },
          {
            id: 2,
            description: 'responsible_id',
            title: I18n.t('inspections.responsible_id'),
            foreignKey: 'employees',
            foreignAttribute: 'responsible_id',
            required: true,
            searchFields: ['name', 'last_name'],
            searchExtraQuery: {
              where: { '[work_positions][operational]': 1, is_inspection_responsible: 1 }
            },
            searchRoute: 'employees',
            metadata: responsibleMetadata(),
            onChange,
            textDisplayFields: ['full_name'],
            textField: 'full_name',
            type: 'search',
            default: defaultResponsible()
          },
        ]
      },
    ]
    setSections(newSections)
  }

  useEffect(() => {
    if (Object.entries(employees).length > 0 && Object.entries(dataItem).length > 0) {
      updateSections()
    }
  }, [employees, dataItem])

  return [dataItem, setDataItem, sections]
}
