import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/layouts/page_form.css'

export default function FormHeader({ title, subtitle, label, badge }) {
  return (
    <div className="form-wrapper-header">
      <div className="form-title-badge-wrapper">
        <div className="form-wrapper-title" data-testid="form-title">
          {title}
        </div>
        {label && <div className="form-wrapper-label">{label}</div>}
        <span id="badge" className="badge">{badge}</span>
      </div>
      <div className="form-wrapper-subtitle">
        {subtitle}
      </div>
    </div>
  )
}

FormHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badge: PropTypes.element,
}

FormHeader.defaultProps = {
  title: '',
  subtitle: '',
  label: undefined,
  badge: undefined,
}
