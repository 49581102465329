import React from 'react'
import PropTypes from 'prop-types'
import DecimalColumn from '/src/ui/core/grid/flexible_column/decimal'
import CurrencyColumn from '/src/ui/core/grid/flexible_column/currency'
import EstimateServiceModel from '/src/models/estimate_service'
import FieldFactory from '/src/ui/core/fields/field_factory'
import UnitColumn from '/src/ui/core/grid/column_cell_factory/unit'

export default function ScopingServiceCellFactory({ field, dataItem, value }) {
  const { contract_service: contractService } = dataItem
  const decimalColumns = ['factor', 'quantity', 'weight', 'crews', 'crew_size',
    'norm_hours', 'team_target_hours', 'budget_target_hours', 'working_hours']

  const currencyColumns = ['application_price', 'materials_price', 'equipment_price', 'other_price']

  const { columns } = new EstimateServiceModel()
  const column = columns.find((c) => c.description === field)

  if (decimalColumns.includes(field)) return (<span className="align-cells-right"><DecimalColumn value={dataItem[field]} /></span>)
  if (currencyColumns.includes(field)) return (<span className="align-cells-right"><CurrencyColumn value={dataItem[field]} /></span>)
  if (field === 'unit') return (<UnitColumn dataItem={dataItem} />)
  if (field === 'id') return (<FieldFactory value={dataItem[field]} type="string" />)
  if (field === 'contract_service') return (
    contractService ? contractService.description :  dataItem.contract_service_id
  )

  return (
    <FieldFactory value={value || dataItem[field]} type={column ? column.type : 'text'} />
  )
}

ScopingServiceCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape()
  ])
}

ScopingServiceCellFactory.defaultProps = {
  field: '',
  dataItem: undefined,
  value: undefined
}
