// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class InspectionForScheduleModel {
  name = I18n.t('inspections.title')

  singularName = I18n.t('inspections.description')

  paramName = 'inspection'

  templateType = 'inspection'

  route = 'inspections'

  subRoute = 'create_recurrencies'

  columns = [
    {
      id: 1,
      description: 'request_id',
      title: I18n.t('requests.id'),
      hideOnForm: true,
      width: 150,
      type: 'integer'
    },
    {
      id: 2,
      description: 'request',
      title: I18n.t('requests.id'),
      foreignKey: 'requests',
      foreignAttribute: 'request_id',
      hideOnGrid: true,
      orderOnForm: 1,
      required: true,
      searchFields: ['id', 'reason', 'comments'],
      searchExtraQuery: { where: {
        '[request_statuses][i18n_id]': [
          'registered',
          'confirmed',
          'in_scoping',
          'authorization_pending',
          'in_planning',
          'in_performance',
          'on_hold'
        ]
      } },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'comments'],
      textField: 'reason',
      type: 'search'
    },
    {
      id: 3,
      description: 'inspect_status',
      title: I18n.t('inspections.inspect_status_id'),
      filterable: false,
      hideOnForm: true,
      width: 200,
      type: 'integer'
    },
    {
      id: 4,
      description: 'responsible_id',
      title: I18n.t('inspections.responsible_id'),
      hideOnForm: true,
      filterable: false,
      width: 200,
      type: 'integer'
    },
    {
      id: 5,
      description: 'responsible',
      title: I18n.t('inspections.responsible_id'),
      foreignKey: 'employees',
      foreignAttribute: 'responsible_id',
      hideOnGrid: true,
      orderOnForm: 2,
      searchFields: ['name', 'last_name'],
      searchExtraQuery: {
        where: { '[work_positions][operational]': 1, is_inspection_responsible: 1 }
      },
      searchRoute: 'employees',
      textDisplayFields: ['name', 'last_name'],
      textField: 'full_name',
      type: 'search'
    },
    {
      id: 6,
      description: 'inspected_type',
      title: I18n.t('inspections.module'),
      orderOnForm: 3,
      metadata: JSON.stringify([
        { id: "Request", description: I18n.t('inspections.inspected_modules.request') },
        { id: "Scope", description: I18n.t('inspections.inspected_modules.scope') },
        { id: "Progress", description: I18n.t('inspections.inspected_modules.progress') }
      ]),
      default: 'Request',
      filterable: false,
      required: true,
      width: 200,
      type: 'drop'
    },
    {
      id: 7,
      description: 'recurrency_uuid',
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'string'
    },
    {
      id: 8,
      description: 'eav_template_id',
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'integer'
    }
  ]
}
