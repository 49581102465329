import React from 'react'
import PropTypes from 'prop-types'

export default function ResponsibleBadge({ fullName }) {

  return (
    <React.Fragment>
      {fullName && (
        <div data-testid="inspect-responsible-badge" className="responsible-badge">
          <span>{fullName}</span>
        </div>
      )}
    </React.Fragment>
  )
}

ResponsibleBadge.propTypes = { fullName: PropTypes.string }

ResponsibleBadge.defaultProps = { fullName: undefined }
