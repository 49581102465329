import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import LabelColumn from '/src/ui/core/grid/label_column'
import ScopesCountColumn from '/src/ui/domain/scopes/scopes_count'
import DecimalColumn from '/src/ui/core/grid/flexible_column/decimal'
import IdCell from '/src/ui/core/grid/id_cell'
import ScopeModel from '/src/models/scope'
import FieldFactory from '/src/ui/core/fields/field_factory'

const RIGHT_ALIGNED_FIELDS = ['team_target_hours', 'quantity', 'crews', 'crew_size', 'working_hours']

export default function ScopeCellFactory({ field, dataItem }) {
  const [scopes] = useStore('interactive_scope_list')

  const recalculateCell = (column, value) => {
    const sameLabel = (
      scopes.filter((scope) => scope.label === dataItem.label)
      .reduce((acc, item) => item[column]  + acc, 0)
    )

    const otherLabel = (
      scopes.filter((scope) => scope.label !== dataItem.label)
      .reduce((acc, item) => item[column]  + acc, 0)
    )
    return  sameLabel - otherLabel + value
  }

  const { columns } = new ScopeModel()
  const column = columns.find((c) => c.description == field)
  let cell

  switch (field) {
    case 'request_id':
      cell = <IdCell id={dataItem[field]} />
      break
    case 'label':
      cell = <LabelColumn value={dataItem[field]} />
      break
    case 'scopes_count':
      cell = <ScopesCountColumn dataItem={dataItem} scopes={scopes} />
      break
    case 'team_target_hours':
      cell = <DecimalColumn value={recalculateCell(field, dataItem[field])} />
      break
    case 'remaining_team_target_hours':
      cell = <DecimalColumn value={dataItem[field]} />
      break
    case 'quantity':
      cell = <DecimalColumn value={recalculateCell(field, dataItem[field])} />
      break
    case 'remaining_quantity':
      cell = <DecimalColumn value={dataItem[field]} />
      break
    default:
      cell = <FieldFactory value={dataItem[field]} type={column ? column.type : 'text'} />
      break
  }
  
  return RIGHT_ALIGNED_FIELDS.includes(field) ? <span className="align-cells-right">{cell}</span> : cell

}

ScopeCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

ScopeCellFactory.defaultProps = {
  field: '',
  dataItem: undefined
}
