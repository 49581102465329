// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
// eslint-disable-next-line
import { normalizeDateToString } from '/src/utils/project_formatter'

export default class ProgressModel {
  name = I18n.t('progresses.title')

  singularName = 'progress'

  paramName = 'progress'

  templateType = 'progress'

  route = 'progresses'

  url = 'performances'

  flexible = true

  parentModels = ['request', 'scope']

  canEditRowStatuses = ['in_progress']

  columns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('progresses.id'),
      hideOnGrid: true,
      hideOnForm: true,
      width: 100,
      type: 'integer'
    },
    {
      id: 2,
      description: 'request_id',
      title: I18n.t('requests.id'),
      hideOnForm: true,
      width: 150,
      type: 'integer'
    },
    {
      id: 3,
      description: 'request',
      title: I18n.t('requests.id'),
      orderOnForm: 1,
      foreignKey: 'requests',
      columnSize: 'large',
      foreignAttribute: 'request_id',
      hideOnGrid: true,
      required: true,
      searchFields: ['id', 'reason', 'comments'],
      searchExtraQuery: { where: {
          '[request_statuses][i18n_id]': [
            'in_planning',
            'in_performance'
          ]
        } },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'comments'],
      textField: 'reason',
      type: 'search'
    },
    {
      id: 4,
      description: 'number',
      title: '#',
      hideOnForm: true,
      readOnly: true,
      width: 100,
      type: 'string'
    },
    {
      id: 5,
      description: 'flag',
      title: I18n.t('progresses.flag'),
      hideOnForm: true,
      readOnly: true,
      sortable: false,
      width: 150,
      type: 'string'
    },
    {
      id: 6,
      description: 'description',
      title: I18n.t('progresses.description'),
      hideOnForm: true,
      readOnly: true,
      width: 150,
      filterable: false,
      type: 'string'
    },
    {
      id: 7,
      description: 'label',
      title: I18n.t('progresses.label'),
      hideOnForm: true,
      editable: false,
      width: 150,
      type: 'string'
    },
    {
      id: 8,
      description: 'PF',
      title: I18n.t('progresses.pf'),
      hideOnForm: true,
      editable: false,
      filterable: false,
      sortable: false,
      type: 'string'
    },
    {
      id: 9,
      description: 'progress',
      title: I18n.t('progresses.progress'),
      hideOnForm: true,
      editable: false,
      width: 200,
      filterable: false,
      sortable: false,
      type: 'string'
    },
    {
      id: 10,
      description: 'delta_quantity',
      title: I18n.t('progresses.delta_qty'),
      hideOnForm: true,
      editable: false,
      width: 150,
      filterable: false,
      type: 'decimal'
    },
    {
      id: 11,
      description: 'delta_hours',
      title: I18n.t('progresses.delta_hours'),
      hideOnForm: true,
      editable: false,
      width: 150,
      filterable: false,
      type: 'decimal'
    },
    {
      id: 12,
      description: 'quantity',
      title: I18n.t('scopes.quantity'),
      orderOnForm: 2,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 13,
      description: 'crews',
      title: I18n.t('scopes.crews'),
      orderOnForm: 3,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 14,
      description: 'crew_size',
      title: I18n.t('scopes.crew_size'),
      orderOnForm: 4,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 15,
      description: 'working_hours',
      title: I18n.t('scopes.working_hours'),
      orderOnForm: 5,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 16,
      description: 'created_at',
      title: I18n.t('scopes.date'),
      editable: false,
      hideOnGrid: true,
      hideOnForm: true,
      default: normalizeDateToString(new Date()),
      readOnly: true,
      width: 200,
      type: 'date'
    },
  ]
}
