import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQueryParam, NumberParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'
import { MdModeEdit } from 'react-icons/md'
import lodash from 'lodash'
import {
  editMenuItem,
  deleteMenuItem,
  duplicateMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useTranslations from '/src/hooks/api/translations'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import Layout from '/src/ui/core/layouts/layout'
import ScopeModel from '/src/models/scope'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import ScopesSidePanel from '/src/ui/domain/scopes/scopes_side_panel'
// eslint-disable-next-line max-len
import ScopingServicesSidePanel from '/src/ui/domain/scopes/scoping_services_side_panel'
import ScopesGrid from '/src/ui/domain/scopes/scopes_grid'
import EstimateServicesGrid from '/src/ui/domain/estimate_services/estimate_services_grid'
import useGetEstimate from '/src/ui/domain/estimate_services/get_estimate_hook.jsx'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import ScopesClusteredGrid from '/src/ui/domain/scopes/scopes_clustered_grid'
import ChooseEstimateFormModal from '/src/ui/domain/estimate_services/choose_estimate_form_modal'
import I18n from '/src/utils/translations'
import hasParent from '/src/models/concerns/parent_checker'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetchById from '/src/hooks/api/fetch_by_id'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import EstimateModel from '/src/models/estimate'
import EstimateServiceModel from '/src/models/estimate_service'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import { templateType } from '/src/models/concerns/template_types'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import { dpmsIdToEditableGrid, contractServiceToEditableGrid } from '/src/utils/columns_formatter'
import { canCreateScope, canEditScope } from '/src/utils/constants/request'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import { byString } from '/src/utils/object'
import '/src/static/css/core/layouts/shadowed_page.css'

// TODO: move ScopesClusteredGrid to another 'page component'.
// eslint-disable-next-line max-lines-per-function
export default function ScopesPage({ match }) {
  const scopeModel = new ScopeModel()
  const itemTemplateId = useQueryParamWithPrevious('eav_template_id')
  const [requestId] = useQueryParam('request_id', NumberParam)
  const history = useHistory()
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [showConfirmation, renderConfirmation] = useConfirmationModal()

  const loadingTranslations = useTranslations()
  const [templateTitle, setTemplateTitle] = useState()
  const [panelModel, setPanelModel] = useState(null) // can be 'scopes', 'scoping_services' or null

  const [selectItemId, setSelectItemId] = useQueryParam('select_item_id', NumberParam)
  const [selectServiceId, setSelectServiceId] = useQueryParam('select_service_id', NumberParam)

  const [globalSubproject] = useStore('subproject')

  // Scope Related
  const scopeGetSections = useFetchAPI('eav_sections')
  const [scopeSelectedItem, setScopeSelectedItem] = useState()
  const [scopeSidePanelDataItem, setScopeSidePanelDataItem] = useState()
  const [scopeSectionsColumns, setScopeSectionsColumns] = useState([])

  // Service Related
  const serviceGetSections = useFetchAPI('eav_sections')
  const [serviceSelectedItem, setServiceSelectedItem] = useState()
  const [serviceSidePanelDataItem, setServiceSidePanelDataItem] = useState()
  const [serviceSectionsColumns, setServiceSectionsColumns] = useState([])
  const [serviceTemplate, setServiceTemplate] = useState()
  const [modalEstimateService, setModalEstimateService] = useState(false)
  const estimateServiceModel = new EstimateServiceModel()

  // Request Related
  const [requestStatuses] = useStore('request_statuses')
  const [authorizedScopeCreation, setAuthorizedScopeCreation] = useState()
  const requestFetch = useFetchAPI('requests')
  const [request, setRequest] = useState()
  const estimate = useGetEstimate()

  // Estimate Related
  const [estimateStatuses] = useStore('estimate_statuses')
  const estimateModel = new EstimateModel()

  // Editable Grid
  const restablishGridSettings = () => ({
    selectedItem: scopeSidePanelDataItem,
    selectedService: serviceSidePanelDataItem
  })

  const onCreateNewItemTop = (newDataItem) => {
    if (requestId && !authorizedScopeCreation) {
      notifyError(I18n.t('scopes.request_status_restriction'))
      return
    }

    newDataItem.request_id = requestId
    return newDataItem
  }

  const onCreateNewItemBottom = (dataItem) => ({
    ...dataItem,
    scope_id: scopeSelectedItem.id,
    discipline_id: serviceTemplate.discipline_id,
    eav_template_id: serviceTemplate.id,
    estimate_id: estimate.id
  })

  const isRowEditableTop = (row) => {
    const requestStatusId = byString(row, 'request.request_status_id')
    return !hasParent(row) && (
      requestStatusId
      ? canEditScope(requestStatuses[requestStatusId].i18n_id)
      : true
    )
  }

  const editableGridProps = {
    topEntity: {
      model: scopeModel,
      onCreateNewItem: onCreateNewItemTop,
      shouldAllowCell: (column, dataItem) => isRowEditableTop(dataItem),
      isRowEditable: isRowEditableTop
    },
    bottomEntity: {
      model: estimateServiceModel,
      onCreateNewItem: onCreateNewItemBottom
    },
    recoverSettings: restablishGridSettings,
    allowCreate: true,
  }

  const [
    editableGrid,
    setInEditMode,
    editableTopGridColumns,
    editableTopGridDataSource,
    editableBottomGridColumns,
    editableBottomGridDataSource
  ] = useEditableGrid(editableGridProps)

  const onSetTopGridColumns = (columns) => {
    editableTopGridColumns.set(dpmsIdToEditableGrid(columns, requestId))
  }

  const onSetBottomGridColumns = (columns) => {
    editableBottomGridColumns.set(contractServiceToEditableGrid(columns, { discipline_id: byString(serviceTemplate, 'discipline_id') }))
  }

  const handleCloseSidePanel = () => {
    setScopeSelectedItem()
    setServiceSelectedItem()
    setScopeSidePanelDataItem()
    setServiceSidePanelDataItem()
    setPanelModel(null)

    dispatch(BusEvents.SIDE_PANEL_CLOSED)

    setColumnStyles(SIDE_PANEL_CLOSED)
  }

  const onScopeGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    editableTopGridDataSource.set(dataSource)

    const newSelectedItem = dataSource.data.filter((item) => (
      item.id === selectItemId || (scopeSelectedItem && item.id === scopeSelectedItem.id)
    ))[0]

    if (!isEqual(scopeSelectedItem, newSelectedItem)) {
      if (!newSelectedItem) handleCloseSidePanel()
      else {
        if (selectItemId) setSelectItemId()
        if (!selectServiceId) setPanelModel('scopes')
        setScopeSelectedItem(newSelectedItem)
        setScopeSidePanelDataItem(newSelectedItem)
        setColumnStyles(SIDE_PANEL_OPENED)
      }
    }
  }

  const onServiceGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    editableBottomGridDataSource.set(dataSource)

    const newSelectedService = dataSource.data.filter((item) => (
      item.id === selectServiceId || (serviceSelectedItem && item.id === serviceSelectedItem.id)
    ))[0]

    if (!isEqual(serviceSelectedItem, newSelectedService)) {
      if (selectServiceId) setSelectServiceId()
      setServiceSelectedItem(newSelectedService)
      setServiceSidePanelDataItem(newSelectedService)

      setPanelModel(!newSelectedService ? 'scopes' : 'scoping_services')
      setColumnStyles(SIDE_PANEL_OPENED)
    }
  }

  const scopesMenuItems = {
    edit: {
      text: I18n.t('actions.edit'),
      icon: <MdModeEdit />,
      visible: (dataItem) => !hasParent(dataItem),
      onClick: (e, dataItem) => {
        let url = `/scopes/${dataItem.id}/edit?eav_template_id=${dataItem.eav_template_id}`
        if (requestId) url += `&request_id=${requestId}`
        if (scopeSelectedItem) url += `&select_item_id=${scopeSelectedItem.id}`
        history.push(url)
      }
    },
    duplicate: duplicateMenuItem(history, scopeModel.route, (dataItem) => !hasParent(dataItem)),
    remove: deleteMenuItem(history, scopeModel, showConfirmation, handleCloseSidePanel,
      (dataItem) => !hasParent(dataItem)
    )
  }

  const serviceUrlParams = () => {
    const params = []

    if (itemTemplateId) params.push(`item_template_id=${itemTemplateId}`)
    if (itemTemplateId) params.push(`scope_template_id=${itemTemplateId}`)
    if (requestId) params.push('filtered_by_request=1')
    if (requestId) params.push(`request_id=${requestId}`)

    return params.join('&')
  }

  const serviceDataParams = (dataItem) => {
    const params = []

    params.push(`select_item_id=${dataItem.scope_id}`)
    params.push(`select_service_id=${dataItem.id}`)
    params.push(`scope_id=${dataItem.scope_id}`)

    return params.join('&')
  }

  const handleDeletedEstimateService = () => {
    setServiceSelectedItem()
    setServiceSidePanelDataItem()
    setPanelModel('scopes')
  }

  const isEstimateServiceGridEditable = () => {
    if  (!estimateStatuses || !estimate) return false

    return !estimateModel.cannotEditStatuses.includes(estimateStatuses[estimate.statusId].i18n_id)
  }

  const estimateServicesMenuItems = {
    edit: editMenuItem(history, estimateServiceModel.route, null, {
      urlParams: serviceUrlParams(),
      dataParams: serviceDataParams
    }),
    duplicate: duplicateMenuItem(history, estimateServiceModel.route, null, {
      urlParams: serviceUrlParams(),
      dataParams: serviceDataParams
    }),
    remove: deleteMenuItem(history, estimateServiceModel, showConfirmation, handleDeletedEstimateService)
  }

  const bulkEditItemVisible = (option) => {
    let editableGridDataSource = editableTopGridDataSource
    if (option === 'bottom') editableGridDataSource = editableBottomGridDataSource

    return (editableGridDataSource && editableGridDataSource.get &&
      !editableGridDataSource.get.loading)
  }

  const estimatesBulkEditItemVisible = (option) => {
    return bulkEditItemVisible(option) && isEstimateServiceGridEditable()
  }

  // Scope
  const onScopeRowClick = useCallback((e) => {
    setScopeSelectedItem((prevScopeSelectedItem) => {
      let newItem = e.dataItem
      if (prevScopeSelectedItem && prevScopeSelectedItem.id === e.dataItem.id)
        newItem = undefined

      if (newItem) {
        setPanelModel('scopes')
      } else {
        setPanelModel(null)
        setServiceSelectedItem()
        setServiceSidePanelDataItem()
      }

      setScopeSidePanelDataItem(newItem)
      setColumnStyles(SIDE_PANEL_OPENED)
      return newItem
    })
  }, [])

  // Service
  const onServiceRowClick = useCallback((e) => {
    setServiceSelectedItem((prevServiceSelectedItem) => {
      let newItem = e.dataItem
      if (prevServiceSelectedItem && prevServiceSelectedItem.id === e.dataItem.id)
        newItem = undefined

      if (newItem) {
        setPanelModel('scoping_services')
      } else {
        setPanelModel('scopes')
      }

      setServiceSidePanelDataItem(newItem)
      return newItem
    })
    setColumnStyles(SIDE_PANEL_OPENED)
  }, [])

  // Request
  const getRequest = (idRequest) => {
    const requestsParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: idRequest } }
    }

    requestFetch.fetchAPI(requestsParams)
  }

  // Scope
  useEffect(() => {
    if (!itemTemplateId) return

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: itemTemplateId } }
    }

    scopeGetSections.fetchAPI(params)
    handleCloseSidePanel()
  }, [itemTemplateId])

  useEffect(() => {
    if (loadingTranslations || scopeGetSections.status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(scopeGetSections.responseData.data[0])
    setScopeSectionsColumns(newSectionsColumns)
  }, [scopeGetSections.status, scopeGetSections.responseData, loadingTranslations])

  useEffect(() => {
    if (!scopeSelectedItem) handleCloseSidePanel()
  }, [scopeSelectedItem])

  // Service
  useEffect(() => {
    if(!itemTemplateId) return

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: {
        where: {
          'eav_templates][parent_template_id': itemTemplateId,
          'eav_templates][template_type': templateType.estimate_service
        }
      }
    }

    serviceGetSections.fetchAPI(params)
    handleCloseSidePanel()
  }, [itemTemplateId])

  useEffect(() => {
    if (loadingTranslations || serviceGetSections.status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(serviceGetSections.responseData.data[0])
    setServiceSectionsColumns(newSectionsColumns)
  }, [serviceGetSections.status, serviceGetSections.responseData, loadingTranslations])

  // request
  useEffect(() => {
    if (!requestId && !scopeSelectedItem) {
      setAuthorizedScopeCreation(undefined)
      return
    }
    const idRequest = !requestId ? scopeSelectedItem.request_id : requestId

    getRequest(idRequest)
  }, [requestId, scopeSelectedItem])

  useEffect(() => {
    if (!request || !request.id) return

    estimate.read(request.id)
  }, [request])

  useEffect(() => {
    if (requestFetch.status !== 'SUCCESS') return
    if (requestFetch.responseData.data.length === 0) return
    if (!Object.keys(requestStatuses).length) return

    const requestItem = requestFetch.responseData.data[0]
    setRequest(requestItem)
    setAuthorizedScopeCreation(canCreateScope(requestStatuses[requestItem.request_status_id].i18n_id))
  }, [requestFetch.status, requestFetch.responseData, requestStatuses])

  useEffect(() => {
    if (!request) return
    if (!scopeSelectedItem) return

    const isRequestValid = (
      request.id === scopeSelectedItem.request_id &&
      request.subproject_id === globalSubproject.id
    )

    setScopeSidePanelDataItem({ ...scopeSelectedItem, request: isRequestValid && request })
  }, [request, scopeSelectedItem, globalSubproject])

  useBus(
    BusEvents.SIDEBAR_ADD_BUTTON_CLICKED,
    () => {
      if (match.params.collection !== undefined || !itemTemplateId) return
      if (authorizedScopeCreation === false) {
        notifyError(I18n.t('scopes.request_status_restriction'))
        return
      }
      if (scopeSelectedItem) {
        if (!serviceTemplate) {
          notifyError(I18n.t('estimate_services.no_template'))
          return
        }
        setModalEstimateService(true)
      } else {
        let url = `/scopes/new?eav_template_id=${itemTemplateId}`
        if (requestId) url += '&filtered_by_request=1'
        if (requestId) url += `&request_id=${requestId}`
        history.push(url)
      }
    }, [requestId, itemTemplateId, serviceTemplate,
        history, scopeSelectedItem, authorizedScopeCreation]
  )

  useBus(
    BusEvents.EXITING_EDITABLE_MODE, ({ payload }) => {
      const item = payload()
      if (!item) return

      const { selectedItem, selectedService } = item

      setPanelModel(selectedService ? 'scoping_services' : 'scopes')

      setScopeSelectedItem(selectedItem && { ...selectedItem })
      setScopeSidePanelDataItem(selectedItem && { ...selectedItem })

      setServiceSelectedItem(selectedService && { ...selectedService })
      setServiceSidePanelDataItem(selectedService && { ...selectedService })

      setColumnStyles(selectedItem ? SIDE_PANEL_OPENED : SIDE_PANEL_CLOSED)
    }, []
  )

  if (match.params.collection === 'clusters') {
    return (
      <Layout key={requestId}>
        <ScopesClusteredGrid request={requestId ? requestId.toString() : ''} />
      </Layout>
    )
  }

  return (
    <Layout key={requestId} showNewButton>
      {renderConfirmation()}
      {editableGrid(
        <React.Fragment>
          <TabsWrapper tabType={scopeModel.templateType} onTemplateChange={setTemplateTitle} />
          <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
            <div
              className={scopeSelectedItem ? 'shadowed-page' : 'unshadowed-page'}
              key={itemTemplateId}
            >
              {modalEstimateService && (
              <ChooseEstimateFormModal
                onClose={() => setModalEstimateService(false)}
                opts={{
                  scope: scopeSelectedItem,
                  service: serviceSelectedItem,
                  serviceTemplate,
                  filtered: requestId ? 1 : 0 }}
              />
              )}
              <ScopesGrid
                requestId={requestId ? requestId.toString() : ''}
                selectedItem={scopeSelectedItem}
                onRowClick={onScopeRowClick}
                onBulkEditItemClick={setInEditMode}
                onGridColumns={onSetTopGridColumns}
                onDataSource={onScopeGridDataSource}
                bulkEditItemVisible={bulkEditItemVisible}
                contextMenuItems={Object.values(scopesMenuItems)}
                templateTitle={templateTitle}
              />
              <EstimateServicesGrid
                dataItem={scopeSelectedItem}
                selectedItem={serviceSelectedItem}
                onRowClick={onServiceRowClick}
                onBulkEditItemClick={setInEditMode}
                onGridColumns={onSetBottomGridColumns}
                onDataSource={onServiceGridDataSource}
                bulkEditItemVisible={estimatesBulkEditItemVisible}
                onTemplate={setServiceTemplate}
                contextMenuItems={Object.values(estimateServicesMenuItems)}
              />
            </div>
            {panelModel === 'scopes' ? (
              <ScopesSidePanel
                dataItem={scopeSidePanelDataItem}
                sections={scopeSectionsColumns}
                onClose={handleCloseSidePanel}
                contextMenuItems={scopesMenuItems}
              />
            ) : (
              <React.Fragment>
                {serviceSidePanelDataItem && (
                <ScopingServicesSidePanel
                  dataItem={serviceSidePanelDataItem}
                  sections={serviceSectionsColumns}
                  onClose={handleCloseSidePanel}
                  contextMenuItems={estimateServicesMenuItems}
                />
              )}
              </React.Fragment>
            )}
          </LayoutPanels>
        </React.Fragment>
      )}
    </Layout>
  )
}

ScopesPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      collection: PropTypes.string
    })
  })
}

ScopesPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      collection: undefined
    })
  })
}
