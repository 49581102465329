import { formatNumberToFloatByProject, normalizeDateToString } from "/src/utils/project_formatter"

export const eavColumnToKendoType = (column) => {
  const kendoTypes = ['text', 'numeric', 'boolean', 'date']
  const numericTypes = ['integer', 'decimal']
  const dateTypes = ['date', 'date_time']
  const textTypes = ['string']

  const { type } = column
  const columnType = { ...column.column_type }

  if (type) {
    if (kendoTypes.includes(type))
      return type
    if (dateTypes.includes(type))
      return 'date'
    if (numericTypes.includes(type))
      return 'numeric'
    if (textTypes.includes(type))
      return 'text'
  }

  if (columnType) {
    if (kendoTypes.includes(columnType.description))
      return columnType.description
    if (dateTypes.includes(columnType.description))
      return 'date'
    if (numericTypes.includes(columnType.description))
      return 'numeric'
    if (textTypes.includes(columnType.description))
      return 'text'
  }

  return 'text'
}

export const columnType = (column) => {
  return column.column_type ? column.column_type.description : column.type
}

const FORMULA_TYPES = ['formula', 'drop_formula']

export const filterFormulaColumns = (columns = []) => (
  columns.filter((column) => (
    FORMULA_TYPES.includes(columnType(column)) || column.formula_id
  )).map((column) => column.description)
)

export const filterVisibleOnWeb = (columns = []) => {
  return columns.filter((column) => column.visible_on_web)
}

export const parseDefaultValue = (column, project) => {
  const defaultValue = column.default

  if (columnType(column) === 'decimal')
    return formatNumberToFloatByProject(defaultValue, project)
  else if (columnType(column) === 'date')
    return normalizeDateToString(new Date())
  else if (columnType(column) === 'date_time')
    return (new Date()).toISOString()

  return defaultValue
}
