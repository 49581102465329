// eslint-disable-next-line
import I18n from '/src/utils/translations'

export default class EstimateServiceModel {
  name = I18n.t('estimate_services.title')

  singularName = I18n.t('estimate_services.description')

  paramName = 'estimate_service'

  route = 'estimate_services'

  flexible = true

  parentModels = ['scope', 'contract_service', 'service_price', 'estimate', 'estimate.request']

  columns = [
    {
      id: 1,
      description: 'number',
      title: '#',
      editable: false,
      hideOnForm: true,
      width: 200,
      type: 'string'
    },
    {
      id: 2,
      description: 'id',
      title: I18n.t('estimate_services.id'),
      editable: false,
      hideOnForm: true,
      width: 200,
      type: 'integer'
    },
    {
      id: 3,
      description: 'description',
      title: I18n.t('estimate_services.description'),
      editable: false,
      hideOnForm: true,
      hideOnGrid: true,
      width: 200,
      type: 'text'
    },
    {
      id: 4,
      description: 'estimate',
      title: I18n.t('estimate_services.estimate'),
      foreignKey: 'estimates',
      foreignAttribute: 'estimate_id',
      hideOnGrid: true,
      hideOnForm: true,
      type: 'integer',
      width: 200
    },
    {
      id: 5,
      description: 'contract_service',
      field: 'contract_service.description',
      title: I18n.t('estimate_services.contract_service'),
      foreignKey: 'contract_services',
      foreignAttribute: 'contract_service_id',
      editable: false,
      required: true,
      type: 'contract_service',
      width: 200
    },
    {
      id: 6,
      description: 'service_package',
      field: 'service_package.description',
      title: I18n.t('estimate_services.service_package'),
      foreignKey: 'service_package',
      foreignAttribute: 'service_package_id',
      filterable: true,
      sortable: false,
      editable: false,
      hideOnForm: true,
      width: 200,
      type: 'text'
    },
    {
      id: 7,
      description: 'discipline',
      title: I18n.t('estimate_services.discipline'),
      foreignKey: 'disciplines',
      foreignAttribute: 'discipline_id',
      field: 'discipline.description',
      filterable: true,
      sortable: false,
      editable: false,
      hideOnForm: true,
      width: 200,
      type: 'text'
    },
    {
      id: 8,
      description: 'factor',
      title: I18n.t('estimate_services.factor'),
      editable: false,
      hideOnForm: true,
      width: 200,
      type: 'numeric'
    },
    {
      id: 9,
      description: 'quantity',
      title: I18n.t('estimate_services.quantity'),
      validation: {
        required: true
      },
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 10,
      description: 'weight',
      title: I18n.t('estimate_services.weight'),
      hideOnForm: true,
      width: 200,
      type: 'numeric'
    },
    {
      id: 11,
      description: 'application_price',
      title: I18n.t('estimate_services.application_price'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 12,
      description: 'materials_price',
      title: I18n.t('estimate_services.materials_price'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 13,
      description: 'equipment_price',
      title: I18n.t('estimate_services.equipment_price'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 14,
      description: 'other_price',
      title: I18n.t('estimate_services.other_price'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 15,
      description: 'norm_hours',
      title: I18n.t('estimate_services.norm_hours'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 16,
      description: 'team_target_hours',
      title: I18n.t('estimate_services.team_target_hours'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 17,
      description: 'budget_target_hours',
      title: I18n.t('estimate_services.budget_target_hours'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 18,
      description: 'working_hours',
      title: I18n.t('estimate_services.working_hours'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 19,
      description: 'crews',
      title: I18n.t('estimate_services.crews'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 20,
      description: 'crew_size',
      title: I18n.t('estimate_services.crew_size'),
      width: 200,
      hideOnForm: true,
      type: 'formula_service'
    },
    {
      id: 21,
      description: 'unit',
      hideOnForm: true,
      title: I18n.t('estimate_services.unit'),
      filterable: false,
      sortable: false,
      width: 200,
      type: 'text'
    },
  ]
}
