import { useEffect } from 'react'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'

export default function useGroupedFormActionButton(model, action) {
  const { loading, responseData, status, fetchAPI } = useFetchAPI(model.route)

  const promptNotification = (result) => {
    notifySuccess(I18n.t(`form.${action}_${result}`, { entity: model.name }))
  }

  const sendFormData = (form) => {
    const data = {}
    data[model.paramName] = form

    const params = {
      requestAction: 'CREATE',
      httpAction: 'post',
      data
    }
    if (model.subRoute) params.additionalResource = { path: model.subRoute }

    fetchAPI(params)
  }

  const handleClick = () => dispatch(BusEvents.FORM_SUBMIT)

  useEffect(() => {
    if (status === 'SUCCESS') {
      dispatch(BusEvents.FORM_FINISHED)
      promptNotification('success')
    }
  }, [status, responseData])

  useEffect(() => {
    if (loading) dispatch(BusEvents.SHOW_LOADING_DIALOG)
    else dispatch(BusEvents.HIDE_DIALOG)
  }, [loading])

  useBus(BusEvents.FORM_SUBMITTED, ({ payload }) => { sendFormData(payload) }, [])

  return handleClick
}
