/* eslint-disable max-lines-per-function */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import { normalizeDateToString } from '/src/utils/project_formatter'

/**
 * This custom hook implements a way of creating sections for schedule forms
 * @return [dataItem, setDataItem, sections]
 */
export default function useScheduleRequestPopupSections() {
  const [requestSectionFinished, setRequestSectionFinished] = useState(false)
  const [scopeSectionFinished, setScopeSectionFinished] = useState(false)
  const [progressSectionFinished, setProgressSectionFinished] = useState(false)
  const [dataItem, setDataItem] = useState({})
  const [sections, setSections] = useState([])
  const [employees] = useStore('employees')

  useEffect(() => updateSectionsStatus(), [dataItem])

  useEffect(() => {
    if (Object.entries(dataItem).length > 0) updateSections()
  }, [employees, dataItem, progressSectionFinished, scopeSectionFinished, requestSectionFinished])



  const defaultDateRange = (entity) => {
    const startDate = dataItem[`${entity}_start_date`]
    const endDate = dataItem[`${entity}_end_date`]
    const dateRange = { start: startDate, end: endDate }

    if (startDate && typeof startDate !== 'string') {
      dateRange.start = normalizeDateToString(startDate)
    }

    if (endDate && typeof endDate !== 'string') {
      dateRange.end =  normalizeDateToString(endDate)
    }

    return dateRange
  }

  const defaultResponsible = (entity) => {
    if (!dataItem) return null
    return dataItem[`${entity}_responsible_id`]
  }

  const responsibleMetadata = (entity) => {
    const employeesArray = Object.values(employees)
    const filteredEmployees = employeesArray.filter((e) => e[`is_${entity}_responsible`])
    return JSON.stringify(filteredEmployees)
  }

  const onResponsibleChange = (description, value) => {
    const item = { ...dataItem }
    item[description] = value
    setDataItem(item)
  }

  const onDateChange = (description, value) => {
    const item = { ...dataItem }
    item[`${description}_start_date`] = value.start
    item[`${description}_end_date`] = value.end
    setDataItem(item)
  }

  const updateSectionsStatus = () => {
    const isAnyRequestFieldEmpty = !dataItem.request_start_date ||
      !dataItem.request_responsible_id || !dataItem.request_end_date

    const isAnyScopeFieldsEmpty = !dataItem.scope_start_date ||
      !dataItem.scope_responsible_id || !dataItem.scope_end_date

    const isAnyProgressFieldEmpty = !dataItem.progress_start_date ||
      !dataItem.progress_responsible_id || !dataItem.progress_end_date

    setRequestSectionFinished(!isAnyRequestFieldEmpty)
    setScopeSectionFinished(!isAnyScopeFieldsEmpty)
    setProgressSectionFinished(!isAnyProgressFieldEmpty)
  }

  const updateSections = () => {
    const newSections = [
      {
        id: 1,
        title: I18n.t('requests.schedule_form.request'),
        finished: requestSectionFinished,
        index: 1,
        eav_columns: [
          {
            id: 1,
            description: 'request',
            title: I18n.t('requests.schedule_form.date'),
            columnSize: 'large',
            default: defaultDateRange('request'),
            onChange: onDateChange,
            type: 'date_range'
          },
          {
            id: 2,
            description: 'request_responsible_id',
            title: I18n.t('requests.schedule_form.responsible'),
            searchFields: ['name', 'last_name'],
            textField: 'full_name',
            onChange: onResponsibleChange,
            type: 'drop',
            default: defaultResponsible('request'),
            metadata: responsibleMetadata('request')
          },
        ]
      },
      {
        id: 2,
        finished: scopeSectionFinished,
        title: I18n.t('requests.schedule_form.scoping'),
        index: 2,
        eav_columns: [
          {
            id: 3,
            title: I18n.t('requests.schedule_form.date'),
            description: 'scope',
            columnSize: 'large',
            default: defaultDateRange('scope'),
            onChange: onDateChange,
            type: 'date_range'
          },
          {
            id: 4,
            description: 'scope_responsible_id',
            title: I18n.t('requests.schedule_form.responsible'),
            searchFields: ['name', 'last_name'],
            textField: 'full_name',
            type: 'drop',
            onChange: onResponsibleChange,
            default: defaultResponsible('scope'),
            metadata: responsibleMetadata('scope')
          },
        ]
      },
      {
        id: 3,
        finished: progressSectionFinished,
        title: I18n.t('requests.schedule_form.performance'),
        index: 3,
        eav_columns: [
          {
            id: 5,
            description: 'progress',
            title: I18n.t('requests.schedule_form.date'),
            columnSize: 'large',
            default: defaultDateRange('progress'),
            onChange: onDateChange,
            type: 'date_range'
          },
          {
            id: 6,
            description: 'progress_responsible_id',
            title: I18n.t('requests.schedule_form.responsible'),
            searchFields: ['name', 'last_name'],
            textField: 'full_name',
            onChange: onResponsibleChange,
            type: 'drop',
            default: defaultResponsible('progress'),
            metadata: responsibleMetadata('progress')
          },
        ]
      }
    ]
    setSections(newSections)
  }

  return [dataItem, setDataItem, sections]
}
