import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { MdArrowForward, MdArrowBack } from 'react-icons/md'
import I18n from '/src/utils/translations'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useFirstFlexibleColumn from '/src/hooks/first_flexible_column'
import InputSearch from '/src/ui/core/inputs/input_search'
import InputDrop from '/src/ui/core/inputs/input_drop'
import Modal from '/src/ui/core/popups/modal'
import { isJson } from '/src/utils/string'
import { isEmpty } from '/src/utils/object'
import '/src/static/css/core/popups/filter_steps_modal.css'

// eslint-disable-next-line max-lines-per-function
export default function FilterStepsModal({ filters, resultTitle, currentValues, onDone, onClose }) {
  const [values, setValues] = useState(currentValues)
  const [displayValues, setDisplayValues] = useState({})
  const [index, setIndex] = useState(0)
  const [stepStatus, setStepStatus] = useState('not_started')
  const [direction, setDirection] = useState('forward')
  const [selectedValue, setSelectedValue] = useState(null)
  const [selectedDisplayValue, setSelectedDisplayValue] = useState(null)
  const [skipping, setSkipping] = useState(true)
  const [status, setStatus] = useState('')
  const [responseData, setResponseData] = useState([])
  const [inspectedColumn, setInspectedColumn] = useState()
  const dropRef = useRef(null)
  const fetchEavColumns = useFetchAPI('eav_columns')
  const filterFirstFlexibleColumn = useFirstFlexibleColumn()

  const actionClick = () => {
    const { description } = filters[index]
    const newValues = { ...values, [description]: selectedValue }
    const newDisplayValues = { ...displayValues, [description]: selectedDisplayValue }

    if (description === 'inspected_template') {
      fetchEavColumns.fetchAPI({
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { eav_template_id: selectedValue } }
      })
    }

    setValues(newValues)
    setDisplayValues(newDisplayValues)
    setDirection('forward')
    setIndex(findJump('forward'))
  }

  const findJump = (direction) => {
    let jump = 1

    if (selectedValue === 'Request') jump = 3
    if (direction === 'backward') jump *= -1

    return Math.max(Math.min(index + jump, filters.length), 0)
  }

  const cancelClick = () => {
    setDirection('backward')
    setIndex(findJump('backward'))
  }

  const doneClick = () => onDone(values, displayValues)

  const onSelectedValue = (_ignored, value, itemValue) => {
    const useitemValue = itemValue && itemValue.computed_text_field !== undefined
    setSelectedDisplayValue(useitemValue ? itemValue.computed_text_field : value)
    setSelectedValue(value)
  }

  const onFetch = (fetchedData) => {
    setResponseData(fetchedData)
    setStatus('SUCCESS')
  }

  const props = {
    className: 'selector-combobox',
    popupClassName: 'filters-steps-combobox-list',
    iconClassName: 'k-i-search',
    onChange: onSelectedValue,
    onFetch,
    ref: dropRef,
    appendTo: '#filter-steps-options',
    forceCombobox: true,
    readOnly: false,
    opened: true
  }

  const [inputProps, setInputProps] = useState(props)

  useEffect(() => {
    if (index >= filters.length) return
    const filter = filters[index].description
    setSelectedValue(values[filter])
  }, [index, filters])

  useEffect(() => {
    if (fetchEavColumns.status !== 'SUCCESS') return
    
    const inspectedColumn = filterFirstFlexibleColumn(fetchEavColumns.responseData.data)
    setInspectedColumn(!inspectedColumn ? {} : inspectedColumn)
  }, [fetchEavColumns.status, fetchEavColumns.responseData])

  useEffect(() => {
    if (index === filters.length) return

    const filter = filters[index]
    const isInspectedId = filter.description === 'inspected_id'

    if (isInspectedId && !inspectedColumn) {
      setStatus('')
      setStepStatus('loading')
      return
    }

    const searchExtraQuery = filter.searchExtraQuery || (
      filter.queryBuilder && filter.queryBuilder(values)
    )
    const searchRoute = filter.searchRoute || (
      filter.searchRouteBuilder && filter.searchRouteBuilder(values)
    )

    if (!filter.searchRoute && filter.metadata) {
      setResponseData(JSON.parse(filter.metadata))
      setStatus('SUCCESS')
      setStepStatus('loaded')
    } else {
      setStatus('')
      setStepStatus('loading')
    }
    
    const textDisplayFields = isInspectedId && inspectedColumn ? 
      [...filter.textDisplayFields, inspectedColumn.description] : filter.textDisplayFields
    
    const searchFields = isInspectedId && inspectedColumn ? 
    [inspectedColumn.description] : filter.searchFields
    
    setInputProps({
      ...props,
      searchRoute,
      searchFields,
      searchExtraQuery,
      textDisplayFields,
      metadata: filter.metadata,
      type: filter.type,
      index
    })
    setSkipping(true)
  }, [values, index, filters, inspectedColumn])

  useEffect(() => {
    if (stepStatus !== 'loading' || status !== 'SUCCESS') return
    setStepStatus('loaded')
  }, [stepStatus, status, responseData, index, skipping])

  useEffect(() => {
    if (stepStatus === 'loaded') dropRef.current.focus()
  }, [stepStatus])

  useEffect(() => {
    if (stepStatus !== 'loaded') return

    setSkipping(true)
  }, [stepStatus])

  const disabled = () => stepStatus !== 'loaded'

  const totalSteps = resultTitle ? filters.length+1 : filters.length

  const isLastStep = () => index === totalSteps - 1

  const renderKeys = (key) => {
    return filters.find((column) => column.description === key).title
  }

  const renderValues = (key) => displayValues[key]

  const renderSearch = () => {
    const { type, index } = inputProps
    const searchProps = (index === 3) ? { ...inputProps, searchOperator: 'contains' } : inputProps

    switch (type) {
      case 'search':
        return <InputSearch key={index} inputProps={searchProps} />
      case 'drop':
        const options = isJson(inputProps.metadata) && JSON.parse(inputProps.metadata)
        return <InputDrop key={index} inputProps={{ ...inputProps, options }} />
      default:
        return <b>Warning</b>
    }
  }

  const prevTempButton = () => {
    return(
      <div className="filter-steps-action-buttons">
        <div id="filter-steps-prev-button" className="filter-steps-button temp-prev">
          {index > 0 &&
            (
            <button className="form-cancel-button" onClick={() => cancelClick()} type="button">
              <MdArrowBack />
              {` ${I18n.t('actions.prev')}`}
            </button>
            )}
        </div>
      </div>
  )}

  return (
    <Modal
      loading={{ status: stepStatus === 'loading', text: I18n.t('main_dialog.loading_title') }}
      height={80}
      width={42}
      title={`${I18n.t('filter_steps_modal.step')} ${index+1}/${totalSteps}`}
      onClose={onClose}
      closable
    >
      <div key={index} id="filter-steps-modal" className="filter-steps-modal">
        <div id="filter-steps-title" className="filter-steps-title">
          {index === filters.length ? I18n.t('filter_steps_modal.filter_result') : filters[index].title}
          <span className="count">{responseData.length}</span>
        </div>

        <div id="filter-steps-subtitle" className="filter-steps-subtitle">
          {`${I18n.t('filter_steps_modal.subtitle', {
              title: index === filters.length ?
                I18n.t('filter_steps_modal.filter_result')
              :
                filters[index].title
            })}`}
        </div>

        <div id="filter-steps-content" className="filter-steps-content">
          {
            index === filters.length ? (
              <div className="filter-result">
                <div className="result-title">
                  <span>{resultTitle}</span>
                  <img src="/static/svg/tick_blue.svg" />
                </div>
                <div className="result-filters">
                  {Object.keys(displayValues).filter((key) => values[key]).map((k) => (
                    <p key={k}>
                      {renderKeys(k)}
                      : 
                      {' '}
                      {renderValues(k)}
                    </p>
                  ))}
                </div>
              </div>
            )
            : (
              <React.Fragment>
                {inputProps && inputProps.type && index == inputProps.index && renderSearch()}
                <div id="filter-steps-options" className="filter-steps-options" />
              </React.Fragment>
            )
          }
        </div>

        { stepStatus !== 'loading' && (
          <div className="filter-steps-action-buttons">
            <div id="filter-steps-prev-button" className="filter-steps-button">
              {index > 0 &&
                (
                <button className="form-cancel-button" disabled={disabled()} onClick={() => cancelClick()} type="button">
                  <MdArrowBack />
                  {` ${I18n.t('actions.prev')}`}
                </button>
                )}
            </div>
                
            <div id="filter-steps-next-button" className="filter-steps-button">
              {isLastStep() ?
                (
                  <button className="next-button" onClick={doneClick} type="button">
                    {`${I18n.t('actions.done')}`}
                  </button>
            
                )
                :
                (
                  <React.Fragment>
                    <button className="next-button" disabled={disabled() || isEmpty(responseData)} onClick={() => actionClick()} type="button">
                      {`${I18n.t('actions.next')} `}
                      <MdArrowForward />
                    </button>
                  </React.Fragment>
              )}
            </div>
          </div>
        )}
        {stepStatus === 'loading' && prevTempButton()}
      </div>
    </Modal>
  )
}

FilterStepsModal.propTypes = {
  filters: PropTypes.array.isRequired,
  resultTitle: PropTypes.string,
  currentValues: PropTypes.object,
  onDone: PropTypes.func,
  onClose: PropTypes.func
}

FilterStepsModal.defaultProps = {
  resultTitle: undefined,
  currentValues: {},
  onDone: () => {},
  onClose: () => {}
}
