import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryParam, NumberParam } from 'use-query-params'
import { extractRouteFromHistory } from '/src/utils/url'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import ColumnsBatchAssigner from '/src/ui/core/popups/columns_batch_assigner'
import '/src/static/css/core/icons/popup_icon.css'

export default function BatchAssignerIcon({}) {
  const [eavTemplateId] = useQueryParam('eav_template_id', NumberParam)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [selectedRecords, setSelectedRecords] = useState([])
  const history = useHistory()

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ payload }) => { setSelectedRecords(payload) }, [setSelectedRecords])

  const handleSuccessfulBatchUpdate = () => {
    setIsPopupOpen(false)
    dispatch(BusEvents.RELOAD_GRID)
  }

  if (selectedRecords.length === 0) {
    return (<div className="popup-icon" id="assigner-icon-id" />)
  }

  const buttonClass = "open-icon-popup"
  const unselectedIconPath = "/static/svg/dynamic-form.svg"
  const selectedIconPath = "/static/svg/dynamic-form-selected.svg"

  return (
    <div className="popup-icon" id="assigner-icon-id">
      <button
        id="assigner-button-id"
        className={buttonClass + (isPopupOpen ? ' active-button' : '')}
        onClick={() => setIsPopupOpen(true)}
      >
        <img
          className="dynamic-form"
          src={isPopupOpen ? selectedIconPath : unselectedIconPath}
          alt="open-batch-assigner"
        />
      </button>
      {isPopupOpen && (
        <ColumnsBatchAssigner
          isOpen={isPopupOpen}
          eavTemplateId={eavTemplateId}
          onClose={() => setIsPopupOpen(false)}
          selectedRecords={selectedRecords}
          modelRoute={extractRouteFromHistory(history)}
          onSuccessfulUpdate={handleSuccessfulBatchUpdate}
        />
      )}
    </div>
  )
}
