// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class AcceptanceInspectionModel {
  name = I18n.t('inspections.title')

  singularName = I18n.t('inspections.description')

  paramName = 'inspection'

  templateType = 'inspection'

  route = 'inspections'

  flexible = true

  columns = [
    {
      id: 1,
      description: 'inspect_date',
      orderOnForm: 1,
      title: I18n.t('inspections.inspect_date'),
      type: 'date',
      required: true,
    },
    {
      id: 2,
      description: 'responsible',
      field: 'responsible.full_name',
      title: I18n.t('inspections.responsible_id'),
      foreignKey: 'employees',
      foreignAttribute: 'responsible_id',
      required: true,
      orderOnForm: 2,
      searchFields: ['name', 'last_name'],
      searchExtraQuery: {
        where: { '[work_positions][operational]': 1, is_inspection_responsible: 1 }
      },
      searchRoute: 'employees',
      textDisplayFields: ['full_name'],
      type: 'search'
    },
  ]
}
