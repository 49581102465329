/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useCallback } from 'react'
import { MdClose } from 'react-icons/md'
import { CgMathEqual } from 'react-icons/cg'
import { AiFillClockCircle } from 'react-icons/ai'
import I18n from '/src/utils/translations'
import {
  dailyHours,
  numberOfWorkers
} from '/src/utils/planning_calculations'
import { decimalHoursToTimeString, timeStringToDecimalHours } from '/src/utils/time'

/**
 * This custom hook implements a way of creating sections for history forms
 * @return [dataItem, sections]
 */
export default function useHistoryPopupFields({ parentDataItem, historyDataItem,
                                                level, formulas }) {

  const [dataItem, setDataItem] = useState()
  const [sections, setSections] = useState([])
  const quantityStr = I18n.t('history_popup.quantity')
  const percentageStr = I18n.t('history_popup.percentage')
  const [progressBySelected, setProgressBySelected] = useState(percentageStr)
  const percentageValueObjects = [
    { label: '10%', value: 0.1 },
    { label: '30%', value: 0.3 },
    { label: '50%', value: 0.5 },
    { label: '70%', value: 0.7 },
    { label: '80%', value: 0.8 },
    { label: '100%', value: 1 }
  ]

  const buildItem = useCallback((includeOnItem) => {
    const item = {
      ...parentDataItem,
      [`${level}_id`]: parentDataItem.id,
      progress_by: progressBySelected,
      _parentItems: {
        request: parentDataItem.eav_template_request,
        scope: parentDataItem.eav_template_scoping,
        contract_service: parentDataItem.eav_template_contract_service,
        progress: parentDataItem.eav_template_progress
      },
      ...includeOnItem,
      accumulated_quantity: undefined,
      daily_progress: undefined
    }

    const percentagePossibleValues = percentageValueObjects.map((p) => p.value)

    if (!percentagePossibleValues.includes((item.accumulated_progress)))
      item.other_value = item.accumulated_progress

    return item
  }, [parentDataItem, percentageValueObjects, progressBySelected, level])

  useEffect(() => {
    let includeOnItem
    if (historyDataItem && historyDataItem.id) {
      includeOnItem = {
        ...historyDataItem,
        daily_quantity_id: historyDataItem.drop_formula_id,
        daily_quantity_variables: historyDataItem.drop_formula_variables,
        number_of_workers: numberOfWorkers(historyDataItem),
      }
    } else {
      includeOnItem = {
        daily_hours: dailyHours(parentDataItem),
        number_of_workers: numberOfWorkers(parentDataItem),
        id: undefined
      }
    }

    setDataItem(buildItem(includeOnItem))

  }, [parentDataItem, historyDataItem, level])

  useEffect(() => { updateSections() }, [dataItem, progressBySelected])

  const onChange = (description, value) => {
    const item = { }
    item[description] = value

    if (description === 'daily_quantity_id') item.drop_formula_id = value
    if (description === 'daily_quantity_variables') item.drop_formula_variables = value
    if (description === 'accumulated_progress') item.other_value = null
    if (description === 'other_value') item.accumulated_progress = value

    if (['crews', 'crew_size', 'working_hours'].includes(description)) {
      item.number_of_workers = numberOfWorkers({ ...dataItem, ...item })
      item.daily_hours = dailyHours({ ...dataItem, ...item })
    }

    setDataItem((oldItem) => ({ ...oldItem, ...item }))
  }

  const progressByOnChange = (_, value) => {
    setProgressBySelected(value)
    onChange('progress_by', value)
  }

  const defaultValue = (property) => dataItem ? dataItem[property] : null

  const updateSections = () => {
    const percentageColumns = [
      {
        id: 3,
        description: 'accumulated_progress',
        title: I18n.t('history_popup.percentage'),
        fieldClass: 'seventy-percent-input',
        forceRadioButton: true,
        onChange,
        valueFormatter: (value) => parseFloat(value),
        default: defaultValue('accumulated_progress'),
        metadata: JSON.stringify(percentageValueObjects),
        type: 'drop'
      },
      {
        id: 4,
        description: 'other_value',
        onChange,
        default: defaultValue('other_value'),
        title: I18n.t('history_popup.other_value'),
        fieldClass: 'thirty-percent-input',
        type: 'percentage',
        number_of_digits: 0
      },
    ]
    
    const qttColumns = [
      {
        id: 3,
        description: 'daily_quantity',
        title: quantityStr,
        onChange,
        columnSize: 'large',
        type: 'controlled_drop_formula',
        eav_template_id: parentDataItem.eav_template_id,
        formulas
      }
    ]
    
    const customColumns = (progressBySelected === quantityStr) ? qttColumns : percentageColumns
    
    const columns = [
      {
        id: 1,
        description: 'progress_by',
        title: I18n.t('history_popup.progress_by'),
        columnSize: 'medium',
        dontExpand: true,
        metadata: JSON.stringify([quantityStr, percentageStr]),
        onChange: progressByOnChange,
        default: percentageStr,
        type: 'drop'
      },
      {
        id: 2,
        description: 'date',
        onChange,
        readOnly: !!(historyDataItem && historyDataItem.id),
        title: I18n.t('history_popup.date'),
        columnSize: 'medium',
        dontExpand: true,
        type: 'date'
      },
      ...customColumns,
      {
        id: 5,
        description: 'crew_size',
        title: I18n.t('history_popup.crew_size'),
        columnSize: 'medium',
        type: 'integer',
        onChange,
        default: defaultValue('crew_size'),
        fieldClass: 'half-minus-35-px',
        elementAfter: (
          <div className="times-wrapper">
            <div className="times"><MdClose /></div>
          </div>
        )
      },
      {
        id: 6,
        description: 'crews',
        title: I18n.t('history_popup.number_of_crews'),
        columnSize: 'medium',
        onChange,
        default: defaultValue('crews'),
        fieldClass: 'half-minus-35-px',
        type: 'integer'
      },
      {
        id: 7,
        description: 'number_of_workers',
        title: I18n.t('history_popup.number_of_workers'),
        columnSize: 'medium',
        calculate: () => {},
        default: defaultValue('number_of_workers'),
        type: 'controlled_formula',
        fieldClass: 'half-minus-65-px min-width-0',
        elementBefore: (
          <div className="equals-wrapper">
            <div className="equals"><CgMathEqual /></div>
          </div>
        ),
        elementAfter: (
          <div className="times-wrapper">
            <div className="times"><MdClose /></div>
          </div>
        )
      },
      {
        id: 8,
        description: 'working_hours',
        title: I18n.t('history_popup.working_hours'),
        columnSize: 'medium',
        onChange,
        fieldClass: 'half-minus-65-px',
        default: defaultValue('working_hours'),
        type: 'masked_text_box',
        mask: '000:00',
        valueFormatter: timeStringToDecimalHours,
        defaultValueFormatter: decimalHoursToTimeString,
        onBlur: ({ target }) => { 
          if (target.state.value) target.setState({ value: target.state.value.replace(/_/g, '0') }) 
        },
        appendButton: (
          <button type="button" id="time-input-icon">
            <AiFillClockCircle />
          </button>
        )
      },
      {
        id: 9,
        description: 'daily_hours',
        title: I18n.t('history_popup.daily_hours'),
        columnSize: 'large',
        type: 'masked_text_box',
        mask: '000:00',
        readOnly: true,
        default: defaultValue('daily_hours'),
        calculate: () => {},
        fieldClass: 'whole-minus-60-px',
        elementBefore: (
          <div className="equals-wrapper">
            <div className="equals"><CgMathEqual /></div>
          </div>
        ),
        valueFormatter: timeStringToDecimalHours,
        defaultValueFormatter: decimalHoursToTimeString,
        appendButton: (
          <button type="button" id="time-input-icon">
            <AiFillClockCircle />
          </button>
        )
      },
      {
        id: 10,
        description: 'comment',
        title: I18n.t('history_popup.comments'),
        onChange,
        columnSize: 'large',
        type: 'string',
        maxLength: 255
      },
    ]

    setSections([{ id: 1, eav_columns: columns }])
  }

  return [dataItem, sections]
}
