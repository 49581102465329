import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdFlipToFront, MdEdit, MdDone, MdErrorOutline } from 'react-icons/md'
import I18n from '/src/utils/translations'
import EditFieldDialog from '/src/ui/core/dialogs/edit_field_dialog'
import '/src/static/css/domain/sub_requests/sub_request.css'
import '/src/static/css/core/buttons/buttons.css'

// eslint-disable-next-line max-lines-per-function
export default function SubRequestPanelBarTitle({ subRequest, status }) {
  const [open, setOpen] = useState(false)

  const [subRequestReason, setSubRequestReason] = useState(subRequest.reason)

  const icon = () => {
    switch (status) {
      case 'success':
        return <MdDone />
      case 'error':
        return <MdErrorOutline />
      default:
        return <MdFlipToFront />
    }
  }

  return (
    <div className='created' data-testid='sub-request-panel-title'>
      <span className={`badge-subrequest ${status}`}>
        {icon()}
      </span>
      <div className='info'>
        <div className='description-column'>
          <span className='description'>
            <div className='sub-request-reason'>
              {subRequestReason}
              <a onClick={(e) => e.stopPropagation()}>
                <MdEdit onClick={(e) => setOpen(true)} role='edit-reason' />
                <EditFieldDialog
                  open={open}
                  onClose={() => setOpen(false)}
                  onSuccess={(reason) => setSubRequestReason(reason)}
                  label={I18n.t('requests.edit_dialog.reason')}
                  field='reason'
                  object={subRequest}
                  resource="requests"
                />
              </a>
            </div>
          </span>
          <span>0.00 • 0,00 h</span>
        </div>
      </div>
    </div>
  )
}

SubRequestPanelBarTitle.propTypes = {
  subRequest: PropTypes.oneOfType([PropTypes.object]).isRequired,
  status: PropTypes.string
}

SubRequestPanelBarTitle.defaultProps = {
  status: null
}
