// eslint-disable-next-line
import I18n from '/src/utils/translations'

export default class EstimateServicePackageableModel {
  constructor(props) {
    if (!props) return
    this.columns.find((x) => x.description === 'service_package').searchExtraQuery = props.query
  }

  name = I18n.t('progress_services.title_packageable')

  singularName = I18n.t('progress_services.description')

  paramName = 'progress_service'

  route = 'progress_services/create_by_package'

  flexible = true

  columns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('progress_services.id'),
      editable: false,
      width: 200,
      type: 'integer',
      hideOnForm: true,
    },
    {
      id: 2,
      description: 'responsible',
      field: 'responsible.full_name',
      title: I18n.t('progress_services.responsible'),
      foreignKey: 'employees',
      foreignAttribute: 'responsible_id',
      filterable: true,
      hideOnGrid: true,
      searchFields: ['name', 'last_name'],
      searchExtraQuery: { where: {  is_progress_responsible: 1 } },
      searchRoute: 'employees',
      textDisplayFields: ['full_name'],
      type: 'search'
    },
    {
      id: 3,
      description: 'service_package',
      title: I18n.t('progress_services.service_package'),
      foreignKey: 'service_package',
      foreignAttribute: 'service_package_id',
      required: true,
      filterable: false,
      editable: false,
      searchFields: ['description'],
      searchRoute: 'service_packages',
      textDisplayFields: ['description'],
      type: 'search',
      width: 200
    }
  ]
}
