import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useBatchEntities from '/src/hooks/api/batch_entities'
import useFieldSettings from '/src/hooks/field_settings'
import SectionedPanel from '/src/ui/core/sectioned_panel/sectioned_panel'
import ColumnInput from '/src/ui/core/inputs/column_input'
import GeneralSection from '/src/ui/core/sectioned_panel/general_section'
import { useForm } from 'react-hook-form'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import useSendForm from '/src/ui/core/forms/send_form_hook'
import useSetupFormulas from '/src/ui/core/forms/setup_formulas'
import useFieldsControl from '/src/ui/core/forms/fields_control_hook'
import useFormulasCalculation from '/src/ui/core/forms/formulas_calculation_hook'
import useParentItemsRegister from '/src/hooks/parent_items_register'
import { sortArrayOfObjectsByNumber } from '/src/utils/object'
import useSetupDatasheetFilter from '/src/ui/core/inputs/setup_datasheet_filter'
import { isPresent } from '/src/utils/boolean_refinements'
import I18n from '/src/utils/translations'
import { indexify } from '/src/utils/array'
import { DROP_COLUMN_TYPES } from '/src/utils/constants/columns'

// eslint-disable-next-line max-lines-per-function
export default function Form({ model, sections, dataItem, includeOnForm, templateId, sectionable,
                               type, disableColumnEdition, submitParams, errorHandler,
                               onFormSubmitAction, hasFormulasServices, formulasServices,
                               processColumn, pollingCallback }) {
  const formRef = useRef(null)
  const [fixedFields, setFixedFields] = useState()
  const [eavColumns, setEavColumns] = useState({})
  const [formComponents, setFormComponents] = useState(I18n.t('form.loading'))
  const { register, handleSubmit, setValue, control, getValues, watch } = useForm()
  const { paramName, columns, route } = model
  const [formulas, formulasControlFields, loadingFormulas, treatFormulaFields] = useSetupFormulas(templateId, paramName)
  const compoundDataItem = dataItem !== null ? { ...dataItem, route } : null
  const formTypes = { new: 'create', edit: 'update' }
  const action = formTypes[type] || type
  const sendFormProps = { model, action, dataItem, eavColumns, submitParams, errorHandler,
                          onFormSubmitAction, pollingCallback }
  const [sendFormData] = useSendForm(sendFormProps)
  const [
    datasheetFilters,
    multipleDatasheetFilters,
    loadingDatasheetFilters,
    treatDatasheetFilterColumns
  ] = useSetupDatasheetFilter(templateId, dataItem, setValue)

  const fieldSettings = useFieldSettings(templateId, ['hide_on_form'])

  const foreignEntities = columns.reduce((filtered, column) => {
    const { foreignKey, query } = column
    if (foreignKey && DROP_COLUMN_TYPES.includes(column.type)) filtered.push({ foreignKey, query })
    return filtered
  }, [])

  const loadingBatch = useBatchEntities(foreignEntities)
  const batchedEntities = useEntitiesCache(foreignEntities)

  // the parentItems are used to calculate equations with parent columns inside
  const parentItems = useParentItemsRegister(dataItem, model, control, register, setValue)

  useFieldsControl(
    watch,
    formulasControlFields,
    eavColumns,
    setEavColumns,
    fixedFields,
    parentItems
  )

  const formulaResultsParams = {
    watch,
    formulas,
    eavColumns,
    fixedFields,
    parentItems,
    includeOnForm,
    dataItem
  }

  const formulasResults = useFormulasCalculation(formulaResultsParams)

  useBus(
    BusEvents.FORM_SUBMIT,
    () => {
      dispatch(BusEvents.INPUT_VALIDATE)
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))
    }, [formRef])

  useEffect(() => {
    if (loadingBatch || sections === null ||
      (templateId > 0 && (loadingFormulas || loadingDatasheetFilters))) return
    if ((hasFormulasServices) && (formulasServices === null)) return

    registerAndStoreFixedFields(formulasResults)
    registerAndStoreFlexibleFields(formulasResults)
  }, [loadingBatch, sections, loadingFormulas, formulas,
    formulasResults, loadingDatasheetFilters, formulasServices])


  useEffect(() => {
    if (Object.values(includeOnForm).length === 0) return
    Object.keys(includeOnForm).forEach((key) => {
      register(key)
      setValue(key, includeOnForm[key])
    })
  }, [includeOnForm])

  useEffect(() => { if (fixedFields) buildRender() }, [fixedFields, eavColumns])

  const treatForeignDrop = (column) => {
    if (column.foreignKey) {
      column.description = column.foreignAttribute
      if (DROP_COLUMN_TYPES.includes(column.type)) {
        column.metadata = JSON.stringify(Object.values(batchedEntities[column.foreignKey]))
      }
    }

    return column
  }

  const shouldShowField = (column) => {
    const name = column.description
    let { hideOnForm } = column

    if ((column.type === 'formula_service') && isPresent(formulasServices)) {
      hideOnForm = !(formulasServices[`${name}_visible`] && !hideOnForm)
    }

    if (dataItem && !hideOnForm && type === 'edit' && column.hideOnUpdate)
      hideOnForm = column.hideOnUpdate(dataItem)

    const setting = fieldSettings[column.foreignAttribute] || fieldSettings[name]

    if (setting) hideOnForm = hideOnForm || setting.hide_on_form

    return !hideOnForm
  }

  const registerFieldsOnFormHook = (cols) => {
    cols.forEach((column) => {
      const id = column.description

      if (getValues(id) !== undefined) return
      register(id)
      if (type === 'new' && column.default) {
        let defaultValue = column.default
        const numberTypes = ['integer', 'decimal', 'percentage']
        if (column.column_type && numberTypes.includes(column.column_type.description))
          defaultValue = parseFloat(column.default)

        setValue(id, defaultValue)
      }

      if (dataItem && dataItem[id] !== undefined) setValue(id, dataItem[id])
    })
  }

  const virtualFields = (cols) => cols.filter((c) => c.virtualField)

  const registerAndStoreFixedFields = (results) => {
    const duplicatedFields = ['request_id', 'responsible_id']
    let fields = columns.filter((c) => !(c.hideOnForm && duplicatedFields.includes(c.description)))
                        .map((c) => {
                          let newColumn = { ...c, visible_on_web: shouldShowField(c) }
                          newColumn = treatForeignDrop(newColumn)
                          return processColumn(newColumn)
                        })

    if (formulas) fields = treatFormulaFields(fields, results, setValue)

    fields = sortArrayOfObjectsByNumber(fields, 'orderOnForm')
    registerFieldsOnFormHook([...fields, ...virtualFields(columns)])
    setFixedFields(fields)
  }

  const registerAndStoreFlexibleFields = (results) => {
    let flexibleFields = [].concat(...sections.map((s) => s.eav_columns))

    if (formulas) flexibleFields = treatFormulaFields(flexibleFields, results, setValue)

    registerFieldsOnFormHook(flexibleFields)
    setEavColumns(indexify(flexibleFields, 'id'))
  }

  const renderColumn = (column) => (
    <ColumnInput
      column={column}
      dataItem={compoundDataItem}
      onChange={setValue}
      control={{ ...control, getValues }}
      disableColumnEdition={disableColumnEdition}
    />
  )

  const updateSectionsWithEavColumns = () => {
    return sections.map((section) => {
      section.eav_columns = section.eav_columns.map((column) => eavColumns[column.id])
      return section
    })
  }

  const getSections = () => {
    const sectionsUpdated = updateSectionsWithEavColumns()

    if(!datasheetFilters && !multipleDatasheetFilters) return sectionsUpdated
    return treatDatasheetFilterColumns(sectionsUpdated, formulasControlFields)
  }

  const shouldRenderColumn = (column) => {
    const { type: columnType, formula } = column

    if (columnType !== 'formula_service') return true

    return isPresent(formula)
  }

  const renderFixedColumn = (column) => {
    return (shouldRenderColumn(column)) ? (
      <ColumnInput
        column={column}
        dataItem={dataItem}
        onChange={setValue}
        control={{ ...control, getValues }}
        disableColumnEdition={disableColumnEdition}
      />
    ) : null
  }

  const buildRender = () => {
    const generalSection = fixedFields.length > 0 ? (
      <GeneralSection
        key={fixedFields}
        fixedFields={fixedFields}
        sectionable={sectionable}
        renderColumn={renderFixedColumn}
      />
    ) : ''

    const sectionedPanel = sections.length > 0 ? (
      <SectionedPanel
        key="sections"
        sections={getSections()}
        enumerable={false}
        hideable
        sectionable={sectionable}
        renderColumn={renderColumn}
      />
    )
    : '' // TODO: Add a nice component to display that the template has no sections

    setFormComponents([generalSection, sectionedPanel])
  }

  return (
    <form key={templateId} ref={formRef} onSubmit={handleSubmit((data) => sendFormData(data))}>
      {formComponents}
    </form>
  )
}

Form.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  sections: PropTypes.oneOfType([PropTypes.array]),
  templateId: PropTypes.number,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  includeOnForm: PropTypes.oneOfType([PropTypes.object] ),
  type: PropTypes.string,
  disableColumnEdition: PropTypes.func,
  submitParams: PropTypes.shape({
    requestAction: PropTypes.string,
    httpAction: PropTypes.string,
    resourceId: PropTypes.number,
    additionalResource: PropTypes.oneOfType([PropTypes.object])
  }),
  errorHandler: PropTypes.func,
  sectionable: PropTypes.bool,
  onFormSubmitAction: PropTypes.func,
  noBatch: PropTypes.bool,
  hasFormulasServices: PropTypes.bool,
  formulasServices: PropTypes.oneOfType([PropTypes.object]),
  processColumn: PropTypes.func,
  pollingCallback: PropTypes.func
}

Form.defaultProps = {
  templateId: 0,
  dataItem: null,
  sections: [],
  includeOnForm: {},
  type: 'new',
  disableColumnEdition: () => {},
  submitParams: undefined,
  errorHandler: undefined,
  sectionable: true,
  onFormSubmitAction: undefined,
  noBatch: false,
  hasFormulasServices: false,
  formulasServices: null,
  processColumn: (column) => column,
  pollingCallback: null
}
