import React, { useCallback, useEffect, useState } from 'react'
import { useQueryParam, NumberParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'
import { MdControlPoint, MdControlPointDuplicate } from 'react-icons/md'
import lodash from 'lodash'
import Layout from '/src/ui/core/layouts/layout'
import ProgressModel from '/src/models/progress'
import PerformanceServiceModel from '/src/models/progress_service'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetchById from '/src/hooks/api/fetch_by_id'
import ChooseFormModal from '/src/ui/core/popups/choose_form_modal'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import I18n from '/src/utils/translations'
import useProgresses from '/src/ui/domain/progresses/progresses'
import useProgressServices from '/src/ui/domain/progress_services/progress_services'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import {
  dpmsIdToEditableGrid,
  contractServiceToEditableGrid,
  showColumnsOnEditableGrid } from '/src/utils/columns_formatter'
import { canCreateProgress } from '/src/utils/constants/request'
import { NON_EDITABLE_SERVICES_STATUSES } from '/src/utils/constants/progress_services'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import { byString } from '/src/utils/object'
import { buildBackURL } from '/src/utils/url'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/layouts/shadowed_page.css'

// eslint-disable-next-line max-lines-per-function
export default function ProgressesPage() {
  const [progressSelectedItem, setProgressSelectedItem] = useState()
  const [serviceSelectedItem, setServiceSelectedItem] = useState()
  const [showNewButton, setShowNewButton] = useState(false)
  const [authorizedProgressCreation, setAuthorizedProgressCreation] = useState()
  const [chooseFormModalVisibility, setChooseFormModalVisibility] = useState(false)
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [requestStatuses] = useStore('request_statuses')
  const [progressStatuses] = useStore('progress_statuses')
  const [progressServiceStatuses] = useStore('progress_service_statuses')
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const [requestId] = useQueryParam('request_id', NumberParam)
  const history = useHistory()
  const [selectItemId, setSelectItemId] = useQueryParam('select_item_id', NumberParam)
  const [selectServiceId, setSelectServiceId] = useQueryParam('select_service_id', NumberParam)
  const request = useFetchById('requests', requestId)
  const progressModel = new ProgressModel()
  const [performanceServiceModel, setPerformanceServiceModel] = useState(new PerformanceServiceModel())

  const [showConfirmation, renderConfirmation] = useConfirmationModal()

  const verifyProgressStatus = (item, statusName) => (
    byString(progressStatuses, `${item.progress_status_id}.i18n_id`) === statusName
  )

  const progressRowEditable = (row) => {
    return progressModel.canEditRowStatuses.includes(byString(progressStatuses, `${row.progress_status_id}.i18n_id`))
  }

  const serviceRowEditable = (row) => {
    const itemStatusI18n = byString(
      progressServiceStatuses,
      `${row.progress_service_status_id}.i18n_id`
    )
    return !NON_EDITABLE_SERVICES_STATUSES.includes(itemStatusI18n)
  }

  const editableGridProps = {
    allowCreate: true,
    recoverSettings: () => ({
      selectedItem: progressSelectedItem,
      selectedService: serviceSelectedItem
    }),
    topEntity: {
      model: progressModel,
      onCreateNewItem: (newDataItem) => {
        if (requestId && !authorizedProgressCreation) {
          notifyError(I18n.t('progresses.request_status_restriction'))
          return
        }

        newDataItem.request_id = requestId
        return newDataItem
      },
      shouldAllowCell: (column, dataItem) => {
        if (!dataItem || dataItem.progress_status_id === undefined) return true
        if (verifyProgressStatus(dataItem, 'pending')) return true
        if (column.available_on_formula) return false
        return progressRowEditable(dataItem)
      },
      isRowEditable: (row) => verifyProgressStatus(row, 'pending') || progressRowEditable(row)
    },
    bottomEntity: {
      model: performanceServiceModel,
      onCreateNewItem: (dataItem) => ({
        ...dataItem,
        progress_id: progressSelectedItem.id,
        discipline_id: serviceTemplate.discipline_id,
        eav_template_id: serviceTemplate.id,
        progress_service_summary: {}
      }),
      shouldAllowCell: (column, dataItem) => {
        const { available_on_formula: availableOnFormula } = column
        const itemOrPropertyMissing = !dataItem || dataItem.progress_service_status_id === undefined

        if (itemOrPropertyMissing || !availableOnFormula) return true
        return serviceRowEditable(dataItem)
      },
      isRowEditable: (row) => serviceRowEditable(row)
    },
  }

  const [
    editableGrid,
    setInEditMode,
    editableTopGridColumns,
    editableTopGridDataSource,
    editableBottomGridColumns,
    editableBottomGridDataSource
  ] = useEditableGrid(editableGridProps)

  const onSetTopGridColumns = (columns) => {
    editableTopGridColumns.set(dpmsIdToEditableGrid(columns, requestId))
  }

  const onSetBottomGridColumns = (columns) => {
    const columnsToShow = [ 'responsible', 'start_date', 'end_date' ]
    let newColumns = contractServiceToEditableGrid(columns, {
      discipline_id: byString(serviceTemplate, 'discipline_id')
    })
    newColumns = showColumnsOnEditableGrid(newColumns, columnsToShow)
    editableBottomGridColumns.set(newColumns)
  }

  useEffect(() => {
    setPerformanceServiceModel((model) => {
      model.setStatuses(progressServiceStatuses)
      return model
    })
  }, [progressServiceStatuses])

  useEffect(() => {
    if (!request) return
    if (!Object.keys(requestStatuses).length) return

    const requestStatusI18nId = requestStatuses[request.request_status_id].i18n_id
    setAuthorizedProgressCreation(canCreateProgress(requestStatusI18nId))
  }, [request, requestStatuses])

  const backURLConfig = () => ({
    eavTemplateId: templateId,
    selectItemId,
    selectServiceId,
    requestId
  })

  const handleCloseSidePanel = () => {
    setProgressSelectedItem()
    setServiceSelectedItem()

    dispatch(BusEvents.SIDE_PANEL_CLOSED)

    setColumnStyles(SIDE_PANEL_CLOSED)
  }

  const onProgressServiceRowClick = useCallback((e) => {
    setServiceSelectedItem((prevServiceSelectedItem) => {
      if (!e) return

      const { dataItem } = e
      let newItem
      if (!prevServiceSelectedItem || prevServiceSelectedItem.id !== dataItem.id)
        newItem = dataItem

      return newItem
    })
  }, [])

  const onProgressRowClick = useCallback((e) => {
    setProgressSelectedItem((prevProgressSelectedItem) => {
      if (prevProgressSelectedItem) {
        handleCloseSidePanel()
        return prevProgressSelectedItem
      }

      setColumnStyles(SIDE_PANEL_OPENED)
      return e.dataItem
    })
  }, [])

  const onProgressGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    editableTopGridDataSource.set(dataSource)

    const newSelectedItem = dataSource.data.filter((item) => (
      item.id === selectItemId || (progressSelectedItem && item.id === progressSelectedItem.id)
    ))[0]

    if (!isEqual(progressSelectedItem, newSelectedItem)) {
      if (!newSelectedItem) handleCloseSidePanel()
      else {
        if (selectItemId) setSelectItemId()
        setProgressSelectedItem(newSelectedItem)
        setColumnStyles(SIDE_PANEL_OPENED)
      }
    }
  }

  const onProgressServiceGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    editableBottomGridDataSource.set(dataSource)

    const selectedService = dataSource.data.filter((item) => (
      item.id === selectServiceId || (serviceSelectedItem && item.id === serviceSelectedItem.id)
    ))[0]

    if (!isEqual(serviceSelectedItem, selectedService)) {
      if (selectServiceId) setSelectServiceId()
      setServiceSelectedItem(selectedService)
      setColumnStyles(SIDE_PANEL_OPENED)
    }
  }

  const useProgressProps = {
    request,
    handleCloseSidePanel,
    setShowNewButton,
    progressSelectedItem,
    serviceSelectedItem,
    onClick: onProgressRowClick,
    showConfirmation,
    setInEditMode,
    onGridColumns: onSetTopGridColumns,
    onGridDataSource: onProgressGridDataSource
  }

  const useProgressServiceProps = {
    requestId,
    handleCloseSidePanel,
    progressSelectedItem,
    serviceSelectedItem,
    serviceModel: performanceServiceModel,
    onClick: onProgressServiceRowClick,
    showConfirmation,
    setInEditMode,
    onGridColumns: onSetBottomGridColumns,
    onGridDataSource: onProgressServiceGridDataSource
  }

  const [progressGrid, progressSidePanel] = useProgresses(useProgressProps)
  const [
    serviceGrid,
    serviceSidePanel,
    serviceTemplate
  ] = useProgressServices(useProgressServiceProps)

  const newServiceQuery = () => {
    if (!progressSelectedItem || !serviceTemplate) return ''

    const query = [`eav_template_id=${serviceTemplate.id}`]
    query.push(`discipline_id=${serviceTemplate.discipline_id}`)
    query.push(`progress_id=${progressSelectedItem.id}`)
    query.push(`item_template_id=${progressSelectedItem.eav_template_id}`)
    if (progressSelectedItem) query.push(`select_item_id=${progressSelectedItem.id}`)
    if (serviceSelectedItem) query.push(`select_service_id=${serviceSelectedItem.id}`)

    if (requestId) query.push(`request_id=${requestId}`)

    return query.join('&')
  }

  const newServicePackageQuery = () => {
    if (!progressSelectedItem || !serviceTemplate) return ''

    const query = [`eav_template_id=${serviceTemplate.id}`]
    query.push(`discipline_id=${serviceTemplate.discipline_id}`)
    query.push(`progress_id=${progressSelectedItem.id}`)
    query.push(`item_template_id=${progressSelectedItem.eav_template_id}`)
    query.push(`request_id=${progressSelectedItem.request_id}`)
    query.push(`filtered_by_request=${requestId ? 1 : 0}`)
    if (progressSelectedItem) query.push(`select_item_id=${progressSelectedItem.id}`)
    if (serviceSelectedItem) query.push(`select_service_id=${serviceSelectedItem.id}`)

    return query.join('&')
  }

  const newItemButtons = () => {
    const opts = {
      buttons: [
        {
          route: 'performances',
          action: 'new',
          query: buildBackURL(backURLConfig()),
          title: I18n.t('progresses.choose_modal.single'),
          subtitle: I18n.t('progresses.choose_modal.single_description'),
          icon: () => <MdControlPoint />
        }
      ],
      title: I18n.t('progress_services.choose_modal.title')
    }

    if (!progressSelectedItem) return opts

    const itemStatusI18n = progressStatuses[progressSelectedItem.progress_status_id].i18n_id

    if (!['canceled', 'done'].includes(itemStatusI18n)){
      opts.buttons.push({
        route: 'progress_services',
        action: 'new',
        query: newServiceQuery(),
        title: I18n.t('progress_services.choose_modal.single'),
        subtitle: I18n.t('progress_services.choose_modal.single_description'),
        icon: () => <MdControlPoint />
      })

      opts.buttons.push({
        route: 'progress_services',
        action: 'create_by_package',
        query: newServicePackageQuery(),
        title: I18n.t('progress_services.choose_modal.package'),
        subtitle: I18n.t('progress_services.choose_modal.package_description'),
        icon: () => <MdControlPointDuplicate />
      })
    }

    return opts
  }

  useBus(
    BusEvents.SIDEBAR_ADD_BUTTON_CLICKED,
    () => {
      if (!templateId) {
        notifyError(I18n.t('templates.no_template'))
        return
      }
      if (authorizedProgressCreation === false) {
        notifyError(I18n.t('scopes.request_status_restriction')) // TODO custom msg according to progress AC
        return
      }
      if (progressSelectedItem) {
        if (!serviceTemplate) {
          notifyError(I18n.t('estimate_services.no_template'))
          return
        }
        setChooseFormModalVisibility(true)
      } else {
        let url = `/performances/new?eav_template_id=${templateId}`
        if (requestId) url += `&request_id=${requestId}`
        history.push(url)
      }
    }, [requestId, templateId, serviceTemplate, history, progressSelectedItem])

  useBus(BusEvents.EXITING_EDITABLE_MODE, ({ payload }) => {
    const item = payload()
    if (!item) return

    const { selectedItem, selectedService } = item

    setProgressSelectedItem(selectedItem && { ...selectedItem })
    setServiceSelectedItem(selectedService && { ...selectedService })
    setColumnStyles(selectedItem ? SIDE_PANEL_OPENED : SIDE_PANEL_CLOSED)
  }, [])

  return (
    <Layout
      key={requestId ? `progresses-filter-${requestId}` : 'progresses'}
      pageTitle={I18n.t('progresses.title')}
      showNewButton={showNewButton}
    >
      {renderConfirmation()}
      {editableGrid(
        <React.Fragment>
          <TabsWrapper tabType={progressModel.templateType} />
          <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
            <div className={progressSelectedItem ? 'shadowed-page' : 'unshadowed-page'}>
              {chooseFormModalVisibility && (
                <ChooseFormModal
                  onClose={() => setChooseFormModalVisibility(false)}
                  opts={newItemButtons()}
                />
              )}
              <div
                className={progressSelectedItem ? 'scope-item-selected' : 'scope-item-not-selected'}
              >
                {progressGrid()}
              </div>
              {serviceGrid(serviceTemplate)}
            </div>
            {serviceSelectedItem ? serviceSidePanel() : progressSidePanel()}
          </LayoutPanels>
        </React.Fragment>
      )}
    </Layout>
  )
}
