/* eslint-disable max-lines-per-function */
import { useMemo } from 'react'
import { useStore } from 'react-context-hook'
import { indexify } from '/src/utils/array'
import { byString } from '/src/utils/object'

export default function useGridSettings({ templateId }) {
  const [gridSettings, setGridSettings] = useStore('grid_settings')
  
  const templateSettings = useMemo(() => {
    if (Array.isArray(gridSettings[templateId])) 
      return { columnsSettings: indexify(gridSettings[templateId], 'description') }
    return gridSettings[templateId] || { columnsSettings: {} }
  }, [gridSettings, templateId])

  const getColumnsWithUpdatedSettings = (columns) => {
    let { columnsOrder } = templateSettings
    const { columnsSettings } = templateSettings

    let updatedColumns = [...columns]

    if (columnsSettings && Object.keys(columnsSettings).length > 0) {
      updatedColumns = columns.map((column) => {
        const columnKey = column.description
        const columnSettings = templateSettings.columnsSettings[columnKey]

        if (columnSettings) {
          if (columnSettings.hide === true && columnsOrder) {
            columnsOrder = columnsOrder.filter((c) => c !== columnKey)
          }
          column.hide = columnSettings.hide || column.hide
          column.width = columnSettings.width || column.width
        }

        return column
      })
    }
    // Update the order index of the columns
    const orderedColumns = updatedColumns.map((c) => {
      const colWithIndex = { ...c }
      const orderIndex = columnsOrder ? columnsOrder.indexOf(c.description) : -1
      colWithIndex.orderIndex = orderIndex === -1 ? undefined : orderIndex + 1
      return colWithIndex
    })
    return orderedColumns
  }

  const getSorting = () => {
    return byString(gridSettings, `${templateId}.sorting`)
  }

  const setSorting = (sorting) => {
    setGridSettings({ ...gridSettings, [templateId]: { ...templateSettings, sorting } })
  }


  const setHiddenColumns = (columns) => {
    const newGridSettings = { ...gridSettings }
    newGridSettings[templateId] = getFormattedHiddenColumns(columns)
    setGridSettings(newGridSettings)
  }

  const setColumnWidth = (columnName, width) => {
    const newGridSettings = { ...gridSettings }

    const columnsSettings = templateSettings.columnsSettings || {}

    columnsSettings[columnName] = { ...columnsSettings[columnName], width }
    newGridSettings[templateId] = { ...templateSettings, columnsSettings }
    setGridSettings(newGridSettings)
  }

  const setColumnsOrder = (columns) => {
    const order = []
    columns.forEach((col) => order[col.orderIndex] = col.description)
    const newGridSettings = { ...gridSettings }

    templateSettings.columnsOrder = order
    newGridSettings[templateId] = templateSettings
    setGridSettings(newGridSettings)
  }

  const getFormattedHiddenColumns = (columns) => {
    const { columnsSettings } = templateSettings
    if (!columnsSettings) return templateSettings
    columns.forEach((column) => {
      if (column.hide === true)
        columnsSettings[column.description] = { ...columnsSettings[column.description], hide: column.hide }
      else if (columnsSettings[column.description])
        delete columnsSettings[column.description].hide
    })
    return templateSettings
  }

  return { getColumnsWithUpdatedSettings, setHiddenColumns, setColumnWidth, setColumnsOrder, getSorting, setSorting }
}
