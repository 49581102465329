import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function ColumnFooterTitle({ selected }) {
  if (selected > 1) return (
    <td className="grid-total">
      {selected} 
      {' '}
      {I18n.t('grid.selecteds')}
    </td>
  )

  if (selected === 1) return (
    <td className="grid-total">
      {selected} 
      {' '}
      {I18n.t('grid.selected')}
    </td>
  )
  return <td className="grid-total">{I18n.t('grid.totals')}</td>
}

ColumnFooterTitle.propTypes = {
  selected: PropTypes.number
}

ColumnFooterTitle.defaultProps = {
  selected: 0
}
