import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import useFetchById from '/src/hooks/api/fetch_by_id'
import useGridFilterSelection from '/src/hooks/grid_filter_selection'
// eslint-disable-next-line max-len
import useRowRenderPerformanceGrids from '/src/ui/domain/progresses/row_render_performance_grids'
import CellFactory from '/src/ui/core/grid/column_cell_factory/cell_factory'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import ProgressServicesStatusFilterIcon from '/src/ui/domain/progress_services/progress_services_status_filter_icon'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import GridFilterIcon from '/src/ui/core/icons/grid_filter_icon'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import { bulkEditingItem } from '/src/ui/core/grid/context_menu_entries'
import ProgressServicesDateFilter from '/src/ui/domain/progress_services/progress_services_date_filter'
import '/src/static/css/core/grid/grid.css'

// eslint-disable-next-line max-lines-per-function
export default function ProgressServicesGrid({ parentItem, selectedItem, onRowClick,
                                               contextMenuItems, filtered, template,
                                               labels, model, requestIdPin, setInEditMode,
                                               onGridColumns, onDataSource }) {
  const templateId = template ? template.id : undefined
  const defaultFilter = template ? [{
    type: 'where', column: 'eav_template_id', operator: 'eq', value: templateId
  }] : []

  const {
    filter: requestFilter,
    gridTitle: requestTitle,
    labels: requestLabel
  } = useRequestGridFilter(requestIdPin, model.path, null, templateId, 'progress')

  const gridTitle = () => {
    return requestIdPin ? requestTitle : model.name
  }

  const pinLabel = () => {
    return requestIdPin ? requestLabel : []
  }

  const [filter, setFilter] = useState(defaultFilter.concat(requestFilter))

  const [requestId, setRequestId] = useState()
  const [selectedDateFilterOption, setSelectedDateFilterOption] = useState(
    I18n.t('progress_services.date_filter.all')
  )
  const [selectedStatusFilterOption, setSelectedStatusFilterOption] = useState()
  const request = useFetchById('requests', requestId)
  const onRowRender = useRowRenderPerformanceGrids('progress_service')

  const onFilterSelection = useGridFilterSelection(setFilter)

  const moreActionsMenuItems = model.path !== 'plannings' ? [
    bulkEditingItem(() => setInEditMode('bottom'))
  ] : []

  const icons = [
    <GridFilterIcon
      template={template}
      model="ProgressService"
      onFilterSelection={onFilterSelection}
    />,
    <ProgressServicesStatusFilterIcon
      key='request-status-filter-icon'
      filter={filter}
      onFilter={setFilter}
      onSelectedOptionChange={setSelectedStatusFilterOption}
    />,
    <ProgressServicesDateFilter
      filter={filter}
      onFilter={setFilter}
      onSelectedOptionChange={setSelectedDateFilterOption}
    />,
    <MoreActionsIcon items={moreActionsMenuItems} />
  ]

  useEffect(() => {
    if (parentItem && parentItem.request_id) setRequestId(parentItem.request_id)
  }, [parentItem])

  useEffect(() => {
    if (!parentItem) {
      setFilter(defaultFilter)
    } else {
      setFilter(defaultFilter.concat({ type: 'where', column: 'progress_id', value: parentItem.id }))
    }
  }, [parentItem, template])

  useEffect(() => {
    setFilter((oldFilter) => {
      const cleanedFilter = oldFilter.filter((f) => !/request_id$/.test(f.column))
      if (requestFilter.length === 0) return cleanedFilter

      return Object.values(cleanedFilter).concat(requestFilter)
    })
  }, [requestFilter])

  const dateFilterLabel = () => (
    <div
      key="progress-services-date-filter"
      className="date-filter-label"
    >
      {selectedDateFilterOption}
    </div>
  )

  const statusFilterLabel = () =>
    selectedStatusFilterOption && selectedStatusFilterOption.length > 0 && (
      <div
        key="progress-services-status-filter"
        className="date-filter-label"
      >
        { `${I18n.t("progress_services.flag_filter")}: ${selectedStatusFilterOption.join(", ")}`}
      </div>
    )

  return (
    (!filtered || filter.length > 1) ? (
      <div className="subgrid" data-testid="progress-service-grid">
        <SimpleGrid
          loadFlexColumns
          model={model}
          sort={[{ field: 'id', dir: 'desc' }]}
          filter={filter}
          labels={labels.concat([dateFilterLabel(), statusFilterLabel(), pinLabel()])}
          contextMenuItems={contextMenuItems}
          gridTitle={gridTitle()}
          icons={icons}
          templateId={templateId}
          columnCellFactory={(
            <CellFactory
              type={model.paramName}
              opts={{ request }}
            />
          )}
          selectedItem={selectedItem}
          onRowClick={onRowClick}
          onRowRender={onRowRender}
          onGridColumns={onGridColumns}
          onDataSource={onDataSource}
          selectFiltering={false}
          additionalEntities={[{ foreignKey: 'progress_service_statuses' }]}
        />
      </div>
    ) : null
  )
}

ProgressServicesGrid.propTypes = {
  parentItem: PropTypes.oneOfType([PropTypes.object]),
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  onRowClick: PropTypes.func,
  setInEditMode: PropTypes.func,
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ).isRequired,
  filtered: PropTypes.bool,
  template: PropTypes.oneOfType([PropTypes.object]),
  labels: PropTypes.arrayOf(PropTypes.object),
  model: PropTypes.oneOfType([PropTypes.object]),
  requestIdPin: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

ProgressServicesGrid.defaultProps = {
  parentItem: undefined,
  selectedItem: undefined,
  onRowClick: () => {},
  setInEditMode: () => {},
  filtered: true,
  labels: []
}
