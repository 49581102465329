import React, { useState, useEffect } from 'react'
import { Switch } from '@progress/kendo-react-inputs'
import PropTypes from 'prop-types'
import useInputError from '/src/ui/core/inputs/input_error'

export default function InputBoolean({ inputProps }) {
  const { id, className, value, onChange, readOnly, title, required } = inputProps
  const [inputValue, setValue] = useState(value || false)
  const error = useInputError({ inputValue, title, required })

  useEffect(() => { onChange(id, inputValue) }, [inputValue])

  useEffect(() => {
    const isChecked = (value || false)
    if (inputValue === isChecked) {
      onChange(id, inputValue)
    } else {
      setValue(isChecked)
    }
  }, [id])

  const onChangeInput = (e) => setValue(e.target.value)

  const inputClass = () => {
    const classes = ['switch']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  return (
    <Switch
      id={id}
      checked={String(inputValue) === 'true'}
      onChange={onChangeInput}
      className={inputClass()}
      disabled={readOnly}
    />
  )
}

InputBoolean.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
  }).isRequired
}
