/* eslint-disable max-lines-per-function */
import { useEffect, useState, useRef } from 'react'
import { objectEquals , byString } from '/src/utils/object'
import {  useStore } from 'react-context-hook'
import { normalizeDateToString } from '/src/utils/project_formatter'
import useObjectFiltering from '/src/ui/core/grid/object_filtering'

export default function useFiltering(filter, onChange, total, templateId) {

  const [templateFilters, setTemplateFilters] = useStore('template_filters')
  
  function getGridSettings(id, type) {
    const templates = templateFilters || "{}"
    return templates[id] && templates[id][type]
  }

  const oldFiltering = useRef()
  const [filtering, setFiltering] = useState( getGridSettings(templateId, 'filtering') || { filter: { logic: 'and', filters: [] } })
  const [dynamicFilters, setDynamicFilters] = useState( getGridSettings(templateId, 'dynamicFilters') || { filter: { logic: 'and', filters: [] } })
  const treatObjectFilter = useObjectFiltering()
  
  useEffect(() => {
    let filters = []
    if (filter && filter.length > 0) filters = filter

    // remove duplicated
    const nonDuplicatedItems = [...filtering.filter.filters]
    filters.forEach((f)=> {
      if (!nonDuplicatedItems.find((nonDuplicated) => objectEquals(nonDuplicated, f))) {
        nonDuplicatedItems.push(f)
      }
    })

    // compare and remove deleted filters in filter props
    const removedFilters = []
    oldFiltering.current && oldFiltering.current.forEach((old)=> {
      if (!filters.find((f) => objectEquals(f, old))) {
        removedFilters.push(old)
      }
    })

    removedFilters.forEach((f)=> {
      const index = nonDuplicatedItems.findIndex((nonDuplicated) => objectEquals(nonDuplicated, f))
      if (index > -1) nonDuplicatedItems.splice(index, 1)
    })

    oldFiltering.current = filters
    setFiltering({ filter: { logic: 'and', filters: nonDuplicatedItems } })
  }, [filter])
  
  useEffect(() => {
    if (onChange) onChange({ ...filtering, total })
  }, [filtering, total, onChange])

  useEffect(() => {
    if (!templateId) return

    const notValueForFilter = ['progress][request_id', 'eav_template_id', 'request_status_id',
                               'parent_id', 'request_id', 'id', 'scope_id', 'progress_id',
                               'progress_service_status_id']

    const dynamicFiltersFilters = byString(dynamicFilters, 'filter.filters')

    const newDynamicFiltersFilters = dynamicFiltersFilters ? dynamicFiltersFilters.filter((f) => {
      const [ actualFilter ] = f.filters
      return !notValueForFilter.includes(actualFilter.field)
    }) : []

    const newDynamicFiltersObj = { filter: { filters: newDynamicFiltersFilters } }

    const newFilters = filtering.filter.filters.filter((f) => {
      return (!notValueForFilter.includes(f.column))
    })

    const count = newFilters.length + byString(dynamicFilters, 'filter.filters.length') 

    if(count > 0) {
      setTemplateFilters({
        ...templateFilters,
        [templateId]: {
          filtering: { filter: { logic: 'and', filters: newFilters } }, 
          dynamicFilters: newDynamicFiltersObj 
        } 
      })
    } else {
      setTemplateFilters({
        ...templateFilters,
        [templateId]: undefined
      })
    }
  }, [filtering, dynamicFilters])

  useEffect(() => {
    if (!templateFilters) return

    localStorage.setItem('template_filters', JSON.stringify(templateFilters))
  }, [templateFilters])

  const convertFilters = (dataFilter) => {
    const operatorConversion = { 'eq': 'where', 'neq': 'where_not' }
    let convertedFilters = dataFilter.filters

    if (convertedFilters.length > 0) {
      convertedFilters = convertedFilters.map((f) => {
        const type = operatorConversion[f.operator] || f.operator
        const value = f.value instanceof Date ? normalizeDateToString(f.value) : f.value

        return { type, column: f.field, value, field: f.originalField }
      })
    }

    return convertedFilters
  }

  const clearFilteringColumn = (column) => {
    setFiltering({
      logic: filtering.filter.logic,
      filter: {
        ...filtering.filter,
        filters: filtering.filter.filters.filter((f) => {
          return f.field !== column || filter.includes(f)
        })
      }
    })
  }

  const onFilterChange = (event, columns) => {
    setDynamicFilters(event || dynamicFilters)

    if (event.filter === null) {
      setFiltering({ filter: { logic: 'and', filters: filter } })
      return
    }

    const dataFilters = event.filter.filters.map((df) => {
      const dataFilter = { ...df }

      if (dataFilter.filters) {
        dataFilter.filters = dataFilter.filters.map((f) => {
          const gridFilter = { ...f, originalField: f.field }
          return treatObjectFilter(gridFilter, columns)
        })
      }

      return dataFilter
    })

    if (event.nativeEvent.type === 'reset') {
      clearFilteringColumn(event.syntheticEvent.column)
      return
    }

    const eventFilterObject = dataFilters[dataFilters.length-1]
    const convertedFilters = convertFilters(eventFilterObject)

    const clearedDuplicate = filtering.filter.filters.filter((f) => {
      return f.column !== convertedFilters[0].column || filter.includes(f)
    })

    setFiltering({ 
      filter: {
        logic: eventFilterObject.logic,
        filters: [...clearedDuplicate, ...convertedFilters]
      } 
    })
  }

  const hasGridFilters = () => {
    if (filtering.filter === null || filtering.filter.filters.length === 0) return false

    const appliedFilters = filtering.filter.filters
    return appliedFilters.length > filter.length
  }

  const clearColumnFilter = (columnName) => {
    clearFilteringColumn(columnName)
    setDynamicFilters({
      logic: dynamicFilters.filter.logic,
      filter: {
        filters: dynamicFilters.filter.filters.filter((f) => {
          return f.filters.every((i) => i.field !== columnName)
        })
      }
    })
  }

  return { filtering, onFilterChange, hasGridFilters, clearColumnFilter, dynamicFilters }
}
