/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { MdAddCircle } from 'react-icons/md'
import RequestModel from '/src/models/request'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useFetchSections from '/src/hooks/api/fetch_sections'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import Steps from '/src/ui/core/layouts/steps'
import ImportDropdown from '/src/ui/core/inputs/import_dropdown'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import FormButtons from '/src/ui/core/forms/form_buttons'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import Form from '/src/ui/core/forms/form'
import ThirdStep from '/src/ui/domain/requests/third_step'
import RequestStatusLabel from '/src/ui/domain/requests/request_status_label'
import I18n from '/src/utils/translations'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/buttons/buttons.css'
import '/src/static/css/inputs.css'

export default function RequestForm({ requestDataItem, type }) {
  const importDropdown = (key = 0) => (
    <ImportDropdown
      key={key}
      model={{
        route: "eav_templates",
        importRoute: "scopes",
        name: I18n.t('requests.eav_template')
      }}
      filter={{ where: { template_type: 'scoping' } }}
      onFileSelected={setTemplatesWithScopes}
    />
  )

  const [statuses] = useStore('request_statuses')
  const [requestModel, setRequestModel] = useState(new RequestModel())
  const [includeOnForm, setIncludeOnForm] = useState({})
  const [templatesWithScopes, setTemplatesWithScopes] = useState({})
  const [allTemplates, setAllTemplates] = useState([importDropdown()])
  const [createdRequest, setCreatedRequest] = useState()
  const getContract = useFetchAPI('project_contracts')
  const [subproject] = useStore('subproject')
  const sections = useFetchSections(subproject.request_eav_template_id)
  const [showConfirmation, renderConfirmation] = useConfirmationModal()

  useEffect(() => {
    setRequestModel((model) => {
      model.setStatuses(statuses)
      return model
    })
  }, [statuses])

  useBus(
    BusEvents.FORM_SUCCESS,
    (response) => {
      setCreatedRequest(response.payload)
    }, [setCreatedRequest])

  useEffect(() => {
    if(createdRequest) confirmRequest(createdRequest)
  }, [createdRequest])

  // Only enable the apply package button if the user successfuly imported
  // at least one file of scope items
  const isApplyPackageButtonEnabled = () => {
    return Object.keys(templatesWithScopes).length > 0
  }

  // TODO: This is just a provisory solution. Ater the refactoring of the Model's options to hide
  // specific fields, we should be able to remove this effect.
  useEffect(() => {
    if (type !== 'duplicate' || Object.keys(statuses).length === 0 || requestDataItem === null) return

    setRequestModel(requestModel)
  }, [statuses, requestDataItem])

  useEffect(() => {
    if (subproject.id === null) return
    fetchContract()
    setIncludeOnForm({
      eav_template_id: subproject.request_eav_template_id,
      subproject_id: subproject.id,
      elevation: 0
    })
  }, [subproject])

  useEffect(() => {
    const { loading, errors, responseData } = getContract
    if (!loading && !errors && responseData.data.length > 0) {
      const contract = responseData.data[0]
      includeDefaultValuesOnModel(contract)
    }
  }, [getContract.loading, getContract.errors, getContract.responseData])

  const params = { requestAction: 'READ', httpAction: 'get' }

  const fetchContract = () => {
    const query = { where: { id: subproject.project_contract_id } }
    getContract.fetchAPI({ ...params, query })
  }

  /**
   * It will include default values on skip_scoping
   * and estimates_authorization on the request model
   */
  const includeDefaultValuesOnModel = (contract) => {
    const { columns } = requestModel
    const skipScoping = columns.find((column) => column.description === 'skip_scoping')
    skipScoping.default = contract.skip_scoping
    const authorization = columns.find((column) => column.description === 'estimates_authorization')
    authorization.default = contract.estimates_authorization
    setRequestModel(requestModel)
  }

  const duplicationCustomAction = {
    requestAction: 'CREATE',
    httpAction: 'post',
    resourceId: requestDataItem ? requestDataItem.id : {},
    additionalResource: { path: 'duplicate_hierarchy' }
  }

  const duplicationCustomError = (errors) => {
    const partialSuccess = I18n.t('api_internal_messages.only_request_copy')
    const messages = errors.join().match(`${partialSuccess}\n(.*)`)
    const requestError = (messages ? messages[1] : null)
    if (requestError === '') {
      const causingError = errors.join().match(`(.*\n.*)\n{2}${partialSuccess}`)[1]
      const renderedError = {
        title: I18n.t(`notification.error`),
        body: causingError,
        status: 'error',
        closable: true,
        closeTimeout: 10
      }
      return renderedError
    }
  }

  const requestModelSection = () => (
    <React.Fragment>
      <Form
        model={requestModel}
        sections={sections}
        dataItem={requestDataItem}
        includeOnForm={includeOnForm}
        templateId={subproject.request_eav_template_id}
        type={type}
        disableColumnEdition={(column) => (type === 'duplicate' && column.type === 'picture')}
        submitParams={type == 'duplicate' ? duplicationCustomAction : undefined}
        errorHandler={type == 'duplicate' ? duplicationCustomError : undefined}
      />
      <FormButtons model={requestModel} awaitFormSuccess />
    </React.Fragment>
  )

  const addNewTemplate = () => (
    setAllTemplates([
      ...allTemplates,
      importDropdown(allTemplates.length)
    ])
  )

  const confirmRequest = (dataItem) => {
    const completeSuccess = () => {
      notifySuccess(
        I18n.t('notification.action_success', {
          action: I18n.t(`actions.past.confirm`),
          model: I18n.t('requests.title'),
          id: dataItem.id
        })
      )
    }

    const cancelClicked = () => {
      dispatch(BusEvents.FORM_CANCEL_BUTTON_CLICKED)
    }

    const confirmationParams = {
      title: I18n.t('requests.confirmation_modal.confirm_title'),
      description: I18n.t('requests.confirmation_modal.confirm_description', { id: dataItem.id }),
      actionButtonText: I18n.t('requests.confirmation_modal.confirm_confirmation'),
      cancelButtonText: I18n.t('requests.confirmation_modal.confirm_cancel'),
      buttonColor: '#607d8b',
      onSuccess: () => completeSuccess,
      onCancel: () => cancelClicked,
      requestParams: {
        resourcePath: 'requests',
        requestAction: 'UPDATE',
        httpAction: 'put',
        resourceId: dataItem.id,
        additionalResource: { path: 'realize' }
      }
    }

    showConfirmation(confirmationParams)
  }

  const getCreationSections = () => {
    if (!sections) return []
    return [{ id: 'general', title: I18n.t('form.general_information') }, ...sections]
  }

  const createSections = () => (
    <Steps
      steps={[{
        id:'first-step',
        awaitFormSuccess: true,
        title: I18n.t('requests.creation_page.first_step'),
        subSections: getCreationSections(),
        body: requestModelSection()
      }, {
        id:'second-step',
        title: I18n.t('requests.creation_page.second_step'),
        body: (
          <React.Fragment>
            {renderConfirmation()}
            <div className="request-form-scope-importation">
              {allTemplates}
              <button
                className="button-cleaned add-template-button"
                type="button"
                onClick={() => addNewTemplate()}
              >
                <MdAddCircle className="add-template-icon" />
                {I18n.t('requests.creation_page.add_template')}
              </button>
              <FormButtons model={requestModel} />
            </div>
          </React.Fragment>
        ),
        isNextButtonEnabled: isApplyPackageButtonEnabled()
      }, {
        id: 'third-step',
        title: I18n.t('requests.creation_page.third_step'),
        body: (
          <div>
            <ThirdStep dataItem={createdRequest} templates={templatesWithScopes} />
            <FormButtons model={requestModel} />
          </div>
        )
      }]}
      modelName="requests"
    />
  )

  const willEdit = () => requestDataItem && (type === 'edit')

  return (
    <FormWrapper
      model={requestModel}
      dataItem={requestDataItem}
      label={willEdit() ? requestDataItem.id : createdRequest && createdRequest.id}
      badge={willEdit() ? <RequestStatusLabel dataItem={requestDataItem} /> : undefined}
      type={type}
      classes={type === 'new' ? 'full' : ''}
      showSidePanel={['edit', 'duplicate']}
      sidePanelSections={getCreationSections()}
    >
      {requestDataItem ? requestModelSection() : createSections()}
    </FormWrapper>
  )
}

RequestForm.propTypes = {
  requestDataItem: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.string.isRequired
}

RequestForm.defaultProps = {
  requestDataItem: null,
}
