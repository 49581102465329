import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import ContractItemBlock from '/src/ui/domain/blocks/contract_item_block'
import PlanningChartBlock from '/src/ui/domain/progress_services/planning_chart_block'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
// eslint-disable-next-line max-len
import GeneralTooltip from '/src/ui/core/tooltips/general_tooltip'
import ProgressServicesTrackBlock from '/src/ui/domain/progress_services/progress_services_track_block'
import ContributionBlock from '/src/ui/domain/progress_services/contribution_block'
import ServicePlanningBlock from '/src/ui/domain/progress_services/service_planning_block'
import ProductivityFactorBlock from '/src/ui/domain/progress_services/productivity_factor_block'
import PlanningTimelineBlock from './planning_timeline_block'
import '/src/static/css/model_side_panel.css'
import '/src/static/css/domain/progress_services/progress_service_side_panel.css'

export default function ProgressServicesSidePanel({ dataItem, onClose,
                                                    sections, contextMenuItems }) {
  const sectionBlocks = useSectionBlock({ sections, dataItem })
  const [disciplines] = useStore('disciplines')
  const [project] = useStore('project')

  const newDataItem = {
    ...dataItem,
    unit: dataItem.contract_service.unit,
    discipline: disciplines[dataItem.discipline_id]
  }

  const contractItemBlock = {
    component: <ContractItemBlock dataItem={newDataItem} templateType='progress_services' />,
    title: I18n.t('progress_services.contract_service'),
    type: 'general'
  }

  const buildSummary = () => ({
    component: (
      <ProgressServicesTrackBlock
        dataItem={dataItem}
        contextMenuItems={contextMenuItems}
      />
    ),
    title: '',
    type: 'track',
    key: 'progress-service-track-block'
  })

  const buildContributionBlock = () => {
    const title = (
      <React.Fragment>
        <span>{I18n.t('progress_services.contribution')}</span>
        <GeneralTooltip>
          <div className="info-icon" title={I18n.t('progress_services.contribution_info')}>i</div>
        </GeneralTooltip>
      </React.Fragment>
    )

    return {
      key: 'contribution_block',
      component: <ContributionBlock dataItem={dataItem} />,
      title,
      type: 'info'
    }
  }

  const buildPlanningTimelineBlock = () => ({
    component: <PlanningTimelineBlock dataItem={dataItem} />,
    title: '',
    key: 'planning_timeline',
    type: 'planning_timeline'
  })

  const buildServicePlanningBlock = () => {
    const title = (
      <React.Fragment>
        <span>{I18n.t('progress_services.service_planning')}</span>
        <GeneralTooltip>
          <div className="info-icon" title={I18n.t('progress_services.contribution_info')}>i</div>
        </GeneralTooltip>
      </React.Fragment>
    )

    return {
      key: 'service_planning_block',
      component: <ServicePlanningBlock dataItem={dataItem} />,
      title,
      type: 'info'
    }
  }

  const buildPlanningChartBlock = () => ({
    component: <PlanningChartBlock dataItem={dataItem} project={project} />,
    title: I18n.t('progress_services.planning_chart.title'),
    type: 'planning'
  })

  const buildPFBlock = () => ({
    component: <ProductivityFactorBlock dataItem={dataItem} />,
    title: '',
    key: 'productivity_factor_block',
    type: 'pf'
  })

  return dataItem && (
    <div className="model-side-panel">
      <BlocksSidePanel
        title={I18n.t('estimate_services.estimate_services_detail.side_panel_title')}
        closable
        onClose={onClose}
        blocks={[
          buildSummary(),
          contractItemBlock,
          buildServicePlanningBlock(),
          buildPlanningTimelineBlock(),
          buildPFBlock(),
          buildPlanningChartBlock(),
          buildContributionBlock(),
          ...sectionBlocks]}
      />
    </div>
  )
}

ProgressServicesSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.oneOfType([PropTypes.object]).isRequired,
}
