import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { formatCurrency, formatNumberWithPrecision } from '/src/utils/project_formatter'
import EstimateServiceValuesTooltip from '/src/ui/domain/estimates/estimate_service_values_tooltip'

/**
 * This custom hook will receive a array of estimate services
 * and will generate building blocks to be used with <Card /> and <ExpandableCard />
 *
 * @param  estimateServices - array of estimate_services
 * @return {[{ tag, title, subtitle, details, id }]} - array of card building blocks
 */
export default function useEstimateServicesCardsBlocks(estimateServices) {
  const [cardParts, setCardParts] = useState([])
  const [project] = useStore('project')

  useEffect(() => {
    if (!estimateServices || !estimateServices.length) return

    setCardParts(estimateServices.map(buildCard))
  }, [estimateServices])


  const buildTag = (tag) => <div className="estimate-item-tag">{tag}</div>

  const buildTitle = (title) => <div className="estimate-item-title">{title}</div>

  const buildSubtitle = (itemService, qtt, hours) => (
    <div className="estimate-item-subtitle">
      <div className="item-service">{itemService}</div>
      <div className="info space">{qtt}</div>
      <div className="info time">{hours}</div>
    </div>
  )
  const getEstimatesValues = (estimateService, unit) => {
    const {
      quantity,
      application_price: applicationPrice,
      materials_price: materialsPrice,
      equipment_price: equipmentPrice,
      other_price: otherPrice,
      team_target_hours: teamTargetHours
    } = estimateService

    const qtt = `${formatNumberWithPrecision(quantity, project)} ${unit.description}`
    const hours = `${formatNumberWithPrecision(teamTargetHours, project)}h`
    const price =  applicationPrice + materialsPrice + equipmentPrice + otherPrice
    const formattedPrice = formatCurrency(price, project)

    return { qtt, hours, price, formattedPrice }
  }

  const buildCard = (estimateService, index) => {
    const {
      id,
      description,
      number,
      scopeNumber,
      unit,
      eav_template_id: templateId
    } = estimateService

    if (!unit) return

    const { qtt, hours, price, formattedPrice } = getEstimatesValues(estimateService, unit)
    const tag = buildTag(index+1)
    const title = buildTitle(description)
    const subtitle = buildSubtitle(`Item ${scopeNumber} - Service ${number}`, qtt, hours)
    const details = (
      <EstimateServiceValuesTooltip
        estimateService={estimateService}
        totalPrice={formattedPrice}
      />
    )

    return {
      tag,
      title,
      subtitle,
      details,
      price,
      key: id,
      templateId
    }
  }

  return cardParts
}
