import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Layout from '/src/ui/core/layouts/layout'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useModelController from '/src/hooks/model_controller'
import ScheduleForm from '/src/ui/domain/inspections/schedule_form'
import { useQueryParam, NumberParam } from 'use-query-params'

export default function ScheduleFormPage({ match }) {
  const history = useHistory()
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const [queryString, setQueryString] = useState(`?eav_template_id=${templateId}`)
  const scheduleFormType = 'new'

  const { dataItem, loading } = useModelController({
    modelType: 'inspections',
    dataItemId: match.params.id,
    onBack: () => history.push(`/inspections${queryString}`),
    onFinish: () => history.push(`/inspections${queryString}`),
  })

  return (
    <Layout key={dataItem} showProjectSwitcher={false}>
      {loading ? (
        <ThreeDotsLoader />
       ) : (
         <ScheduleForm
           dataItem={dataItem}
           templateId={templateId}
           type={scheduleFormType}
         />
       )}
    </Layout>
  )
}

ScheduleFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      collection: PropTypes.string
    })
  })
}

ScheduleFormPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined,
      collection: undefined
    })
  })
}
