import React, { useState } from 'react'
import { useQueryParam, NumberParam } from 'use-query-params'
import { useParams } from 'react-router-dom'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import PopupScopeLabel from '/src/ui/domain/scopes/popup_scope_label'

export default function LabelIcon() {
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [selectedItems, setSelectedItems] = useState([])
  const url = useParams()

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ payload }) => { setSelectedItems(payload) }, [setSelectedItems])

  const isScopePage = () => (url.resource === 'scopes' && !url.collection)
  const isVisible = () => (
    requestId && isScopePage() && selectedItems.length
  )

  return (
    isVisible() ? (
      <PopupScopeLabel
        selectedItems={selectedItems}
      />
  ) : null )
}
