import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MdAddCircle, MdEdit } from 'react-icons/md'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import InspectedModule from '/src/models/concerns/inspected_module'
import FilterStepsModal from '/src/ui/domain/inspections/popups/filter_steps_modal'
import '/src/static/css/core/inputs/input_filter.css'

export default function InputModuleFilter({ inputProps }) {
  const { onChange } = inputProps
  const { columns } = new InspectedModule()

  const [open, setOpen] = useState(false)
  const [stepFilters, setStepFilters] = useState(columns)
  const [currentValues, setCurrentValues] = useState({})

  useEffect(() => {
    updateCurrentValues(stepFilters)
  }, [stepFilters])

  const updateCurrentValues = () => {
    let newCurrentValues = {}
    stepFilters.forEach((f) => {
      const val = f.value

      onChange(f.description, val)
      if (!val) return

      newCurrentValues = { ...newCurrentValues, [f.description]: val }
    })

    setCurrentValues(newCurrentValues)
  }

  const onDone = (values, displayValues) => {
    const newStepFilters = stepFilters.map((f) => {
      return { ...f, value: values[f.description], displayValue: displayValues[f.description] }
    })

    setStepFilters(newStepFilters)
    setOpen(false)
  }

  const renderValues = (value) => {
    if (Array.isArray(value) && value[0] === null) return I18n.t('fields.na')

    return Array.isArray(value) ? value.map((v) => v.computed_text_field || v).join(", ") : value
  }

  const renderFilters = () => {
    return stepFilters.map((filter) => {
      const { id, title, displayValue } = filter
      return (
        <div key={id}>
          {`${title}: ${displayValue ? renderValues(displayValue) : I18n.t('fields.na')}`}
        </div>
      )
    })
  }

  return (
    <React.Fragment>
      <div className="filter">
        <div className="filter-header">
          <div className="filter-group-title">
            <div className="filter-title">{I18n.t('inspections.module')}</div>
          </div>
          <div className="filter-edit" onClick={() => { setOpen(true) }}>
            <MdEdit />
          </div>
        </div>
        <div className="filter-body">
          <div className="filters">
            {renderFilters()}
          </div>
        </div>
      </div>
      <div className="error-label" />
      {open && (
        <FilterStepsModal
          filters={stepFilters}
          skipEmpty={false}
          resultTitle={I18n.t('inspections.module')}
          onClose={() => setOpen(false)}
          currentValues={currentValues}
          onDone={onDone}
        />
      )}
    </React.Fragment>
  )
}

InputModuleFilter.propTypes = {
  inputProps: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })),
  }).isRequired
}
