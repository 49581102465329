// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class EstimateModel {
  name = I18n.t('estimate.title')

  singularName = I18n.t('estimate.title')

  paramName = 'estimate'

  route = 'estimates'

  flexible = false

  cannotEditStatuses = ['on_hold', 'pending_authorization', 'authorized', 'done']

  columns = [
    {
      id: 1,
      description: 'estimate_date',
      title: I18n.t('requests.schedule_form.date'),
      columnSize: 'medium',
      type: 'date'
    },
    {
      id: 2,
      description: 'responsible_id',
      title: I18n.t('inspections.responsible_id'),
      foreignKey: 'employees',
      foreignAttribute: 'responsible_id',
      searchFields: ['name', 'last_name'],
      searchExtraQuery: { where: { '[work_positions][operational]': 1 } },
      searchRoute: 'employees',
      columnSize: 'medium',
      textDisplayFields: ['full_name'],
      textField: 'full_name',
      type: 'search'
    },
  ]
}
