import React from 'react'
import PropTypes from 'prop-types'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import '/src/static/css/domain/requests/track_block.css'

export default function TrackBlock({ children, detail, actions, idText }) {
  const { id, reason, comments } = detail

  return (
    <div className="detail-summary">
      <div className="information">
        <div className="title-side-panel">
          { idText || I18n.t('track_block.id') }
          <span className="summary-request-id">{`#${id}`}</span>
        </div>
        {reason && (
          <div className="reference-side-panel">
            <div className="summary-label">{I18n.t('requests.reason')}</div>
            <div className="summary-value">{reason}</div>
          </div>
        )}
        <div className="comments-side-panel">
          <div className="summary-label">{I18n.t('requests.comments')}</div>
          <div className="summary-value">{comments}</div>
        </div>
        {children && <div className="children">{children}</div>}
      </div>
      <div className="summary-buttons">{actions}</div>
    </div>
  )
}

TrackBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.array
  ]),
  request: PropTypes.shape({
    id: PropTypes.number,
    reason: PropTypes.string,
    comments: PropTypes.string
  }),
  actions: PropTypes.element,
  idText: PropTypes.string
}

TrackBlock.defaultProps = {
  children: undefined,
  request: { reason: '', comments: '' },
  actions: null,
  idText: null
}
