// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class DatasheetModel {
  name = I18n.t('datasheets.title')

  singularName = 'datasheet'

  paramName = 'datasheet'

  templateType = 'datasheet'

  route = 'datasheets'

  flexible = true

  columns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('datasheets.id'),
      hideOnGrid: false,
      hideOnForm: true,
      width: 120,
      type: 'integer'
    },
    {
      id: 2,
      description: 'number',
      title: '#',
      readOnly: true,
      width: 200,
      type: 'integer'
    },
  ]
}
