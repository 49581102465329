import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { MdAddCircle, MdEdit, MdCheck } from 'react-icons/md'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import FilterStepsModal from '/src/ui/core/popups/filter_steps_modal'
import '/src/static/css/core/inputs/input_contract_service.css'

export default function InputContractService({ inputProps }) {
  const { onChange, dataItem, readOnly, control } = inputProps

  const disciplineId = control.getValues('discipline_id')

  const descriptionFilter = {
    filterAttribute: 'description',
    title: I18n.t('form.inputs.contract_service.subtitle'),
    query: { where: { discipline_id: disciplineId } }
  }

  const [subproject] = useStore('subproject')

  const [open, setOpen] = useState(false)
  const [stepFilters, setStepFilters] = useState()
  const [contractService, setContractService] = useState()

  const templateFetchAPI = useFetchAPI('eav_templates')
  const columnsFetchAPI = useFetchAPI('eav_columns')
  const contractServicesFetchAPI = useFetchAPI('contract_services')

  const ALLOWED_COLUMN_TYPES = ['string', 'integer', 'decimal', 'drop', 'lookup', 'formula',
                                'percentage', 'date', 'time', 'drop_formula']

  const filterColumns = (column) => {
    return ALLOWED_COLUMN_TYPES.includes(column.column_type.description) && column.visible_on_web
  }

  useEffect(() => {
    setStepFilters([descriptionFilter])
    if (!dataItem || !dataItem.contract_service_id) return

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: dataItem.contract_service_id } }
    }
    contractServicesFetchAPI.fetchAPI(params)
  }, [])

  useEffect(() => {
    if (!subproject || !subproject.id) return

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: {
          subproject_id: subproject.id,
          template_type: 'contract_service'
        }
      }
    }

    templateFetchAPI.fetchAPI(params)
  }, [subproject])

  useEffect(() => {
    if (templateFetchAPI.status !== 'SUCCESS' || templateFetchAPI.responseData.data.length == 0) return

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { eav_template_id: templateFetchAPI.responseData.data[0].id },
        order: { position: 'asc' }
      }
    }

    columnsFetchAPI.fetchAPI(params)
  }, [templateFetchAPI.status, templateFetchAPI.responseData])

  useEffect(() => {
    if (columnsFetchAPI.status !== 'SUCCESS') return

    const visibleColumns = columnsFetchAPI.responseData.data.filter(filterColumns)
    const newStepFilters = visibleColumns.map((column) => {
      return {
        filterAttribute: column.description,
        title: column.title,
        query: { where: { discipline_id: disciplineId } }
      }
    })

    setStepFilters([...newStepFilters, descriptionFilter])
  }, [columnsFetchAPI.status, columnsFetchAPI.responseData])

  useEffect(() => {
    if (contractServicesFetchAPI.status !== 'SUCCESS') return
    if (contractServicesFetchAPI.responseData.data.length === 0) return

    const newContractService = contractServicesFetchAPI.responseData.data[0]
    setContractService(newContractService)
    onChange('contract_service_id', newContractService.id)
  }, [contractServicesFetchAPI.status, contractServicesFetchAPI.responseData])

  const onDone = (values) => {
    if (values.description) {
      setContractService(values.selectedObj)
      onChange('contract_service_id', values.selectedObj.id)
    }
    setOpen(false)
  }

  const openModal = () => {
    const newStepFilters = stepFilters.map((filter) => {
      return {
        ...filter,
        query: { where: { discipline_id: disciplineId } }
      }
    })

    setStepFilters(newStepFilters)
    setOpen(true)
  }

  return (
    <React.Fragment>
      <div className="contract-service">
        <div className={`contract-service-body${contractService ? ' filled' : ''}`}>
          {contractService ? (
            <React.Fragment>
              <div className="description">
                {contractService.description}
              </div>
              {!readOnly && (
                <React.Fragment>
                  <div className="contract-service-check">
                    <MdCheck />
                  </div>
                  <div className="contract-service-edit" onClick={openModal}>
                    <MdEdit />
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <div className="add-service" onClick={openModal}>
              <MdAddCircle />
              <button type="button" className="add-service-button">
                {I18n.t('form.inputs.contract_service.add')}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="error-label" />
      {open && (
        <FilterStepsModal
          filters={stepFilters}
          route="contract_services"
          onClose={() => setOpen(false)}
          onDone={onDone}
        />
      )}
    </React.Fragment>
  )
}

InputContractService.propTypes = {
  inputProps: PropTypes.shape({
    tag: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })),
    template: PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  }).isRequired
}
