import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import axios from 'axios'

export default function loadingDownloadFile({ url, fileName }) {
  dispatch(BusEvents.SHOW_LOADING_DIALOG)

  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    dispatch(BusEvents.HIDE_DIALOG)

    const linkUrl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = linkUrl
    link.setAttribute('download', `${fileName}.xlsx`)
    document.body.appendChild(link)
    link.click()
  })
}