import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useFetchAPI, { MAX_PAGE_SIZE } from '/src/hooks/api/fetch_api'

export default function usePaginatedFetch(path, onFetchData) {
  const { status, responseData, fetchAPI } = useFetchAPI(path)
  const [dataFetched, setDataFetched] = useState([])

  const [dataOptions, setDataOptions] = useState()
  const [pagesLeft, setPagesLeft] = useState()
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    const treatAPIResponse = () => {
      const { data, total } = responseData

      const hasData = data.length > 0

      setDataFetched((prevData) => {
        const nextData = [...prevData, ...data]
        if (onFetchData) return onFetchData(nextData)
        return nextData
      })

      if (hasData)
        skip === 0 ? setTotalPagesLeft(total) : setPagesLeft((prevPagesLeft) => prevPagesLeft - 1)
    }

    if (status === 'SUCCESS') treatAPIResponse()
  }, [status, responseData])

  useEffect(() => {
    if (pagesLeft > 0) setSkip((prevSkip) => prevSkip + MAX_PAGE_SIZE)
    else setSkip(0)
  }, [pagesLeft])

  useEffect(() => {
    if (skip > 0) callAPI()
  }, [skip])

  const callAPI = (forceNewFetch, queryParams) => {
    if (!path) return

    const dataOptionsToSend = queryParams || dataOptions
    const paging = dataOptionsToSend && dataOptionsToSend.paging ? { ...dataOptionsToSend.paging } : {}
    paging.skip = skip
    paging.pageSize = MAX_PAGE_SIZE

    const requestQueryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      dataOptions: { ...dataOptionsToSend, paging }
    }
    
    fetchAPI(requestQueryParams)

    if (forceNewFetch) {
      setDataOptions(queryParams)
      setDataFetched([])
      setSkip(0)
    }
  }

  const setTotalPagesLeft = (totalItems) => {
    const totalPages = Math.ceil(totalItems / MAX_PAGE_SIZE)

    setPagesLeft(totalPages - 1)
  }

  return [dataFetched, callAPI]
}

usePaginatedFetch.propTypes = {
  path: PropTypes.string,
  onFetchData: PropTypes.func
}

usePaginatedFetch.defaultProps = {
  path: undefined,
  onFetchData: (data) => data
}
