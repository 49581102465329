import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Steps from '/src/ui/core/layouts/steps'
import I18n from '/src/utils/translations'
import InspectionForScheduleModel from '/src/models/inspection_for_schedule'
import InspectedTemplateModel from '/src/models/inspected_template'
import InspectedItemModel from '/src/models/inspected_item'
import ScheduleInspection from '/src/models/schedule_inspection'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import GroupedFormButtons from '/src/ui/core/forms/grouped_form_buttons'
import useGroupFormStep from '/src/ui/core/forms/group_form_step_hook'
import Form from '/src/ui/core/forms/form'
import Information from '/src/ui/core/alerts/information'
import useFetchAPI from '/src/hooks/api/fetch_api'
import '/src/static/css/domain/inspections/inspection_form.css'

export default function ScheduleForm({ dataItem, templateId, type }) {
  const [model] = useState(new InspectionForScheduleModel())
  const [scheduledModel] = useState(new ScheduleInspection())
  const [includeOnForm, setIncludeOnForm] = useState({})
  const [scheduleFields, setScheduleFields] = useState({})
  const [groupAndDispatchFields] = useGroupFormStep(scheduleFields, setScheduleFields)
  const [inspectedTemplate, setInspectedTemplate] = useState(new InspectedTemplateModel(scheduleFields))
  const [inspectedItem, setInspectedItem] = useState(new InspectedItemModel(scheduleFields))
  const getEavColumns = useFetchAPI('eav_columns')

  useEffect(() => {
    setIncludeOnForm({ eav_template_id: templateId })
  }, [])

  useEffect(() => {
    if (!scheduleFields || !scheduleFields.inspected_type) return
    setInspectedTemplate(new InspectedTemplateModel(scheduleFields))
    setInspectedItem(new InspectedItemModel(scheduleFields))
  }, [scheduleFields])

  const groupAndCheckRequiredFields = (columns) => {
    const requireds = columns.filter((c) => c.required).map((c) => {
      return c.foreignAttribute || c.description
    })

    return (data) => groupAndDispatchFields(data, requireds)
  }

  useEffect(() => {
    if (!scheduleFields || !scheduleFields.inspected_type) return
    if (!scheduleFields.inspected_type === 'Request') return
    if (!scheduleFields.inspected_template || scheduleFields.inspected_id) return

    const params = { requestAction: 'READ', httpAction: 'get' }
    const query = {
      where: { eav_template_id: scheduleFields.inspected_template },
      order: { position: 'asc' }
    }
    getEavColumns.fetchAPI({ ...params, query })
  }, [scheduleFields])

  useEffect(() => {
    const { loading, errors, responseData } = getEavColumns
    if (!loading && !errors && responseData.data.length > 0) {
      const formulaAndStringTypes = ['string', 'formula']
      const filteredColumns = responseData.data.filter((eavColumn) => {
        return formulaAndStringTypes.includes(eavColumn.column_type.description)
      })
      if (filteredColumns.length === 0) return
      const inputSearchDisplayFields = ['number'].concat(filteredColumns[0].description)
      setInputSearchDisplayFields(inputSearchDisplayFields)
    }
  }, [getEavColumns.loading, getEavColumns.errors, getEavColumns.responseData])

  const setInputSearchDisplayFields = (inputSearchDisplayFields) => {
    const { columns } = inspectedItem
    const inspected = columns.find((a) => a.description === 'inspected_id')
    inspected.textDisplayFields = inputSearchDisplayFields
    setInspectedItem(inspectedItem)
  }

  const inspectionGeneralInformation = () => (
    <Form
      key="main-form"
      model={model}
      dataItem={dataItem}
      includeOnForm={includeOnForm}
      templateId={templateId}
      type={type}
      onFormSubmitAction={groupAndCheckRequiredFields(model.columns)}
    />
  )

  const inspectedChooseTemplate = () => (
    <Form
      key="inspected-template-form"
      model={inspectedTemplate}
      dataItem={dataItem}
      onFormSubmitAction={groupAndCheckRequiredFields(inspectedTemplate.columns)}
    />
  )

  const inspectedChooseItem = () => (
    <Form
      key="inspected-item-form"
      model={inspectedItem}
      dataItem={dataItem}
      onFormSubmitAction={groupAndCheckRequiredFields(inspectedItem.columns)}
    />
  )

  const onScheduleFormSubmit = (data) => {
    if (data.recurrency_interval.start && data.recurrency_interval.end) {
      data.start_date = data.recurrency_interval.start
      data.end_date = data.recurrency_interval.end
      groupAndDispatchFields(data, ['repetitions', 'each'])
    }
  }

  const scheduleFormStep = () => (
    <React.Fragment>
      <Form
        key="schedule-form"
        model={scheduledModel}
        dataItem={dataItem}
        onFormSubmitAction={onScheduleFormSubmit}
      />
      <div className="schedule-form-hints">
        <Information message={I18n.t('inspections.schedule_form.hints')} />
        <Information
          message={I18n.t('inspections.schedule_form.further_step_information')}
          color='blue'
        />
      </div>
      <GroupedFormButtons model={model} action="schedule" />
    </React.Fragment>
  )

  const skipWhenRequest = (dataItem) => dataItem.inspected_type === 'Request' ? 2 : 0

  const scheduleSteps = () => (
    <Steps
      steps={[{
        id: 'general-step',
        awaitFormSuccess: true,
        title: I18n.t('inspections.schedule_form.general_step'),
        body: inspectionGeneralInformation()
      }, {
        id: 'detailed-template-step',
        awaitFormSuccess: true,
        title: I18n.t('inspections.schedule_form.detailed_template_step'),
        autoSkipCondition: skipWhenRequest,
        body: inspectedChooseTemplate()
      }, {
        id: 'detailed-item-step',
        awaitFormSuccess: true,
        title: I18n.t('inspections.schedule_form.detailed_item_step'),
        autoSkipCondition: skipWhenRequest,
        body: inspectedChooseItem()
      }, {
        id: 'recurrence-step',
        title: I18n.t('inspections.schedule_form.recurrence_step'),
        body: scheduleFormStep()
      }]}
    />
  )

  return (
    <FormWrapper
      model={model}
      dataItem={dataItem}
      title={I18n.t('form.schedule_entity', { entity: model.name })}
      classes="full"
    >
      {scheduleSteps()}
    </FormWrapper>
  )
}

ScheduleForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  templateId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
}

ScheduleForm.defaultProps = {
  dataItem: null,
}
