import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { MdArrowForward, MdArrowBack } from 'react-icons/md'
import I18n from '/src/utils/translations'
import InputSearch from '/src/ui/core/inputs/input_search'
import Modal from '/src/ui/core/popups/modal'
import { isBlank }  from '/src/utils/boolean_refinements'
import { escapeCommas } from '/src/utils/string'
import '/src/static/css/core/popups/filter_steps_modal.css'

// eslint-disable-next-line max-lines-per-function
export default function FilterStepsModal({ route, filters, resultTitle, tag, currentValues,
                                           skipEmpty, onDone, onClose }) {
  const [values, setValues] = useState(currentValues)
  const [index, setIndex] = useState(0)
  const [stepStatus, setStepStatus] = useState('not_started')
  const [direction, setDirection] = useState('forward')
  const [selectedValue, setSelectedValue] = useState(null)
  const [skipping, setSkipping] = useState(true)
  const [status, setStatus] = useState('')
  const [responseData, setResponseData] = useState([])
  const dropRef = useRef(null)

  const isFilterSingle = filters.length === 1
  const totalStepsWithResultTitle = isFilterSingle ? 1 : filters.length + 1
  const totalSteps = resultTitle ? totalStepsWithResultTitle : filters.length

  const skipClick = () => {
    const newValues = { ...values, [filters[index].filterAttribute]: null }

    if (isFilterSingle) {
      onDone(newValues)
      return
    }

    setValues(newValues)
    setDirection('forward')
    setIndex(Math.min(index + 1, filters.length))
  }

  const actionClick = () => {
    const newValues = { ...values, [filters[index].filterAttribute]: selectedValue }
    setValues(newValues)
    setDirection('forward')
    setIndex(Math.min(index + 1, filters.length))
  }

  const cancelClick = () => {
    setDirection('backward')
    setIndex(Math.max(index - 1, 0))
  }

  const doneClick = () => {
    let newValues = isFilterSingle ? {
      ...values, [filters[index].filterAttribute]: selectedValue
    } : { ...values }

    if (!resultTitle) {
      const last = filters[index].filterAttribute
      let selectedObj

      if (selectedValue && selectedValue[0] && responseData.length > 0) {
        if (typeof selectedValue[0] === 'object')
          selectedObj = responseData.find((item) => item[last] === selectedValue[0][last])
        else
          selectedObj = responseData.find((item) => item[last] === selectedValue[0])
      }

      newValues = { ...values, [last]: selectedValue, selectedObj }
    }

    onDone(newValues)
  }

  const onSelectedValue = (_ignored, value) => {
    if (Array.isArray(value)) {
      const val = value.map((item) => {
        return {
          [filters[index].filterAttribute]: item,
          computed_text_field: item.toString()
        }
      })
      setSelectedValue(val)
      return
    }

    if (isBlank(value)) {
      setSelectedValue(null)
      return
    }
  
    setSelectedValue([value])
  }

  const onFetch = (fetchedData) => {
    setResponseData(fetchedData)
    setStatus('SUCCESS')
  }

  const props = {
    className: 'selector-combobox',
    popupClassName: 'filters-steps-combobox-list',
    iconClassName: 'k-i-search',
    searchRoute: route,
    searchOperator: 'contains',
    onChange: onSelectedValue,
    onFetch,
    ref: dropRef,
    appendTo: '#filter-steps-options',
    readOnly: false,
    opened: true
  }

  const [inputProps, setInputProps] = useState(props)

  useEffect(() => {
    if (index >= filters.length) return
    const filter = filters[index].filterAttribute
    setSelectedValue(values[filter])
  }, [index, filters])

  useEffect(() => {
    if (index === filters.length) return

    const query = filters[index].query ? filters[index].query : {}
    const params = { requestAction: 'READ', httpAction: 'get', query }

    // only apply filters from previous steps
    for (let i = 0; i < index; i++) {
      const filter = filters[i].filterAttribute

      if (values[filter]) {
        const value = values[filter]
        if (value[0] !== null && value[0] !== undefined) {
          const isObj = typeof value[0] === 'object'
          const whereVal = isObj ? value.map((item) => item.computed_text_field) : value[0]
          const filterValue = Array.isArray(whereVal) ? whereVal : value
          params.query.where = {
            ...params.query.where,
            [filter]: escapeCommas(filterValue)
          }
        }
      } else if (values[filter] === null && params.query.where)
        delete params.query.where[filter]
    }

    if (resultTitle || index < filters.length-1)
      params.query.distinct = [ filters[index].filterAttribute ]

    setStepStatus('loading')
    setStatus('')
    setInputProps({
      ...props,
      multiple: !!filters[index].multiple,
      distinct: true,
      searchFields: [filters[index].filterAttribute],
      searchExtraQuery: params.query,
      textDisplayFields: [filters[index].filterAttribute],
      keyField: filters[index].filterAttribute,
      value: values[filters[index].filterAttribute],
      index
    })
    setSkipping(true)
  }, [values, index, filters])

  useEffect(() => {
    if (stepStatus !== 'loading' || status !== 'SUCCESS') return
    const current = filters[index].filterAttribute

    let found
    let value

    if (Array.isArray(values[current])) {
      const currentValues = values[current][0] ? values[current].map((v) => v.computed_text_field || v) : []
      const present = responseData.filter((x) => currentValues.includes(x.computed_text_field))
      found = present.length == values[current].length ? values[current] : present
      value = found.length > 0 ? found : undefined
    } else if (values[current]){
      found = responseData.find((opt) => opt.computed_text_field === values[current].toString())
      value = found ? values[current] : undefined
    }

    // this lines resets the value from the step when it is not found
    setSelectedValue(value)

    setStepStatus('loaded')
  }, [stepStatus, status, responseData, index, skipping])

  useEffect(() => {
    if (stepStatus === 'loaded') dropRef.current.focus()
  }, [stepStatus])

  useEffect(() => {
    if (stepStatus !== 'loaded' || !skipping) return

    if (skipEmpty && responseData.length === 0 && index !== totalSteps - 1) {
      direction == 'backward' && index > 0 ? cancelClick() : actionClick()
    }

    setSkipping(false)
  }, [stepStatus, index, responseData])

  const disabled = () => stepStatus !== 'loaded'

  const isLastStep = () => index === totalSteps - 1

  const renderValues = (key) => {
    if (!Array.isArray(values[key])) return values[key]

    return values[key].filter((v) => v || (typeof val === 'object' && v[key])).map((val) => {
      return typeof val === 'object' ? val[key] : val
    }).join(", ")
  }

  const renderSearch = () => {
    return <InputSearch key={index} inputProps={inputProps} />
  }

  return (
    <Modal
      loading={{ status: stepStatus === 'loading', text: I18n.t('main_dialog.loading_title') }}
      height={90}
      width={50}
      title={`${I18n.t('filter_steps_modal.step')} ${index+1}/${totalSteps}`}
      onClose={onClose}
      closable
    >
      <div key={index} id="filter-steps-modal" className="filter-steps-modal">
        <div id="filter-steps-title" className="filter-steps-title">
          {index === filters.length ? I18n.t('filter_steps_modal.filter_result') : filters[index].title}
          {tag ?
            <span>{tag}</span>
          :
            <span className="count">{responseData.length}</span>}
        </div>

        <div id="filter-steps-subtitle" className="filter-steps-subtitle">
          {`${I18n.t('filter_steps_modal.subtitle', {
              title: index === filters.length ?
                I18n.t('filter_steps_modal.filter_result')
              :
                filters[index].title
            })}`}
        </div>

        <div id="filter-steps-content" className="filter-steps-content">
          {
            index === filters.length && !isFilterSingle ? (
              <div className="filter-result">
                <div className="result-title">
                  <span>{resultTitle}</span>
                  <img src="/static/svg/tick_blue.svg" />
                </div>
                <div className="result-filters">
                  {Object.keys(values).filter((key) => values[key]).map((k) => (
                    <p key={k}>{`${k}: ${renderValues(k)}`}</p>
                  ))}
                </div>
              </div>
            )
            : (
              <React.Fragment>
                {inputProps && inputProps.searchExtraQuery && index == inputProps.index && renderSearch()}
                <div id="filter-steps-options" className="filter-steps-options" />
              </React.Fragment>
            )}
        </div>

        <div className="filter-steps-action-buttons">
          <div id="filter-steps-prev-button" className="filter-steps-button">
            {index > 0 && (
              <button className="form-cancel-button" disabled={disabled()} onClick={() => cancelClick()} type="button">
                <MdArrowBack />
                {` ${I18n.t('actions.prev')}`}
              </button>
            )}
          </div>

          <div id="filter-steps-next-button" className="filter-steps-button">
            {isLastStep() ?
              (
                <React.Fragment>
                  {isFilterSingle && (
                    <button
                      className="form-cancel-button"
                      disabled={disabled()}
                      onClick={() => skipClick()}
                      type="button"
                    >
                      {`${selectedValue === undefined ?
                        I18n.t('actions.cancel') :
                        I18n.t('actions.clear')}`}
                    </button>
                  )}

                  <button className="next-button" onClick={doneClick} type="button">
                    {`${I18n.t('actions.done')}`}
                  </button>
                </React.Fragment>
              )
            :
              (
                <React.Fragment>
                  <button className="form-cancel-button" disabled={disabled()} onClick={() => skipClick()} type="button">
                    {`${I18n.t('actions.skip')}`}
                  </button>

                  <button className="next-button" disabled={disabled()} onClick={() => actionClick()} type="button">
                    {`${I18n.t('actions.next')} `}
                    <MdArrowForward />
                  </button>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

FilterStepsModal.propTypes = {
  route: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  tag: PropTypes.string,
  resultTitle: PropTypes.string,
  currentValues: PropTypes.object,
  skipEmpty: PropTypes.bool,
  onDone: PropTypes.func,
  onClose: PropTypes.func
}

FilterStepsModal.defaultProps = {
  tag: undefined,
  resultTitle: undefined,
  currentValues: {},
  skipEmpty: true,
  onDone: () => {},
  onClose: () => {}
}
