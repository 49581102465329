import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import TrackBlock from '/src/ui/domain/requests/track_block'
import InspectionStatusLabel from '/src/ui/domain/inspections/inspection_status_label'
import InspectionActionButtons from '/src/ui/domain/inspections/inspection_action_buttons'
import '/src/static/css/model_side_panel.css'

export default function InspectionsSidePanel({ dataItem, onClose, sections, actions  }) {
  const [blocks, setBlocks] = useState()
  const [show, setShow] = useState(true)
  const sectionBlocks = useSectionBlock({ sections, dataItem })

  const actionButtons = () => (
    <InspectionActionButtons dataItem={dataItem} onSubmitted={onClose} actions={actions} />
  )

  const buildSummary = () => {
    return {
      component: (
        <TrackBlock
          detail={{ ...dataItem.request, id: dataItem.request_id }}
          actions={actionButtons()}
        >
          <InspectionStatusLabel dataItem={dataItem} />
        </TrackBlock>
      ),
      title: '',
      type: 'track',
      key: 'inspection-track-block'
    }
  }

  const handleClose = () => {
    onClose()
    setShow(false)
  }

  useEffect(() => {
    setShow(!!dataItem)
    if (!dataItem) handleClose()
    else setBlocks([buildSummary(), ...sectionBlocks])
  }, [dataItem])

  return (
    <div className="model-side-panel">
      {dataItem && (
        <BlocksSidePanel
          title={I18n.t('inspections.inspections_detail.side_panel_title')}
          closable
          onClose={handleClose}
          blocks={blocks}
        />
      )}
    </div>
  )
}

InspectionsSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  actions: PropTypes.arrayOf(PropTypes.object),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired
}

InspectionsSidePanel.defaultProps = {
  dataItem: undefined,
  actions: []
}
