import React, { useEffect } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useHistory , useParams } from 'react-router-dom'
import Layout from '/src/ui/core/layouts/layout'
import I18n from '/src/utils/translations'
import { useQueryParam, NumberParam, StringParam, BooleanParam } from 'use-query-params'
import { buildBackURL } from '/src/utils/url'
import FormHeader from '/src/ui/core/forms/form_header'
import HistoriesFeed from '/src/ui/domain/progresses/histories_feed'
import '/src/static/css/core/layouts/page_form.css'

export default function ProgressHistoryPage() {
  const history = useHistory()
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const [itemTemplateId] = useQueryParam('item_template_id', NumberParam)
  const [selectItemId] = useQueryParam('select_item_id', NumberParam)
  const [selectServiceId] = useQueryParam('select_service_id', NumberParam)
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [filterByRequest] = useQueryParam('filtered_by_request', BooleanParam)
  const [templateName, setTemplateName] = useQueryParam('eav_template_name', StringParam)
  const [number] = useQueryParam('number', NumberParam)
  const url = useParams()
  const level = url.resource === 'performances' ? 'progress' : 'progress_service'
  const { fetchAPI, status, responseData } = useFetchAPI('eav_templates')

  const [path] = useQueryParam('path', StringParam)
  const backRoute = path || 'performances'
  const backText = path ? I18n.t(`${backRoute}.title`) : I18n.t('form.back', { entity: I18n.t(`${backRoute}.title`) })

  useEffect(() => {
    if (!templateId) return

    const params = { requestAction: 'READ', httpAction: 'get' }
    const query = { where: { id: templateId } }
    fetchAPI({ ...params, query })
  }, [])

  useEffect(() => {
    if (status !== 'SUCCESS') return

    setTemplateName(responseData.data[0].title)
  }, [status])

  const backURLConfig = () => ({
    eavTemplateId: itemTemplateId || templateId,
    selectItemId,
    selectServiceId,
    requestId: (filterByRequest) ? requestId : null
  })

  const onBack = () => history.push(`/${backRoute}?${buildBackURL(backURLConfig())}`)

  const renderHistoriesFeed = () => {
    const itemId = level === 'progress' ? selectItemId : selectServiceId

    return itemId && (
      <HistoriesFeed
        level={level}
        itemId={itemId}
      />
    )
  }

  return (
    <Layout
      key={requestId}
      pageTitle={I18n.t('progresses.title')}
      showProjectSwitcher={false}
    >
      <div className="form-wrapper">
        <button
          type="button"
          className="back-button"
          data-testid="back-button-form"
          onClick={() => onBack()}
        >
          <div className="back-icon"><MdKeyboardArrowLeft /></div>
          <div className="back-text">{backText}</div>
        </button>
        <FormHeader
          title={I18n.t(`progress_histories.${level}_title`)}
          label={requestId}
          subtitle={I18n.t('progress_histories.subtitle')}
          badge={(
            <div className="extra-badge">
              {I18n.t(`progress_histories.${level}_badge`, { number })}
            </div>
          )}
        />
        {renderHistoriesFeed()}
      </div>
    </Layout>
  )
}
