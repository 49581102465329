// eslint-disable-next-line import/prefer-default-export
export function isJson(s) {
  try {
    const o = JSON.parse(s)

    if (o && typeof o === "object") {
        return o
    }
  }
  catch (e) { return false }

  return false
}

export function fullTitleDisplay(title, lenghtLimit) {
  return title.length <= lenghtLimit ? '' : title
}

export function titleLimiter(title, lenghtLimit) {
  return title.length <= lenghtLimit ? title : `${title.substring(0, lenghtLimit)}...`
}

/**
 * This function will remove accents and make the string lowercase
 * @param string
 * ex: ÁfḉâAd -> afçaad
* */
export const sanitizeString = (string) => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
}

export function basicSnakeCase(string) {
  return string.toLocaleLowerCase().replaceAll(' ', '_')
}

export function escapeCommas(value) {
  if (Array.isArray(value))
    return value.map((item) => (typeof item === 'string' ? item.replaceAll(',', '\\,') : item))
  if (typeof value === 'string') return value.replaceAll(',', '\\,')

  return value
}
