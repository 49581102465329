import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useBeforeUnload from '/src/hooks/before_unload'
import useTemplateId from '/src/hooks/template_id'
import EditableGridLoader from '/src/ui/core/grid/editable/editable_grid_loader'
import EditableGrid from '/src/ui/core/grid/editable_grid'
import ActionToolbar from '/src/ui/core/toolbar/action_toolbar'
import CellFactory from '/src/ui/core/grid/column_cell_factory/cell_factory'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import I18n from '/src/utils/translations'
import { isBlank } from '/src/utils/boolean_refinements'

// eslint-disable-next-line max-lines-per-function
export default function EditableGridWrapper({ dataSource, columns, inEdit, onCancel,
                                              onAction, children, model, allowCreate, allowDelete,
                                              onCreateNewItem, shouldAllowCell, isRowEditable }) {
  const [numChanges, setNumChanges] = useState(0)
  const [hasChanges, setHasChanges] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [showDiscardConfirmation, renderDiscardConfirmation] = useConfirmationModal()

  const templateId = useTemplateId(model)

  useBeforeUnload(inEdit && hasChanges)

  useEffect(() => {
    if (!inEdit) return

    setNumChanges(0)
    setHasChanges(false)
  }, [inEdit])

  const onCancelBulkEditing = () => {
    hasChanges ? showDiscardConfirmation({
      title: I18n.t('discard_modal.title'),
      description: I18n.t('discard_modal.description'),
      actionButtonText: I18n.t('discard_modal.ok'),
      cancelButtonText: I18n.t('discard_modal.cancel'),
      buttonColor: '#B33939',
      onSuccess: () => onCancel
    }) : onCancel()
  }

  const onSaveBulkEditing = () => {
    onAction()
    dispatch(BusEvents.RELOAD_GRID)
  }

  const onEdit = (numChanges) => {
    setNumChanges(numChanges)
    setHasChanges(numChanges > 0)
  }

  useBus(BusEvents.ENABLE_SAVE_EDITABLE_GRID, () => setLoadingSave(false), [])
  useBus(BusEvents.DISABLE_SAVE_EDITABLE_GRID, () => setLoadingSave(true), [])

  return (
    <React.Fragment>
      <div className={inEdit ? 'hide-grid' : 'display-grid'}>
        {children}
      </div>
      {inEdit && (
        <div className='display-grid' data-testid='editable-grid-wrapper'>
          {renderDiscardConfirmation()}
          <Prompt when={inEdit && hasChanges} message={I18n.t('discard_modal.description')} />
          <EditableGridLoader
            isLoading={isBlank(templateId)}
            opts={{
              gridTitle: model.name,
              itemsQuantity: dataSource.data.length,
              isAllowedCreate: allowCreate
            }}
          >
            <EditableGrid
              model={model}
              templateId={templateId}
              dataSource={dataSource.data}
              columns={columns}
              shouldAllowCell={shouldAllowCell}
              columnCellFactory={<CellFactory type={model.paramName} />}
              onEdit={onEdit}
              onAction={onSaveBulkEditing}
              onCancel={onCancel}
              allowCreate={allowCreate}
              allowDelete={allowDelete}
              onCreateNewItem={onCreateNewItem}
              isRowEditable={isRowEditable}
            />
          </EditableGridLoader>
          <ActionToolbar
            description={`${I18n.t('actions.unsaved_changes')} (${numChanges})`}
            cancelText={I18n.t('actions.discard')}
            onCancel={onCancelBulkEditing}
            actionText={I18n.t('actions.save_button_keyboard')}
            onAction={() => dispatch(BusEvents.SAVE_EDITABLE_GRID)}
            loadingAction={loadingSave}
          />
        </div>
      )}
    </React.Fragment>
  )
}

EditableGridWrapper.propTypes = {
  children: PropTypes.node,
  dataSource: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.arrayOf(PropTypes.object),
  inEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  allowCreate: PropTypes.bool,
  allowDelete: PropTypes.bool,
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onCreateNewItem: PropTypes.func,
  shouldAllowCell: PropTypes.func,
  isRowEditable: PropTypes.func
}

EditableGridWrapper.defaultProps = {
  children: <React.Fragment />,
  dataSource: { data: [], total: 0 },
  columns: [],
  inEdit: false,
  allowCreate: false,
  allowDelete: true,
  onCancel: () => {},
  onAction: () => {},
  onCreateNewItem: (newItem) => newItem,
  shouldAllowCell: () => true,
  isRowEditable: () => true
}
