import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { useQueryParam, NumberParam } from 'use-query-params'
import ScopeModel from '/src/models/scope'
import useExcelExportUrl from '/src/hooks/api/export_excel_url'
import TemplatableGrid from '/src/ui/core/grid/templatable_grid'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import {
  bulkEditingItem,
  importMenuItem,
  exportMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import '/src/static/css/core/grid/grid.css'

// eslint-disable-next-line max-lines-per-function
export default function ScopesGrid({ requestId, selectedItem, onRowClick, bulkEditItemVisible,
                                     onBulkEditItemClick, onDataSource, onGridColumns,
                                     contextMenuItems, templateTitle }) {
  const scopeModel = new ScopeModel()
  const [openImport, setOpenImport] = useState(false)
  const [icons, setIcons] = useState([])
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const onFilter = (requestItem) => { if (!requestItem.parent_id) setIcons(['label', 'clustered', 'batch_assigner']) }

  const { filter, gridTitle, labels } = useRequestGridFilter(requestId, scopeModel.route, onFilter, templateId)

  const scopesFilter = useMemo(() => (
    [...filter, { type: 'with', column: 'request_status', value: true }]
  ), [filter])

  const exportParams = { entity: 'scopes', templateId, isApi: true }
  const { linkExport, setExportFilters } = useExcelExportUrl(exportParams)
  const exportIcon = exportMenuItem(linkExport, { templateTitle, model: scopeModel })

  const moreActionsMenuItems = [
    bulkEditingItem(
      () => onBulkEditItemClick('top'),
      () => bulkEditItemVisible('top')
    ),
    importMenuItem(setOpenImport)
  ].concat(!templateTitle ? [] : [exportIcon])

  const onClosePopupImport = () => {
    setOpenImport(false)
    dispatch(BusEvents.RELOAD_GRID)
  }

  return (
    <div className={selectedItem ? 'scope-item-selected' : 'scope-item-not-selected'}>
      { openImport && (
        <PopupImportFile
          modelRoute={scopeModel.route}
          templateId={templateId}
          onClose={onClosePopupImport}
        />
      )}
      <TemplatableGrid
        contextMenuItems={contextMenuItems}
        model={scopeModel}
        labels={labels}
        filter={scopesFilter}
        gridTitle={gridTitle}
        onFilterUpdate={setExportFilters}
        icons={[...icons, <MoreActionsIcon items={moreActionsMenuItems} />]}
        selectedItem={selectedItem}
        onRowClick={onRowClick}
        onDataSource={onDataSource}
        onGridColumns={onGridColumns}
        selecting
      />
    </div>
  )
}

ScopesGrid.propTypes = {
  requestId: PropTypes.string,
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  onRowClick: PropTypes.func,
  onBulkEditItemClick: PropTypes.func.isRequired,
  onGridColumns: PropTypes.func.isRequired,
  onDataSource: PropTypes.func.isRequired,
  bulkEditItemVisible: PropTypes.func.isRequired,
  templateTitle: PropTypes.string,
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ).isRequired
}

ScopesGrid.defaultProps = {
  requestId: undefined,
  selectedItem: undefined,
  onRowClick: () => {},
  templateTitle: '',
}
