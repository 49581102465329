/**
 * Converts hours and minutes string to decimal value
 * @param timeString The given time in the 12:30 format (HH:mm)
 */
export const timeStringToDecimalHours = (timeString) => {
    if (!timeString) return 0

    const hours = timeString.replace(/_/g, '0')
    const arr = hours.split(':')
    if (arr.length !== 2) { return 0 }
    const hrs = parseInt(arr[0], 10)
    const min = parseInt(arr[1], 10) / 60
    return hrs + min
}

/**
 * Converts a decimal value which is supposed to represent hours to
 * an hour format HHH:mm. Up to 999:59 -> 999h and 59min
 * @param decimalHours The given value representing hours
 * @returns {string} The formatted hours string in HH:mm format
 */
export const decimalHoursToTimeString = (decimalHours) => {
    if (typeof decimalHours !== 'number' || decimalHours < 0) {
        return '000:00'
    }

    function digitFormatter(val, length) {
        let res = ''
        if (val === 0) res += '0'.repeat(length)
        else {
            if (length > 2 && val < 100) res += '0'
            if (val < 10) res += '0'

            res += val
        }

        return res.substring(0, length)
    }

    const round = Math.floor(decimalHours)
    const dec = Math.round((decimalHours - round) * 60)
    return `${digitFormatter(round, 3)  }:${  digitFormatter(dec, 2)}`
}
