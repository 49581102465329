import React from 'react'
import PropTypes from 'prop-types'
import SpinnerIcon from '/src/ui/core/icons/spinner_icon'
import '/src/static/css/core/buttons/action_button.css'

export default function ActionButton({ icon, title, onClick, loading }) {
  return (
    <button
      type="button"
      className="button-action"
      onClick={onClick}
      disabled={loading}
    >
      {loading ? <SpinnerIcon className='action-btn-spinner' /> : (
        <>
          {icon}
          {title}
        </>
      )}
    </button>
  )
}

ActionButton.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool
}

ActionButton.defaultProps = {
  icon: undefined,
  onClick: () => {},
  loading: false
}
