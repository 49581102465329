const filterOperators = {
  'text': [
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' }
  ],
  'numeric': [
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' }
  ],
  'date': [
    { text: 'grid.filterBetweenOperator', operator: 'between' },
    { text: 'grid.filterEqOperator', operator: 'contains' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' }
  ],
  'boolean': [
    { text: 'grid.filterEqOperator', operator: 'eq' }
  ]
}

export default filterOperators
