/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo } from 'react'
import SubRequestBadge from '/src/ui/domain/requests/sub_request_badge'
import LabelFilter from '/src/ui/core/buttons/label_filter'
import useFetchById from '/src/hooks/api/fetch_by_id'
import I18n from '/src/utils/translations'

/**
 * Fetch a request from a given dpmsid and set filter, gridTitle and label
 * This custom hook will just fetch a request by its id.
 *
 * @param requestId
 * @return {filter, gridTitle, labels}
 */
export default function useRequestGridFilter(requestId, route, onFilter, templateId, parent) {
  const requestItem = useFetchById('requests', requestId)

  const filter = useMemo(() => {
    const column = parent ? `${parent}][request_id` : 'request_id'
    return requestId ? [ { type: 'where', column, operator: 'eq', value: requestId } ] : []
  }, [requestId, parent])

  const gridTitle = useMemo(() => (
    requestItem ? requestItem.reason : I18n.t('grid.all')
  ), [requestItem])

  const labels = useMemo(() => {
    if (!requestItem) return []
    const templateFilter = templateId ? `eav_template_id=${templateId}` : ''
    const link = `/${route}?${templateFilter}`

    return [
      <LabelFilter
        key={requestItem.id}
        link={link}
        text={requestItem.id.toString()}
        classes={requestItem.parent_id ? 'subrequest-filtered' : ''}
        icon={requestItem.parent_id ? <SubRequestBadge show /> : null}
      />
    ]
  }, [requestItem, route, templateId])

  useEffect(() => {
    if (onFilter && requestItem) onFilter(requestItem)
  }, [requestItem])

  return {
    filter,
    gridTitle,
    labels
  }
}
