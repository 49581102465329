import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { pluralize } from 'inflected'
import I18n from '/src/utils/translations'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import {
  dailyHours,
  numberOfWorkers
} from '/src/utils/planning_calculations'
import '/src/static/css/core/grid/grid.css'

export default function ServicePlanningBlock({ dataItem }) {
  const [project] = useStore('project')
  const [fields, setFields] = useState([])
  const [workingHours, setWorkingHours] = useState(0)
  const [totalHours, setTotalHours] = useState(0)

  useEffect(() => {
    if (!dataItem) return

    const numCrews = dataItem.crews
    const crewSize = dataItem.crew_size
    const numWorkers = numberOfWorkers(dataItem)

    const workingHoursValue = formatNumberWithPrecision(dataItem.working_hours, project)
    const totalHoursValue = formatNumberWithPrecision(dailyHours(dataItem), project)

    setFields([
      { value: numCrews, description: 'no_crews', unit: 'crew' },
      { value: crewSize, description: 'crew_size', unit: 'person' },
      { value: numWorkers, description: 'no_workers', unit: 'worker' },
    ])

    setWorkingHours(workingHoursValue)
    setTotalHours(totalHoursValue)
  }, [dataItem, project])

  const getValueUnit = (value, unit) => (
    I18n.t(`progress_services.${value === 1 ? unit : pluralize(unit)}`)
  )

  const infoField = (field) => (
    <div className="field" key={field.description}>
      <span>{I18n.t(`progress_services.${field.description}`)}</span>
      <span className="value">
        {`${field.value} `}
        {getValueUnit(field.value, field.unit)}
      </span>
    </div>
  )

  return (
    <div className="info-fields">
      {fields && fields.map((field) => infoField(field))}
      <div className="working-hours">
        <div className="field">
          <span>{I18n.t(`progress_services.working_hours`)}</span>
          <span className="value">{`${workingHours}h`}</span>
        </div>
        <div className="field">
          <span>{I18n.t(`progress_services.total_hours`)}</span>
          <span className="total-hours-value">{`${totalHours}h`}</span>
        </div>
      </div>
    </div>
  )
}

ServicePlanningBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

ServicePlanningBlock.defaultProps = {
  dataItem: {}
}
