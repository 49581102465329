/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import EstimateServiceModel from '/src/models/estimate_service'
import useFetchSections from '/src/hooks/api/fetch_sections'
import includeGeneralSection from '/src/ui/domain/eav_sections/include_general_section'
import FormButtons from '/src/ui/core/forms/form_buttons'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import useFormulasServices from '/src/hooks/formulas_services'
import processColumn from '/src/ui/domain/formulas_services/columns_processor'
import Form from '/src/ui/core/forms/form'
import I18n from '/src/utils/translations'


export default function EstimateServiceForm({ dataItem, scopeId, templateId,
                                              disciplineId, estimateId, type }) {
  const query = {
    query: {
      where: {
        discipline_id: dataItem ? dataItem.discipline_id : disciplineId
      }
    }
  }

  const [estimateServiceModel, setEstimateServiceModel] = useState(new EstimateServiceModel(query))
  const [formulasStore] = useStore('formulas')
  const sections = useFetchSections(templateId)
  const formulasServices = useFormulasServices(templateId, true)

  const isEditMode = () => (type === 'edit')

  useEffect(() => {
    if (!isEditMode()) return
    const { columns } = estimateServiceModel
    const contractService = columns.find((a) => a.description === 'contract_service')
    contractService.readOnly = true
    setEstimateServiceModel(estimateServiceModel)
  }, [])

  const duplicationCustomAction = {
    requestAction: 'CREATE',
    httpAction: 'post'
  }

  const includeOnForm = () => {
    if (isEditMode()) return undefined
    return {
      scope_id: scopeId,
      eav_template_id: templateId,
      estimate_id: estimateId,
      discipline_id: disciplineId
    }
  }

  return (
    <FormWrapper
      model={estimateServiceModel}
      dataItem={dataItem}
      type={type}
      backText={I18n.t('estimate_services.back')}
      sidePanelSections={includeGeneralSection(sections)}
    >
      <React.Fragment>
        <Form
          model={estimateServiceModel}
          sections={sections}
          dataItem={dataItem}
          includeOnForm={includeOnForm()}
          templateId={templateId}
          type={type}
          submitParams={type === 'duplicate' ? duplicationCustomAction : undefined}
          hasFormulaServices
          formulasServices={formulasServices}
          processColumn={
            (column) => processColumn(
              column,
              formulasServices,
              formulasStore,
              estimateServiceModel.paramName
            )
          }
        />
        <FormButtons model={estimateServiceModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

EstimateServiceForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  scopeId: PropTypes.number,
  templateId: PropTypes.number,
  disciplineId: PropTypes.number,
  estimateId: PropTypes.number,
  type: PropTypes.string.isRequired
}

EstimateServiceForm.defaultProps = {
  dataItem: null,
  scopeId: undefined,
  templateId: undefined,
  disciplineId: undefined,
  estimateId: undefined,
}
