import React from 'react'
import ContentLoader from 'react-content-loader'

export default function GridLoader() {
  const Loader = () => {
    return (
      <ContentLoader
        speed={2}
        height={60}
        width="100%"
      >
        <circle cx="10" cy="30" r="10" />
        <rect x="65" y="23" width="15" height="15" />
        <rect x="130" y="23" rx="10" ry="10" width="200" height="15" />
        <rect x="380" y="23" rx="10" ry="10" width="300" height="15" />
        <rect x="720" y="23" rx="10" ry="10" width="200" height="15" />
        <rect x="970" y="23" rx="10" ry="10" width="200" height="15" />
        <rect x="1230" y="23" rx="10" ry="10" width="200" height="15" />
        <rect x="1480" y="23" rx="10" ry="10" width="200" height="15" />
      </ContentLoader>
    )
  }
  return (
    <React.Fragment>
      <div className="loading-header" />
      {Array(9)
        .fill("")
        .map((e, i) => (
          <div className="loading-line" key={i}><Loader key={i} /></div>
        ))}
    </React.Fragment>
  )
}
