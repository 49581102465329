import React, { useState } from 'react'
import '/src/static/css/login.css'
import PropTypes from 'prop-types'
import LanguageSwitch from '/src/ui/core/menus/language_switch'
import KaeferLoginButton from '/src/ui/core/buttons/kaefer_login_button'
import I18n from '/src/utils/translations'

export default function LoginWrapper({ children }) {
  const [pictureIndex] = useState(Math.ceil(Math.random() * 10))

  const picturePath = `/static/images/kaefer-worker-${pictureIndex}.jpg`

  return (
    <div className="login-wrapper">
      <div className="login-info-wrapper">
        <div className="logo-login-wrapper">
          <div className="logo-login">
            <img className="dpms-logo-full" src="/static/svg/logo-red.svg" alt="" />
          </div>
          <LanguageSwitch />
        </div>
        <div className="login-mission-wrapper">
          <div className="login-mission">
            <h1>
              {I18n.t('login.title_1')}
              {' '}
              <br />
              {' '}
              {I18n.t('login.title_2')}
            </h1>
          </div>
        </div>
        <div className="login-title-wrapper">
          <div className="login-title">{I18n.t('login.log_in')}</div>
          <div className="login-subtitle">{I18n.t('login.welcome_back')}</div>
        </div>
        <KaeferLoginButton />
        <React.Fragment>
          {children}
        </React.Fragment>
        <div className="rights-reserved-kaefer">
          {I18n.t('login.copyright', { year: new Date().getFullYear() })}
        </div>
      </div>
      <div className="login-image-wrapper">
        <img src={picturePath} />
      </div>
    </div>
  )
}

LoginWrapper.propTypes = {
  children: PropTypes.element.isRequired
}
