import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { MdCheck } from 'react-icons/md'

export default function PopupMenuItem({ item }) {
  let visible = true
  if (item.visible) visible = item.visible(item.dataItem)

  const onClick = (e) => {item.onClick(e, item.dataItem)}

  const buttonClasses = classNames({
    'context-menu-item': true,
    'context-menu-selected': !item.title && item.selected,
    [item.className]: !!item.className
  })

  const textClasses = classNames({
    'context-menu-item-text': true,
    'context-menu-title-text': item.title,
    'context-menu-selected-text': !item.title && item.selected
  })

  return (
    <React.Fragment>
      {visible ? (
        <button
          type="button"
          className={buttonClasses}
          onClick={onClick}
          data-testid="popup-button-item"
        >
          {item.icon ? item.icon : null}
          <span className={textClasses}>{item.text}</span>
          {item.selected && !item.title ? <MdCheck /> : null}
        </button>
      ) : null}
    </React.Fragment>
  )
}

PopupMenuItem.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.element,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    visible: PropTypes.func,
    dataItem: PropTypes.oneOfType([PropTypes.object]),
    title: PropTypes.bool,
    selected: PropTypes.bool
  }).isRequired,
}
