/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { MdThumbUp } from 'react-icons/md'
import { FaHandPaper } from 'react-icons/fa'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import AcceptanceInspectionModel from '/src/models/acceptance_inspection'
import useFormModal from '/src/ui/core/popups/form_modal'
// eslint-disable-next-line import/no-cycle
import useInspectionAcceptancePopupFields from '/src/ui/domain/inspections/inspection_acceptance_popup_fields'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'

/**
 * Custom hook that exposes renderModal() and openModal() functions
 * for inspection acceptance
 *
 * @param dataItem
 * @return {[*, *]}
 * @constructor
 */
export default function useInspectionAcceptancePopup(dataItem, onSubmitted) {
  const [data, setData, sections] = useInspectionAcceptancePopupFields()
  const formTitle =  I18n.t('inspections.acceptance_modal.inspection_acceptance')
  const formSubtitle = I18n.t('inspections.acceptance_modal.subtitle')
  const modalTitle = I18n.t('inspections.acceptance')
  const [inspectStatuses] = useStore('inspect_statuses')
  const inspectionStatusesArray = inspectStatuses ? Object.values(inspectStatuses) : []
  const pendingInspection = inspectionStatusesArray.find((e) => e.i18n_id === 'pending')

  const { status, fetchAPI } = useFetchAPI('inspections')
  const [acceptanceMessage, setAcceptanceMessage] = useState('')
  const [clickedButton, setClickedButton] = useState('')
  const model = new AcceptanceInspectionModel()

  const denyAcceptance = () => (
    pendingInspection && (dataItem.inspect_status_id !== pendingInspection.id)
  )

  const putMemberRoute = (member, message) => {
    setAcceptanceMessage(message)

    const params = {
      requestAction: 'UPDATE',
      httpAction: 'put',
      resourceId: dataItem.id,
      additionalResource: { path: member }
    }

    fetchAPI(params)
  }

  const rejectInspection = () => {
    putMemberRoute('reject', I18n.t('inspections.notifications.reject_successful'))
  }

  const approveInspection = () => {
    putMemberRoute('approve', I18n.t('inspections.notifications.approve_successful'))
  }

  const acceptanceCall = (e, button, submit, data) => {
    e.preventDefault()
    setClickedButton(button.type)
    submit(data)
    dispatch(BusEvents.FORM_SUBMIT)
  }

  const buttons = [
    {
      id: 1,
      text: I18n.t('actions.reject'),
      type: 'reject',
      onClick: acceptanceCall,
      halfWidth: true,
      disabled: denyAcceptance(),
      class: 'reject-button',
      icon: <FaHandPaper />
    },
    {
      id: 2,
      text: I18n.t('actions.approve'),
      type: 'approve',
      onClick: acceptanceCall,
      halfWidth: true,
      disabled: denyAcceptance(),
      class: 'action-button',
      icon: <MdThumbUp />
    }
  ]

  const modalProps = {
    modalTitle,
    formTitle,
    formSubtitle,
    label: `${dataItem.request_id} - #${dataItem.number}`,
    sections,
    buttons,
    dataItem: data,
    model,
    width: 30.5,
    height: 57.7,
    sectionable: false,
    closable: true
  }
  const [openModal, renderModal] = useFormModal(modalProps)

  useBus(BusEvents.FORM_SUCCESS, () => {
    if (clickedButton === 'approve') approveInspection()
    if (clickedButton === 'reject') rejectInspection()
  }, [clickedButton, data])

  useEffect(() => { if (Object.values(dataItem).length) setData(dataItem) }, [dataItem])

  useEffect(() => {
    if (status === 'FETCHING') return dispatch(BusEvents.SHOW_LOADING_DIALOG)

    if (status === 'SUCCESS') {
      notifySuccess(acceptanceMessage)
      dispatch(BusEvents.RELOAD_GRID)

      if (onSubmitted) onSubmitted()
    }

    if (status === 'SUCCESS' || status === 'ERROR') return dispatch(BusEvents.HIDE_DIALOG)
  }, [status, acceptanceMessage])


  return [openModal, renderModal]
}

useInspectionAcceptancePopup.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

useInspectionAcceptancePopup.defaultProps = {
  dataItem: {}
}
