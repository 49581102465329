/**
 * Verify if a value is blank,
 * i.e. undefined, null, NaN or a string just with whitespaces
 *
 * isBlank("  ") === true
 * isBlank(undefined) === true
 * isBlank(null) === true
 * isBlank(4) === false
 *
 * @return true if an input is undefined/null/NaN/BlankString otherwise
 */
export function isBlank (input) {
  if (input === undefined || input === null || (typeof input === 'number' && isNaN(input))) {
    return true
  }
  if (typeof input === 'string') return /^\s*$/.test(input)
  return false
}

/**
 * Verify if a value is present,
 * i.e. not undefined, not null, not NaN or a sring with something inside
 *
 * isPresent("  ") === false
 * isPresent(undefined) === false
 * isPresent(null) === false
 * isPresent(4) === true
 *
 * @return false if an input is undefined/null/NaN/BlankString otherwise
 */
export function isPresent (input) {
  return !isBlank(input)
}

/**
 * Verifiy if a object is blank or zero
 *
 * isBlankOrZero("  ") === true
 * isBlankOrZero(undefined) === true
 * isBlankOrZero(null) === true
 * isBlankOrZero(0) === true
 *
 * @return true if object is blank or zero
 */
export function isBlankOrZero (input) {
  return (input === 0 || isBlank(input))
}

/**
 * Verifiy if a object is blank or false
 *
 * isBlankOrFalse("  ") === true
 * isBlankOrFalse(undefined) === true
 * isBlankOrFalse(null) === true
 * isBlankOrFalse(false) === true
 *
 * @return true if object is blank or false
 */
export function isBlankOrFalse (input) {
  return (input === false || isBlank(input))
}
