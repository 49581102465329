import React from 'react'
import PropTypes from 'prop-types'
import { MdInfoOutline } from 'react-icons/md'
import '/src/static/css/core/alerts/information.css'

export default function Information({ message, color }) {
  return (
    <div className={`alert-information ${color}`}>
      <div className="icon">
        <MdInfoOutline />
      </div>
      <div className="text">{message}</div>
    </div>
  )
}

Information.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['yellow', 'blue']),
}

Information.defaultProps = {
  color: 'yellow'
}
