import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { NavLink } from 'react-router-dom'
import {
  MdLayers,
  MdSettings,
  MdExtension
} from 'react-icons/md'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import SideBarReportButton from '/src/ui/core/buttons/sidebar_report_button'
import SideBarDatasheetButton from '/src/ui/core/buttons/sidebar_datasheet_button'
import templateSideMenuLinks from '/src/ui/core/menus/side_menu_templates'
import SideBarCenterItem from '/src/ui/core/menus/sidebar_center_item'
import SideBarNewButton from '/src/ui/core/menus/sidebar_new_button'
import I18n from '/src/utils/translations'
import '/src/static/css/core/menus/sidebar.css'

// eslint-disable-next-line max-lines-per-function
export default function SideBarItems({ showNewButton }) {
  const [globalSubproject] = useStore('subproject')
  const [opened, setOpened] = useState()
  const [validatedSession, setValidatedSession] = useState(false)
  const isOpen = (item) => item === opened
  const onClick = (item) => isOpen(item) ? setOpened() : setOpened(item)

  useBus(
    BusEvents.REMEMBER_TOKEN_UPDATED,
    () => setValidatedSession(true),
    [setValidatedSession]
  )

  return (
    <div className="side-menu-wrapper" onMouseLeave={() => setOpened()}>
      <div className="side-menu">
        {showNewButton && <SideBarNewButton />}
        {globalSubproject.id && (
          <React.Fragment>
            <SideBarCenterItem
              icon={<MdLayers />}
              title={I18n.t('side_menu.templates')}
              opened={isOpen(I18n.t('side_menu.templates'))}
              onClick={() => onClick(I18n.t('side_menu.templates'))}
            >
              {templateSideMenuLinks().map((item) => (
                <li key={item.title}>
                  <NavLink to={item.link}>
                    <MdExtension />
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </SideBarCenterItem>
            {validatedSession && <SideBarReportButton />}
            <SideBarDatasheetButton />
          </React.Fragment>
        )}
      </div>
      <div className="menu-config">
        <div className="config-icon side-menu">
          <SideBarCenterItem
            icon={<MdSettings />}
            title={I18n.t('side_menu.settings')}
            link="settings"
          />
        </div>
      </div>
    </div>
  )
}

SideBarItems.propTypes = {
  showNewButton: PropTypes.bool
}

SideBarItems.defaultProps = {
  showNewButton: false
}
