import React from 'react'
import PropTypes from 'prop-types'
import Modal from '/src/ui/core/popups/modal'
import I18n from '/src/utils/translations'
import '/src/static/css/user_menu.css'

const APP_VERSION = import.meta.env.SNOWPACK_PUBLIC_APP_VERSION

// eslint-disable-next-line max-lines-per-function
export default function AboutVersionModal(onModalClose) {
  const renderVersionModal = () => (
    <div className="about-version-modal">
      <Modal
        title={I18n.t('user_menu.about_version')}
        height={296}
        width={380}
        sizeInPx
        onClose={() => onModalClose(false)}
        closable
      >
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <div className="dpms-logo">
            <img className="dpms-logo-full" src="/static/svg/logo-red.svg" alt="" />
          </div>
          <div className="version">
            <p>{APP_VERSION}</p>
          </div>
          <div className="copyright">
            <p>{I18n.t('login.copyright', { year: new Date().getFullYear() })}</p>
          </div>
        </div>
      </Modal>
    </div>
  )

  return renderVersionModal()
}

AboutVersionModal.propTypes = {
  onModalClose: PropTypes.func
}

AboutVersionModal.defaultProps = {
  onModalClose: () => {}
}
