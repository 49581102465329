import React from 'react'
import I18n from '/src/utils/translations'
import PropTypes from 'prop-types'
import Section from '/src/ui/core/layouts/section'
import FieldsGroup from '/src/ui/core/layouts/fields_group'
import '/src/static/css/core/layouts/sectioned_panel.css'

export default function GeneralSection({ fixedFields, sectionable, renderColumn }) {

  return (
    <React.Fragment>
      <Section
        id="general"
        index={1}
        title={I18n.t('form.general_information')}
        sectionable={sectionable}
        body={(
          <FieldsGroup
            columns={fixedFields}
            renderColumn={renderColumn}
          />
)}
        enumerable={false}
        hideable
      />
    </React.Fragment>
  )
}

GeneralSection.propTypes = {
  fixedFields: PropTypes.oneOfType([PropTypes.array]).isRequired,
  sectionable: PropTypes.bool,
  renderColumn: PropTypes.func
}

GeneralSection.defaultProps = {
  dataItem: null,
  sectionable: true,
  renderColumn: () => {}
}
