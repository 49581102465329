import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/buttons/buttons.css'

export default function Button({ className, disabled, type, onClick,
                                 icon, text, halfWidth, role }) {

  const getClasses = () => {
    const classes = ['button-flex']
    if (className) classes.push(className)
    if (halfWidth) classes.push('half-width')
    return classes.join(' ')
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={getClasses()}
      disabled={disabled}
      role={role}
    >

      {icon && (
        <div className="button-icon">
          {icon}
        </div>
      )}
      <span>
        {text}
      </span>
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  text: PropTypes.string.isRequired,
  halfWidth: PropTypes.bool,
  role: PropTypes.string
}

Button.defaultProps = {
  className: null,
  disabled: false,
  type: 'button',
  onClick: () => {},
  icon: null,
  halfWidth: false,
  role: 'button'
}
