import React from 'react'
import PropTypes from 'prop-types'
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout'
import Card from './card'
import '/src/static/css/core/cards/card.css'

export default function ExpandableCard({ tag, title, subtitle, details, height, body, className }) {
  const card = (
    <Card
      tag={tag}
      title={title}
      subtitle={subtitle}
      details={details}
      height={height}
      className={className}
    />
  )

  return (
    <PanelBar>
      <PanelBarItem title={card} className="expandable-card-wrapper">
        {body}
      </PanelBarItem>
    </PanelBar>
  )
}

ExpandableCard.propTypes = {
  tag: PropTypes.element,
  title: PropTypes.element.isRequired,
  body: PropTypes.element,
  subtitle: PropTypes.element,
  details: PropTypes.element,
  height: PropTypes.number,
  className: PropTypes.string
}

ExpandableCard.defaultProps = {
  tag: undefined,
  subtitle: undefined,
  details: undefined,
  body: undefined,
  height: 72,
  className: ''
}
