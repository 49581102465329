import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/dropdown.css'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import RadioButton from '/src/ui/core/inputs/radio_button'
import useInputDrop from '/src/ui/core/inputs/input_drop_hook'
import { isBlank } from '/src/utils/boolean_refinements'

/**
 *  It will create a combobox using an array of
 *  either objects or strings as options
 *
 *  If you do use objects, make sure you to have both properties id (for value)
 *  and description (for text reference on the combobox)
 *
 * @param inputProps
 * @return {*}
 * @constructor
 */
export default function InputDrop({ inputProps }) {
  const { options, opened, forceCombobox, forceRadioButton, ref,
          popupClassName, iconClassName, appendTo, virtual, 
          onPageChange, isDynamicDrop, dependency, dataItem } = inputProps

  const dependencyAttr = `${dependency}_id`
  const dependencyValue = dataItem ? dataItem[dependencyAttr] : undefined

  const { data, error, value, wrapperClass, inputClass, onFocus, onBlur,
    readOnly, onFilterChange, onComboChange, isOptionsString } = useInputDrop(inputProps)

  const shouldShowRadioButton = () => {
    if (forceRadioButton) return true
    if (forceCombobox || isDynamicDrop) return false
    if (options.length <= 4 && isOptionsString()) return true
    return false
  }

  if (shouldShowRadioButton())
    return <RadioButton inputProps={inputProps} />

  return (
    <div className={wrapperClass}>
      <ComboBox
        id={inputProps.id}
        className={inputClass()}
        iconClassName={iconClassName}
        data={data}
        value={value}
        onChange={onComboChange}
        onFocus={onFocus}
        onBlur={onBlur}
        popupSettings={{
          appendTo: document.querySelector(appendTo || 'body'),
          className: `combobox-list ${popupClassName || ''}`
        }}
        filterable
        textField={isOptionsString() ? null : (inputProps.textField || 'description')}
        valueField={isOptionsString() ? null : 'id'}
        onFilterChange={onFilterChange}
        clearButton
        disabled={readOnly || (dependency && isBlank(dependencyValue))}
        opened={opened}
        ref={ref}
        virtual={virtual}
        onPageChange={onPageChange}
      />
      <div className="error-label">{error}</div>
    </div>
  )
}

InputDrop.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.array]),
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    dependency: PropTypes.string,
    appendTo: PropTypes.string,
    opened: PropTypes.bool,
    forceCombobox: PropTypes.bool,
    forceRadioButton: PropTypes.bool,
    popupClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    ref: PropTypes.oneOfType([PropTypes.object])
  }).isRequired,
}
