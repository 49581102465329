import React from 'react'
import { useStore } from 'react-context-hook'
import { titleize } from 'inflected'
import '@progress/kendo-theme-material/dist/all.css'
import '/src/static/css/pages/home.css'
import Layout from '/src/ui/core/layouts/layout'
import useBatchEntities from '/src/hooks/api/batch_entities'
import I18n from '/src/utils/translations'

export default function Home() {
  const [user] = useStore('user')
  const loadingBatch = useBatchEntities()

  const cards = ['create_request', 'scoping', 'performance', 'inspection']

  const handleClick = () => {
    const appUrl = "https://play.google.com/store/apps/details?id=com.kaefer.dpms"
    window.open(appUrl, "_blank")
  }

  return (
    <Layout>
      <div className="welcome-user">
        {I18n.t('home.welcome_user', { user: user ? titleize(user.name) : '' })}
      </div>
      <div className="home-content">
        <div className="banner">
          <div className="banner-content">
            <h1>{I18n.t('home.dpms_mobile')}</h1>
            <h3>{I18n.t('home.banner_description')}</h3>

            <button id="google-play-button" className="google-play-button" onClick={handleClick}>
              <img src="/static/svg/google-play-icon.svg" alt="" />
              <div className="google-play">
                <div className="available-on">
                  {I18n.t('home.available_on')}
                </div>
                <div className="google-play-desc">Google Play</div>
              </div>
            </button>
          </div>
        </div>
        <div className="getting-started">
          <div className="getting-started-text">{I18n.t('home.getting_started')}</div>

          <div className="cards">
            {cards.map((card, index) => (
              <div key={`card-${index}`} className="card">
                <div className="number">{index+1}</div>
                <div className="card-text">
                  <span className="title">{I18n.t(`home.${card}.title`)}</span>
                  <p>{I18n.t(`home.${card}.text`)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
