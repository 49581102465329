import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SidePanelField from '/src/ui/core/panels/side_panel_field'
import I18n from '/src/utils/translations'
import '/src/static/css/core/grid/grid.css'

export default function GeneralBlock({ dataItem, model }) {
  const [fieldsColumns, setFieldsColumns] = useState([])

  useEffect(() => {
    if (!model || !dataItem) return

    const { columns } = model

    const newFieldsColumns = columns.filter((c) => !c.hideOnSidePanel)
    newFieldsColumns.sort((a, b) => a.orderOnSidePanel - b.orderOnSidePanel)

    setFieldsColumns(newFieldsColumns)
  }, [model])

  return (
    <React.Fragment>
      {fieldsColumns && (
        <div className="detail-row">
          {fieldsColumns.map((field) => (
            <SidePanelField
              field={{ ...field, value: dataItem[field.description] }}
              key={field.description}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

GeneralBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  model: PropTypes.object
}

GeneralBlock.defaultProps = {
  dataItem: {},
  model: {}
}
