import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { MdDone } from "react-icons/md"
import '/src/static/css/core/inputs/value_selector.css'
import InputSearch from '/src/ui/core/inputs/input_search'

// eslint-disable-next-line max-lines-per-function
export default function ValueSelector({
  id, categoryName, route, query, searchFields, placeholder,
  textDisplayFields, onApply, state, labelLeft, labelRight
}) {

  const [selectedValue, setSelectedValue] = useState(null)
  const [isInputSearchDisabled, setIsInputSearchDisabled] = useState(false)
  const [actionButton, setActionButton] = useState()

  const onSelectedValue = (id, value) => {
    if (selectedValue === null) enableActionButton()
    setSelectedValue(value)
  }

  const enableActionButton = () => {
    setActionButton((currButton) => {
      return React.cloneElement(currButton, { disabled: false }, currButton.props.children)
    })
  }

  const moveToSelectingState = () => {
    let applyButton = (
      <button
        className="apply-button"
        onClick={()=> onApply(selectedValue)}
      >
        {I18n.t('form.inputs.value_selector.apply')}
      </button>
    )
    if (selectedValue === null) {
      const { children } = applyButton.props
      applyButton = React.cloneElement(applyButton, { disabled: true }, children)
    }
    setActionButton(applyButton)
  }

  const moveToWaitingState = () => {
    const waitLabel = I18n.t('form.inputs.value_selector.waiting')
    const waitingButton = (
      <button className="wait-button" disabled>{waitLabel}</button>
    )
    setActionButton(waitingButton)
    setIsInputSearchDisabled(true)
  }

  const moveToDoneState = () => {
    const doneDiv = (
      <div className='applied'>
        <MdDone />
        <span>
          {I18n.t('form.inputs.value_selector.done')}
        </span>
      </div>
    )
    setActionButton(doneDiv)
    setIsInputSearchDisabled(true)
  }

  useEffect(() => {
    switch(state) {
      case 'waiting':
        moveToWaitingState()
        break
      case 'done':
        moveToDoneState()
        break
      case 'selecting':
      default:
        moveToSelectingState()
        break
    }
  }, [state, selectedValue])

  const inputProps = {
    id,
    className: 'selector-combobox',
    onChange: onSelectedValue,
    options: [],
    label: labelRight,
    searchRoute: route,
    searchFields,
    textDisplayFields,
    searchExtraQuery: query,
    readOnly: isInputSearchDisabled,
    placeholder
  }

  return (
    <div className="value-selector">
      <span className="category-name">
        {labelLeft && <small>{labelLeft}</small>}
        {categoryName}
      </span>
      <div className="actions">
        <InputSearch inputProps={inputProps} />
        {actionButton}
      </div>
    </div>
  )
}

ValueSelector.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  categoryName: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  query: PropTypes.oneOfType([PropTypes.object]),
  searchFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  textDisplayFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onApply: PropTypes.func,
  placeholder: PropTypes.string,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  state: PropTypes.string.isRequired
}

ValueSelector.defaultProps = {
  query: {},
  placeholder: '',
  labelLeft: '',
  labelRight: '',
  onApply: (value) => {}
}
