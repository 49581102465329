import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import ProgressServiceModel from '/src/models/progress_service'
import { deleteMenuItem, cancelMenuItem, completeMenuItem, duplicateMenuItem, editMenuItem, historyMenuItem } from '/src/ui/core/grid/context_menu_entries'
import { useLocation } from 'react-router-dom'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useTranslations from '/src/hooks/api/translations'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import { templateType } from '/src/models/concerns/template_types'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import { useQueryParam, NumberParam } from 'use-query-params'
import ProgressServicesGrid from '/src/ui/domain/progress_services/progress_services_grid'
// eslint-disable-next-line max-len
import ProgressServicesSidePanel from '/src/ui/domain/progress_services/progress_services_side_panel'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/layouts/shadowed_page.css'

// eslint-disable-next-line max-lines-per-function
export default function useProgressServices({ request, handleCloseSidePanel, progressSelectedItem,
                                              serviceSelectedItem, onClick, showConfirmation, filtered,
                                              labels, serviceModel, requestIdPin, setInEditMode,
                                              onGridColumns, onGridDataSource }) {
  const [serviceSectionsColumns, setServiceSectionsColumns] = useState([])
  const [serviceTemplate, setServiceTemplate] = useState()
  const [subproject] = useStore('subproject')
  const [progressServiceStatuses] = useStore('progress_service_statuses')
  const loadingTranslations = useTranslations()
  const { fetchAPI, status, responseData } = useFetchAPI('eav_sections')
  const { fetchAPI: fetchTemplate, status: templateStatus,
          responseData: templateResponse } = useFetchAPI('eav_templates')
  const itemTemplateId = useQueryParamWithPrevious('eav_template_id')
  const [requestId] = useQueryParam('request_id', NumberParam)
  const history = useHistory()
  const progressServiceModel = serviceModel || new ProgressServiceModel()
  const location = useLocation()

  const fetchServiceTemplate = () => {
    const params = {
      requestAction: 'READ',
      httpAction:'get',
      query: {
        where: {
          parent_template_id: itemTemplateId,
          template_type: 'progress_service'
        }
      }
    }

    fetchTemplate(params)
  }

  useEffect(() => {
    if (!itemTemplateId) return

    let whereClause = {
      'eav_templates][template_type': templateType.progress_service
    }

    if (location.pathname == '/plannings')
      whereClause.eav_template_id = itemTemplateId
    else if (location.pathname == '/performances')
      whereClause['eav_templates][parent_template_id'] = itemTemplateId

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: {
        where: whereClause
      }
    }

    fetchAPI(params)
    handleCloseSidePanel()
  }, [itemTemplateId])

  useEffect(() => {
    if (progressSelectedItem) fetchServiceTemplate()
  }, [progressSelectedItem])

  useEffect(() => {
    if (templateStatus !== 'SUCCESS') return

    const { data } = templateResponse
    if(!data || data.length === 0 || !data[0] || !data[0].id) return
    setServiceTemplate(data[0])
  }, [templateResponse, templateStatus])

  useEffect(() => {
    if (loadingTranslations || status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(responseData.data[0])
    setServiceSectionsColumns(newSectionsColumns)
  }, [status, responseData, loadingTranslations])

  const closeSidePanelItem = () => onClick()

  const urlParams = () => {
    const params = []
    const hasRequest = request || requestId
    if (itemTemplateId) params.push(`item_template_id=${itemTemplateId}`)
    params.push(`filtered_by_request=${hasRequest ? 1 : 0}`)
    progressServiceModel.path && params.push(`path=${progressServiceModel.path}`)
    return params.join('&')
  }

  const historiesDataParams = (dataItem) => {
    const params = []
    params.push(`select_item_id=${dataItem.progress_id}`)
    params.push(`select_service_id=${dataItem.id}`)
    return params.join('&')
  }

  const editDataParams = (dataItem) => {
    const params = []
    if (dataItem) {
      params.push(`progress_id=${dataItem.progress_id}`)
      params.push(`discipline_id=${dataItem.discipline_id}`)
    }
    if (progressSelectedItem) params.push(`select_item_id=${progressSelectedItem.id}`)
    if (serviceSelectedItem) params.push(`select_service_id=${serviceSelectedItem.id}`)

    return params.join('&')
  }

  const statusI18n = (statusId) => {
    const statusObject = progressServiceStatuses && progressServiceStatuses[statusId]
    return statusObject && statusObject.i18n_id
  }

  const progressServicesMenuItems = {
    history: historyMenuItem(
      history,
      progressServiceModel.route,
      (dataItem) => {
        if (subproject.progress_type === 'progress') return false

        const allowedStatuses = ['planned', 'in_planning', 'in_performance', 'done']
        const status = statusI18n(dataItem.progress_service_status_id)
        if (allowedStatuses.includes(status)) return true

        return false
      },
      {
        urlParams: urlParams(),
        dataParams: historiesDataParams
      }
    ),
    duplicate: duplicateMenuItem(
      history,
      progressServiceModel.route,
      (dataItem) => {
        const allowedStatuses = ['planned', 'in_planning', 'in_performance']
        const status = statusI18n(dataItem.progress_service_status_id)
        if (allowedStatuses.includes(status)) return true

        return false
      },
      {
        urlParams: urlParams(),
        dataParams: editDataParams
      }
    ),
    remove: deleteMenuItem(
      history,
      progressServiceModel,
      showConfirmation,
      closeSidePanelItem,
      (dataItem) => {
        if (dataItem.estimate_service_id !== null) return false

        const allowedStatuses = ['planned', 'in_planning', 'in_performance']
        const status = statusI18n(dataItem.progress_service_status_id)
        if (allowedStatuses.includes(status)) return true

        return false
      }
    ),
    cancel: cancelMenuItem(
      progressServiceModel,
      showConfirmation,
      (dataItem) => {
        if (dataItem.estimate_service_id === null) return false

        const allowedStatuses = ['planned', 'in_planning', 'in_performance']
        const status = statusI18n(dataItem.progress_service_status_id)
        if (allowedStatuses.includes(status)) return true

        return false
      }
    ),
    complete: completeMenuItem(
      progressServiceModel,
      showConfirmation,
      closeSidePanelItem,
      (dataItem) => {
        if (subproject.progress_type === 'progress') return false

        const allowedStatuses = ['planned', 'in_planning', 'in_performance']
        const status = statusI18n(dataItem.progress_service_status_id)
        if (allowedStatuses.includes(status)) return true

        return false
      }
    ),
    edit: editMenuItem(
      history,
      progressServiceModel.route,
      (dataItem) => {
        const status = statusI18n(dataItem.progress_service_status_id)
        const allowedStatuses = ['planned', 'in_planning', 'in_performance', 'on_hold']

        if (allowedStatuses.includes(status)) return true

        return false
      },
      {
        urlParams: urlParams(),
        dataParams: editDataParams
      }
    )
  }

  return [
    (template) => (
      <ProgressServicesGrid
        parentItem={progressSelectedItem}
        selectedItem={serviceSelectedItem}
        template={template}
        filtered={filtered}
        onRowClick={onClick}
        contextMenuItems={[
          progressServicesMenuItems.history,
          progressServicesMenuItems.duplicate,
          progressServicesMenuItems.edit,
          progressServicesMenuItems.remove,
          progressServicesMenuItems.cancel,
          progressServicesMenuItems.complete
        ]}
        labels={labels}
        model={progressServiceModel}
        requestIdPin={requestIdPin}
        setInEditMode={setInEditMode}
        onGridColumns={onGridColumns}
        onDataSource={onGridDataSource}
      />
    ),
    () => (
      <ProgressServicesSidePanel
        dataItem={serviceSelectedItem}
        sections={serviceSectionsColumns}
        onClose={handleCloseSidePanel}
        contextMenuItems={progressServicesMenuItems}
      />
    ),
    serviceTemplate,
    setServiceTemplate
  ]
}

useProgressServices.defaultProps = {
  filtered: true,
  labels: []
}
