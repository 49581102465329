import React from 'react'
import PropTypes from 'prop-types'
import { byString } from '/src/utils/object'
import FlagColumn from '/src/ui/domain/progress_services/flag_column'
import UnitColumn from '/src/ui/core/grid/column_cell_factory/unit'
import ProductivityColumn from '/src/ui/domain/progress_services/productivity_column'
import DescriptionColumn from '/src/ui/domain/progresses/description_column'
import ProgressColumn from '/src/ui/domain/progresses/progress_column'
import DecimalColumn from '/src/ui/core/grid/flexible_column/decimal'
import IdCell from '/src/ui/core/grid/id_cell'
import NumberColumn from '/src/ui/core/grid/column_cell_factory/number_column'
import FieldFactory from '/src/ui/core/fields/field_factory'

// eslint-disable-next-line max-lines-per-function
export default function ProgressServiceCellFactory({ field, dataItem, columns, opts }) {
  const column = columns.find((c) => [c.description, c.field].includes(field))

  const columnValue = (trueField) => {
    let newValue = column ? dataItem[column.description] : dataItem[field]
    if (newValue && typeof newValue === 'object') {
      const searchField = trueField || field
      newValue = byString(dataItem, searchField)
    }
    return newValue
  }

  const RIGHT_ALIGNED_FIELDS = ['application_price', 'materials_price', 'equipment_price', 'other_price', 
  'norm_hours', 'team_target_hours', 'budget_target_hours', 'quantity', 'crews', 'crew_size', 'working_hours']

  let cell

  switch(field) {
    case 'request.id':
      cell = <IdCell id={dataItem.request.id} />
      break
    case 'item_number':
      cell = <NumberColumn value={dataItem.progress.number} />
      break
    case 'service_number':
      cell = <NumberColumn value={dataItem.number} />
      break
    case 'unit':
      cell = <UnitColumn dataItem={dataItem} />
      break
    case 'progress_service_summary.actual_progress':
      cell = <ProgressColumn data={dataItem} summaryKey="progress_service_summary" />
      break
    case 'productivity_factor':
      cell = <ProductivityColumn dataItem={dataItem} />
      break
    case 'delta_hours':
      cell = <DecimalColumn value={column.default(dataItem)} />
      break
    case 'delta_quantity':
      cell = <DecimalColumn value={column.default(dataItem)} />
      break
    case 'description':
      cell = <DescriptionColumn data={dataItem} columns={columns} />
      break
    case 'flag':
      cell = <FlagColumn dataItem={dataItem} request={dataItem.request} />
      break
    case 'responsible_id':
      cell = <FieldFactory value={columnValue('responsible.full_name')} type={column ? column.type : 'text'} />
      break
    default:
      cell = (
        <FieldFactory value={columnValue()} type={column ? column.type : 'text'} />
      )
      break
  }

  return RIGHT_ALIGNED_FIELDS.includes(field) ? (
    <span className="align-cells-right">{cell}</span>
  ) : (
    cell
  )
}

ProgressServiceCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.arrayOf(PropTypes.object),
  opts: PropTypes.oneOfType([PropTypes.object])
}

ProgressServiceCellFactory.defaultProps = {
  field: undefined,
  dataItem: undefined,
  columns: [],
  opts: {}
}
