import { useState, useEffect } from 'react'
import useCookie from '/src/hooks/cookie'
import { useStore } from 'react-context-hook'
import { filterToQuery } from '/src/hooks/api/query'


const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL
const MAX_RECORDS = 1000
/**
 * This hook will create excel export URL based on the entity name
 * @param entity - string, name of the entity
 * @param templateId - number/string, id of the template
 * @param isApi - boolean, if or not it is a API route
 * @returns {
 *   {
 *    linkExport: string created with the link to the excel export,
 *    setExportFilters: function to set filters to be included on the url
 *   }
 * }
 */
export default function useExportExcelUrl({ entity, templateId, isApi }) {
  const [exportFilters, setExportFilters] = useState()
  const [globalProject] = useStore('project')
  const [globalSubproject] = useStore('subproject')
  const [locale] = useStore('language')
  const [getToken] = useCookie('authentication_token')
  const apiUrl = isApi ? 'api/v1/' : ''
  const baseUrl = `${SERVER_URL}/${apiUrl}${entity}/export_xlsx.xlsx`
  // eslint-disable-next-line max-len
  const queryString = `?eav_template_id=${templateId}&project_id=${globalProject.id}&subproject_id=${globalSubproject.id}&locale=${locale}&remember_token=${getToken()}`
  const exportPath = baseUrl+queryString
  const [linkExport, setLinkExport] = useState(exportPath)


  useEffect(() => {
    let exportNew = exportPath

    if (exportFilters) {
      const { total, filter } = exportFilters

      if(total && total > MAX_RECORDS) exportNew = undefined
      else if (filter.filters.length > 0) {
        const filters = filter.filters.map((f) => filterToQuery(f)).join('&')
        exportNew += `&${filters}`
      }
    }

    setLinkExport(exportNew)
  }, [exportPath, exportFilters])

  return { linkExport, setExportFilters }
}
