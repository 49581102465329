import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import useCookie from '/src/hooks/cookie'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useClearBatchEntities from '/src/hooks/clear_batch_entities'
import Home from '/src/pages/home'
import Login from '/src/pages/login'
import RequestFormPage from '/src/pages/request_form_page'
import ScopesPage from '/src/pages/scopes_page'
import SettingsPage from '/src/pages/settings_page'
import SettingsEmbedPage from '/src/pages/settings_embed_page'
import ScopeController from '/src/pages/scope_controller'
import Authorization from '/src/pages/authorization'
import PlanningPage from '/src/pages/planning_page'
import Inspections from '/src/pages/inspections'
import DatasheetsPage from '/src/pages/datasheets_page'
import ProgressesPage from '/src/pages/progresses_page'
import ProgressFormPage from '/src/pages/progress_form_page'
import ProgressHistoryPage from '/src/pages/progress_history_page'
import DatasheetFormPage from '/src/pages/datasheet_form_page'
import InspectionFormPage from '/src/pages/inspection_form_page'
import ScheduleFormPage from '/src/pages/schedule_form_page'
import Report from '/src/pages/report'
import SubRequest from '/src/pages/sub_request'
import RequestsPage from '/src/pages/requests_page'
import EstimateFormPage from '/src/pages/estimate_form_page'
import EstimateServiceFormPage from '/src/pages/estimate_service_form_page'
import EstimateServicePackageableFormPage from '/src/pages/estimate_service_packageable_form_page'
import ProgressServiceFormPage from '/src/pages/progress_service_form_page'
import ProgressServicePackageableFormPage from '/src/pages/progress_service_packageable_form_page'
import EavTemplatePage from '/src/pages/eav_template_page'
import UserFormPage from '/src/pages/user_form_page'
import Layout from '/src/ui/core/layouts/layout'
import { notifyWarning } from '/src/ui/core/dialogs/notifications'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'

// eslint-disable-next-line max-lines-per-function
export default function AuthRequired({ match }) {
  const [floatingUser, setFloatingUser] = useState()
  const [globalUser] = useStore('user')
  const [getToken, setToken] = useCookie('authentication_token')
  const getUser = useFetchAPI('users')
  const [floatingSubproject, setFloatingSubproject] = useState()
  const [globalSubproject, setGlobalSubproject] = useStore('subproject')
  const getSubprojects = useFetchAPI('users')
  const [globalProject, setGlobalProject] = useStore('project')
  const getProjects = useFetchAPI('users')
  const history = useHistory()
  const loadingComponent = (
    <Layout showProjectSwitcher={false}>
      <ThreeDotsLoader />
    </Layout>
  )
  const [page, setPage] = useState(loadingComponent)

  const clearBatchEntities = useClearBatchEntities()

  const toRender = {
    'home': () => <Home />,
    'requests': () => <RequestsPage match={match} />,
    'requests/:id/edit': () => <RequestFormPage match={match} />,
    'requests/:id/duplicate': () => <RequestFormPage match={match} />,
    'requests/:id/sub_requests': () => <SubRequest match={match} />,
    'requests/:id/estimate': () => <EstimateFormPage match={match} />,
    'requests/new': () => <RequestFormPage match={match} />,
    'scopes': () => <ScopesPage match={match} />,
    'scopes/:id/edit': () => <ScopeController match={match} />,
    'scopes/:id/duplicate': () => <ScopeController match={match} />,
    'scopes/new': () => <ScopeController match={match} />,
    'scopes/clusters': () => <ScopesPage match={match} />,
    'estimate_services/new': () => <EstimateServiceFormPage match={match} />,
    'estimate_services/create_by_package': () => <EstimateServicePackageableFormPage match={match} />,
    'estimate_services/:id/duplicate': () => <EstimateServiceFormPage match={match} />,
    'estimate_services/:id/edit': () => <EstimateServiceFormPage match={match} />,
    'settings': () => <SettingsPage user={globalUser} />,
    'settings/edit': () => <SettingsEmbedPage user={globalUser} />,
    'authorizations': () => <Authorization />,
    'plannings': () => <PlanningPage />,
    'performances': () => <ProgressesPage />,
    'performances/new': () => <ProgressFormPage match={match} />,
    'performances/:id/edit': () => <ProgressFormPage match={match} />,
    'performances/:id/duplicate': () => <ProgressFormPage match={match} />,
    'progress_services/create_by_package': () => <ProgressServicePackageableFormPage match={match} />,
    'performances/:id/history': () => <ProgressHistoryPage match={match} />,
    'progress_services/new': () => <ProgressServiceFormPage match={match} />,
    'progress_services/:id/edit': () => <ProgressServiceFormPage match={match} />,
    'progress_services/:id/history': () => <ProgressHistoryPage match={match} />,
    'progress_services/:id/duplicate': () => <ProgressServiceFormPage match={match} />,
    'inspections': () => <Inspections />,
    'datasheets': () => <DatasheetsPage />,
    'datasheets/new': () => <DatasheetFormPage match={match} />,
    'datasheets/:id/edit': () => <DatasheetFormPage match={match} />,
    'datasheets/:id/duplicate': () => <DatasheetFormPage match={match} />,
    'inspections/schedule': () => <ScheduleFormPage match={match} />,
    'inspections/new': () => <InspectionFormPage match={match} />,
    'inspections/:id/edit': () => <InspectionFormPage match={match} />,
    'inspections/:id/duplicate': () => <InspectionFormPage match={match} />,
    'reports': () => <Report />,
    'eav_templates': () => <EavTemplatePage />,
    'users/:id/edit': () => <UserFormPage match={match} user={globalUser} />,
    'not-found': () => <h1>Not found</h1> // TODO: a component for this
  }

  const render = () => {
    if (match.isExact && match.url === '/') {
      return toRender.home()
    }

    let routePath = match.params.resource
    if (match.params.id) routePath = `${routePath}/:id`
    if (match.params.collection) routePath = `${routePath}/${match.params.collection}`

    if (routePath.match(/login/)) {
      routePath = 'home'
    } else if (!Object.keys(toRender).includes(routePath)) {
      routePath = 'not-found'
    }
    return toRender[routePath]()
  }

  const fetchUser = () => {
    const params = {
      requestAction: 'READ',
      httpAction:'get',
      resourceId: globalUser.id,
    }
    getUser.fetchAPI(params)
  }

  const fetchUserSubproject = () => {
    const params = {
      requestAction: 'READ',
      httpAction:'get',
      resourceId: globalUser.id,
      additionalResource: { path: 'subprojects' }
    }
    getSubprojects.fetchAPI(params)
  }

  const fetchUserProject = () => {
    const params = {
      requestAction: 'READ',
      httpAction:'get',
      resourceId: globalUser.id,
      additionalResource: { path: 'projects' }
    }
    getProjects.fetchAPI(params)
  }

  const validateToken = () => {
    fetchUser()
  }

  const clearSubproject = () => {
    if (globalSubproject) setGlobalSubproject({})
    if (globalProject) setGlobalProject({})
  }

  const forceHomePage = () => {
    setPage(<Home />)
    const msg = I18n.t('notification.select_subproject')
    notifyWarning({ body: msg, closeTimeout: 10 })
    history.push(``)
  }

  const cleanLogin = () => {
    clearSubproject()
    clearBatchEntities()
    forceHomePage()
  }

  useEffect(() => {
    const { responseData: { data }, status } = getUser
    if (status === 'FETCHING') setPage(loadingComponent)
    if (status === 'ERROR') setPage(<Login />)
    if (status === 'SUCCESS') {
      setFloatingUser(data[0])
    }
  }, [getUser.responseData, getUser.status])

  useEffect(() => {
    if (floatingUser) {
      if (floatingUser.remember_token && floatingUser.remember_token !== getToken()) {
        setToken(floatingUser.remember_token)
        dispatch(BusEvents.REMEMBER_TOKEN_UPDATED)
      }

      fetchUserSubproject()
    }
  }, [floatingUser])

  useEffect(() => {
    const { responseData: { data }, status } = getSubprojects
    if (status === 'FETCHING' || status === 'NOT_STARTED') return
    if (status === 'ERROR') setPage(<Login />)
    if (status === 'SUCCESS') {
      if (!floatingUser.subproject_id) {
        cleanLogin()
      } else {
        const userSubproject = data.find((subproject) => {
          return subproject.id === floatingUser.subproject_id
        })
        setFloatingSubproject(userSubproject)
        fetchUserProject()
      }
    }
  }, [floatingUser, getSubprojects.responseData, getSubprojects.status])

  useEffect(() => {
    const { responseData: { data }, status } = getProjects
    if (status === 'FETCHING' || status === 'NOT_STARTED') return
    if (status === 'ERROR') setPage(<Login />)
    if (status === 'SUCCESS') {
      if (!floatingSubproject) {
        cleanLogin()
        return
      }
      const userProject = data.find((project) => {
        return project.id === floatingSubproject.project_id
      })
      // setGlobalUser(floatingUser)
      setGlobalSubproject(floatingSubproject)
      setGlobalProject(userProject)
      setPage(render())
    }
  }, [floatingSubproject, getProjects.responseData, getProjects.status])

  useEffect(() => {
    if (globalUser) validateToken()
    else setPage(<Login />)
  }, [match.url, globalUser])

  useEffect(() => {
    if (globalProject && globalSubproject && globalUser)
      dispatch(BusEvents.REMEMBER_TOKEN_UPDATED)
  }, [globalProject, globalSubproject, globalUser, history.location.search])

  return (
    <React.Fragment>
      {page}
    </React.Fragment>
  )
}

AuthRequired.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    url: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      resource: PropTypes.string,
      collection: PropTypes.string
    })
  }).isRequired
}
