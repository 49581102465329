import { byString } from '/src/utils/object'
import { isBlank, isPresent } from '/src/utils/boolean_refinements'
import { getParents, isGranparentModel } from '/src/models/concerns/parents'

const convertValue = (value, type) => {
  if (value === undefined) return null
  if (!value) return value

  try {
    switch (type) {
      case 'integer':
      case 'decimal':
      case 'datasheet_filter':
      case 'datasheet_aggregation':
        if (typeof value === 'string') return `"${value.replaceAll('"', '\\"')}"`
        if (typeof value === 'boolean') return value
        return Number(value)
      case 'multiple_datasheet_filter':
        if (Array.isArray(value)) {
          if (Number(value[0])) {
            return value
          } else {
            return `"${value.toString().replaceAll('"', '\\"')}"`
          }
        }
      case 'date':
        return `"${new Date(value).toISOString().replace(new RegExp('T.*'), '')}"`
      case 'date_time':
        return `"${new Date(value).toISOString()}"`
      case 'drop':
      case 'string':
      case 'time':
        if (Array.isArray(value)) return `"${value.toString().replaceAll('"', '\\"')}"`
        return `"${value.replaceAll('"', '\\"')}"`
      default:
        return value
    }
  } catch {
    return null
  }
}

export const buildEquation = (formula, variables) => {
  let { equation } = formula
  if (!formula.fields || formula.fields.length === 0) return equation

  Object.values(variables).forEach((v) => {
    let { value } = v
    value = convertValue(value, v.type)
    equation = equation.replace(new RegExp(`\\b${v.description}\\b`, 'g'), value)
  })

  return equation
}

export const splitFormulaVariable = (description) => {
  let [field, value] = description.split('.')
  field = field.replace('eav_template_', '')
  if (field === 'scoping') field = 'scope'
  return [field, value]
}

export const isSelfVariable = (ownerName, variableOwner) => {
  if (Array.isArray(ownerName)) return ownerName.includes(variableOwner)
  return variableOwner === ownerName
}

const getVariableOwnerId = ({ variableOwner, parents, parentModels, actualFormValues }) => {
  const variableOwnerModel = parentModels && parentModels.find((model) => (
    model.includes(variableOwner)
  ))
  if (variableOwnerModel && isGranparentModel(variableOwnerModel)) {
    const [parentModel, grandparentModel] = getParents(variableOwnerModel)
    const parentId = actualFormValues[`${parentModel}_id`]
    return byString(parents, `${parentModel}.${parentId}.${grandparentModel}_id`)
  }
  return actualFormValues[`${variableOwner}_id`]
}

const getParentVariableValue = ({
  formula, actualFormValues, variableOwner, variableName
}) => {
  const { parents, parentModels } = formula

  const parentKey = `${variableOwner}.${variableName}`
  const parentValue = byString(parents, parentKey)

  if (isPresent(parentValue)) return parentValue

  const variableOwnerId = getVariableOwnerId({
    variableOwner, parents, parentModels, actualFormValues
  })
  const parentBatchKey = `${variableOwner}.${variableOwnerId}.${variableName}`

  return byString(parents, parentBatchKey)
}

export function buildVariables(formula, actualFormValues, types = {}) {
  const fields = formula.fields || []
  const fieldsVariables = {}

  fields.forEach((field) => {
    const { description } = field
    const [variableOwner, variableName] = splitFormulaVariable(description)
    const { entity: ownerName } = formula

    if (isSelfVariable(ownerName, variableOwner)) {
      fieldsVariables[variableName] = {
        description,
        value: actualFormValues[variableName],
        type: types[field.variable_type_id].description
      }
    } else {
      const parentKey = `${variableOwner}.${variableName}`

      if (fieldsVariables[parentKey] === undefined) fieldsVariables[parentKey] = {}

      fieldsVariables[parentKey] = {
        description,
        value: getParentVariableValue({ formula, actualFormValues, variableOwner, variableName }),
        type: types[field.variable_type_id].description
      }
    }
  })

  return fieldsVariables
}

export const getVariablesUpdates = (variables, formulaParents, watchForm) => {
  const newVariables = {}
  let hasNewValue = false

  Object.keys(variables).forEach((name) => {
    const value = name.includes('.') ? byString(formulaParents, name) : watchForm[name]

    if (value === variables[name].value) {
      newVariables[name] = variables[name]
    } else {
      hasNewValue = true
      newVariables[name] = {
        description: variables[name].description,
        value,
        type: variables[name].type
      }
    }
  })

  return { hasNewValue, newVariables }
}

const nonLookupValue = (variableValue) => {
  const value = parseFloat(variableValue)
  return isBlank(value) ? 0 : value
}

const editableLookupValue = (isSelfLookup, values) => {
  if (values.variable) return values.variable
  if (isSelfLookup) return values.item
  return values.parent
}

const fixedLookupValue = (isSelfLookup, values) => {
  if (isSelfLookup) return values.item
  if (values.variable) return values.variable
  return values.parent
}

export const getFormulaVariableValue = (ownerName, field, params) => {
  const { item: paramItem, variables: paramVariables } = params
  const { _parentItems: parentItems } = paramItem
  const { description, editable } = field
  const isLookupField = description.includes('.')
  const [variableOwner, variableName] = splitFormulaVariable(description)

  const variableValue = paramVariables[description]
  const itemValue = paramItem[variableName]
  const parentValue = byString(parentItems, `${variableOwner}.${variableName}`)
  const isSelfLookup = isSelfVariable(ownerName, variableOwner)

  if (isLookupField) {
    const values = { variable: variableValue, item: itemValue, parent: parentValue }
    if (editable) return editableLookupValue(isSelfLookup, values)
    return fixedLookupValue(isSelfLookup, values)
  }
  return nonLookupValue(variableValue)
}
