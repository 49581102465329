import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import { formatCurrency } from '/src/utils/project_formatter'
import '/src/static/css/domain/estimates/estimate_service_prices_block.css'
import '/src/static/css/core/grid/grid.css'

export default function EstimateServicePricesBlock({ dataItem }) {
  const [project] = useStore('project')

  const {
    application_price: applicationPrice,
    materials_price: materialsPrice,
    equipment_price: equipmentPrice,
    other_price: otherPrice,
  } = dataItem

  const pricesHash = {
    [I18n.t('estimate.tooltip.application')]: formatCurrency(applicationPrice, project),
    [I18n.t('estimate.tooltip.material')]: formatCurrency(materialsPrice, project),
    [I18n.t('estimate.tooltip.equipment')]: formatCurrency(equipmentPrice, project),
    [I18n.t('estimate.tooltip.others')]: formatCurrency(otherPrice, project)
  }

  const getTotal = () => {
    if (!dataItem) return

    return applicationPrice + materialsPrice + equipmentPrice + otherPrice
  }

  return (
    <React.Fragment>
      <div className='estimate-service-prices'>
        {Object.keys(pricesHash).map((key) => (
          <div className='price' key={`price_${key}`}>
            <span>{key}</span>
            <span className='currency'>{pricesHash[key]}</span>
          </div>
      ))}
        <div className='total'>
          <div className='price'>
            <span>{I18n.t('estimate_services.total')}</span>
            <span className='currency'>{formatCurrency(getTotal(), project)}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

EstimateServicePricesBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

EstimateServicePricesBlock.defaultProps = {
  dataItem: {}
}
