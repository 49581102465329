export default function useEditableGridCustomColumn({ datasheetFilters, datasheetColumns }) {
  const defineInputProps = (column) => {
    const columnType = column.column_type.description
    const inputProps = {}

    switch(columnType) {
      case 'drop':
        inputProps.forceCombobox = true
        inputProps.popupClassName = 'editable-grid-combobox'
        break
      case 'cascade_drop':
      case 'lookup':
        inputProps.popupClassName = 'editable-grid-combobox'
        break
      case 'datasheet_filter':
        if (!datasheetFilters[column.id]) break

        const { datasheet_template_id, datasheet_column_id } = datasheetFilters[column.id]

        if (!datasheetColumns[datasheet_column_id]) break

        inputProps.popupClassName = 'editable-grid-combobox'
        inputProps.datasheetTemplateId = datasheet_template_id
        inputProps.datasheetColumn = datasheetColumns[datasheet_column_id].description
        break
    }

    return inputProps
  }

  const modifyColumnTypeField = (column) => {
    const modifiedColumn = { ...column }
    const columnType = column.column_type && column.column_type.description

    switch(columnType) {
      case 'multiline_text':
        modifiedColumn.useStringInput = true
        break
      case 'link':
        modifiedColumn.column_type.useStringField = true
        break
      case 'datasheet_filter':
        modifiedColumn.column_type.single = true
        break
    }

    return modifiedColumn
  }

  return [defineInputProps, modifyColumnTypeField]
}
