import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'

export default function UnitColumn({ dataItem }) {
  const [units] = useStore('units')

  if (Object.values(units).length === 0) return ''

  if (!dataItem || !dataItem.contract_service) return I18n.t('fields.na')

  const unitId = dataItem.contract_service.unit_id
  const unit = units[unitId]

  return (
    <div className="unit-wrapper">
      <div className="unit">{unit.description}</div>
    </div>
  )
}

UnitColumn.propTypes = { dataItem: PropTypes.oneOfType([PropTypes.object]) }

UnitColumn.defaultProps = { dataItem: undefined }
