import { useState, useEffect } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'

/**
 * Fetches a contract_service with the latest price filled
 * Calls the route: /api/v1/contract_services/latest_prices
 *
 * Ex:
 * > useFetchPricedService(id)
 * {
 *   id: N,
 *   description: 'Contract Service Description',
 *   ...
 *   latest_price: {
 *     materials_price: x,
 *     application_price: y,
 *     ...
 *     other_price: z
 *   }
 * }
 */
export default function useFetchPricedService(service_id) {
  const [pricedService, setPricedService] = useState({})
  const { fetchAPI, status, responseData } = useFetchAPI('contract_services')

  useEffect(() => {
    if (!service_id) return

    const params = { requestAction: 'READ', httpAction: 'get' }
    const query = { where: { id: service_id } }
    const additionalResource = { path: 'latest_prices' }
    fetchAPI({ ...params, query, additionalResource })
  }, [service_id])

  useEffect(() => {
    if (status !== 'SUCCESS') return

    const newService = responseData.data[0]
    setPricedService(newService)
  }, [status, responseData])

  return pricedService
}
