import { useState, useEffect } from 'react'
import { singularize, pluralize } from 'inflected'
import { useWatch } from 'react-hook-form'
import useSyncBatch from '/src/hooks/api/sync_batch'

/**
 * Fetches all the parent items for the desired item
 *
 * @param item - Object (The item used to fetch it's parents)
 * @param parents - Array of Strings (The parents that will be feched)
 * @return parentItems - Object - The parent item models with their fields
 *
 * Ex:
 * > useWatchParents({ id: 1, scope_id: 1, ... }, ['scope', 'estimate.request'])
 * {
 *   'scope': { id: 1, description: 'My Scope' },
 *   'request': { id: 2, reason: 'My Reason', test_integer: 666 }
 * }
 */
export default function useWatchParents(item, parents, control) {
  const [updatedItem, setUpdatedItem] = useState(item || {})
  const [parentItems, setParentItems] = useState({})
  const [getBatch, setBatch] = useState({})
  const [parentRequiresUpdate, setParentRequiresUpdate] = useState(true)
  const [isGrandparentRequired, setIsGrandparentRequired] = useState(false)
  const [parentsReload, setParentsReload] = useState(false)
  const [grandparentsReload, setGrandparentsReload] = useState(false)

  if (parents.length === 0) return [parentItems, setParentItems]

  const hierarchy = {
    'parents': parents.filter((parent) => !parent.includes('.')),
    'grandparents': parents.filter((parent) => parent.includes('.'))
  }

  const fieldsToWatch = hierarchy.parents.map((p) => `${p}_id`)
  const watchFormValues = useWatch({ control, name: fieldsToWatch })

  const parentEntities = {}
  const grandparentEntities = {}

  //     type: 'parents', 'grandparents'
  // entities: parentEntities, grandparentEntities
  const fetchAncestors = (type, entities) => {
    hierarchy[type].forEach((entity) => {
      let foreign = entity
      let id = updatedItem[`${foreign}_id`]
      let field

      if (type === 'grandparents') {
        [field, foreign] = entity.split('.')
        let parentItem = parentEntities[pluralize(field)].get()

        if (parentItem === undefined) return
        if (typeof parentItem === 'function') parentItem = parentItem()

        id = Object.values(parentItem)[0][`${foreign}_id`]
      }

      const query = { where: { id: id || 0 } }

      const get = (() => {
        return getBatch[foreign]
      })

      const set = ((newValue) => {
        setBatch((oldValues) => {
          const newValues = {}
          newValues[foreign] = newValue

          return { ...oldValues, ...newValues }
        })
      })

      entities[pluralize(foreign)] = { get, set, query }
    })
  }

  fetchAncestors('parents', parentEntities)

  if ((hierarchy.grandparents.length > 0) && isGrandparentRequired) {
    fetchAncestors('grandparents', grandparentEntities)
  }

  const { loading:parentsLoading } = useSyncBatch(parentEntities, parentsReload)
  const { loading:grandparentsLoading } = useSyncBatch(grandparentEntities, grandparentsReload)

  //     type: 'parents', 'grandparents'
  // entities: parentEntities, grandparentEntities
  const wasResponded = (type, entities) => {
    const models = hierarchy[type].map((h) => h.replace(/.*\./, ''))

    for (let i = 0; i < models.length; i++) {
      if (!entities[pluralize(models[i])]) return false
      if (!entities[pluralize(models[i])].get()) return false
    }

    return models.every((m) => {
      if (entities[pluralize(m)].query.where.id === 0) return true // Initial state
      if (Object.values(entities[pluralize(m)].get())[0] === undefined) return false // Fetch started

      // Item fetched
      return entities[pluralize(m)].query.where.id === Object.values(entities[pluralize(m)].get())[0].id
    })
  }

  if (parentsReload && wasResponded('parents', parentEntities)) {
    setParentsReload(false)
  }

  if (grandparentsReload && wasResponded('grandparents', grandparentEntities)) {
    setGrandparentsReload(false)
  }

  const updateParentItems = (entities) => {
    const newParentItems = {}

    Object.keys(entities).forEach((key) => {
      let value = {}

      if (entities[key].get() !== undefined) {
        value = Object.values(entities[key].get())[0] // uniq id
      }

      newParentItems[singularize(key)] = value
    })

    setParentItems((items) => {
      return { ...items, ...newParentItems }
    })
  }

  const fireGrandparentsBatch = () => {
    if (hierarchy.grandparents.length === 0) return

    const grandparents = hierarchy.grandparents.map((gp) => gp.replace(/.*\./, ''))
    const isGrandparentCaptured = grandparents.every((gp) => Object.keys(parentItems).includes(gp))

    if (isGrandparentCaptured) {
      setIsGrandparentRequired(false)
      return
    }

    setIsGrandparentRequired(true)
  }

  // Update item by user changes on form
  useEffect(() => {
    const watchFormObject = watchFormValues.reduce((map, value, index) => {
      map[fieldsToWatch[index]] = value
      return map
    }, {})

    setUpdatedItem((updatedItem) => {
      return { ...updatedItem, ...watchFormObject }
    })

    // watchForm is initialized with undefineds
    if (watchFormValues.every((k) => k === undefined)) return

    setParentRequiresUpdate(true)
    setParentsReload(true)
    if (hierarchy.grandparents.length > 0) setGrandparentsReload(true)
  }, [watchFormValues])

  // Update parent items
  useEffect(() => {
    if (parentsLoading || parentsReload || isGrandparentRequired || !parentRequiresUpdate) {
      return
    }

    updateParentItems(parentEntities)
    setParentRequiresUpdate(false)
    fireGrandparentsBatch()
  }, [parentsLoading, isGrandparentRequired, parentsReload, parentRequiresUpdate])

  // Update grandparent items
  useEffect(() => {
    if (grandparentsLoading || grandparentsReload || !isGrandparentRequired) {
      return
    }

    updateParentItems(grandparentEntities)
    setIsGrandparentRequired(false)
  }, [grandparentsLoading, isGrandparentRequired, grandparentsReload])

  return [parentItems, setParentItems]
}
