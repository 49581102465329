import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import InputNumeric from '/src/ui/core/inputs/input_numeric'
import InputDate from '/src/ui/core/inputs/input_date'
import InputDateRange from '/src/ui/core/inputs/input_date_range'
import InputText from '/src/ui/core/inputs/input_text'
import InputDecimal from '/src/ui/core/inputs/input_decimal'
import '/src/static/css/core/grid/column_menu.css'

React.useLayoutEffect = React.useEffect

export default function ColumnMenuFilterUI({ columnType, firstFilterProps }) {
  const { operator, operators, booleanValues } = firstFilterProps

  const opSelected = operators.find((o) => o.operator === operator)

  const [operatorFilter, setOperatorFilter] = useState(opSelected || operators[0])

  const handleChange = (id, value) => {
    firstFilterProps.onChange({
      value,
      operator: operatorFilter.operator
    })
  }

  const integerInput = () => {
    return (
      <InputNumeric
        format="#"
        inputProps={{
          value: firstFilterProps.value,
          onChange: handleChange
        }}
      />
    )
  }

  const decimalInput = () => {
    return (
      <InputDecimal
        inputProps={{
          value: firstFilterProps.value,
          onChange: handleChange
        }}
      />
    )
  }

  const formatBoolean = (booleanValue) => {
    return booleanValues.filter((value) => value.operator === booleanValue)[0]
  }

  const booleanInput = () => {
    return (
      <DropDownList
        data={booleanValues}
        textField="text"
        value={formatBoolean(firstFilterProps.value !== undefined ? firstFilterProps.value : '')}
        onChange={(event) => handleChange(0, event.target.value.operator, undefined)}
      />
    )
  }

  const dateInput = () => {
    if (operatorFilter.operator === 'between') {
      return (
        <InputDateRange
          inputProps={{
            className: 'filter-input-date',
            value: firstFilterProps.value,
            onChange: handleChange
          }}
        />
      )
    }
    return (
      <InputDate
        inputProps={{
          className: 'filter-input-date',
          value: firstFilterProps.value,
          onChange: handleChange
        }}
      />
    )
  }

  const textInput = () => {
    return (
      <InputText
        inputProps={{
          className: 'k-textbox',
          value: firstFilterProps.value,
          onChange: handleChange
        }}
      />
    )
  }

  const inputFilter = () => {
    switch (columnType) {
      case 'integer':
        return integerInput()
      case 'numeric':
      case 'decimal':
        return decimalInput()
      case 'boolean':
        return booleanInput()
      case 'date':
      case 'date_time':
        return dateInput()
      default:
        return textInput()
    }
  }

  return (
    <div>
      {columnType !== 'boolean' && (
        <DropDownList
          data={operators}
          textField="text"
          value={operatorFilter}
          onChange={(event) => setOperatorFilter(event.target.value)}
        />
      )}
      {inputFilter()}
    </div>
  )
}

ColumnMenuFilterUI.propTypes = {
  columnType: PropTypes.string.isRequired,
  firstFilterProps: PropTypes.oneOfType([PropTypes.object]).isRequired
}
