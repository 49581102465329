import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function RequestStatusLabel({ dataItem }) {
  const [status, setStatus] = useState()
  const [statuses] = useStore('request_statuses')

  useEffect(() => {
    if (!statuses || statuses.length === 0) return
    setStatus(statuses[dataItem.request_status_id])
  }, [statuses, dataItem])

  return (
    <React.Fragment>
      {status && (
        <CustomTooltip
          maxLength={14}
          className={`label workflow-status ${status.i18n_id.replace('_', '-')}`}
        >
          {I18n.t(`requests.statuses.${status.i18n_id}`)}
        </CustomTooltip>
      )}
    </React.Fragment>
  )
}

RequestStatusLabel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}
