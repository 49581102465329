import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { MdControlPoint, MdControlPointDuplicate } from 'react-icons/md'
import I18n from '/src/utils/translations.js'
import Modal from '/src/ui/core/popups/modal'
import ChooseButton from '/src/ui/core/buttons/choose_button'
import '/src/static/css/core/popups/choose_modal.css'

// eslint-disable-next-line max-lines-per-function
export default function ChooseEstimateFormModal({ onClose, opts }) {
  const history = useHistory()

  const mountUrlFilters = (isScope) => {
    let filters = `request_id=${opts.scope.request_id}`
    filters += `&scope_id=${opts.scope.id}`
    filters += `&eav_template_id=${!isScope ?  opts.serviceTemplate.id : opts.scope.eav_template_id}`
    filters += `&discipline_id=${opts.serviceTemplate.discipline_id}`
    filters += `&scope_template_id=${opts.scope.eav_template_id}`
    filters += `&filtered_by_request=${opts.filtered ? 1 : 0}`
    filters += `&select_item_id=${opts.scope.id}`
    if (opts.service) filters += `&select_service_id=${opts.service.id}`
    return filters
  }

  return (
    <div className="confirmation-modal choose-modal">
      <Modal
        height={45}
        width={28}
        title={I18n.t('estimate_services.choose_modal.title')}
        onClose={onClose}
        closable
      >
        <div className="body">
          <ChooseButton
            onClick={() => history.push(`/scopes/new?${mountUrlFilters(true)}`)}
            title={I18n.t('scopes.choose_modal.single')}
            subtitle={I18n.t('scopes.choose_modal.single_description')}
            icon={<MdControlPoint />}
          />
          <ChooseButton
            onClick={() => history.push(`/estimate_services/new?${mountUrlFilters()}`)}
            title={I18n.t('estimate_services.choose_modal.single')}
            subtitle={I18n.t('estimate_services.choose_modal.single_description')}
            icon={<MdControlPoint />}
          />
          <ChooseButton
            onClick={() =>
              history.push(`/estimate_services/create_by_package?${mountUrlFilters()}`)}
            title={I18n.t('estimate_services.choose_modal.package')}
            subtitle={I18n.t('estimate_services.choose_modal.package_description')}
            icon={<MdControlPointDuplicate />}
          />
        </div>
      </Modal>
    </div>
  )
}

ChooseEstimateFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  opts: PropTypes.oneOfType([PropTypes.object]).isRequired
}
