import { useEffect, useMemo, useState } from 'react'
import { useStore } from 'react-context-hook'
import processColumn from '/src/ui/domain/formulas_services/columns_processor'

export default function useProcessFixedColumns({ model, columns, formulasServices }) {
  const [processedColumns, setProcessedColumns] = useState(columns)
  const [formulasStore] = useStore('formulas')

  const entity = useMemo(() => (
    model.paramName === 'scope' ? ['scope', 'scoping'] : model.paramName
  ), [model.paramName])

  useEffect(() => {
    if (!columns || !formulasStore || !formulasServices) return

    setProcessedColumns(
      columns.map((column) => (
        (column.type) ? processColumn(column, formulasServices, formulasStore, entity) : column
      ))
    )
  }, [columns, entity, formulasServices, formulasStore])

  return processedColumns
}
