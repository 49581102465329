import React, { useState } from 'react'
import { useStore } from 'react-context-hook'
import { MdStorage } from 'react-icons/md'
import I18n from '/src/utils/translations'
import SideBarCenterItem from '/src/ui/core/menus/sidebar_center_item'
import CategoryItem from '/src/ui/domain/datasheets/category_item'
import useSyncBatch from '/src/hooks/api/sync_batch'

// eslint-disable-next-line max-lines-per-function
export default function SideBarDatasheetButton() {
  const [opened, setOpened] = useState(false)
  const [categories, setCategories] = useState()
  const onClick = () => setOpened(!opened)

  const batchedEntities = {
    datasheet_categories: { get: categories , set: setCategories }
  }

  useSyncBatch(batchedEntities)

  const getCategoriesList = () => {
    if (!categories) return
    return Object.values(categories).map((category) => <CategoryItem category={category} key={category.id} />)
  }

  return (
    <SideBarCenterItem
      icon={<MdStorage />}
      title={I18n.t('side_menu.datasheets')}
      opened={opened}
      onClick={onClick}
    >
      {categories && getCategoriesList()}
    </SideBarCenterItem>
  )
}
