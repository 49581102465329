import React from 'react'
import PropTypes from 'prop-types'
import GridHeaderIcons from '/src/ui/core/grid/grid_header_icons'
import GridHeaderCounter from '/src/ui/core/grid/grid_header_counter'
import GridHeaderPaging from '/src/ui/core/grid/grid_header_paging'
import GridPageSize from '/src/ui/core/grid/grid_page_size'
import BasicGridHeader from '/src/ui/core/grid/basic_grid_header'
import useChangePage from '/src/hooks/change_page'
import '/src/static/css/core/grid/grid.css'

export default function SimpleGridHeader({ page, take, total, onPageChange, onChangePageSize, gridTitle,
                                           icons, selectedItem, itemsQuantity, children }) {

  const { currentPage, jumpToPage, pageClick } = useChangePage(page, total, onPageChange)

  return (
    <BasicGridHeader gridTitle={gridTitle} itemsQuantity={itemsQuantity} labels={children}>
      <div className="entity-grid-header-icons">
        <GridHeaderIcons icons={icons} selectedItem={selectedItem} />
        <div className="entity-grid-pagination" data-testid="grid-options">
          <GridPageSize onChangePageSize={onChangePageSize} quantity={take} />
          <GridHeaderCounter page={currentPage} jumpToPage={jumpToPage} total={total} />
          <GridHeaderPaging onPageChange={pageClick} take={take} />
        </div>
      </div>
    </BasicGridHeader>
  )
}

SimpleGridHeader.propTypes = {
  page: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  gridTitle: PropTypes.string.isRequired,
  icons: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onChangePageSize: PropTypes.func,
  labels: PropTypes.oneOfType([PropTypes.array]),
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  itemsQuantity: PropTypes.number
}

SimpleGridHeader.defaultProps = {
  selectedItem: undefined,
  labels: [],
  itemsQuantity: undefined,
  onChangePageSize: () => {}
}
