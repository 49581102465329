import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useQueryParam, NumberParam, BooleanParam } from 'use-query-params'
import '/src/static/css/layout.css'

export default function TopMenuItem({ item }) {
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [filterByRequest] = useQueryParam('filtered_by_request', BooleanParam)
  
  const pinRequest = () => {
    if (requestId && filterByRequest !== false) return `?request_id=${requestId}`
    return ''
  }

  return (
    <NavLink to={`/${item.name}${pinRequest()}`}>{item.title}</NavLink>
  )
}

TopMenuItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.string).isRequired
}
