import React from 'react'
import PropTypes from 'prop-types'
import { useSetStoreValue } from 'react-context-hook'
import codes from 'http-status-codes'
import I18n from '/src/utils/translations'
import Upload from '/src/ui/core/inputs/upload'
import KendoFileStatus from '/src/utils/kendo_file_status'
import '/src/static/css/core/inputs/input_upload.css'

// eslint-disable-next-line max-lines-per-function
// This is an uncontrolled general Upload input,
// refer to kendo for more, information and definition
// (https://www.telerik.com/kendo-react-ui/components/upload/modes/)
// eslint-disable-next-line max-lines-per-function
export default function InputUpload({ inputProps }) {
  const { id, dataItem, allowedExtensions, removeUrl,
          saveUrl, defaultFiles, multiple, autoUpload  } = inputProps

  const initFiles = () => {
    if (defaultFiles) return defaultFiles()

    if (!dataItem || !dataItem[`${id}_file_name`]) return []

    return [
      {
        name: dataItem[`${id}_file_name`],
        size: dataItem[`${id}_file_size`],
        uid: id,
        status: KendoFileStatus.uploaded,
        progress: 100
      }
    ]
  }

  const setNotification = useSetStoreValue('notification')

  const message = (scenario, status) => ({
    title: I18n.t(`form.inputs.attachment.${scenario}_${status}`),
    status,
    closable: true,
    closeTimeout: 10
  })

  const handleAPIResponse = (event, allowedCode, type) => {
    if (!event.response || !event.response.status) return

    const status = event.response.status === allowedCode ? 'success' : 'error'
    setNotification(message(type, status))
  }

  const settings = {
    id: `kendo-upload-${id}`,
    defaultFiles: defaultFiles || initFiles(),
    saveField: id,
    saveUrl,
    removeUrl,
    onStatusChange: (event) => handleAPIResponse(event, codes.OK, 'upload'),
    onRemove: (event) => handleAPIResponse(event, codes.NO_CONTENT, 'remove'),
    autoUpload: !!autoUpload,
    multiple: !!multiple,
    restrictions: { allowedExtensions }
  }

  return (
    <div className='input-upload' id={id}>
      <Upload settings={settings} />
    </div>
  )
}

InputUpload.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    dataItem: PropTypes.oneOfType([PropTypes.object]),
    allowedExtensions: PropTypes.arrayOf(PropTypes.string),
    saveUrl: PropTypes.string,
    removeUrl: PropTypes.string,
    defaultFiles: PropTypes.oneOfType([PropTypes.array]),
    multiple: PropTypes.bool,
    autoUpload: PropTypes.bool
  }).isRequired
}
