/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */

export function dpmsIdToEditableGrid(columns, requestId) {
  const isColumnRequestId = (column) => column.description === 'request_id'

  const requestIdColumn = columns.find((column) => isColumnRequestId(column))
  const requestColumn = columns.find((column) => column.description === 'request')

  if (!requestIdColumn || !requestColumn) return columns

  const newRequestColumn = {
    ...requestColumn,
    ...requestIdColumn,
    hideOnGrid: false,
    hideOnForm: false,
    type: requestColumn.type,
    blockPreviousItemEdition: true,
    allowOnEditableGrid: requestId === undefined
  }

  Object.keys(newRequestColumn).forEach((key) => requestIdColumn[key] = newRequestColumn[key])

  const newColumns = columns.filter((column) => column.description !== 'request')
  newColumns.forEach((column) => {
    if (isColumnRequestId(column) && requestId) column.allowOnEditableGrid = false
  })

  return newColumns
}

export function contractServiceToEditableGrid(columns, opts) {
  const newColumns = columns.map((column) => {
    if (column.description === 'contract_service') {
      return {
          ...column,
          field: 'contract_service.description',
          type: 'search',
          blockPreviousItemEdition: true,
          allowOnEditableGrid: true,
          searchFields: ['id', 'description'],
          searchExtraQuery: { where: {
            'discipline_id': opts.discipline_id
          } },
          searchRoute: 'contract_services/latest_prices',
          textDisplayFields: ['description'],
          textField: 'description',
      }
    }

    return column
  })

  return newColumns
}

export function showColumnsOnEditableGrid(columns, descriptions) {
  return columns.map((column) => (descriptions.includes(column.description)) ? {
    ...column,
    hideOnGrid: false,
    allowOnEditableGrid: true
  } : column)
}

export function subRequestToEditableGrid(columns) {
  return columns.map((c) => (c.description === 'sub') ? { ...c, hideLockIcon: true } : c)
}

export function editColumnsOnEditableGrid(columns, descriptions) {
  return columns.map((column) => (descriptions.includes(column.description)) ? {
    ...column,
    editable: true
  } : column)
}

export function foreignDropToEditableGrid(columns, descriptions, batchedEntities) {
  return columns.map((column) => (descriptions.includes(column.description)) ? {
    ...column,
    description: column.foreignAttribute,
    metadata: JSON.stringify(Object.values(batchedEntities[column.foreignKey])),
    dataItemFieldToDisplay: column.description,
    field: `${column.description}.description`,
  } : column)
}

export function multilineTextToEditableGrid(columns) {
  return columns.map((c) => (c.type === 'multiline_text') ? { ...c, useStringInput: true } : c)
}

export function subAreaToEditableGrid(columns) {
  return columns.map((column) => column.description === 'subarea' ? {
    ...column,
    type: 'drop',
  } : column)
}
