import React from 'react'
import { useWatch } from 'react-hook-form'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import InputControlledDropFormula from './input_controlled_drop_formula'
import '/src/static/css/core/inputs/input_drop_formula.css'

/**
 *  It will create a drop_formula input responsible to select
 *  a drop_formula_id and open the formula modal with that formula
 *
 * @param inputProps
 * @return {*}
 * @constructor
 */
// eslint-disable-next-line max-lines-per-function
export default function InputDropFormula({ inputProps }) {
  const formData = useWatch({ control: inputProps.control })

  const newInputProps = {
    ...inputProps,
    formData,
    closeModalButtonText: I18n.t('formula_modal.cancel'),
    appendModalToBody: false,
    modalSimpleTitle: true,
  }

  return <InputControlledDropFormula inputProps={newInputProps} />
}

InputDropFormula.propTypes = {
  inputProps: PropTypes.oneOfType([PropTypes.object]).isRequired
}
