import { useState } from 'react'

const useEditableGridEditedItems = () => {
  const [editedItems, setEditedItems] = useState([])

  const updateEditedItems = (nextDataSource) => {
    setEditedItems(nextDataSource.filter(
      (item) => (
        (item.to_be_deleted && item.id !== undefined) ||
        item.dirty ||
        (!item.to_be_deleted && item.id === undefined)
      )
    ))
  }

  return [editedItems, setEditedItems, updateEditedItems]
}

export default useEditableGridEditedItems
