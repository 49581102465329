import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import TrackBlock from '/src/ui/domain/requests/track_block'
import useFetchById from '/src/hooks/api/fetch_by_id'
import TrackBlockGeneralButtons from '/src/ui/core/buttons/track_block_general_buttons'
import '/src/static/css/core/blocks/track_block.css'

// eslint-disable-next-line max-lines-per-function
export default function EstimateServicesTrackBlock({ dataItem, contextMenuItems }) {
  const { contract_service: contractService } = dataItem
  const scope = useFetchById('scopes', dataItem.scope_id)
  const request = useFetchById('requests', scope ? scope.request_id : undefined)

  const buttons = (
    <TrackBlockGeneralButtons
      dataItem={dataItem}
      contextMenuItems={contextMenuItems}
    />
  )

  return !request ? <ThreeDotsLoader /> : (
    <TrackBlock detail={request} actions={buttons}>
      <div className="scope-track-block-children">
        <div className="summary-item">
          {I18n.t('estimate_services.service')} 
          {' '}
          {dataItem.number}
        </div>
      </div>
      {contractService && (
        <div>
          <div className="summary-label">
            {I18n.t('estimate_services.description_label')}
          </div>
          <div className="summary-value">
            {contractService.description}
          </div>
        </div>
      )}
    </TrackBlock>
  )
}

EstimateServicesTrackBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  contextMenuItems: PropTypes.shape({
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    remove: PropTypes.oneOfType([PropTypes.object]).isRequired
  }).isRequired
}

EstimateServicesTrackBlock.defaultProps = {
  dataItem: undefined
}
