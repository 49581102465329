import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ProgressBar } from '@progress/kendo-react-progressbars'
import '/src/static/css/core/progress_bars/colored_progress_bar.css'

export default function ColoredProgressBar({ progress }) {
  progress = parseFloat((progress*100).toFixed(2))

  const classes = { green: progress <= 100, red: progress > 100 }

  const fill = () => {
    if (progress === 0) return 0

    const fillableValue = progress % 100
    return fillableValue === 0 ? 100 : fillableValue
  }

  return (
    <div className="colored-progress-bar-wrapper">
      <div className={classNames('progress-value', classes)}>
        {`${progress}%`}
      </div>
      <div className="progress-bar">
        <ProgressBar className={classNames('progress', classes)} value={fill()} />
      </div>
    </div>
  )
}

ColoredProgressBar.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

ColoredProgressBar.defaultProps = {
  progress: 0.0
}
