// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { normalizeDateToString } from '/src/utils/project_formatter'

export default class ScheduleInspection {
  name = I18n.t('inspections.schedule_detail')

  paramName = 'inspection'

  route = 'inspections'

  columns = [
    {
      id: 1,
      description: 'recurrency_interval',
      title: I18n.t('inspections.recurrency_interval'),
      default: { start: normalizeDateToString(new Date()), end: normalizeDateToString(new Date()) },
      required: true,
      orderOnForm: 1,
      columnSize: 'large',
      type: 'date_range'
    },
    {
      id: 2,
      description: 'repetitions',
      title: I18n.t('inspections.repeat'),
      default: 1,
      min: 1,
      required: true,
      orderOnForm: 2,
      type: 'integer'
    },
    {
      id: 3,
      description: 'each',
      title: I18n.t('inspections.every'),
      metadata: JSON.stringify([
        { id: 'day', description: I18n.t('inspections.repeat_every_value.day') },
        { id: 'month', description: I18n.t('inspections.repeat_every_value.month') },
        { id: 'year', description: I18n.t('inspections.repeat_every_value.year') }
      ]),
      default: 'day',
      required: true,
      orderOnForm: 3,
      type: 'drop'
    }
  ]
}
