import React from 'react'
import PropTypes from "prop-types"

export default function UnformattedCell({ field, dataItem, value }) {
  switch (field) {
    default:
      return value || dataItem[field] || null
  }
}

UnformattedCell.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
}

UnformattedCell.defaultProps = {
  field: '',
  dataItem: {},
  value: undefined
}