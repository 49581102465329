/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid'
import BasicGridHeader from '/src/ui/core/grid/basic_grid_header'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import useWatchParentsBatch from '/src/hooks/watch_parents_batch'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetch from '/src/hooks/api/fetch'
import EditableGridLoader from '/src/ui/core/grid/editable/editable_grid_loader'
import useFormulasAtGrid from '/src/ui/core/grid/editable/editable_grid_formulas'
import CustomizeCell from '/src/ui/core/grid/customize_cell'
import ErrorColumn from '/src/ui/core/grid/column_cell_factory/error_column'
import AddRow from '/src/ui/core/grid/editable/add_row'
import GridHeaderCell from '/src/ui/core/grid/editable/grid_header_cell'
import EditModeIcon from '/src/ui/core/icons/edit_mode_icon'
import { columnType, eavColumnToKendoType } from '/src/models/concerns/eav_column'
import I18n from '/src/utils/translations'
import useSearchDatasheetFilters from '/src/ui/core/grid/search_datasheet_filters'
import useEditableGridCustomColumn from '/src/ui/core/grid/editable_grid_custom_column'
import useEditableGridKeyboardNavigation from '/src/ui/core/grid/editable_grid_keyboard_navigation'
import useEditableGridRowsDeletion from '/src/ui/core/grid/editable_grid_rows_deletion'
import useEditableGridEditedItems from '/src/ui/core/grid/editable_grid_edited_items'
import useBulkWatch from '/src/ui/core/grid/editable/editable_grid_watch'
import useProcessFixedColumns from '/src/ui/core/grid/editable/process_fixed_columns'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import { ERROR } from '/src/utils/constants/chart_colors'
import { isBlankOrFalse, isPresent } from '/src/utils/boolean_refinements'
import { removeProperties } from '/src/utils/object'
import { fieldWidthByType } from '/src/utils/constants/fields'
import { ALLOWED_COLUMN_TYPES, attributesToRemove, onSave } from '/src/ui/core/grid/editable/utils'
import '/src/static/css/core/grid/editable_grid.css'

// eslint-disable-next-line max-lines-per-function
export default function EditableGrid({ dataSource, columns, columnCellFactory, dataKey,
                                       model, onEdit, onAction, onCancel, allowCreate,
                                       allowDelete, onCreateNewItem, shouldAllowCell,
                                       isRowEditable, templateId }) {
  const [newDataSource, setNewDataSource] = useState(dataSource)
  const cellVisibilities = useRef({})
  const cellMandatories = useRef({})
  const cellConditionalFormats = useRef({})

  const saveInBatchPath = `${model.route}/save_in_batch`
  const { fetch } = useFetch()
  const [focusOnRequiredField, setFocusOnRequiredField] = useState(false)
  const parents = useWatchParentsBatch(newDataSource, model.parentModels)

  const [datasheetFilters, datasheetColumns, isDatasheetFilterSingle] = useSearchDatasheetFilters(templateId)
  const [defineInputProps, modifyColumnTypeField] = useEditableGridCustomColumn({ datasheetFilters, datasheetColumns })

  const {
    fieldSettings, formulasServices, formulas, formulasControlFields, loadingFormulas,
    treatFormulaFields
  } = useFormulasAtGrid(templateId, model)

  const isLoadingEditableGrid = loadingFormulas || !parents

  useEditableGridKeyboardNavigation(isLoadingEditableGrid)

  const [editedItems, setEditedItems, updateEditedItems] = useEditableGridEditedItems()

  const renderDeletionColumn = useEditableGridRowsDeletion(
    newDataSource, setNewDataSource, isRowEditable, updateEditedItems
  )

  const updateDataSourceAfterSave = useCallback((response) => {
    setNewDataSource((ds) => (
      ds.filter((item) => (
        !item.to_be_deleted || item.id !== undefined
      )).map((item) => {
        if (!item.dirty && !item.to_be_deleted) return item

        const positionOnEdited = editedItems.findIndex((x) => (x.virtualKey != null && (x.virtualKey === item.virtualKey)) || (x.id != null && (x.id === item.id)))

        if (positionOnEdited === -1) return ({ ...item })

        return ({
          ...item,
          inError: response[positionOnEdited].error
        })
      })
    ))
  }, [editedItems])

  useEffect(() => {
    dispatch(BusEvents.SIDE_PANEL_CLOSED)
  }, [])

  useEffect(() => {
    if (!focusOnRequiredField) return
    const displayedInput = document.querySelector('table input')
    if (displayedInput) displayedInput.focus()
    setFocusOnRequiredField(false)
  }, [focusOnRequiredField])

  useEffect(() => {
    dispatch(BusEvents.INPUT_VALIDATE)
  }, [newDataSource])

  const requiredColumns = useMemo(() => (
    columns.reduce((arr, column) => {
      if (column.required) arr.push(column.description)
      return arr
    }, [])
  ), [columns])

  const getDataItemKey = useCallback((dataItem) => (
    dataItem[dataKey] ? dataKey : 'virtualKey'
  ), [dataKey])

  const cellConditionalFormat = useCallback((dataItem, column) => {
    const dataItemId = dataItem[getDataItemKey(dataItem)]
    const itemConditionalFormats = cellConditionalFormats.current[dataItemId] || {}
    return itemConditionalFormats[column.id] || {}
  }, [getDataItemKey])

  const isCellVisible = useCallback((dataItem, column) => {
    const dataItemId = dataItem[getDataItemKey(dataItem)]
    const itemMandatories = cellMandatories.current[dataItemId] || {}
    const isMandatory =
      isPresent(itemMandatories[column.id]) ?
      itemMandatories[column.id] : requiredColumns.includes(column.description)

    const itemVisibilities = cellVisibilities.current[dataItemId]
    return itemVisibilities ? isMandatory || itemVisibilities[column.id] : true
  }, [getDataItemKey, requiredColumns])

  const isColumnAllowed = (column, dataItem) => {
    const isItemOld = dataItem && dataItem.created_at

    if (dataItem) {
      if (!shouldAllowCell(column, dataItem)) return false
      if (isCellVisible(dataItem, column) === false) return false
    }
    if (!ALLOWED_COLUMN_TYPES.includes(columnType(column))) return false
    if (column.allowOnEditableGrid === false) return false
    if (column.hideOnGrid) return false
    if (column.editable === false && column.allowOnEditableGrid !== true) return false
    if (column.allowOnEditableGrid === true &&
      column.blockPreviousItemEdition === true && isItemOld) return false
    if (columnType(column) === 'link' && column.formula_id !== null) return false
    if (columnType(column) === 'datasheet_filter') return isDatasheetFilterSingle(column)
    if (column.hideOnForm || column.readOnly) return false

    return true
  }

  const processedColumns = useProcessFixedColumns({ model, columns, formulasServices })

  const { itemChange, onItemUpdate } = useBulkWatch({
    gridState: {
      dataSource, columns: processedColumns, newDataSource, setNewDataSource, cellVisibilities,
      cellMandatories, cellConditionalFormats
    },
    onEditCallback: (changedDataSource, numChanges) => {
      onEdit(numChanges)
      updateEditedItems(changedDataSource)
    },
    formulas, formulasControlFields, parents, parentModels: model.parentModels,
    includeOnFormula: {}, treatFormulaFields, getDataItemKey
  })

  const isColumnRequired = (column, item) => {
    const colName = column.description

    const dataItemId = item[getDataItemKey(item)]
    const itemMandatories = cellMandatories.current[dataItemId] || {}
    const isColumnRequired =
      isPresent(itemMandatories[column.id]) ? 
      itemMandatories[column.id] : requiredColumns.includes(colName)

    return isColumnAllowed(column, item) && isColumnRequired
  }

  const isRequiredColumnEmpty = (column, item) => {
    const colName = column.description
    return isColumnRequired(column, item) && isBlankOrFalse(item[colName])
  }

  const isRequiredFieldsEmpty = () => {
    const dataCopy = newDataSource.map((row) => ({ ...row, inEdit: false, requiredAndEmpty: [] }))

    let focusedIndex
    let focusedColName

    Object.keys(dataCopy).forEach((i) => {
      if (dataCopy[i].to_be_deleted) return

      columns.forEach((column) => {
        const colName = column.description
        const requiredAndEmpty = isRequiredColumnEmpty(column, dataCopy[i])

        if (!requiredAndEmpty) return

        dataCopy[i].requiredAndEmpty.push(colName)
        focusedIndex = focusedIndex || i
        focusedColName = focusedColName || colName
      })
    })
    if (!focusedIndex) return false
    dataCopy[focusedIndex] = { ...dataCopy[focusedIndex], inEdit: focusedColName }
    setNewDataSource(dataCopy)
    return true
  }

  const saveGridChangesSuccess = (data) => {
    dispatch(BusEvents.HIDE_DIALOG)
    const successOnEdition = data.filter((item) => !item.error)
    const errorOnEdition = data.filter((item) => item.error)
    if (successOnEdition.length > 0) onSave('success', successOnEdition.length)
    if (errorOnEdition.length > 0) {
      onSave('error', errorOnEdition.length)
      updateDataSourceAfterSave(data)
    } else {
      onAction()
    }
  }

  const fetchSaveGrid = (fetchData) => {
    const saveInBatchArgs = {
      httpAction: 'post',
      data: { items: fetchData }
    }

    fetch(saveInBatchPath, saveInBatchArgs, {
      onSuccess: ({ data }, params) => saveGridChangesSuccess(data),
      onError: (response, message) => dispatch(BusEvents.HIDE_DIALOG)
    })
  }

  const saveGridChanges = () => {
    if (isRequiredFieldsEmpty()) {
      setFocusOnRequiredField(true)
      notifyError(I18n.t('grid.editable.error.mandatory'))
      return
    }
    if (editedItems.length === 0) {
      onCancel()
      return
    }

    const clearEditedItems = editedItems.map((item) => {
      const newItem = { ...item }
      return removeProperties(newItem, attributesToRemove(columns))
    })

    fetchSaveGrid(clearEditedItems)
    dispatch(BusEvents.SHOW_LOADING_DIALOG)
  }

  const OnAfterCreateNewItem = (createdItem) => {
    setNewDataSource((ds) => {
      const newDs = ds.map((item) => ({ ...item, inEdit: undefined }))
      return [createdItem, ...newDs]
    })

    setEditedItems((previous) => [createdItem, ...previous])

    dispatch(BusEvents.DISABLE_SAVE_EDITABLE_GRID)
    onItemUpdate(createdItem)
  }

  useBus(
    BusEvents.SAVE_EDITABLE_GRID,
    () => saveGridChanges(),
    [saveGridChanges]
  )

  const enterEdit = (dataItem, column) => {
    if (!isColumnAllowed(column, dataItem)) return
    if (column.blockPreviousItemEdition && dataItem.created_at) return
    const key = getDataItemKey(dataItem)
    setNewDataSource((ds) =>
      ds.map((item) => ({
        ...item,
        inEdit: item[key] === dataItem[key] ? column.description : undefined
      }))
    )
  }

  const customizedCell = (column) => {
    return (props) => {
      const cell = { ...props }
      if (column.column_type) cell.dataItem.inputProps = defineInputProps(column)

      const cellColumn = modifyColumnTypeField({
        ...column,
        editable: isColumnAllowed(column, cell.dataItem),
        required: () => isColumnRequired(column, cell.dataItem)
      })

      return (
        <CustomizeCell
          cell={cell}
          column={cellColumn}
          columnCellFactory={columnCellFactory}
          columns={columns}
          onClick={() => enterEdit(props.dataItem, column)}
          inBulkEdit
          cellVisibility={() => isCellVisible(cell.dataItem, column)}
          cellConditionalFormat={() => cellConditionalFormat(cell.dataItem, column)}
        />
      )
    }
  }

  const renderErrorColumn = <Column key="inError" width={40} cell={ErrorColumn} />

  const renderColumns = useMemo(
    () =>
      columns
        .filter((column) => {
          const setting =
            fieldSettings[column.foreignAttribute] || fieldSettings[column.description] || {}
          const columnVisible = formulasServices
            ? formulasServices[`${column.description}_visible`]
            : true
          const hideFormulaColumn = columnVisible !== undefined ? !columnVisible : false
          return !hideFormulaColumn && !column.hideOnGrid && !setting.hide_on_grid
        })
        .map((column) => (
          <Column
            key={column.description}
            field={column.field || column.description}
            title={column.title}
            width={column.width || fieldWidthByType(columnType(column))}
            cell={customizedCell(column)}
            editor={eavColumnToKendoType(column)}
            headerCell={(props) => (
              <GridHeaderCell
                title={props.title}
                column={column}
                isColumnEditable={isColumnAllowed}
              >
                {props.children}
              </GridHeaderCell>
            )}
          />
        )),
    [columns, formulasServices, datasheetFilters, datasheetColumns]
  )

  const rowRender = (trElement, dataItem) => {
    const trProps = { ...trElement.props }

    const isItemToDelete = dataItem.dataItem.to_be_deleted
    const isItemInError = dataItem.dataItem.inError

    if (isItemToDelete && !isItemInError) {
      trProps.className = `${trProps.className} to-delete-row`
    } else if (dataItem.dataItem.dirty) {
      trProps.style = { ...trProps.style, color: '#2474E8', border: 'none' }
      trProps.className = `${trProps.className} k-state-selected`
    }

    if (!isRowEditable(dataItem.dataItem)) {
      trProps.className = `${trProps.className} faded-row`
    }

    if (isItemInError) {
      const errorColor = isItemToDelete ? '#F5D700' : ERROR
      trProps.style = { ...trProps.style, color: errorColor, border: `2px solid ${errorColor}` }
      trProps.className = `${trProps.className} k-state-selected`
    }

    return React.cloneElement(trElement, { ...trProps }, trElement.props.children)
  }

  return (
    <div id="editable-grid" className="entity-grid-wrapper">
      <BasicGridHeader
        gridTitle={model.name}
        itemsQuantity={newDataSource.length}
        labels={[
          <EditModeIcon key="edit" />,
          allowCreate && (
            <AddRow
              key="add"
              templateId={templateId}
              columns={columns}
              onCreateNewItem={onCreateNewItem}
              onAfterCreateNewItem={OnAfterCreateNewItem}
              loading={isLoadingEditableGrid}
            />
          )
        ]}
      />
      <EditableGridLoader
        isLoading={isLoadingEditableGrid}
        shouldRenderHeader={false}
      >
        <Grid
          data={newDataSource}
          className={newDataSource.length === 0 ? 'no-records' : ''}
          total={newDataSource.length}
          resizable
          onItemChange={itemChange}
          editField="inEdit"
          dataItemKey={dataKey}
          rowRender={rowRender}
        >
          <GridNoRecords>{I18n.t('grid.empty_after_filtering')}</GridNoRecords>
          {allowDelete && renderDeletionColumn()}
          {renderErrorColumn}
          {renderColumns}
        </Grid>
      </EditableGridLoader>
    </div>
  )
}

EditableGrid.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnCellFactory: PropTypes.element,
  columns: PropTypes.oneOfType([PropTypes.array]),
  dataKey: PropTypes.string,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  allowCreate: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onCreateNewItem: PropTypes.func,
  shouldAllowCell: PropTypes.func,
  isRowEditable: PropTypes.func,
  templateId: PropTypes.number
}

EditableGrid.defaultProps = {
  columnCellFactory: null,
  columns: [],
  dataKey: 'id',
  onEdit: () => {},
  allowCreate: false,
  allowDelete: true,
  onCreateNewItem: (newItem) => newItem,
  shouldAllowCell: () => true,
  isRowEditable: () => true,
  templateId: undefined
}
