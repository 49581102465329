import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import includeGeneralSection from '/src/ui/domain/eav_sections/include_general_section'
import useFetchSections from '/src/hooks/api/fetch_sections'
import InspectionModel from '/src/models/inspection'
import CreationInspectionModel from '/src/models/creation_inspection'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import FormButtons from '/src/ui/core/forms/form_buttons'
import Form from '/src/ui/core/forms/form'
import inspectedFormatter from '/src/ui/domain/inspections/inspected_formatter'
import InspectionStatusLabel from '/src/ui/domain/inspections/inspection_status_label'
import '/src/static/css/domain/inspections/inspection_form.css'

export default function InspectionForm({ dataItem, templateId, type }) {
  const sections = useFetchSections(templateId)
  const customFormatter = (item) => {
    const { inspected = {}, inspected_type: inspectedType } = item
    return inspectedFormatter(inspected, inspectedType)
  }
  const buildModel = () => (type === 'new' ?
    new CreationInspectionModel() :
    new InspectionModel(customFormatter)
  )
  const [model] = useState(buildModel())
  const [includeOnForm, setIncludeOnForm] = useState({})
  const readOnlyColumns = ['request_id', 'inspected_type']
  const readOnlyTypes = ['attachment', 'picture']
  const isDuplicate = () => type === 'duplicate'
  const isCreation = () => type === 'new'

  const disabledColumns = (column) => {
    let result = readOnlyColumns.includes(column.description)

    if (isDuplicate() || isCreation()) {
      result = result || readOnlyTypes.includes(column.type)
    }
    return result
  }

  useEffect(() => {
    if (dataItem && dataItem.inspected_id) {
      setIncludeOnForm({ eav_template_id: templateId, inspected_id: dataItem.inspected_id })
    }
    else setIncludeOnForm({ eav_template_id: templateId })
  }, [dataItem])

  const formReady = () => (
    <FormWrapper
      model={model}
      dataItem={dataItem}
      label={dataItem && dataItem.request_id}
      badge={dataItem && <InspectionStatusLabel dataItem={dataItem} />}
      type={type}
      sidePanelSections={includeGeneralSection(sections)}
    >
      <Form
        key="main-form"
        model={model}
        sections={sections}
        dataItem={dataItem}
        includeOnForm={includeOnForm}
        templateId={templateId}
        type={isDuplicate() ? 'new' : type}
        disableColumnEdition={disabledColumns}
      />
      <FormButtons model={model} awaitFormSuccess />
    </FormWrapper>
  )

  return formReady()
}

InspectionForm.propTypes = {
  dataItem: PropTypes.shape({
    inspected: PropTypes.object,
    inspected_type: PropTypes.string
  }),
  templateId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
}

InspectionForm.defaultProps = {
  dataItem: {
    inspected: {},
    inspected_type: 'Request'
  }
}
