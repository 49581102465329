import React from 'react'
import PropTypes from 'prop-types'
import { MdThumbUp } from 'react-icons/md'
import I18n from '/src/utils/translations'
import Button from '/src/ui/core/buttons/button'
import PopupButton from '/src/ui/core/buttons/popup_button'
import useInspectionAcceptancePopup from '/src/ui/domain/inspections/inspection_acceptance_popup'
import '/src/static/css/core/blocks/track_block.css'

export default function InspectionActionButtons({ dataItem, actions, onSubmitted }) {
  const [openModal, renderModal] = useInspectionAcceptancePopup(dataItem, onSubmitted)

  const items = actions.map((action) => ({ ...action, dataItem }))

  return (
    <React.Fragment>
      {renderModal()}
      <div className="track-block-buttons">
        <Button
          text={I18n.t('inspections.acceptance')}
          icon={<MdThumbUp />}
          className="edit-button-track-block"
          onClick={() => openModal()}
        />
        <PopupButton items={items} text={I18n.t('actions.more')} />
      </div>
    </React.Fragment>
  )
}

InspectionActionButtons.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  actions: PropTypes.arrayOf(PropTypes.object),
  onSubmitted: PropTypes.func
}

InspectionActionButtons.defaultProps = {
  dataItem: undefined,
  actions: [],
  onSubmitted: undefined
}
