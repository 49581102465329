import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useUpdateEffect from '/src/hooks/update_effect'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import { editMenuItem, duplicateMenuItem } from '/src/ui/core/grid/context_menu_entries'
import { status } from '/src/utils/constants/request'
import RequestModel from '/src/models/request'
import useRejectRequest from '/src/ui/domain/requests/reject_request'
import useRequestConfirmationPopup from '/src/ui/domain/requests/request_confirmation_popup'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import I18n from '/src/utils/translations'
import {
  MdDateRange,
  MdCheck,
  MdPanTool,
  MdReplay,
  MdCheckCircle,
  MdClear,
  MdDelete,
  MdThumbUp
} from 'react-icons/md'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/panels/side_panel.css'

// eslint-disable-next-line max-len, max-lines-per-function
export default function useRequestContextMenu({ setAuthorizeDataItem,
                                                setScheduleDataItem,
                                                showConfirmation }){
  const requestModel = new RequestModel()
  const history = useHistory()
  const getOrder = useFetchAPI('orders')
  const rejectAction = useRejectRequest(showConfirmation)
  const showActionConfirmation = useRequestConfirmationPopup(showConfirmation)
  const successBtnColor = '#607D8B'

  useUpdateEffect(() => {
    if (getOrder.status === 'FETCHING')
      return dispatch(BusEvents.SHOW_LOADING_DIALOG)

    if (getOrder.status === 'SUCCESS') {
      dispatch(BusEvents.HIDE_DIALOG)

      if (getOrder.responseData.data.length === 0) {
        notifyError(I18n.t('requests.notification.missing_order_error'))
        return true
      }

      setAuthorizeDataItem(getOrder.responseData.data[0])
    }

    return true
  }, [getOrder.status, getOrder.requestAction, getOrder.responseData])

  const editPossibleStatus = ['registered', 'confirmed', 'in_scoping',
                              'authorization_pending', 'in_planning',
                              'in_performance', 'on_hold', 'clustered']
  const duplicatePossibleStatus = ['registered', 'confirmed', 'in_scoping',
                                   'authorization_pending', 'in_planning',
                                   'in_performance', 'done', 'on_hold']
  const schedulePossibleStatus = ['registered', 'confirmed', 'in_scoping',
                                  'authorization_pending', 'in_planning',
                                  'in_performance', 'on_hold', 'clustered']
  const revertPossibleStatus = ['canceled', 'confirmed', 'in_scoping',
                                'authorization_pending', 'in_planning',
                                'in_performance', 'done', 'on_hold', 'clustered']
  const donePossibleStatus = ['confirmed', 'in_scoping', 'authorization_pending',
                              'in_planning', 'in_performance', 'on_hold']
  const cancelPossibleStatus = ['registered', 'confirmed', 'in_scoping',
                                'authorization_pending', 'in_planning',
                                'in_performance', 'on_hold']

  const deleteParams = (item) => {
    const opts = { id: item.id }
    if (status[item.request_status_id] === 'registered') return { path: 'revert_workflow', ...opts }
    return { requestAction: 'DESTROY', httpAction: 'delete', path: 'delete_sub_request', ...opts }
  }

  const deleteActionOpts = (item) => {
    if (!item.parent_id) return { actionName: 'delete' }

    const opts = {
      actionName: 'delete',
      customSuccessMessage: {
        body: I18n.t('requests.notification.deleting_sub_request', { id: item.id }),
        closeTimeout: 10
      }
    }
    return opts
  }

  const actionParams = (opts) => {
    return {
      resourcePath: 'requests',
      requestAction: opts.requestAction || 'UPDATE',
      httpAction: opts.httpAction || 'put',
      resourceId: opts.id,
      additionalResource: { path: opts.path }
    }
  }

  const contextMenuIcons = [
    editMenuItem(history, requestModel.route, (dataItem) => {
      return (editPossibleStatus.includes(status[dataItem.request_status_id]))
    }),
    duplicateMenuItem(history, requestModel.route, (dataItem) => {
      return (duplicatePossibleStatus.includes(status[dataItem.request_status_id]))
    }),
    {
      text: I18n.t('actions.schedule'),
      icon: <MdDateRange />,
      onClick: (e, item) => setScheduleDataItem(item),
      visible: (dataItem) => (schedulePossibleStatus.includes(status[dataItem.request_status_id]))
    },
    {
      text: I18n.t('actions.confirm'),
      icon: <MdCheck />,
      onClick: (e, dataItem) => {
        showActionConfirmation( 
          dataItem, 
          actionParams({ id: dataItem.id, path: 'realize' }),
          { 
            actionName: 'confirm', 
            buttonColor: successBtnColor,
            actionText: I18n.t('requests.confirmation_modal.confirm_confirmation'),
            cancelText: I18n.t('requests.confirmation_modal.confirm_cancel') 
          }
        )
      },
      visible: (dataItem) => (status[dataItem.request_status_id] === 'registered')
    },
    {
      text: I18n.t('actions.reject'),
      icon: <MdPanTool />,
      onClick: (e, dataItem) => rejectAction(dataItem),
      visible: (dataItem) => (status[dataItem.request_status_id] === 'authorization_pending')
    },
    {
      text: I18n.t('actions.approve'),
      icon: <MdThumbUp />,
      onClick: (e, dataItem) => {
        const orderQueryParams = {
          requestAction: 'READ',
          httpAction: 'get',
          query: { where: { request_id: dataItem.id, 'order_statuses][description': 'pending' } }
        }

        getOrder.fetchAPI(orderQueryParams)
      },
      visible: (dataItem) => (status[dataItem.request_status_id] === 'authorization_pending')
    },
    {
      text: I18n.t('actions.revert'),
      icon: <MdReplay />,
      onClick: (e, dataItem) => {
        showActionConfirmation( dataItem, actionParams({ id: dataItem.id, path: 'revert_workflow' }),
                                { actionName: 'revert' }
        )
      },
      visible: (dataItem) => (
        !dataItem.parent_id && revertPossibleStatus.includes(status[dataItem.request_status_id])
      )
    },
    {
      text: I18n.t('actions.done'),
      icon: <MdCheckCircle />,
      onClick: (e, dataItem) => {
        showActionConfirmation( dataItem, actionParams({ id: dataItem.id, path: 'complete' }),
                                { actionName: 'complete', actionText: I18n.t('actions.done'), 
                                  buttonColor: successBtnColor }
        )
      },
      visible: (dataItem) => (donePossibleStatus.includes(status[dataItem.request_status_id]))
    },
    {
      text: I18n.t('actions.cancel'),
      icon: <MdClear />,
      onClick: (e, dataItem) => {
        showActionConfirmation( dataItem, actionParams({ id: dataItem.id, path: 'cancel' }),
                                { actionName: 'cancel', actionText: I18n.t('actions.yes'), 
                                  cancelText: I18n.t('actions.no') }
        )
      },
      visible: (dataItem) => (cancelPossibleStatus.includes(status[dataItem.request_status_id]))
    },
    {
      text: I18n.t('actions.delete'),
      icon: <MdDelete />,
      onClick: (e, dataItem) => {
        showActionConfirmation(dataItem, actionParams(deleteParams(dataItem)),
                               deleteActionOpts(dataItem))
      },
      visible: (dataItem) => (
        (dataItem.parent_id != null && status[dataItem.request_status_id] !== 'in_performance') ||
        status[dataItem.request_status_id] === 'registered'
      )
    }
  ]

  return contextMenuIcons
}
