import React from 'react'
import PropTypes from 'prop-types'
import ProductivityFactorColumn from '/src/ui/core/grid/productivity_factor_column'
import { calculateServicePF } from '/src/utils/productivity_factor'

export default function ProductivityColumn({ dataItem }) {
  return dataItem.progress_service_summary ? (
    <ProductivityFactorColumn value={calculateServicePF(dataItem)} />
  ) : null
}

ProductivityColumn.propTypes = {
  dataItem: PropTypes.shape({
    progress_service_summary: PropTypes.shape({
      actual_quantity: PropTypes.number,
      actual_hours: PropTypes.number,
    }),
    quantity: PropTypes.number,
    team_target_hours: PropTypes.number
  })
}

ProductivityColumn.defaultProps = {
  dataItem: {}
}
