import { useEffect } from 'react'
import I18n from '/src/utils/translations'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess, notifyError } from '/src/ui/core/dialogs/notifications'
import { moveScreenToError } from '/src/ui/core/inputs/input_error'
import { isBlankOrFalse } from '/src/utils/boolean_refinements'

/**
 *  Hook designed to gather and send form data
 * @param model - project formatted model - mandatory
 * @param action - type of action for the submit (create or update) - mandatory
 * @param eavColumns - Flexible columns to be included on the form - optional
 * @param hideLoadingDialog - boolean to prevent loading dialog popup to be fired - optional
 * @param dataItem - The dataItem to be updated on the update scenario - optional
 * @param submitParams - default params used on form send call - optional
 * @param errorHandler - function to treat error message - optional
 * @param onSuccess - callback function to be called on success - optional
 * @param filterDataAttributes - callback function to filter data before send to API - optional
 * @return {((function(*=): (undefined))|*)[]} [function to send form data to API,
 * boolean loading for the API call]
 */
export default function useSendForm({ model, action, eavColumns, hideLoadingDialog,
                                      dataItem, submitParams, errorHandler, onSuccess,
                                      onFormSubmitAction, filterDataAttributes, pollingCallback }) {
  const { errors, loading, requestAction, responseData, fetchAPI } = useFetchAPI(model.route)

  // Return the notification message settings based on the action and status
  const message = (status) => I18n.t(`form.${action}_${status}`, { entity: model.name })

  const afterSend = (response) => {
    notifySuccess(response)
    dispatch(BusEvents.FORM_SUBMITTED)
    if (onSuccess) onSuccess()
  }

  const onFinishedJob = () => {
    afterSend(message('success'))
  }

  const onJobError = () => {
    notifyError(I18n.t('notification.error_job'))
    dispatch(BusEvents.HIDE_DIALOG)
  }

  useEffect(() => {
    if (errors && errorHandler) {
      const response = errorHandler(errors)
      if (response) afterSend(response)
    }

    if (errors || loading) return
    // TODO: Treat api response from form data sent
    if (['CREATE', 'UPDATE'].includes(requestAction)) {
      if(pollingCallback) return pollingCallback({ responseData, onFinishedJob, onJobError })
      dispatch({ type: BusEvents.FORM_SUCCESS, payload: responseData })
      afterSend(message('success'))
    }
  }, [errors, loading, responseData])

  useEffect(() => {
    if (hideLoadingDialog) return
    if (loading) dispatch(BusEvents.SHOW_LOADING_DIALOG)
    else if (!pollingCallback) dispatch(BusEvents.HIDE_DIALOG)
  }, [loading])

  const isAnyRequired = (data) => {
    // TODO: add the colum type in the following array
    //  if you are developing new form field
    const allowedForMandatoryCheck = [
      'boolean', 'string', 'integer', 'percentage', 'decimal', 'search',
      'drop', 'multiple_drop', 'picture', 'multiline_text', 'cascade_drop',
      'conditional_cascade_drop', 'time', 'link', 'module_filter', 'date',
      'date_time', 'lookup', 'datasheet_filter', 'multiple_datasheet_filter'
    ]

    let fixedFieldsRequired = []
    let flexibleFieldsRequired = []

    if(model.columns) {
      fixedFieldsRequired = model.columns.filter((column) => {
        return column.required && allowedForMandatoryCheck.includes(column.type)
      })
    }

    if (eavColumns) {
      flexibleFieldsRequired = Object.values(eavColumns).filter((column) => {
        return column.required && allowedForMandatoryCheck.includes(column.column_type.description)
      })
    }

    const requiredFields = [...fixedFieldsRequired, ...flexibleFieldsRequired]


    if (!requiredFields) return false
    moveScreenToError()
    return requiredFields.some((field) => {
      const fieldDescription = field.foreignKey ? field.foreignAttribute : field.description
      return isBlankOrFalse(data[fieldDescription])
    })
  }

  const sendFormData = (form) => {
    if (isAnyRequired(form)) return

    const data = {}
    data[model.paramName] = form

    if (filterDataAttributes) data[model.paramName] = filterDataAttributes(form)

    let params = {
      requestAction: action === 'create' ? 'CREATE' : 'UPDATE',
      httpAction: action === 'create' ? 'post' : 'put',
      data
    }

    if (action === 'update') params.resourceId = dataItem ? dataItem.id : null

    if (submitParams) params = { ...params, ...submitParams }

    fetchAPI(params)
  }

  return onFormSubmitAction ? [onFormSubmitAction, false] : [sendFormData, loading]
}
