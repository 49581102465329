import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/grid/grid.css'
import useEavColumnsFields from '/src/hooks/eav_columns_fields'
import SidePanelField from '/src/ui/core/panels/side_panel_field'

export default function SectionBlock({ dataItem, eavColumns }) {
  const [fields, setFields] = useState([])
  const fieldsObject = useEavColumnsFields(eavColumns, dataItem)

  useEffect(() => {
    if (!fieldsObject) return

    setFields(Object.values(fieldsObject))
  }, [dataItem])

  return (
    <React.Fragment>
      {fields && (
        <div className='detail-row'>
          {fields.map((field) => <SidePanelField field={field} key={field.id} />)}
        </div>
      )}
    </React.Fragment>
  )
}

SectionBlock.propTypes = {
  dataItem: PropTypes.shape({ pictures: PropTypes.arrayOf(PropTypes.object) }),
  eavColumns: PropTypes.arrayOf(PropTypes.object)
}

SectionBlock.defaultProps = {
  dataItem: { pictures: [] },
  eavColumns: []
}
