import React from 'react'
import PropTypes from 'prop-types'
import CancelButton from '/src/ui/core/buttons/cancel_button'
import ActionButton from '/src/ui/core/buttons/action_button'
import '/src/static/css/core/toolbar/action_toolbar.css'

export default function ActionToolbar({ className, description, cancelText, onCancel, 
                                        actionText, onAction, actionIcon, loadingAction }) {
  return (
    <div className={className || 'action-toolbar'}>
      <div className='action-toolbar-description'>
        {description}
      </div>
      <CancelButton 
        title={cancelText} 
        onClick={onCancel}
      />
      <ActionButton 
        icon={actionIcon}
        title={actionText} 
        onClick={onAction}
        loading={loadingAction}
      />
    </div>
  )
}

ActionToolbar.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  cancelText: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  actionIcon: PropTypes.element,
  loadingAction: PropTypes.bool
}

ActionToolbar.defaultProps = {
  className: undefined,
  description: undefined,
  onCancel: () => {},
  onAction: () => {},
  actionIcon: undefined,
  loadingAction: false
}
