import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Button from '/src/ui/core/buttons/button'
import PopupButton from '/src/ui/core/buttons/popup_button'

export default function TrackBlockGeneralButtons({ dataItem, contextMenuItems }) {
  const { edit, duplicate, remove } = contextMenuItems

  const items = [
    { ...duplicate, dataItem },
    { ...remove, dataItem }
  ]

  return edit.text && (
    <div className="track-block-buttons">
      <Button
        text={edit.text}
        icon={edit.icon}
        className="edit-button-track-block"
        onClick={(e) => edit.onClick(e, dataItem)}
      />
      <PopupButton items={items} text={I18n.t('actions.more')} />
    </div>
  )
}

const contextMenuShape = PropTypes.shape({
  text: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
}).isRequired

TrackBlockGeneralButtons.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  contextMenuItems: PropTypes.shape({
    edit: contextMenuShape,
    duplicate: contextMenuShape,
    remove: contextMenuShape
  }).isRequired
}

TrackBlockGeneralButtons.defaultProps = {
  dataItem: undefined
}
