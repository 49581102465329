import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { MdClose } from 'react-icons/md'
import GeneralTooltip from '/src/ui/core/tooltips/general_tooltip'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import { isValueData, parseDateToString } from '/src/utils/calendar'

// eslint-disable-next-line max-lines-per-function
export default function LabelFilter({ onClick, link, text, icon, classes,
                                      suppressLink, value, operator }) {
  const [globalProject] = useStore('project')
  let parsedValue = value

  if (isValueData(parsedValue)) {
    parsedValue = parseDateToString(value, globalProject)
  } else if (typeof value === 'boolean') {
    parsedValue = value ? I18n.t('actions.yes') : I18n.t('actions.no')
  }

  const operatorText = () => (
    <div>
      <span className="bold">{text}</span>
      {operator === 'contains' && isValueData(parsedValue)
        ? I18n.t(`filtering.eq`)
        : I18n.t(`filtering.${operator}`)}
      <span className="bold">{`"${parsedValue}"`}</span>
    </div>
  )

  const label = () => {
    if (parsedValue === null || parsedValue === undefined) return text

    if (!operator) return `${text}: ${parsedValue}`

    return (
      <GeneralTooltip content={operatorText} anchor="pointer">
        <div className="label-filter-text" title="tooltip">
          {text}
          :
          <div className="label-filter-value" title="tooltip">{parsedValue}</div>
        </div>
      </GeneralTooltip>
    )
  }

  return (
    <span className={`label-filter ${classes}`} data-testid="label-filter">
      {icon}
      {label()}
      {!suppressLink ? (
        <Link onClick={onClick} to={link} data-testid="close">
          <span className="close"><MdClose /></span>
        </Link>
      ) : (
        <a onClick={onClick} data-testid="close">
          <span className="close"><MdClose /></span>
        </a>
      )}
    </span>
  )
}

LabelFilter.propTypes = {
  icon: PropTypes.element,
  classes: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool
  ]),
  suppressLink: PropTypes.bool,
  operator: PropTypes.string
}

LabelFilter.defaultProps = {
  icon: <React.Fragment />,
  classes: '',
  operator: undefined,
  suppressLink: false,
  value: undefined,
  link: '/',
  onClick: () => {}
}
