import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/dropdown.css'
import useInputDrop from '/src/ui/core/inputs/input_drop_hook'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { useWatch } from 'react-hook-form'
import { filterBy } from '@progress/kendo-data-query'

export default function InputCascadeDrop({ inputProps }) {
  const { options, dependency, control, dataItem } = inputProps

  const [dropOptions, setDropOptions] = useState(options)
  const [data, setData] = useState(options)
  const [disabled, setDisabled] = useState(false)
  const foreignAttr = `${dependency}_id`
  const [inputHookProps, setInputHookProps] = useState(inputProps)
  const { wrapperClass, inputClass, error, onFocus, onBlur,
          value, onComboChange, isOptionsString } = useInputDrop(inputHookProps)
  const [dropValue, setDropValue] = useState(value)

  const defaultValue = dataItem ? dataItem[foreignAttr] : undefined
  const dependencyValue = useWatch({ control, name: foreignAttr, defaultValue })

  useEffect(() => {
    if (dependencyValue !== undefined) {
      const filteredOptions = options.filter((option) => option[foreignAttr] === dependencyValue)
      setDisabled(false)
      setInputHookProps({ ...inputProps, options: filteredOptions })
      setDropOptions(filteredOptions)
      setData(filteredOptions)
      setDropValue(null)
    } else {
      setDisabled(true)
    }
  }, [dependencyValue])

  useEffect(() => {
    setDropValue(value)
  }, [value])

  const onFilterChange = (e) => setData(filterBy(dropOptions, e.filter))

  return (
    <div className={wrapperClass}>
      <ComboBox
        disabled={disabled}
        id={inputProps.id}
        className={inputClass()}
        data={data}
        value={dropValue}
        onChange={onComboChange}
        onFocus={onFocus}
        onBlur={onBlur}
        popupSettings={{ className: 'combobox-list' }}
        filterable
        textField={isOptionsString() ? null : 'description'}
        valueField={isOptionsString() ? null : 'id'}
        onFilterChange={onFilterChange}
        clearButton={false}
      />
      <div className="error-label">{error}</div>
    </div>
  )
}

InputCascadeDrop.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.oneOfType([
      PropTypes.bool, PropTypes.func
    ]),
    options: PropTypes.array,
    dependency: PropTypes.string,
    control: PropTypes.oneOfType([PropTypes.object]),
    dataItem: PropTypes.object
  }).isRequired
}
