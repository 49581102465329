import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import { NumberParam, useQueryParam } from 'use-query-params'
import RequestModel from '/src/models/request'
import I18n from '/src/utils/translations'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useTranslations from '/src/hooks/api/translations'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import Layout from '/src/ui/core/layouts/layout'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import LoadingCover from '/src/ui/core/layouts/loading_cover'
import RequestsGrid from '/src/ui/domain/requests/requests_grid'
import RequestsPageSidePanel from '/src/ui/domain/requests/requests_page_side_panel'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import { normalizeDateToString } from '/src/utils/project_formatter'
import {
  subRequestToEditableGrid,
  editColumnsOnEditableGrid,
  foreignDropToEditableGrid,
  multilineTextToEditableGrid,
  subAreaToEditableGrid
} from '/src/utils/columns_formatter'
import { DROP_COLUMN_TYPES } from '/src/utils/constants/columns'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import '/src/static/css/core/grid/grid.css'

export default function RequestsPage({ match }) {
  const model = new RequestModel()
  const [sidePanelDataItem, setSidePanelDataItem] = useState()
  const [showLoading, setShowLoading] = useState(false)
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [selectedRequest, setSelectedRequest] = useState()
  const [sectionsColumns, setSectionsColumns] = useState([])
  const history = useHistory()
  const [subproject] = useStore('subproject')
  const [requestStatuses] = useStore('request_statuses')
  const loadingTranslations = useTranslations()
  const {
    status: sectionsStatus,
    responseData: sectionsResponse,
    fetchAPI: fetchSections
  } = useFetchAPI('eav_sections')

  const [templateId, setTemplateId] = useQueryParam('eav_template_id', NumberParam)

  const foreignEntities = model.columns.reduce((filtered, column) => {
    const { foreignKey, query } = column
    if (foreignKey && DROP_COLUMN_TYPES.includes(column.type)) filtered.push({ foreignKey, query })
    return filtered
  }, [])
  const batchedEntities = useEntitiesCache(foreignEntities)

  const onCreateNewItem = (newDataItem) => ({
    ...newDataItem,
    eav_template_id: templateId,
    service_desired_date: normalizeDateToString(new Date()),
    request_date: normalizeDateToString(new Date()),
    elevation: 0
  })

  const isRequestEditable = (row) => {
    if(!requestStatuses) return false
    if(row.request_status_id === undefined) return true
    
    return !model.cannotEditStatuses.includes(requestStatuses[row.request_status_id].i18n_id)
  }

  const isRequestColumnEditable = (dataItem) => {
    return isRequestEditable(dataItem)
  }

  const editableGridProps = {
    topEntity: {
      model,
      onCreateNewItem,
      shouldAllowCell: (_, dataItem) => isRequestColumnEditable(dataItem),
      isRowEditable: (row) => isRequestEditable(row)
    },
    allowCreate: true,
    allowDelete: false,
    recoverSettings: () => selectedRequest,
  }

  const [
    editableGrid,
    setInEditMode,
    editableGridColumns,
    editableGridDataSource
  ] = useEditableGrid(editableGridProps)

  const onGridColumns = (columns) => {
    const columnsToEdit = [
      'reason', 'comments', 'information', 'service_desired_date', 'discipline', 'area', 'subarea'
    ]
    const foreignDrops = [ 'discipline', 'area', 'subarea' ]
    columns = subAreaToEditableGrid(columns)
    columns = editColumnsOnEditableGrid(columns, columnsToEdit)
    columns = foreignDropToEditableGrid(columns, foreignDrops, batchedEntities)
    columns = multilineTextToEditableGrid(columns)
    editableGridColumns.set(subRequestToEditableGrid(columns))
  }

  useEffect(() => {
    setTemplateId(subproject.request_eav_template_id, 'replaceIn')
  }, [subproject.request_eav_template_id, setTemplateId])

  useEffect(() => {
    if (!subproject || !subproject.request_eav_template_id) return

    const query = { where: { eav_template_id: subproject.request_eav_template_id } }
    const params = { httpAction: 'get', additionalResource: { path: 'eav_columns' }, query }

    fetchSections(params)
  }, [subproject])

  useEffect(() => {
    if (loadingTranslations || sectionsStatus !== 'SUCCESS') return
    setNewSectionsColumns()
  }, [loadingTranslations, sectionsStatus])

  useBus(
    BusEvents.SIDEBAR_ADD_BUTTON_CLICKED,
    () => { history.push('/requests/new') },
    [history]
  )

  const setNewSectionsColumns = () => {
    const newSectionsColumns = filterVisibleOnWeb(sectionsResponse.data[0])
    setSectionsColumns(newSectionsColumns)
  }

  const handleCloseSidePanel = () => {
    setColumnStyles(SIDE_PANEL_CLOSED)
    setSidePanelDataItem()
    setSelectedRequest()
  }

  const onRowClick = useCallback((e) => {
    setSidePanelDataItem((prevSidePanelDataItem) => {
      const isSidePanelOpened = prevSidePanelDataItem && prevSidePanelDataItem.id === e.dataItem.id
      const nextSidePanelDataItem = isSidePanelOpened ? undefined : e.dataItem
      setSelectedRequest(nextSidePanelDataItem)

      if (nextSidePanelDataItem) {
        setShowLoading(true)
        setColumnStyles(SIDE_PANEL_OPENED)
        return nextSidePanelDataItem
      }

      handleCloseSidePanel()
      return prevSidePanelDataItem
    })
  }, [])

  const onRowRender = (_, { dataItem }) => {
    if (dataItem && dataItem.id && sidePanelDataItem &&
        dataItem.id === sidePanelDataItem.id &&
        dataItem.request_status_id !== sidePanelDataItem.request_status_id)

      setSidePanelDataItem(dataItem)
  }

  useBus(BusEvents.EXITING_EDITABLE_MODE, ({ payload }) => {
    const item = payload()
    if (!item) return

    setShowLoading(true)
    setSelectedRequest({ ...item })
    setSidePanelDataItem({ ...item })
    setColumnStyles(SIDE_PANEL_OPENED)
  }, [])

  return (
    <React.Fragment>
      <LoadingCover show={showLoading} text={I18n.t('main_dialog.loading_title')} />
      <Layout key={match.params.id} showNewButton>
        {editableGrid(
          <React.Fragment>
            <LayoutPanels wrapperClass="side-panel-wrapper no-template no-tabs-above" columnStyles={columnStyles}>
              <div className={sidePanelDataItem ? 'shadowed-page' : 'unshadowed-page'}>
                <RequestsGrid
                  onRowClick={onRowClick}
                  onRowRender={onRowRender}
                  selectedRequest={selectedRequest}
                  editableGridFunctions={{
                    setInEditMode,
                    onGridColumns,
                    editableGridDataSource
                  }}
                />
              </div>
              <RequestsPageSidePanel
                dataItem={sidePanelDataItem}
                sections={sectionsColumns}
                onClose={handleCloseSidePanel}
                onBlocksBuild={() => setShowLoading(false)}
              />
            </LayoutPanels>
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  )
}

RequestsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      collection: PropTypes.string
    })
  })
}

RequestsPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined,
      collection: undefined
    })
  })
}
