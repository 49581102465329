import React, { useState, useEffect } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'
import PropTypes from 'prop-types'
import ColumnAssigner from '/src/ui/core/popups/column_assigner'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { Dialog } from '@progress/kendo-react-dialogs'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import I18n from '/src/utils/translations'
import '/src/static/css/dialogs.css'
import '/src/static/css/core/popups/columns_batch_assigner.css'
import { MdAddCircle } from 'react-icons/md'

const ALLOWED_COLUMN_TYPES = ['boolean', 'date', 'decimal', 'integer', 'string', 'drop',
                              'multiple_drop', 'percentage', 'multiline_text']

export default function ColumnsBatchAssigner({ isOpen, onClose, eavTemplateId, selectedRecords,
                                               modelRoute, onSuccessfulUpdate }) {

  const [eavColumns, setEavColumns] = useState([])

  // Stores the assigned value for each column as hash, where the
  // key is the columns's description and the value is the assigned value.
  const [columnValuePairs, setColumnValuePairs] = useState({})
  const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = useState(false)
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false)
  const getEavColumns = useFetchAPI('eav_columns')
  const putUpdateInBatch = useFetchAPI(`${modelRoute}/update_in_batch`)

  useEffect(() => {
    const params = { requestAction: 'READ', httpAction: 'get' }
    const query = { where: { eav_template_id: eavTemplateId } }
    getEavColumns.fetchAPI({ ...params, query })
  }, [])

  useEffect(() => {
    const { loading, errors, responseData } = getEavColumns
    if (!loading && !errors && responseData.data.length > 0) {
      const allowedColumns = responseData.data.filter((eavColumn) => {
        return ALLOWED_COLUMN_TYPES.includes(eavColumn.column_type.description)
      })
      setEavColumns(allowedColumns)
    }
  }, [getEavColumns.loading, getEavColumns.errors, getEavColumns.responseData])

  useEffect(() => {
    const numColumnValuePairs = Object.keys(columnValuePairs).length
    if (numColumnValuePairs > 0 && !isUpdateButtonEnabled) {
      setIsUpdateButtonEnabled(true)
    } else if (numColumnValuePairs === 0 && isUpdateButtonEnabled) {
      setIsUpdateButtonEnabled(false)
    }
  }, [columnValuePairs])

  useEffect(() => {
    const { loading, errors, responseData } = putUpdateInBatch
    if (!loading && isUpdateInProgress) {
      dispatch(BusEvents.HIDE_DIALOG)
      setIsUpdateInProgress(false)
      if(errors) return
      displaySuccessMessage()
      onSuccessfulUpdate()
    }
  }, [putUpdateInBatch.loading, putUpdateInBatch.errors, putUpdateInBatch.responseData])

  const displaySuccessMessage = () => {
    const singleSuccessKey = 'dialogs.columns_batch_assigner.single_success'
    const multipleSuccessKey = 'dialogs.columns_batch_assigner.multiple_success'

    const body = (selectedRecords.length > 1 ?
      I18n.t(multipleSuccessKey, { numberOfItems : selectedRecords.length }) :
      I18n.t(singleSuccessKey)
    )

    notifySuccess(body)
  }

  const addOrEditColumnValuePair = (eavColumn, valueToAssign) => {
    setColumnValuePairs((currentColumnValuePairs) => {
      const updatedColumnValuePairs = { ...currentColumnValuePairs }
      updatedColumnValuePairs[eavColumn.description] = valueToAssign
      return updatedColumnValuePairs
    })
  }

  const sendBatchUpdate = () => {
    // Generate the data in the format used by the update_in_batch action:
    // { record_a.id: { column_description: value }, record_b.id: { column_description: value }... }
    const formattedData = {}
    selectedRecords.forEach((record) => {
      formattedData[record.id] = columnValuePairs
    })

    const updateInBatchArgs = {
      httpAction: 'put',
      data: { fields: formattedData }
    }

    setIsUpdateInProgress(true)
    putUpdateInBatch.fetchAPI(updateInBatchArgs)
    dispatch(BusEvents.SHOW_LOADING_DIALOG)
  }

  return (
    <React.Fragment>
      {isOpen && (
        <Dialog
          className="columns-batch-assigner"
          title={I18n.t('dialogs.columns_batch_assigner.header')}
          closeIcon
          onClose={onClose}
          width={518}
          height={643}
        >
          <div className="title">
            <span>{I18n.t('dialogs.columns_batch_assigner.title')}</span>
          </div>
          <div className="subtitle">
            <span>
              {I18n.t('dialogs.columns_batch_assigner.subtitle', {
                numberOfItems: selectedRecords.length
              })}
            </span>
          </div>
          <div className="actions-header">
            <span>{I18n.t('dialogs.columns_batch_assigner.actions')}</span>
          </div>
          <ColumnAssigner eavColumns={eavColumns} onDone={addOrEditColumnValuePair} />
          {/* <div className="add-action-field">
            <span className="add-action-button">
              <MdAddCircle />
              <span>{I18n.t('dialogs.columns_batch_assigner.add_action')}</span>
            </span>
          </div> */}
          <button
            className="update-button"
            disabled={!isUpdateButtonEnabled}
            onClick={sendBatchUpdate}
          >
            {I18n.t('dialogs.columns_batch_assigner.update')}
          </button>
        </Dialog>
      )}
    </React.Fragment>
  )
}

ColumnsBatchAssigner.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSuccessfulUpdate: PropTypes.func,
  eavTemplateId: PropTypes.number.isRequired,
  selectedRecords: PropTypes.arrayOf(PropTypes.object).isRequired,
  modelRoute: PropTypes.string.isRequired
}

ColumnsBatchAssigner.defaultProps = {
  onClose: () => {},
  onSuccessfulUpdate: () => {}
}
