import axios from 'axios'

const GMAPS_API_KEY = import.meta.env.SNOWPACK_GMAPS_API_KEY

const fetchAddress = async (latitude, longitude) => {
  return axios.get('https://maps.google.com/maps/api/geocode/json', {
    params: {
      latlng: `${latitude},${longitude}`,
      key: GMAPS_API_KEY
    }
  })
}

export default fetchAddress
