import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Itemize from '/src/ui/core/layouts/itemize'
import '/src/static/css/core/forms/form_side_panel.css'

export default function FormSidePanel({ steps, activeStep, linkableSection, linkableSubSection }) {
  const showCounter = (counter) => (Number.isNaN(counter) ? '' : `${counter}.`)
  
  const displayItem = (item, linkable, counter) => {
    if (!linkable) return <span>{`${showCounter(counter + 1)} ${item.title}`}</span>
    return <a href={`#${item.id}`}>{`${showCounter(counter + 1)} ${item.title}`}</a>
  }

  return (
    <ul id="reference-links" className="form-side-panel">
      {steps.filter((s) => s && !s.hide).map((section, i) => (
        <li key={section.title} className={activeStep === i ? 'active' : ''}>
          <Itemize type={activeStep > i ? 'checked' : ''} disabled={activeStep < i}>
            {displayItem(section, linkableSection, i)}
          </Itemize>
          {section.subSections && (
          <ul>
            {section.subSections.map((subSection) => (
              <li key={subSection.id}>
                <Itemize type={activeStep > i ? 'checked' : ''} disabled={activeStep < i}>
                  {displayItem(subSection, linkableSubSection)}
                </Itemize>
              </li>
            ))}
          </ul>
          )}
        </li>
      ))}
    </ul>
  )
}

FormSidePanel.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      awaitFormSuccess: PropTypes.bool,
      title: PropTypes.string,
      body: PropTypes.element,
      subSections: PropTypes.arrayOf(PropTypes.object)
    })
  ).isRequired,
  activeStep: PropTypes.number,
  linkableSection: PropTypes.bool,
  linkableSubSection: PropTypes.bool,
}

FormSidePanel.defaultProps = {
  activeStep: null,
  linkableSection: true,
  linkableSubSection: true,
}
