import React, { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import processColumn from '/src/ui/domain/formulas_services/columns_processor'
import PropTypes from 'prop-types'
import useFetchSections from '/src/hooks/api/fetch_sections'
import useFormulasServices from '/src/hooks/formulas_services'
import ProgressServiceModel from '/src/models/progress_service'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import FormButtons from '/src/ui/core/forms/form_buttons'
import Form from '/src/ui/core/forms/form'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'
import { NON_EDITABLE_FORMULA_FIELD_STATUSES } from '/src/utils/constants/progress_services'

// eslint-disable-next-line max-lines-per-function
export default function ProgressServiceForm({ dataItem, templateId, disciplineId,
                                              progressId, type, path }) {
  const progressServiceModel = new ProgressServiceModel()
  const sections = useFetchSections(templateId)
  const formulasServices = useFormulasServices(templateId, true)
  const [formulasStore] = useStore('formulas')
  const entity = I18n.t('progress_services.description')
  const backText = path ? I18n.t('planning.title') : I18n.t('progress_services.back')
  const [progressServiceStatuses] = useStore('progress_service_statuses')

  const isEditMode = () => (type === 'edit')
  const isDuplicateForm = () => (type === 'duplicate')

  if (isEditMode()) {
    const { columns } = progressServiceModel
    const contractServiceCol = columns.find((a) => a.description === 'contract_service')
    contractServiceCol.readOnly = true
  }

  const includeOnForm = () => {
    if (!isEditMode()) return {
      eav_template_id: templateId,
      discipline_id: disciplineId,
      progress_id: progressId
    }
  }

  const formTypes = {
    new: 'form.create_entity',
    edit: 'form.update_entity',
    duplicate: 'form.duplicate_entity'
  }

  const duplicationCustomAction = {
    requestAction: 'CREATE',
    httpAction: 'post'
  }

  if (!templateId) return (<ThreeDotsLoader />)

  const itemStatusI18n = dataItem && progressServiceStatuses && progressServiceStatuses[dataItem.progress_service_status_id].i18n_id

  return (
    <FormWrapper
      model={progressServiceModel}
      dataItem={dataItem}
      label={(isEditMode() || isDuplicateForm()) && dataItem && dataItem.request.id}
      type={type}
      backText={backText}
      subtitle={I18n.t(`form.subtitle`, { entity })}
      title={I18n.t(formTypes[type], { entity })}
    >
      <React.Fragment>
        <Form
          model={progressServiceModel}
          sections={sections}
          dataItem={dataItem}
          includeOnForm={includeOnForm()}
          templateId={templateId}
          type={type}
          hasFormulaServices
          formulasServices={formulasServices}
          disableColumnEdition={
            isEditMode() && NON_EDITABLE_FORMULA_FIELD_STATUSES.includes(itemStatusI18n) ? (column) => (column.available_on_formula) : undefined
          }
          processColumn={
            (column) => processColumn(column, formulasServices, formulasStore, progressServiceModel.paramName)
          }
          submitParams={type === 'duplicate' ? duplicationCustomAction : undefined}
        />
        <FormButtons model={progressServiceModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

ProgressServiceForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  templateId: PropTypes.number.isRequired,
  disciplineId: PropTypes.number,
  progressId: PropTypes.number,
  type: PropTypes.string.isRequired
}

ProgressServiceForm.defaultProps = {
  dataItem: null,
  disciplineId: undefined,
  progressId: undefined
}
