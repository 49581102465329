import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import Button from '/src/ui/core/buttons/button'
import '/src/static/css/core/buttons/popup_button.css'

export default function PopupButton({ onClick, text, items, role }) {
  const [icon, setIcon] = useState(<MdKeyboardArrowDown />)
  const popupButtonRef = useRef(null)

  return (
    <PopupMenu
      items={items}
      popupButtonRef={popupButtonRef}
      onOpen={() => setIcon(<MdKeyboardArrowUp />)}
      onClose={() => setIcon(<MdKeyboardArrowDown />)}
    >
      <Button
        text={text}
        className="popup-button"
        icon={icon}
        onClick={onClick}
        role={role}
      />
    </PopupMenu>
  )
}

PopupButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ).isRequired,
  role: PropTypes.string
}

PopupButton.defaultProps = {
  onClick: () => {},
  role: 'button'
}
