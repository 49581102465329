import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import useCookie from '/src/hooks/cookie'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function Image({ image, onClick }) {
  const [error, setError] = useState(false)
  const [getToken] = useCookie('authentication_token')
  const [project] = useStore('project')
  const [subproject] = useStore('subproject')
  const subprojectQuery = `&subproject_id=${subproject.id}&project_id=${project.id}`
  const initialSource = `${SERVER_URL+image.file_path}?remember_token=${getToken()}${subprojectQuery}`
  const [imageSource, setImageSource] = useState(initialSource)

  const placeholderSource = '/static/svg/image-placeholder.svg'

  useEffect(() => { if(error) setImageSource(placeholderSource) }, [error])

  return (
    <div className="side-panel-image">
      <img
        className={error ? 'image-placeholder': ''}
        src={imageSource}
        alt={image.uuid}
        onClick={() => { if (!error) onClick(image) }}
        onError={() => setError(true)}
      />
    </div>
  )
}

Image.propTypes = {
  image: PropTypes.shape({
    file_path: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func
}

Image.defaultProps = {
  onClick: () => {}
}
