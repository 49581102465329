import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { useQueryParam, StringParam } from 'use-query-params'
import { isPresent } from '/src/utils/refinements'

// Use this query: ?filter=column:value, to filter the templates by the desired column and value
export default function useTabs(templateType) {
  const [filter] = useQueryParam('filter', StringParam) // E.g. filter=datasheet_category_id:292
  const [column, value] = isPresent(filter) ? filter.split(':') : []
  const history = useHistory()

  const { status, loading, responseData, errors, fetchAPI, requestAction } = useFetchAPI('eav_templates')

  const read = () => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { template_type: templateType },
        order: { position: 'asc' }
      }
    }

    if (isPresent(filter)) {
      params.query.where[column] = value
    }

    fetchAPI(params)
  }

  useEffect(() => { read() }, [filter])

  useEffect(() => {
    if (status !== 'SUCCESS') return
    if (responseData.data.length > 0) return

    history.push('')
  }, [status, responseData])

  return {
    loading,
    responseData,
    status,
    errors,
    requestAction
  }
}
