// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class InspectedModule {

  columns = [
    {
      id: 1,
      description: 'request_id',
      title: I18n.t('requests.id'),
      foreignKey: 'requests',
      foreignAttribute: 'request_id',
      orderOnForm: 1,
      required: true,
      searchFields: ['id', 'reason', 'comments'],
      searchExtraQuery: { where: {
        '[request_statuses][i18n_id]': [
          'registered',
          'confirmed',
          'in_scoping',
          'authorization_pending',
          'in_planning',
          'in_performance',
          'on_hold',
          'done'
        ]
      } },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'comments'],
      type: 'search'
    },
    {
      id: 2,
      description: 'inspected_type',
      title: I18n.t('inspections.module'),
      orderOnForm: 2,
      metadata: JSON.stringify([
        { id: "Request", description: I18n.t('inspections.inspected_modules.request') },
        { id: "Scope", description: I18n.t('inspections.inspected_modules.scope') },
        { id: "Progress", description: I18n.t('inspections.inspected_modules.progress') }
      ]),
      default: 'Request',
      required: true,
      width: 200,
      type: 'drop'
    },
    {
      id: 3,
      description: 'inspected_template',
      title: I18n.t('inspections.template'),
      orderOnForm: 3,
      required: true,
      searchFields: ['title'],
      queryBuilder: (previous) => {
        const { inspected_type: inspectedType } = previous
        const templateTypes = {
          'Scope': 'scoping',
          'Progress': 'progress'
        }

        return { where: { template_type: templateTypes[inspectedType] } }
      },
      searchRoute: 'eav_templates',
      textDisplayFields: ['title'],
      type: 'search',
    },
    {
      id: 4,
      description: 'inspected_id',
      title: I18n.t('inspections.inspected'),
      orderOnForm: 4,
      required: true,
      searchFields: ['number'],
      queryBuilder: (previous) => {
        const { request_id: requestId, inspected_template: inspectedTemplate } = previous

        return { where: { request_id: requestId, eav_template_id: inspectedTemplate } }
      },
      searchRouteBuilder: (previous) => {
        const { inspected_type: inspectedType } = previous
        const searchRoute = {
          'Scope': 'scopes',
          'Progress': 'progresses'
        }

        return searchRoute[inspectedType]
      },
      textDisplayFields: ['number'],
      textField: 'number',
      type: 'search'
    }
  ]
}
