/* eslint-disable import/prefer-default-export */
import I18n from '/src/utils/translations'

const convertLocaleApiKey = (key) => key.replace(/\./, '.index.')

export const CustomI18n = {}

CustomI18n.t = (key) => {
  const translations = localStorage.getItem('translations')
  let parsedTranslations

  try { parsedTranslations = JSON.parse(translations) }
  catch (e) { parsedTranslations = {} }
  const title = (parsedTranslations && parsedTranslations[convertLocaleApiKey(key)]) || I18n.t(key)

  return title
}
