import React, { useRef, useState } from 'react'
import { useStore } from 'react-context-hook'
import '/src/static/css/user_menu.css'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import UserMenu from '/src/ui/core/menus/user_menu'
import useLogout from '/src/ui/domain/login/logout_hook'
import AboutVersionModal from '/src/ui/core/menus/about_version_modal'

// eslint-disable-next-line max-lines-per-function
export default function UserAvatar() {
  const popupButtonRef = useRef(null)
  const [user] = useStore('user')
  const logout = useLogout()
  const [modalOpened, setModalOpened] = useState(false)

  const userInitials = () => {
    if (!user) return null

    const { name } = user
    const firstLetter = name.substr(0, 1).toUpperCase()
    const index = name.lastIndexOf(' ')
    let secondLetter = ''
    if (index > 0) {
      secondLetter = name.substr(index+1, 1).toUpperCase()
    } else {
      secondLetter = name.substr(1, 1).toUpperCase()
    }
    return firstLetter+secondLetter
  }

  return (
    <div>
      { modalOpened ?
        AboutVersionModal(setModalOpened) : (
          <PopupAnchored
            body={<UserMenu user={user} logout={logout} onVersionClick={setModalOpened} />}
            popupButtonRef={popupButtonRef}
            bodyClass="popup-body-class"
            anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
            popupAlign={{ horizontal: 'right', vertical: 'top' }}
          >
            <button
              type="button"
              className="header-user"
              data-testid="header-user"
              ref={popupButtonRef}
            >
              {userInitials()}
            </button>
          </PopupAnchored>
        )}
    </div>
  )
}
