const userKeys = ['user', 'user_role']

export function setUser(state) {
  const keys = Object.keys(state)
  if(state.user_role) {
    keys.forEach((key) => {
      if (userKeys.includes(key)) {
        localStorage.setItem(key, JSON.stringify(state[key]))
      }
    })
  } else {
    const removableKeys = [...userKeys, ...keys]
    removableKeys.forEach((key) => {
      localStorage.removeItem(key)
    })
  }
}

export function getUser() {
  const user = localStorage.getItem('user')
  const userRole = localStorage.getItem('user_role')

  if(userRole) return { user: JSON.parse(user), user_role: JSON.parse(userRole) }
  return {}
}
