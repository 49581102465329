import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import xss from 'xss'
import I18n from '/src/utils/translations'
import '/src/static/css/core/fields/link_field.css'

export default function LinkColumn({ value }) {
  const [link, setLink] = useState(I18n.t('fields.na'))

  const getAttributes = (nodeMap) => {
    const attrs = {}
    Array.prototype.slice.call(nodeMap).forEach((item) => {
      const key = item.name === 'class' ? 'className' : item.name
      attrs[key] = item.value
    })
    
    return attrs
  }

  useEffect(() => {
    if (!value) return

    if (value.includes("</a>")) {
      const sanitizedValue = xss(value)
      const el = document.createElement('div')
      el.innerHTML = sanitizedValue
      const anchor = el.firstElementChild
      let attributes = getAttributes(anchor.attributes)
      attributes = {
        ...attributes,
        className: `${attributes.className ? `${attributes.className  } ` : ''}link-field`,
        target: '_blank',
        rel: 'noopener noreferrer'
      }
      const reactEl = React.createElement('a', attributes, el.firstElementChild.innerText)
      setLink(reactEl)
      return
    }

    const tag = (
      <a className="link-field" target="_blank" rel="noopener noreferrer" href={value}>{value}</a>
    )
    setLink(tag)
  }, [value])

  return link
}

LinkColumn.propTypes = {
  value: PropTypes.string
}

LinkColumn.defaultProps = {
  value: null
}
