import React  from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { IntlService } from '@progress/kendo-react-intl'
import I18n from '/src/utils/translations'
import HistoriesFeedItemActions from './histories_feed_item_actions'
import { 
  formattedLocaleDate, 
  formatNumberWithPrecision, 
  formatPercentageWithPrecision,
  formatHours,
} from '/src/utils/project_formatter'
import {
  calculateProgressHistoryItemPF,
  calculateServiceHistoryItemPF,
  formatPF
} from '/src/utils/productivity_factor'
import { isPresent } from '/src/utils/boolean_refinements'
import '/src/static/css/domain/progresses/histories_feed_item.css'


export default function HistoriesFeedItem({ level, item, itemActions, markerDisabled,
                                            entityDataItem, contractServiceUnit,
                                            actionsVisible, deletePopupEnabled }) {
  const [project] = useStore('project')
  const formattedPercentage = formatPercentageWithPrecision(item.accumulated_progress, project.number_of_digits, project)
  const formattedDailyQty = `${formatNumberWithPrecision(item.daily_quantity, project)}${contractServiceUnit}`
  const formattedDailyHours = formatHours(item.daily_hours)

  const formatDate = (date) => {
    const opts = { project }
    opts.parser = new IntlService()
    opts.value = date

    return formattedLocaleDate(opts)
  }  

  const calculatePF = () => {
    if (level === 'progress') return calculateProgressHistoryItemPF(entityDataItem, item)
    return calculateServiceHistoryItemPF(entityDataItem, item)
  }

  const renderItemInfo = (title, data) => {
    return (
      <div>
        <div className='info-title'>{title}</div>
        <div className='info-data'>{data}</div>
      </div>
    )
  }

  const getMarkerClass = () => {
    return `marker ${markerDisabled ? 'marker-disabled' : ''}`
  }

  const getInfoContainerClass = () => {
    return `info-container${deletePopupEnabled ? '-delete' : ''}`
  }

  return (
    <div className='histories-feed-item'>
      <div className={getMarkerClass()} />
      <div className='histories-feed-item-info'>
        <div className='item-title'>
          {formatDate(item.date)}
        </div>
        <div className='item-subtitle'>
          {isPresent(item.comment) ? item.comment : I18n.t('fields.na')}
        </div>
        <div className={getInfoContainerClass()}>
          {renderItemInfo(
            I18n.t('progress_histories.progress'), 
            formattedPercentage
          )}
          {renderItemInfo(
            I18n.t('progress_histories.quantity'), 
            formattedDailyQty
          )}
          {renderItemInfo(
            I18n.t('progress_histories.hours'), 
            formattedDailyHours
          )}
          {renderItemInfo(
            I18n.t('progress_histories.production_factor'), 
            formatPF(calculatePF(), project)
          )}
          <HistoriesFeedItemActions
            level={level}
            itemId={entityDataItem.id}
            item={item}
            actions={itemActions}
            buttonInactive={!actionsVisible || markerDisabled}
          />
        </div>
      </div>
    </div>
  )
}

HistoriesFeedItem.propTypes = {
  level: PropTypes.string.isRequired,
  entityDataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  item: PropTypes.shape({
    date: PropTypes.string.isRequired,
    comment: PropTypes.string,
    accumulated_progress: PropTypes.number.isRequired,
    daily_quantity: PropTypes.number.isRequired,
    daily_hours: PropTypes.number.isRequired,
  }).isRequired,
  itemActions: PropTypes.arrayOf(PropTypes.object).isRequired,
  markerDisabled: PropTypes.bool,
  contractServiceUnit: PropTypes.string,
  actionsVisible: PropTypes.bool,
  deletePopupEnabled: PropTypes.bool
}

HistoriesFeedItem.defaultProps = {
  markerDisabled: false,
  actionsVisible: false,
  contractServiceUnit: '',
  deletePopupEnabled: false
}
