import React from 'react'
import '/src/static/css/core/grid/grid.css'
import I18n from '/src/utils/translations'

export default function SubRequestListHeader({ subRequests }){
  return (
    <React.Fragment>
      <span className='subrequests-title' data-testid='subrequests-title'>
        {I18n.t('requests.sub_requests')}
      </span>
      <span className='subrequests-label' data-testid='subrequests-label'>
        {subRequests.length}
      </span>
    </React.Fragment>
  )
}
