import { useState } from 'react'
import useSyncBatch from '/src/hooks/api/sync_batch'
import { templateType } from '/src/models/concerns/template_types'

/**
 * Custom Hook to treat datasheet filter fields
 * @return {[*, *[], function(*): *]} (formatted Columns)
 */
export default function useSetupDatasheetFilter(templateId, dataItem, onChange) {
  const [datasheetFilters, setDatasheetFilters] = useState()
  const [multipleDatasheetFilters, setMultipleDatasheetFilters] = useState()
  const [templates, setTemplates] = useState()
  const queryFilters =  { where: { eav_columns: { eav_template_id: templateId } } }
  const queryTemplates =  { where: { template_type: templateType.datasheet } }

  const batchedEntities = {
    eav_datasheet_filters: {
      get: datasheetFilters, set: setDatasheetFilters, key: 'eav_column_id', query: queryFilters
    },
    multiple_eav_datasheet_filters: {
      get: multipleDatasheetFilters,
      set: setMultipleDatasheetFilters,
      key: 'eav_column_id',
      query: queryFilters
    },
    eav_templates: { get: templates, set: setTemplates, queryTemplates }
  }

  const { loading } = useSyncBatch(batchedEntities)

  const groupByTemplateAndTag = (datasheetFilterFields, datasheetFilterColumn) => {
    if (!datasheetFilterColumn.datasheet) return datasheetFilterFields

    const datasheetTemplateId = datasheetFilterColumn.datasheet.datasheet_template_id
    const key = datasheetFilterColumn.metadata + datasheetTemplateId

    const datasheetFilterFieldsCopy = { ...datasheetFilterFields }
    if (!datasheetFilterFieldsCopy[key]) {
      datasheetFilterFieldsCopy[key] = {
        id: key,
        filters: [],
        onChange,
        template: templates[datasheetTemplateId],
        tag: datasheetFilterColumn.metadata,
        type: 'datasheet_filter'
      }
    }

    const datasheetFilterColumnCopy = { ...datasheetFilterColumn }

    if (dataItem && dataItem[datasheetFilterColumn.description]) {
      datasheetFilterColumnCopy.value = dataItem[datasheetFilterColumn.description]
    }

    datasheetFilterFieldsCopy[key].filters.push(datasheetFilterColumnCopy)

    return datasheetFilterFieldsCopy
  }

  const createDatasheetFilters = (fields) => fields.reduce(groupByTemplateAndTag, {})

  const createSectionsHashWithAllEavColumns = (sections) => {
    const sectionsHash = {}

    sectionsHash.allEavColumns = sections.reduce((memo, section) => {
      sectionsHash[section.id] = { ...section, eav_columns: [] }
      return [...memo, ...section.eav_columns]
    }, [])

    return sectionsHash
  }

  const setDatasheetFilterFields = (sectionsHash, formulasControlFields) => {
    const datasheetFilterColumns = []
    const sectionsHashCopy = { ...sectionsHash }
    const { allEavColumns } = sectionsHashCopy

    const mappedControlFields = {}
    formulasControlFields.forEach((formula) => {
      mappedControlFields[formula.eav_column_id] = {
        ...mappedControlFields[formula.eav_column_id],
        [formula.eav_column_field]: true
      }
    })

    allEavColumns.forEach((field) => {
      const { type, column_type: columnType } = field
      const typeDescription = columnType ? columnType.description : type

      const datasheetFilterTypes = ['datasheet_filter', 'multiple_datasheet_filter']
      if (datasheetFilterTypes.includes(typeDescription)) {
        const datasheet = datasheetFilters[field.id] || multipleDatasheetFilters[field.id]
        const controlField = mappedControlFields[field.id]
        const visibilityByFormula = controlField && controlField.visible_on_web
        datasheetFilterColumns.push({
          ...field,
          datasheet,
          visibility_by_formula: visibilityByFormula
        })
      } else {
        sectionsHashCopy[field.eav_section_id].eav_columns.push(field)
      }
    })

    sectionsHashCopy.datasheetFilterFields = createDatasheetFilters(datasheetFilterColumns)
    return sectionsHashCopy
  }

  const setEavColumnsOnNewSections = (sectionsHash) => {
    const sectionsHashCopy = { ...sectionsHash }
    const { datasheetFilterFields } = sectionsHashCopy

    Object.values(datasheetFilterFields).forEach((filter) => {
      filter.position = filter.filters.sort((a, b) => a.position - b.position)[0].position
      const { eav_section_id: sectionId } = filter.filters[0]
      sectionsHashCopy[sectionId].eav_columns = [...sectionsHashCopy[sectionId].eav_columns, filter]
    })

    Object.values(sectionsHashCopy).forEach((section) => {
      if (sectionsHashCopy[section.id])
        sectionsHashCopy[section.id].eav_columns.sort((a, b) => a.position - b.position)
    })
    return sectionsHashCopy
  }

  const treatDatasheetFilterColumns = (sections, formulasControlFields = []) => {
    let sectionsHash = createSectionsHashWithAllEavColumns(sections)
    sectionsHash = setDatasheetFilterFields(sectionsHash, formulasControlFields)
    sectionsHash = setEavColumnsOnNewSections(sectionsHash)

    delete sectionsHash.allEavColumns
    delete sectionsHash.datasheetFilterFields

    return Object.values(sectionsHash).reverse()
  }

  return [datasheetFilters, multipleDatasheetFilters, loading, treatDatasheetFilterColumns]
}
