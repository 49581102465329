import React, { useRef, useState, useEffect } from 'react'
import { MdTraffic, MdClose, MdCheck } from 'react-icons/md'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'

const STATUS_BLACKLIST = ['in_performance', 'on_hold']

export default function ProgressServicesStatusFilterIcon({ filter, onFilter, onSelectedOptionChange }){
  const [popupOpened, setPopupOpened] = useState(false)
  const [statuses, setStatuses] = useState([])

  const [progressServiceStatuses] = useStore('progress_service_statuses')

  const popupButtonRef = useRef(null)

  const extra = { id: 1000, active: true, description: "Extra", filtered: false, i18n_id: "extra" }

  useEffect(() => {
    if (!progressServiceStatuses || progressServiceStatuses.length === 0) return

    const statusList = Object.values(progressServiceStatuses).map((s) => { return { ...s, filtered: false } })
    setStatuses([...statusList, extra])
  }, [progressServiceStatuses])

  const getFilteredStatusesNames = (newStatuses) => {
    const statusesNames = newStatuses.filter((x) => x.filtered).map((x) => I18n.t(`progress_services.statuses.${x.i18n_id}`))
    return statusesNames
  }

  const changeFilter = (selectedStatus) => {
    if (!popupOpened) return

    setPopupOpened(false)

    const newStatuses = statuses.map((x) => x)

    const { filtered } = selectedStatus
    newStatuses.filter((s) => selectedStatus.id === s.id).forEach((s) => s.filtered = !filtered)

    let filterIds = newStatuses.filter((x) => x.filtered).map((s) => s.id)

    if (!filterIds) return

    const planned = Object.values(progressServiceStatuses).find((pss) => pss.i18n_id == 'planned')

    const isFilteringExtra = filterIds.includes(extra.id)
    const isFilteringPlanned = filterIds.includes(planned.id)
    filterIds = filterIds.filter((item) => (item !== extra.id) && (item !== planned.id))

    const filterObject = { type: 'where', column: 'progress_service_status_id', operator: 'eq', value: filterIds }
    const extraFilterObject = { type: 'where', column: 'estimate_service_id', operator: 'eq', value: null }
    const plannedFilterObject = { type: 'where_not', column: 'responsible_id', operator: 'eq', value: 'null' }
    const plannedFilterObject2 = { type: 'where_not', column: 'start_date', operator: 'eq', value: 'null' }
    const plannedFilterObject3 = { type: 'where_not', column: 'end_date', operator: 'eq', value: 'null' }

    // Remove selected filters from grid filters
    let newFilter = []
    if (selectedStatus.i18n_id === "extra") {
      const filterWithoutExtra = removeSelectedFilter(extraFilterObject)
      newFilter = filterWithoutExtra.filter((x) => x.column !== 'progress_service_status_id')
    } else if (selectedStatus.i18n_id === 'planned') {
      let filterWithoutPlanned = removeSelectedFilter(plannedFilterObject)
      filterWithoutPlanned = removeSelectedFilter(plannedFilterObject2, filterWithoutPlanned)
      filterWithoutPlanned = removeSelectedFilter(plannedFilterObject3, filterWithoutPlanned)
      newFilter = filterWithoutPlanned.filter((x) => x.column !== 'progress_service_status_id')
    } else {
      newFilter = removeSelectedFilter(filterObject)
    }

    // Add selected filters to grid filters
    if (selectedStatus.i18n_id === 'planned' && isFilteringPlanned) {
      newFilter = addFilter(plannedFilterObject, newFilter)
      newFilter = addFilter(plannedFilterObject2, newFilter)
      newFilter = addFilter(plannedFilterObject3, newFilter)
    }
    if (selectedStatus.i18n_id === "extra" && isFilteringExtra) {
      newFilter = addFilter(extraFilterObject, newFilter)
    }
    if (filterIds.length > 0) newFilter = addFilter(filterObject, newFilter)

    onFilter(newFilter)
    setStatuses(newStatuses)
    onSelectedOptionChange(getFilteredStatusesNames(newStatuses))
  }

  const removeSelectedFilter = ({ column, operator }, source = filter) => {
    return source.filter((x) => x.column !== column || x.operator !== operator)
  }

  const addFilter = (filterObject, source = filter) => {
    const newFilter = source.map((x) => x)
    newFilter.push(filterObject)
    return newFilter
  }

  const allowedStatuses = statuses.filter((s) => s.id && !STATUS_BLACKLIST.includes(s.i18n_id))

  const clearFilters = () => {
    setPopupOpened(false)
    const newStatuses = statuses.map((s) => { return { ...s, filtered: false }})
    setStatuses(newStatuses)

    const newFilters = filter.filter((x) => {
      return x.column !== "progress_service_status_id" &&
        x.column !== "estimate_service_id" &&
        x.column !== "responsible_id" &&
        x.column !== "start_date" &&
        x.column !== "end_date"
    })
    onFilter(newFilters)

    onSelectedOptionChange(getFilteredStatusesNames(newStatuses))
  }

  const description = (status) => {
    const statusTitle = I18n.t(`progress_services.statuses.${status.i18n_id}`)
    if (statusTitle.length <= 14) return statusTitle
    return `${statusTitle.substring(0, 14)}...`
  }

  const popupBody = (
    <React.Fragment>
      <div id="popup-service-filter" className="popup-service-filter">
        <div className="flag-filter">
          {I18n.t("progress_services.flag_filter")}
        </div>
        {allowedStatuses.map((status) => {
          const i18n = status.i18n_id || ''
          return (
            <div key={status.id} className={status.filtered ? 'status-selected' : 'status-not-selected'}>
              <div className={`circle ${i18n.replace('_', '-')}-circle`} />
              <span
                className="workflow-status status-label"
                onClick={() => changeFilter(status)}
              >
                {description(status)}
                {status.filtered && <MdCheck />}
              </span>
            </div>
          )
        })}
      </div>
      <button
        id="clear-filters"
        className="clear-filters clear-service-statuses"
        type="button"
        onClick={clearFilters}
      >
        <MdClose />
        {I18n.t(`grid.clear_filters`)}
      </button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <div>
        <PopupAnchored
          body={popupBody}
          popupButtonRef={popupButtonRef}
          forceOpen={popupOpened}
          setForceOpen={setPopupOpened}
          onClick={() => setPopupOpened(!popupOpened)}
        >
          <button
            id="open-filter-popup-button"
            type="button"
            className={popupOpened ? 'btn-icon active': 'btn-icon'}
            ref={popupButtonRef}
          >
            <MdTraffic />
          </button>
        </PopupAnchored>
      </div>
    </React.Fragment>
  )
}

ProgressServicesStatusFilterIcon.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFilter: PropTypes.func.isRequired,
  onSelectedOptionChange: PropTypes.func.isRequired
}

ProgressServicesStatusFilterIcon.defaultProps = {
  filter: [],
  onFilter: () => {}
}
