import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { withStore } from 'react-context-hook'
import Routers from './routers'
import NotificationGroup from '/src/ui/core/dialogs/notification_group'
import Notifications from '/src/ui/core/dialogs/notifications'
import useInitialState from '/src/utils/store/initial_store'
import TranslationLoader from '/src/utils/translation_loader'

function AppRouter() {
  return (
    <TranslationLoader>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <div className='page'>
            <NotificationGroup />
            <Notifications />
            <Routers />
          </div>
        </QueryParamProvider>
      </BrowserRouter>
    </TranslationLoader>
  )
}

const [ initialState, config ] = useInitialState()
export default withStore(AppRouter, initialState, config)
