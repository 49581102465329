import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/gps.css'
import I18n from '/src/utils/translations'
import { MdLocationOn } from 'react-icons/md'
import fetchAddress from '/src/utils/geoloc'
import { isPresent } from '/src/utils/refinements'

export default function InputGPS({ inputProps }) {
  const { value } = inputProps

  const [data, setData] = useState('')

  useEffect(() => {
    if (!value) {
      setData(I18n.t('form.inputs.gps.without_location'))
    } else {
      const [latitude, longitude] = isPresent(value) ? JSON.parse(value) : [0, 0]
      fetchAddress(latitude, longitude).then((r) => setData(r.data.results[0].formatted_address))
    }
  }, [])

  return (
    <div className="gps-form-text" title={data}>
      <span className="icon">
        <MdLocationOn />
      </span>
      <span className="gps-button-text">
        {data}
      </span>
    </div>
  )
}

InputGPS.propTypes = {
  inputProps: PropTypes.shape({
    value: PropTypes.string
  }).isRequired
}
