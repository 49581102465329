import React from 'react'
import { useStore } from 'react-context-hook'
import { useParams } from 'react-router-dom'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { titleize } from 'inflected'
import { isPresent } from '/src/utils/refinements'
import I18n from '/src/utils/translations'
import '/src/static/css/core/menus/header.css'

const translate = (param) => {
  let title = I18n.t(`${param}.title`)
  if (title.includes('[missing')) title = I18n.t(`actions.${param}`)
  if (title.includes('[missing')) title = titleize(param)
  return title
}

const translateShortcuts = (param) => {
  const title = I18n.t(`shortcuts.${param}`)
  if (title.includes('[missing')) return titleize(param)
  return title
}

export default function Breadcrumb() {
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [page] = useQueryParam('page', StringParam)
  const [template_type] = useQueryParam('template_type', StringParam)
  const [report_name] = useQueryParam('report_name', StringParam)
  const [filter] = useQueryParam('filter', StringParam)
  const [number] = useQueryParam('number', NumberParam)
  const [templateName] = useQueryParam('eav_template_name', StringParam)
  const [column, value] = isPresent(filter) ? filter.split(':') : []
  const [path] = useQueryParam('path', StringParam)
  const url = useParams()
  
  const [categories] = useStore('datasheet_categories')

  const isDatasheetCategory = () => {
    return filter && filter.split(':')[0] === 'datasheet_category_id' && categories && categories[value]
  }

  let flow = []

  // Request > 2599
  requestId && flow.push(I18n.t('requests.title'), requestId)

  // Request > 2599 > Scopes
  if (url.resource) {
    if (!requestId || url.resource !== 'requests')
      path ? flow.push(translate(path)) : flow.push(translate(url.resource)) 
  } else {
    flow.push(I18n.t('home.home_title'))
  }

  // Datasheets > Alfa
  isDatasheetCategory() && flow.push(categories[value].description)

  // Request > 2599 > Scopes > 12975
  url.resource!=='requests' && url.id && flow.push(`${url.id}`)

  url.collection==='sub_requests' && url.id && flow.push(`${url.id}`)

  // Request > 2599 > Scopes > 12975 > Edit
  url.collection && flow.push(translate(url.collection))

  // Templates > Scoping
  template_type && flow.push(I18n.t(`templates.${template_type}`))

  // Reports > My Well-Grounded Report
  report_name && flow.push(report_name)

  if (url.collection === 'history') {
    flow = []

    path ? flow.push(I18n.t('plannings.title')) : flow.push(I18n.t('performance.title'))

    templateName && flow.push(templateName)

    url.resource === 'performances' && flow.push(I18n.t('progress_histories.progress_history'))
    
    url.resource === 'progress_services' && flow.push(I18n.t('progress_histories.progress_service_history'))
    
    requestId && flow.push(`#${requestId}`)
    
    url.resource === 'performances' && number && flow.push(I18n.t('progress_histories.progress_badge', { number }))
    
    url.resource === 'progress_services' && number && flow.push(I18n.t('progress_histories.progress_service_badge', { number }))
  }

  if(page) flow = [translateShortcuts(page)]

  return (
    <div className="page-indicator">
      {flow.map((crumb) => <div key={crumb} className="breadcrumb-item">{crumb}</div>)}
    </div>
  )
}
