import React from 'react'
import { MdAttachMoney, MdFlag, MdEdit } from 'react-icons/md'
import { GiTargeted } from 'react-icons/gi'
import { IoMdSpeedometer } from 'react-icons/io'
import { FaCheckDouble } from 'react-icons/fa'
import RequestStatusLabel from '/src/ui/domain/requests/request_status_label'
import { withRouter, useHistory } from 'react-router-dom'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import QRCode from 'qrcode.react'
import Button from '/src/ui/core/buttons/button'
import PopupButton from '/src/ui/core/buttons/popup_button'
import { useQueryParam, NumberParam, BooleanParam } from 'use-query-params'
import '/src/static/css/core/blocks/request_detail_summary.css'
import '/src/static/css/core/blocks/track_block.css'

export default withRouter(function RequestDetailSummary({ dataItem }){
  const history = useHistory()
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [isFilteredByRequest, setIsFilteredByRequest] = useQueryParam('filtered_by_request',
                                                                      BooleanParam)
  const requestStatus = dataItem.request_status.i18n_id
  const nonEditableStatuses = ['canceled', 'deleting', 'done']
  const isEditable = !nonEditableStatuses.includes(requestStatus)

  const getOptionVisibility = (option) => {
    const visibilities = {
      "canceled": [],
      "deleting": [],
      "clustered": [ 'scopings' ],
      "registered": [ 'inspections' ],
      "confirmed": [ 'scopings', 'estimates', 'inspections' ],
      "in_scoping": [ 'scopings', 'estimates', 'inspections' ],
      "authorization_pending": [ 'scopings', 'estimates', 'inspections' ],
      "on_hold": [ 'scopings', 'estimates', 'inspections' ],
      "in_planning": [ 'scopings', 'estimates', 'planning', 'performance', 'inspections' ],
      "in_performance": [ 'scopings', 'estimates', 'planning', 'performance', 'inspections' ],
      "done": [ 'scopings', 'estimates', 'planning', 'performance', 'inspections' ]
    }
    const statusArray = visibilities[requestStatus]

    return statusArray && statusArray.includes(option)
  }

  const contextMenuIcons = [
    {
      text: I18n.t('requests.request_detail.scopes'),
      icon: <GiTargeted />,
      onClick: (e, data) => {
        history.push(`/scopes?request_id=${  dataItem.id}`)
      },
      visible: () => getOptionVisibility('scopings')
    },
    {
      text: I18n.t('requests.request_detail.estimates'),
      icon: <MdAttachMoney />,
      onClick: (e, data) => {
        history.push(`/requests/${dataItem.id}/estimate`)
        if (requestId !== undefined) setIsFilteredByRequest(true)
      },
      visible: () => getOptionVisibility('estimates')
    },
    {
      text: I18n.t('requests.request_detail.planning'),
      icon: <MdFlag />,
      onClick: (e, data) => {
        history.push(`/plannings?request_id=${  dataItem.id}`)
      },
      visible: () => getOptionVisibility('planning')
    },
    {
      text: I18n.t('requests.request_detail.performance'),
      icon: <IoMdSpeedometer />,
      onClick: (e, data) => {
        history.push(`/performances?request_id=${  dataItem.id}`)
      },
      visible: () => getOptionVisibility('performance')
    },
    {
      text: I18n.t('requests.request_detail.inspections'),
      icon: <FaCheckDouble />,
      onClick: (e, data) => {
        history.push(`/inspections?request_id=${  dataItem.id}`)
      },
      visible: () => getOptionVisibility('inspections')
    }
  ]

  return (
    <div className='detail-summary'>
      <div className="request-main-info">
        <div>
          <div className="title-side-panel">
            DPMSID
            <span className="summary-request-id">
              #
              {dataItem.id}
            </span>
          </div>
          <div className='detail-row'>
            <RequestStatusLabel dataItem={dataItem} />
          </div>
        </div>
        <QRCode value={dataItem.id.toString()} size={76} />
      </div>
      <div className='detail-field' data-testid='detail-reference-title'>
        <span>{I18n.t('requests.reason')}</span>
        <p>{dataItem.reason}</p>
        <span>{I18n.t('requests.comments')}</span>
        <p>{dataItem.comments}</p>
      </div>
      {history && (
        <div className="actions-row">
          {contextMenuIcons.filter((x) => x.visible()).length > 0 && (
            <div className={`track-block-buttons${!isEditable ? ' show-button-expanded' : ''}`}>
              {isEditable && (
                <Button
                  text={I18n.t('requests.request_detail.edit')}
                  icon={<MdEdit />}
                  className="edit-button-track-block"
                  onClick={() => history.push(`/requests/${dataItem.id}/edit`)}
                  role="edit-button-panel"
                />
              )}
              <PopupButton
                items={contextMenuIcons}
                text={I18n.t('requests.request_detail.show')}
                role="show-button"
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
})
