import React  from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/cards/card.css'

export default function Card({ tag, title, subtitle, details, height, className }) {
  const inputClass = () => {
    const classes = ['card-wrapper']
    if (className) classes.push(className)
    return classes.join(' ')
  }

  return (
    <div className={inputClass()} style={{ height: `${height}px` }}>
      {tag && <div className="card-tag">{tag}</div>}
      <div className="card-body">
        <div className="card-title">{title}</div>
        {subtitle && <div className="card-subtitle">{subtitle}</div>}
      </div>
      {details && <div className="card-details">{details}</div>}
    </div>
  )
}

Card.propTypes = {
  tag: PropTypes.element,
  title: PropTypes.element.isRequired,
  subtitle: PropTypes.element,
  details: PropTypes.element,
  height: PropTypes.number,
  className: PropTypes.string
}

Card.defaultProps = {
  tag: undefined,
  subtitle: undefined,
  details: undefined,
  height: 89,
  className: undefined
}
