import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import { MdSentimentSatisfied, MdSentimentNeutral, MdSentimentDissatisfied } from 'react-icons/md'
import { formatNumberWithPrecision, formattedNumberToFloat } from '/src/utils/project_formatter'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import classNames from 'classnames'

export default function ProductivityFactorColumn({ value }) {
  const [globalProject] = useStore('project')

  const formatPF = () => {
    if (isNaN(value)) return value

    return formatNumberWithPrecision(value, globalProject)
  }

  const formattedValue = formatPF()
  const floatFormattedValue = formattedNumberToFloat(formattedValue)

  const icon = () => {
    if (value === I18n.t('fields.na')) return <MdSentimentNeutral />

    if (floatFormattedValue <= 1) return <MdSentimentSatisfied />

    return <MdSentimentDissatisfied />
  }

  const classes = {
    'blank-pf': value === I18n.t('fields.na'),
    'good-pf': floatFormattedValue <= 1,
    'bad-pf': floatFormattedValue > 1,
  }

  return (
    <CustomTooltip className={classNames(classes)} maxLength={12}>
      <div className="cell-wrapper">
        <div className={classNames(classes)} title={I18n.t('grid.productivity_factor')}>
          <div className="cell-icon">{icon()}</div>
          <div className="cell-name">{formatPF()}</div>
        </div>
      </div>
    </CustomTooltip>
  )
}

ProductivityFactorColumn.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}
