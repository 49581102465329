import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Popup } from '@progress/kendo-react-popup'
import usePopupAnchoredHover from '/src/ui/core/popups/popup_anchored_hover'
import usePopupAnchoredClick from '/src/ui/core/popups/popup_anchored_click'
import '/src/static/css/popup_anchored.css'

// eslint-disable-next-line max-lines-per-function
export default function PopupAnchored({ children, body, forceOpen, popupButtonRef, animatePopup,
                                        isHoverable, bodyClass, anchorAlign, popupAlign, disabled,
                                        setForceOpen, onClick, onOpen, onClose, setActive }) {

  const [open, setOpen] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const popupRef = useRef(null)
  let closePopupTimeout

  const setOpenActive = (value) => {
    setOpen(value)
    setActive(value)
  }

  if (isHoverable) {
    usePopupAnchoredHover({ open, popupRef, popupButtonRef, setOpenActive })
  } else {
    usePopupAnchoredClick({ popupRef, popupButtonRef, setOpenActive, setForceOpen })
  }

  const onOpenButtonClick = (e) => {
    if (isHoverable || disabled) return
    onClick(e)
    setActive(!open)
    setOpen(!open)
  }

  const onMouseLeave = () => {
    closePopupTimeout = setTimeout(() => {
      setOpen(false)
    }, 1500)
  }

  const onMouseEnter = () => {
    clearTimeout(closePopupTimeout)
  }

  return (
    <div
      className="open-popup-button-wrapper"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <span
        className="open-popup"
        ref={(button) => {
          setAnchor(button)
        }}
        onClick={(e) => onOpenButtonClick(e)}
      >
        {children}
      </span>
      <Popup
        show={!disabled && (setForceOpen ? forceOpen : open)}
        onOpen={onOpen}
        onClose={onClose}
        popupClass="popup-content"
        anchor={anchor}
        anchorAlign={anchorAlign}
        popupAlign={popupAlign}
        animate={animatePopup}
      >
        <div className={`popup-body ${bodyClass}`} ref={popupRef}>
          {body}
        </div>
      </Popup>
    </div>
  )
}

PopupAnchored.propTypes = {
  body: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  forceOpen: PropTypes.bool,
  isHoverable: PropTypes.bool,
  bodyClass: PropTypes.string,
  anchorAlign: PropTypes.oneOfType([PropTypes.object]),
  popupAlign: PropTypes.oneOfType([PropTypes.object]),
  popupButtonRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  setActive: PropTypes.func,
  setForceOpen: PropTypes.func,
  animatePopup: PropTypes.bool,
  disabled: PropTypes.bool
}

PopupAnchored.defaultProps = {
  isHoverable: false,
  bodyClass: '',
  anchorAlign: { vertical: "bottom" },
  popupAlign: { vertical: "top" },
  forceOpen: false,
  onOpen: () => {},
  onClose: () => {},
  onClick: () => {},
  setActive: () => {},
  setForceOpen: undefined,
  animatePopup: true,
  disabled: false
}
