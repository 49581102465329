import React, { useState, useEffect } from 'react'
import { useStore, useSetStoreValue } from 'react-context-hook'
import PropTypes from 'prop-types'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { MdBackup } from 'react-icons/md'
import codes from 'http-status-codes'
import useSyncBatch from '/src/hooks/api/sync_batch'
import useCookie from '/src/hooks/cookie'
import I18n from '/src/utils/translations'
import Modal from '/src/ui/core/popups/modal'
import Upload from '/src/ui/core/inputs/upload'
import IconButton from '/src/ui/core/buttons/icon_button'
import KendoFileStatus from '/src/utils/kendo_file_status'
import { guid } from '@progress/kendo-react-common'
import lodash from 'lodash'
import { byString } from '/src/utils/object'
import { merge, remove } from '/src/utils/array'
import { MAX_PICTURE_SIZE, MAX_ATTACHMENT_SIZE } from '/src/models/concerns/attachments_sizes'
import DeleteAttachment from '/src/ui/core/inputs/delete_attachment'
import '/src/static/css/core/inputs/input_attachment.css'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL
const MAX_ALLOWED_NUM_UPLOADS = 5
const PICTURE_CATEGORY_ID = 2

/* eslint-disable no-plusplus */
// eslint-disable-next-line max-lines-per-function
export default function InputAttachment({ inputProps }) {
  const { id, allowedExtensions, maxFileSize,
          onChange, dataItem, columnId, isPictureInput } = inputProps
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(isPictureInput && 'Pictures')
  const [modalOpened, setModalOpened] = useState(false)
  const [batchedCategories, setBatchedCategories] = useState()
  const [globalProject] = useStore('project')
  const [globalSubproject] = useStore('subproject')
  const [getToken] = useCookie('authentication_token')
  const [innerFiles, setInnerFiles] = useState([])
  const setNotification = useSetStoreValue('notification')
  const { uniqBy } = lodash

  const defaultParams = {
    creationSaveAction: 'attachments.json',
    updateSaveAction: 'upload_attachment',
    creationDeleteAction: 'attachments/remove',
    updateDeleteAction: 'delete_attachment',
    fileColumnType: 'attachment'
  }

  const pictureParams = {
    creationSaveAction: 'pictures.json',
    updateSaveAction: 'upload_photo',
    creationDeleteAction: 'pictures/remove',
    updateDeleteAction: 'delete_photo',
    fileColumnType: 'picture'
  }

  const {
    creationSaveAction,
    updateSaveAction,
    creationDeleteAction,
    updateDeleteAction,
    fileColumnType
  } = isPictureInput ? pictureParams : defaultParams

  const pictureCategory = {
    description: 'Pictures',
    file_category_id: 2
  }

  const formTypes = {
    'create': (dataItem === null || (dataItem !== null && dataItem.id === undefined)),
    'edit': dataItem !== null
  }

  const filesUuids = (item) => {
    if (!item || !item[id]) return []
    return item[id]
  }

  const [uuids, setUuids] = useState(filesUuids(dataItem))

  const initFiles = () => {
    const columnUuids = filesUuids(dataItem)
    if (columnUuids.length === 0) return []
    const itemFiles = isPictureInput ? dataItem.pictures : dataItem.attachments
    const columnFiles = itemFiles.filter((at) => dataItem[id].includes(at.uuid))
    const kendoFiles = []

    if (columnFiles) {
      columnFiles.forEach((file) => {
        const fileName = isPictureInput ? file.photo_file_name : file.attachment_file_name
        const fileSize = isPictureInput ? file.photo_file_size : file.attachment_file_size
        kendoFiles.push({
          getRawFile: () => file,
          name: fileName,
          size: fileSize,
          file_category_id: file.file_category_id || PICTURE_CATEGORY_ID,
          uid: file.uuid,
          uuid: file.uuid,
          status: KendoFileStatus.uploaded,
          progress: 100
        })
      })
    }

    return kendoFiles
  }

  const [files, setFiles] = useState(initFiles())

  useEffect(() => {
    onChange(id, uuids)
  }, [id, uuids])

  useSyncBatch({ file_categories: { get: batchedCategories, set: setBatchedCategories } })

  let params = `?eav_column_id=${columnId}`
  params += `&project_id=${globalProject.id}&subproject_id=${globalSubproject.id}`
  params += `&remember_token=${getToken()}`

  const createUrl = (path) => {
    return `${SERVER_URL}/api/v1/${path}${params}`
  }

  const updateUrl = (action) => {
    return `${SERVER_URL}/api/v1/${dataItem.route}/${dataItem.id}/${action}.json${params}`
  }

  const updateFiles = (event) => {
    setInnerFiles(event.newState)
    if (!event.response) return

    if (event.response.status === codes.CREATED) {
      const newUuid = event.response.response.uuid
      setUuids((oldUuids) => { return merge(oldUuids, [newUuid]) })
    } else if (event.response.status === codes.NO_CONTENT) {
      const removedUuids = event.affectedFiles.map((file) => file.uuid)
      setUuids((oldUuids) => { return remove(oldUuids, removedUuids) })
    }
  }

  const message = (scenario, status, body) => ({
    title: I18n.t(`form.inputs.attachment.${scenario}_${status}`),
    body,
    status,
    closable: true,
    closeTimeout: 10
  })

  const onBeforeUpload = (event) => {
    event.additionalData.fileCategoryId = selectedCategory.id
    event.additionalData.uuid = guid()
  }

  const onStatusChange = (event) => {
    const showUploadErrorMsg = () => {
      const responseData = byString(event.response, 'response.data')
      if (responseData && responseData.error) {
        setNotification(message('upload', 'error', event.response.response.data.error))
      }
      else if (responseData && responseData.photo) {
        let msgBody = byString(event.response, 'response.data.photo')
        msgBody = msgBody ? msgBody.join('; ') : undefined
        setNotification(message('upload', 'error', msgBody))
      }
      else {
        setNotification(message('upload', 'error', undefined))
      }
    }

    updateFiles(event)
    if (!event.response) return

    if (event.response.status === codes.CREATED) {
      const affectedIndex = event.newState.findIndex((file) => {
        return file.uid === event.affectedFiles[0].uid
      })
      event.newState[affectedIndex].uuid = event.response.response.uuid
      event.newState[affectedIndex].file_category_id = event.response.response.file_category_id || PICTURE_CATEGORY_ID

      updateFiles(event)
      setNotification(message('upload', 'success'))
    } else {
      showUploadErrorMsg()
    }
  }

  const settings = {
    id: `attachment-kendo-upload-${id}`,
    selectText: I18n.t('form.inputs.attachment.select_files'),
    onFiles: () => { return innerFiles },
    multiple: false,
    saveField: formTypes.create ? fileColumnType : id,
    saveUrl: formTypes.create ? createUrl(creationSaveAction) : updateUrl(updateSaveAction),
    onAdd: updateFiles,
    onBeforeUpload,
    onProgress: updateFiles,
    onRemove: updateFiles,
    onStatusChange,
    restrictions: { allowedExtensions, maxFileSize }
  }

  const importClick = () => {
    document.getElementsByClassName('k-button k-primary k-upload-selected')[0].click()
  }

  const modalFooter = (
    <div className="import-button">
      <button type="button" disabled={innerFiles.length < 1} onClick={() => importClick()}>
        {I18n.t('actions.import_file')}
      </button>
    </div>
  )

  const extensions = () => {
    let index = -1
    const maxSize = isPictureInput ? MAX_PICTURE_SIZE : MAX_ATTACHMENT_SIZE

    return (
      <ul>
        {allowedExtensions && (
          <li key={index++}>
            <span>{I18n.t('form.inputs.attachment.allowed_extensions')}</span>
            {': '}
            <span>{allowedExtensions.join(', ')}</span>
          </li>
        )}
        <li key={index++}>{I18n.t('form.inputs.attachment.max_size', { max_size: maxSize })}</li>
        <li key={index++}>{I18n.t('form.inputs.attachment.one_file')}</li>
      </ul>
    )
  }

  const printModal = () => (
    <span className="input-attachment-modal">
      <Modal
        title={I18n.t('form.inputs.attachment.submit')}
        footer={modalFooter}
        onClose={() => setModalOpened(false)}
        closable
      >
        <div className="center">
          <div className="form-info">
            <h4>{`${I18n.t('importation_popup.requirements')}:`}</h4>
            {extensions()}
          </div>
          <Upload settings={settings} />
        </div>
      </Modal>
    </span>
  )

  useEffect(() => {
    if (!batchedCategories) return
    setCategories(Object.values(batchedCategories))
  }, [batchedCategories])

  useEffect(() => {
    if (innerFiles.length && innerFiles.every((e) => e.status === KendoFileStatus.uploaded)) {
      setModalOpened(false)
      setFiles((prevFiles) => uniqBy([...prevFiles, ...innerFiles], 'uuid'))

      setInnerFiles([])
    }
  }, [innerFiles])

  const openImportModal = () => {
    if (files.length >= MAX_ALLOWED_NUM_UPLOADS) {
      const messageBody = I18n.t('form.inputs.attachment.maximum_num_uploads_error',
        { max_num: MAX_ALLOWED_NUM_UPLOADS })
      setNotification(message('add', 'error', messageBody))
      return
    }

    setModalOpened(true)
  }

  const iconTitle = () => {
    return isPictureInput ? I18n.t('actions.upload_picture') : I18n.t('actions.upload_file')
  }

  const attachmentClass = () => {
    let baseClass = "import-dropdown"
    return isPictureInput ? baseClass + " import-picture" : baseClass
  }

  const orderByName = (categories) => {
    return categories.sort((a, b) =>
      (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0)
    )
  }

  const renderUploadComponent = () => (
    <div className="input-attachment" id={id}>
      <div className="d-flex">
        {!isPictureInput && (<div className="form-input-combobox-wrapper">
          <DropDownList
            onChange={(e) => setSelectedCategory(e.target.value)}
            data={orderByName(categories)}
            dataItemKey='id'
            textField='description'
            popupSettings={{ className: 'combobox-list' }}
          />
        </div>)}
        <IconButton
          disabled={!selectedCategory}
          onClick={openImportModal}
          icon={<MdBackup />}
          title={iconTitle()}
        />
      </div>
      {modalOpened && printModal()}
    </div>
  )

  const onDelete = (attachment) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.uuid !== attachment.uuid))
    onChange(id, null)
  }

  const getCategory = (file) => {
    if (file && file.file_category_id) {
      return batchedCategories && batchedCategories[file.file_category_id].description
    }
  }

  const anyFileUploaded = () => !!files.length

  const removeUrlParamsCreate = (file) => ({
    requestAction: 'DESTROY',
    httpAction: 'delete',
    query: { where: { eav_column_id: columnId } },
    data: { uuid: file.uuid }
  })

  const removeUrlParamsUpdate = (file) => ({
    requestAction: 'DESTROY',
    httpAction: 'delete',
    resourceId: dataItem.id,
    additionalResource: { path: updateDeleteAction },
    data: {
      eav_column_id: columnId,
      uuid: file.uuid
    }
  })

  const renderUploadedFiles = () => {
    return (
      <React.Fragment>
        {files.map((file, index) => (
          <DeleteAttachment
            file={file}
            fileCategory={getCategory(file)}
            onDelete={onDelete}
            route={formTypes.create ? creationDeleteAction : dataItem.route}
            removeUrlParams={formTypes.create ?
              removeUrlParamsCreate(file) :
              removeUrlParamsUpdate(file)}
            key={`${index}_${file.uuid}`}
          />
        ))}
      </React.Fragment>
    )
  }

  return (
    <div className={attachmentClass()}>
      {renderUploadComponent()}
      {anyFileUploaded() && renderUploadedFiles()}
    </div>
  )
}

InputAttachment.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.array,
    dataItem: PropTypes.oneOfType([PropTypes.object]),
    columnId: PropTypes.number,
    readOnly: PropTypes.bool,
    allowedExtensions: PropTypes.oneOfType([PropTypes.array]),
    maxFileSize: PropTypes.number,
    onChange: PropTypes.func,
    isPictureInput: PropTypes.bool
  }).isRequired
}
