/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { GridColumn as Column } from '@progress/kendo-react-grid'

const useSelectableRows = (responseData, field) => {
  const [checkbox, setCheckbox] = useState({ checked: false, indeterminate: false })
  const [selectableItems, setSelectableItems] = useState({})
  const [formattedData, setFormattedData] = useState() 

  useEffect(() => {
    const data = {}
    responseData.data.forEach((d) => data[d[field]] = false)
    setSelectableItems(data)
  }, [responseData])

  useEffect(() => {

    setFormattedData(
      responseData.data.map((d) => {
        const selectedValue = 
          (selectableItems[d[field]] === undefined) ? false : selectableItems[d[field]]
        return { ...d, selected: selectedValue }
      })
    )

    if (checkbox) {
      let numSelectedItems = 0
      Object.keys(selectableItems).forEach((k) => {
        if (selectableItems[k]) {
          numSelectedItems += 1
        }
      })

      if (!numSelectedItems) {
        setCheckbox({ checked: false, indeterminate: false })
      } else if (numSelectedItems === responseData.data.length) {
        setCheckbox({ checked: true, indeterminate: false })
      } else {
        setCheckbox({ checked: false, indeterminate: true })
      }
    }
  }, [selectableItems])

  return {
    onSelectionChange: (e) => {
      setSelectableItems((currSelectableItems) => {
        const data = { ...currSelectableItems }
        data[e.dataItem[field]] = !data[e.dataItem[field]]
        return data
      })
    },
    selectionColumn:
      (<Column
        key='selected'
        field='selected'
        width={80}
        headerCell={() => (
          <input
            data-testid="grid-input-check"
            type="checkbox" 
            className="k-checkbox"
            checked={checkbox.checked}
            // eslint-disable-next-line no-param-reassign
            ref={(el) => el && (el.indeterminate = checkbox.indeterminate)}
            onChange={(e) => {
              setSelectableItems((currSelectableItems) => {
                const keys = Object.keys(currSelectableItems)
                const data = {}
                const shouldUncheck = keys.findIndex((k) => currSelectableItems[k] === true)
                const isChecked = (shouldUncheck === -1)
                keys.forEach((k) => data[k] = isChecked)
                e.value = isChecked
                return data
              })
            }}
          />
        )}
      />),
    formattedData,
    SelectedRows: formattedData && formattedData.length > 0 ? 
      formattedData.filter((item) => item.selected) : { length: 0 }
  }
}

export default useSelectableRows
