import React from 'react'
import PropTypes from 'prop-types'
import { variableType } from '/src/models/concerns/variable_types'
import InputDecimal from '/src/ui/core/inputs/input_decimal'
import FieldFactory from '/src/ui/core/fields/field_factory'
import ColumnInput from '/src/ui/core/inputs/column_input'

// eslint-disable-next-line max-lines-per-function
export default function InputFormulaModal({ field, onChange })  {
  const { description, variable_type_id: variableTypeId, value } = field

  const isLookupField = description.includes('.')

  const type = variableType[variableTypeId]

  const inputProps = {
    id: description,
    testId: description,
    title: description,
    name: description,
    readOnly: isLookupField,
    value,
    onChange
  }

  const inputComponent = () => {
    if (!isLookupField) return <InputDecimal inputProps={inputProps} />

    if (field.editable) {
      const column = {
        id: description,
        description,
        title: description,
        name: description,
        default: value,
        type: type === 'datasheet_filter' ? 'string' : type
      }

      return <ColumnInput column={column} onChange={onChange} />
    }

    return (
      <div className="formula-lookup-value">
        <FieldFactory value={value} type={type} />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="formula-input">
        <div className="result-label">{description}</div>
        {inputComponent()}
      </div>
    </React.Fragment>
  )
}

InputFormulaModal.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    variable_type_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    editable: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}
