import React, { useState, useEffect, useRef } from 'react'
import { useQueryParam, NumberParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useTranslations from '/src/hooks/api/translations'
import useFirstFlexibleColumn from '/src/hooks/first_flexible_column'
import ProgressModel from '/src/models/progress'
import ProgressesSidePanel from '/src/ui/domain/progresses/progresses_side_panel'
import TemplatableGrid from '/src/ui/core/grid/templatable_grid'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import hasParent from '/src/models/concerns/parent_checker'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import useRowRenderPerformanceGrids from './row_render_performance_grids'
import {
  bulkEditingItem,
  cancelMenuItem,
  deleteMenuItem,
  duplicateMenuItem,
  editMenuItem,
  historyMenuItem,
} from '/src/ui/core/grid/context_menu_entries'
import { canCreateProgress } from '/src/utils/constants/request'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/layouts/shadowed_page.css'

// eslint-disable-next-line max-lines-per-function
export default function useProgresses({ request, handleCloseSidePanel, setShowNewButton,
                                        progressSelectedItem, serviceSelectedItem,
                                        onClick, showConfirmation, setInEditMode,
                                        onGridColumns, onGridDataSource }) {
  const [progressSectionsColumns, setProgressSectionsColumns] = useState([])
  const [panelDescription, setPanelDescription] = useState({})
  const [sidePanelFields, setSidePanelFields] = useState({})
  const [reqStatuses] = useStore('request_statuses')
  const templateId = useQueryParamWithPrevious('eav_template_id')
  const [subproject] = useStore('subproject')
  const [progressStatuses] = useStore('progress_statuses')

  const history = useHistory()
  const progressModel = new ProgressModel()
  const {
    filter,
    gridTitle,
    labels
  } = useRequestGridFilter(request && request.id, progressModel.url, null, templateId)

  const { fetchAPI, status, responseData } = useFetchAPI('eav_sections')
  const loadingTranslations = useTranslations()
  const filterFirstFlexibleColumn = useFirstFlexibleColumn()
  const onRowRender = useRowRenderPerformanceGrids('progress')

  useEffect(() => {
    if (!templateId) return

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: templateId } }
    }

    fetchAPI(params)
    handleCloseSidePanel()
  }, [templateId])

  useEffect(() => {
    if (loadingTranslations || status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(responseData.data[0])
    setProgressSectionsColumns(newSectionsColumns)

    const eavColumns = responseData.data[0].map((section) => section.eav_columns).flat()
    const descriptionColumn = filterFirstFlexibleColumn(eavColumns)

    if (!descriptionColumn) {
      setPanelDescription({})
      return
    }

    setPanelDescription(descriptionColumn.description)
  }, [status, responseData, loadingTranslations])

  useEffect(() => {
    if (!progressSelectedItem) return

    setSidePanelFields(() => ({ description: progressSelectedItem[panelDescription] }))
  }, [progressSelectedItem, panelDescription])

  useEffect(() => {
    if (!Object.keys(reqStatuses).length) return
    setShowNewButton(!request || canCreateProgress(reqStatuses[request.request_status_id].i18n_id))
  }, [request, reqStatuses])

  const progressStatusesArray = progressStatuses ? Object.values(progressStatuses) : []
  const pendingStatus = progressStatusesArray.find((e) => e.i18n_id === 'pending')
  const canceledStatus = progressStatusesArray.find((e) => e.i18n_id === 'canceled')
  const progressStatus = progressStatusesArray.find((e) => e.i18n_id === 'in_progress')

  const historyVisibility = (dataItem) => {
    if (canceledStatus && subproject) {
      return (dataItem.progress_status_id !== canceledStatus.id &&
        dataItem.quantity > 0 &&
        subproject.progress_type === "progress"
      ) 
    }
  }

  const editVisibility = (dataItem) => {
    if (pendingStatus && progressStatus)
      return [pendingStatus.id, progressStatus.id].includes(dataItem.progress_status_id)
  }

  const deleteVisibility = (dataItem) => {
    if(pendingStatus && progressStatus) {
      return dataItem.scope_id === null && [pendingStatus.id, progressStatus.id].includes(dataItem.progress_status_id)
    }
  }

  const cancelVisibility = (dataItem) => {
    if (pendingStatus && progressStatus){
      return (dataItem.scope_id !== null && [pendingStatus.id, progressStatus.id].includes(dataItem.progress_status_id)
      )
    }
  }

  const duplicateVisibility = (dataItem) => {
    if (pendingStatus && progressStatus){
      return !hasParent(dataItem) && [pendingStatus.id, progressStatus.id].includes(dataItem.progress_status_id)
    }
  }

  const historiesUrlParams = () => {
    const params = []
    params.push(`filtered_by_request=${request ? 1 : 0}`)
    return params.join('&')
  }

  const historiesDataParams = (dataItem) => {
    const params = []
    params.push(`select_item_id=${dataItem.id}`)
    return params.join('&')    
  }

  const editUrlParams = () => {
    const params = []

    if (request) params.push('filtered_by_request=1')
    if (request) params.push(`request_id=${request.id}`)

    return params.join('&')
  }

  const editDataParams = () => {
    const params = []

    if (progressSelectedItem) params.push(`select_item_id=${progressSelectedItem.id}`)
    if (serviceSelectedItem) params.push(`select_service_id=${serviceSelectedItem.id}`)

    return params.join('&')
  }

  const progressesMenuItems = {
    history: historyMenuItem( history,  progressModel.url,  (dataItem) => historyVisibility(dataItem), {
      urlParams: historiesUrlParams(),
      dataParams: historiesDataParams
    }),
    edit: editMenuItem(history, progressModel.url, (dataItem) => editVisibility(dataItem), {
      urlParams: editUrlParams(),
      dataParams: editDataParams
    }),
    duplicate: duplicateMenuItem( history,  progressModel.url, (dataItem) => duplicateVisibility(dataItem), {
      urlParams: editUrlParams(),
      dataParams: editDataParams
    }),
    cancel: cancelMenuItem(progressModel, showConfirmation, (dataItem) => cancelVisibility(dataItem)),
    remove: deleteMenuItem(
      history, 
      progressModel, 
      showConfirmation, 
      handleCloseSidePanel, 
      (dataItem) => deleteVisibility(dataItem)
    ),
  }

  const moreActionsMenuItems = [
    bulkEditingItem(() => setInEditMode('top'))
  ]

  const icons = [
    <MoreActionsIcon
      items={moreActionsMenuItems}
    />
  ]

  return [
    () => (
      <TemplatableGrid
        contextMenuItems={Object.values(progressesMenuItems)}
        model={progressModel}
        labels={labels}
        filter={filter}
        gridTitle={gridTitle}
        icons={icons}
        selectedItem={progressSelectedItem}
        onRowClick={onClick}
        onGridColumns={onGridColumns}
        onDataSource={onGridDataSource}
        onRowRender={onRowRender}
        selecting
      />
    ),
    () => (
      <ProgressesSidePanel
        dataItem={progressSelectedItem}
        sections={progressSectionsColumns}
        onClose={handleCloseSidePanel}
        contextMenuItems={progressesMenuItems}
        sidePanelFields={sidePanelFields}
      />
    )
  ]
}
