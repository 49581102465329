import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/blocks/shortcut_blocks_list.css'
import Block from '/src/ui/core/grid/side_panel/block'
import ShortcutBlock from '/src/ui/core/blocks/shortcut_block'

export default function ShortcutBlocksList({ links }) {
  const blocks = links.map((link) => {
    return {
      border: false,
      component: <ShortcutBlock items={link.shortcuts} />,
      title: link.title,
      type: 'shortcut'
    }
  })

  return (
    <React.Fragment>
      <div className="shortcut-blocks-list">
        {blocks &&
          blocks.map((block) => <Block block={block} key={block.title} />)}
      </div>
    </React.Fragment>
  )
}

ShortcutBlocksList.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired
}
