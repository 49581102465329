import { useState, useMemo  } from 'react'
import { pluralize } from 'inflected'
import useSyncBatch from '/src/hooks/api/sync_batch'
import useWatchGrandparentsBatch from '/src/hooks/watch_grandparents_batch'

/**
 * Fetches all the parent items for the desired items
 *
 * @param items - Array of items (The items used to fetch it's parents)
 * @param parentModels - Array of Strings (The parents that will be fetched)
 * @return parentItemss - Object - The parent of items with their fields
 *
 * Ex:
 * > useWatchParentsBatch([{ id: 1, scope_id: 1, ... }], ['scope', 'estimate.request'])
 * {
 *   'scope': [{ id: 1, description: 'My Scope' }],
 *   'request': [{ id: 2, reason: 'My Reason', test_integer: 666 }]
 * }
 */
export default function useWatchParentsBatch(items = [], parentModels = []) {
  const [batchedParents, setBatchedParents] = useState([])

  const parents = useMemo(() =>
    parentModels.filter((parent) => !parent.includes('.')),
    [parentModels]
  )

  const parentEntities = useMemo(() => {
    if (!parents || !parents.length) return {}

    const entitiesKeys = {}
    parents.forEach((entity) => {
      const ids = items.map((item) =>  item[`${entity}_id`]).filter(Number)
      let uniques = [...new Set(ids)]
      uniques = ids.filter((id) =>  !batchedParents[entity] || !batchedParents[entity][id])

      if (!uniques.length) return

      const query = { where: { id: uniques } }
      const get = (() => batchedParents[entity])
      const set = (newValue) => {
        setBatchedParents((oldValues) => {
          return { ...oldValues, [entity]: { ...(oldValues[entity] || {}), ...newValue } }
        })
      }

      entitiesKeys[pluralize(entity)] = { get, set, query }
    })

    return entitiesKeys
  }, [items, parents, batchedParents])

  const { loading: parentsLoading } = useSyncBatch(parentEntities, true)
  const {
    batch: batchedGrandParents, grandparentsLoading
  } = useWatchGrandparentsBatch(batchedParents, parentModels)

  const batch = useMemo(() => (
    !parentsLoading && !grandparentsLoading ?
    { ...batchedParents, ...batchedGrandParents } : undefined
  ), [batchedParents, batchedGrandParents, parentsLoading, grandparentsLoading])

  return batch
}
