import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { MdBackup } from 'react-icons/md'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import IconButton from '/src/ui/core/buttons/icon_button'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import formatBytes from '/src/utils/converter'

/* eslint-disable max-lines-per-function */
export default function ImportDropdown({ model, filter, dataItemKey, textField, onFileSelected }) {
  const [value, setValue] = useState()
  const [btnClicked, setBtnClicked] = useState(false)
  const [file, setFile] = useState()
  const { responseData, errors, fetchAPI } = useFetchAPI(model.route)

  const read = () => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: filter
    }

    fetchAPI(params)
  }

  useEffect(() => { read() }, [])

  useEffect(() => {
    if (!file || !onFileSelected) return

    onFileSelected((prevState) => ({
      ...prevState,
      [value[dataItemKey]]: { file, value }
    }))
  }, [file, onFileSelected])

  const handleClick = (e) => {
    setBtnClicked(true)
  }

  const beforeUpload = () => (
    <div className="d-flex">
      <div className="form-input-combobox-wrapper">
        <DropDownList
          data={responseData.data}
          dataItemKey={dataItemKey}
          textField={textField}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label={(value) ? model.name : I18n.t('actions.choose_entity', { entity: model.name })}
          popupSettings={{ className: 'combobox-list' }}
        />
      </div>
      <PopupImportFile 
        setFile={setFile}
        modelRoute={model.importRoute} 
        templateId={value && value[dataItemKey]}
        button={(
          <IconButton
            onClick={(e) => handleClick(e)}
            icon={<MdBackup />}
            title={I18n.t('actions.upload_file')} 
          />
        )}
      />
    </div>
  )

  const afterUpload = () => (
    <div className="d-flex">
      <div className="left">
        <p>{value[textField]}</p>
      </div>
      <div className="form-input-combobox-wrapper">
        <input
          disabled
          className="form-input-text"
          value={`${file.name} ${formatBytes(file.size)}`}
          type="text"
        />
      </div>
    </div>
  )

  let errorFlag
  if (btnClicked && !value) {
    errorFlag = <p className="error-label">{I18n.t('importation_drop.choose_error')}</p>
  }

  return (
    <div className={`import-dropdown ${(file) ? 'selected': ''}`}>
      {file ? afterUpload() : beforeUpload()}
      {errorFlag || errors}
    </div>
  )
}

ImportDropdown.propTypes = {
  onFileSelected: PropTypes.func,
  textField: PropTypes.string,
  dataItemKey: PropTypes.string,
  model: PropTypes.shape({
    name: PropTypes.string,
    route: PropTypes.string,
    importRoute: PropTypes.string
  }).isRequired,
  filter: PropTypes.oneOfType([PropTypes.object])
}

ImportDropdown.defaultProps = {
  textField: "title",
  dataItemKey: "id",
  filter: {},
  onFileSelected: undefined
}
