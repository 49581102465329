// eslint-disable-next-line import/prefer-default-export
export const templateType = {
  'request': 0,
  'inspection': 1,
  'asset': 2,
  'contract_service': 3,
  'estimate_service': 4,
  'scoping': 5,
  'progress': 6,
  'progress_service': 7,
  'datasheet': 8
}
