/*  This file contains all the valid Events that can be dispatched and
 * listen to using the Bus custom hook. Remeber to ALLWAYS check all
 * the Events described here before adding a new one, is possible
 * that the Event you need is already defined. If you do need to add
 * a new event, make sure to add a comment explaing what is the
 * purpose of this event and in what context it should be used. Let's
 * try to keep this file nice and organized.
 */

const BusEvents = {
  // Fired when the user clicks on the 'add button' inside the side bar.
  // Necessary since multiple components in diferent pages need to react
  // to this action. For example, while on the requests page, if the user clicks on
  // this button, the page should render the form to create a new request.
  SIDEBAR_ADD_BUTTON_CLICKED: 'SIDEBAR_ADD_BUTTON_CLICKED',

  // Fire to restabilish the selected item grid and sidepanel
  EXITING_EDITABLE_MODE: 'EXITING_EDITABLE_MODE',

  // Fired when user clicks on the form's 'create/update' button.
  FORM_SUBMIT: 'FORM_SUBMIT',

  // Fired when the user clicks on the form's cancel button.
  FORM_CANCEL_BUTTON_CLICKED: 'FORM_CANCEL_BUTTON_CLICKED',

  // Fired when the user clicks on the form's cancel button.
  FORM_BACK_BUTTON_CLICKED: 'FORM_BACK_BUTTON_CLICKED',

  // Fired after the a form is successfully submitted.
  FORM_SUBMITTED: 'FORM_SUBMITTED',

  // Fired after a form is submitted and no error is returned.
  FORM_SUCCESS: 'FORM_SUCCESS',

  // Fired when all steps of an form is concluded.
  FORM_FINISHED: 'FORM_FINISHED',

  // Fired when the user clicks on the next button in a Steps component.
  STEPS_NEXT_BUTTON_CLICKED: 'STEPS_NEXT_BUTTON_CLICKED',

  // Fired to open a loading dialog
  SHOW_LOADING_DIALOG: 'SHOW_LOADING_DIALOG',

  // Fired to open a success dialog
  SHOW_SUCCESS_DIALOG: 'SHOW_SUCCESS_DIALOG',

  // Fired to open a falure dialog
  SHOW_FAILURE_DIALOG: 'SHOW_FAILURE_DIALOG',

  // Fired to open a service in progress dialog
  SHOW_SERVICE_IN_PROGRESS_DIALOG: 'SHOW_SERVICE_IN_PROGRESS_DIALOG',

  // Fired to update the current selected items
  SELECTED_ROWS: 'SELECTED_ROWS',

  // Fired to close a dialog
  HIDE_DIALOG: 'HIDE_DIALOG',

  // Fired to open an notification
  NOTIFICATION: 'NOTIFICATION',

  // Fired to reload the currently active Grid.
  RELOAD_GRID: 'RELOAD_GRID',

  // Fired when the user wants to skip from form
  FORM_DISCARD_CHANGES_CONFIRM: 'FORM_DISCARD_CHANGES_CONFIRM',

  // Fired when the side panel is closed
  SIDE_PANEL_CLOSED: 'SIDE_PANEL_CLOSED',

  // Fired when the remember token is validated
  REMEMBER_TOKEN_UPDATED: 'REMEMBER_TOKEN_UPDATED',

  // Fired when the section updates its display
  FORM_SECTION_DISPLAY_UPDATE: 'FORM_SECTION_DISPLAY_UPDATE',

  // Fired when inputs need to be validated
  INPUT_VALIDATE: 'INPUT_VALIDATE',

  // Fired when the editable grid is to be saved
  SAVE_EDITABLE_GRID: 'SAVE_EDITABLE_GRID',

  // Fired when batch entities are loading
  BATCH_ENTITIES_LOADING: 'BATCH_ENTITIES_LOADING',

  // Fired when save button on the editable grid needs to be enabled
  ENABLE_SAVE_EDITABLE_GRID: 'ENABLE_SAVE_EDITABLE_GRID',

  // Fired when save button on the editable grid needs to be disabled
  DISABLE_SAVE_EDITABLE_GRID: 'DISABLE_SAVE_EDITABLE_GRID'
}

export default BusEvents
