import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdArrowDownward } from 'react-icons/md'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import Section from '/src/ui/core/layouts/section'
import I18n from '/src/utils/translations'
import FormSidePanel from '/src/ui/core/forms/form_side_panel'
import '/src/static/css/core/layouts/steps.css'

// eslint-disable-next-line max-lines-per-function
export default function Steps({ steps, hideable, modelName }) {
  const [activeStep, setActiveStep] = useState(0)

  const forward = (payload) => {
    const nextStep = activeStep + 1
    const { autoSkipCondition } = steps[nextStep]

    if (autoSkipCondition) setActiveStep(nextStep + autoSkipCondition(payload))
    else setActiveStep(nextStep)
  }

  useBus(
    BusEvents.FORM_SUBMITTED,
    ({ payload }) => {
      if (activeStep >= steps.length - 1) return
      forward(payload)
    }, [activeStep])

  const handleClick = () => {
    if (steps[activeStep].awaitFormSuccess) {
      dispatch(BusEvents.STEPS_NEXT_BUTTON_CLICKED)
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const nextStepButton = (i, isEnabled = true) => {
    let buttonName = I18n.t('actions.next')
    if (modelName === 'requests' && steps[activeStep+1]) buttonName = steps[activeStep+1].title
    
    return (
      activeStep === i && activeStep < steps.length - 1 && (
        <button
          className="next-button"
          onClick={() => handleClick()}
          type="button"
          disabled={!isEnabled}
        >
          {`${buttonName}`}
          <MdArrowDownward />
        </button>
      )
    )
  }

  return (
    <div className="steps">
      <section className="steps-body">
        <Section
          id={steps[activeStep].id}
          index={activeStep+1}
          title={steps[activeStep].title}
          body={steps[activeStep].body}
          hideable={hideable}
        />
        <div className="buttons-col">
          {nextStepButton(activeStep, steps[activeStep].isNextButtonEnabled)}
        </div>
      </section>
      <aside>
        <FormSidePanel activeStep={activeStep} steps={steps} linkableSection={false} />
      </aside>
    </div>
  )
}

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      awaitFormSuccess: PropTypes.bool,
      title: PropTypes.string,
      autoSkipCondition: PropTypes.func,
      body: PropTypes.element,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      subSections: PropTypes.arrayOf(PropTypes.object),
      isNextButtonEnabled: PropTypes.bool
    })
  ).isRequired,
  hideable: PropTypes.bool
}

Steps.defaultProps = {
  hideable: false
}
