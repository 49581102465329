import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Layout from '/src/ui/core/layouts/layout'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useModelController from '/src/hooks/model_controller'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import EstimateForm from '/src/ui/domain/estimates/estimate_form'
import EstimateSidePanel from '/src/ui/domain/estimates/estimate_side_panel'
import { useQueryParam, BooleanParam } from 'use-query-params'
import '/src/static/css/domain/estimates/estimate.css'

export default function EstimateFormPage({ match }) {
  const [estimate, setEstimate] = useState()
  const history = useHistory()
  const [isFilteredByRequest] = useQueryParam('filtered_by_request', BooleanParam)
  const { status, responseData, fetchAPI } = useFetchAPI('estimates')

  const getRequestUrlString = () => {
    return isFilteredByRequest ? `/requests?request_id=${match.params.id}` : '/requests'
  }

  useModelController({
    modelType: 'estimates',
    onBack: () => {history.push(getRequestUrlString())},
    onFinish: () => {history.push(getRequestUrlString())}
  })
  const columnStyles = [{ width: '58.3%' }, { width: '41.7%' }]

  useEffect(() => {
    const estimateQueryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { request_id: match.params.id } }
    }

    fetchAPI(estimateQueryParams)
  }, [])

  useEffect(() => {
    if (status !== 'SUCCESS') return
    setEstimate(responseData.data[0] || {})
  }, [status, responseData])

  return (
    <Layout showProjectSwitcher={false}>
      <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
        <EstimateForm requestId={match.params.id} estimate={estimate} />
        <EstimateSidePanel estimate={estimate} />
      </LayoutPanels>
    </Layout>
  )
}


EstimateFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired
}
