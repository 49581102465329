export const status = {
  1: 'registered',
  2: 'canceled',
  3: 'confirmed',
  4: 'in_scoping',
  5: 'authorization_pending',
  6: 'in_planning',
  7: 'in_performance',
  8: 'done',
  9: 'on_hold',
  10: 'clustered'
}

const requestStatusToCreateScopes = ['in_scoping', 'confirmed']
const requestStatusToEditScopes = [
  'registered', 'confirmed', 'in_scoping', 'authorization_pending'
]

const requestStatusToCreateProgresses = ['in_planning', 'in_performance']

const canCreateScope = (statusString) => requestStatusToCreateScopes.includes(statusString)
const canEditScope = (statusString) => requestStatusToEditScopes.includes(statusString)

const canCreateProgress = (statusString) => requestStatusToCreateProgresses.includes(statusString)

export { canCreateScope, canEditScope, canCreateProgress }
