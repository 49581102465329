import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { MdInsertChart } from 'react-icons/md'
import { fullTitleDisplay, titleLimiter } from '/src/utils/string'

export default function CategoryItem({ category }) {
  const { id, description } = category
  const lenghtLimit = 20

  const url = () => {
    return `/datasheets?filter=datasheet_category_id:${id}`
  }

  const renderAnchor = () => {
    return  (
      <Link to={url()} title={fullTitleDisplay(description, lenghtLimit)}>
        <MdInsertChart />
        {titleLimiter(description, lenghtLimit)}
      </Link>
    )
  }

  return (
    <li key={id}>
      {renderAnchor()}
    </li>
  )
}

CategoryItem.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string
  }).isRequired
}
