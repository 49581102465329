import React from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import I18n from '/src/utils/translations'
import ActionToolbar from '/src/ui/core/toolbar/action_toolbar'
import useGroupedFormActionButton from '/src/ui/core/forms/grouped_form_action_button_hook'

export default function GroupedFormButtons({ model, action }) {
  const handleClick = useGroupedFormActionButton(model, action)

  return(
    <ActionToolbar
      className='form-buttons'
      cancelText={I18n.t('form.cancel')}
      onCancel={() => dispatch(BusEvents.FORM_DISCARD_CHANGES_CONFIRM)}
      actionText={I18n.t('actions.save_exit')}
      onAction={() => handleClick()}
    />
  )
}

GroupedFormButtons.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  action: PropTypes.string.isRequired
}
