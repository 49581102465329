import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { MdAdd } from 'react-icons/md'
import { useStore } from 'react-context-hook'
import SpinnerIcon from '/src/ui/core/icons/spinner_icon'
import { isPresent } from '/src/utils/boolean_refinements'
import { parseDefaultValue } from '/src/models/concerns/eav_column'

export default function AddRow({
  templateId, columns, onCreateNewItem, onAfterCreateNewItem, loading
}) {
  const virtualKeyCounter = useRef(1)
  const [project] = useStore('project')
  const [globalSubproject] = useStore('subproject')

  const defaultValues = useMemo(() => {
    const eavColumnsWithDefault = columns.filter((column) => (
      column.column_type && isPresent(column.default)
    ))
    return eavColumnsWithDefault.reduce((currentValues, column) => ({
      ...currentValues, [column.description]: parseDefaultValue(column, project)
    }), {})
  }, [columns, project])

  const createNewItem = () => {
    const newDataItem = {
      ...defaultValues,
      eav_template_id: templateId,
      virtualKey: virtualKeyCounter.current,
      subproject_id: globalSubproject.id
    }
    if (onCreateNewItem) return onCreateNewItem(newDataItem)
    return newDataItem
  }

  const addNewItemToGrid = () => {
    const newDataItem = createNewItem()

    if (!newDataItem) return

    onAfterCreateNewItem(newDataItem)
    virtualKeyCounter.current += 1
  }
  return (
    loading ? <SpinnerIcon key="create-spinner" className='add-btn-spinner' /> : (
      <button type="button" key="create" className="new-grid-item-btn" onClick={addNewItemToGrid}>
        <MdAdd />
      </button>
    )
  )
}

AddRow.propTypes = {
  templateId: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  onCreateNewItem: PropTypes.func,
  onAfterCreateNewItem: PropTypes.func,
  loading: PropTypes.bool
}

AddRow.defaultProps = {
  templateId: null,
  columns: [],
  onCreateNewItem: (newItem) => newItem,
  onAfterCreateNewItem: () => {},
  loading: false
}
