import { useEffect, useState } from 'react'
import useUpdateEffect from '/src/hooks/update_effect'
import useFetchAPI from '/src/hooks/api/fetch_api'
import { useStore } from 'react-context-hook'

export default function useTranslations() {
  const [loadingTranslations, setLoadingTranslations] = useState(true)
  const { loading, errors, responseData, fetchAPI } = useFetchAPI('translations')

  useEffect(() => {
    const params = { requestAction: 'READ', httpAction: 'get' }
    fetchAPI(params)
  }, [])

  const buildLocaleKeys = (data) => {
    return data.reduce((map, obj) => {
      map[obj.key] = obj.value
      return map
    }, {})
  }

  useUpdateEffect(() => {
    if (loading || errors) return

    const result = buildLocaleKeys(responseData.data)
    localStorage.setItem('translations', JSON.stringify(result))
    setLoadingTranslations(false)
  }, [loading, errors, responseData])

  return loadingTranslations
}
