import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Button from '/src/ui/core/buttons/button'
import PopupButton from '/src/ui/core/buttons/popup_button'

export default function FlexibleActionButtons({ dataItem, mainItem, items }) {
  const itemsVisible = items.filter((item) => {
    if (item.visible) return item.visible(item.dataItem)
    return true
  })
  const hasVisibleActions = itemsVisible && itemsVisible.length > 0

  const mainButtonClasses = () => {
    const classes = ['main-button-track-block']
    if (!hasVisibleActions) classes.push('expand-button')
    return classes.join(' ')
  }

  return (
    <div className="track-block-buttons">
      {mainItem && (
        <Button
          text={mainItem.text}
          icon={mainItem.icon}
          className={mainButtonClasses()}
          onClick={(e) => mainItem.onClick(e, dataItem)}
        />
      )}
      {hasVisibleActions && <PopupButton items={itemsVisible} text={I18n.t('actions.more')} />}
    </div>
  )
}

FlexibleActionButtons.propTypes = {
  mainItem: PropTypes.oneOfType([
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.element,
      onClick: PropTypes.func,
    }),
    PropTypes.bool
  ]),
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  items: PropTypes.oneOfType([PropTypes.array]).isRequired
}

FlexibleActionButtons.defaultProps = {
  mainItem: undefined,
  dataItem: undefined
}
