import React, { useState, useEffect } from 'react'
import { useQueryParam, NumberParam } from 'use-query-params'
import useBus from '/src/hooks/bus/bus'
import { useStore } from 'react-context-hook'
import BusEvents from '/src/hooks/bus/bus_events'
import PlanningModel from '/src/models/planning'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import useProgressServices from '/src/ui/domain/progress_services/progress_services'
import useFetchById from '/src/hooks/api/fetch_by_id'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import Label from '/src/ui/core/grid/label'
import I18n from '/src/utils/translations'
import '/src/static/css/core/grid/grid.css'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import '/src/static/css/core/layouts/shadowed_page.css'

export default function PlanningGrid({ templates }) {
  const [requestIdPin] = useQueryParam('request_id', NumberParam)

  const [templateTitle, setTemplateTitle] = useState()
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [serviceSelectedItem, setServiceSelectedItem] = useState()
  const [progressServiceStatuses] = useStore('progress_service_statuses')

  const [showConfirmation, renderConfirmation] = useConfirmationModal()

  const [selectServiceId] = useQueryParam('select_service_id', NumberParam)
  const selectedService = useFetchById('progress_services', selectServiceId)
  const [planningModel, setPlanningModel] = useState(new PlanningModel())

  const closeSidePanel = () => {
    setColumnStyles(SIDE_PANEL_CLOSED)
  }
  const openSidePanel = () => {
    setColumnStyles(SIDE_PANEL_OPENED)
  }
  useBus(BusEvents.SIDE_PANEL_CLOSED, () => {
    closeSidePanel()
    setServiceSelectedItem(undefined)
  }, [serviceSelectedItem])

  const selectedTemplate = templates.find((template) => template.title === templateTitle)

  const onProgressServiceRowClick = (e) => {
    if (!e) {
      setServiceSelectedItem()
      closeSidePanel()
      return
    }

    const { dataItem } = e
    let newItem
    if (!serviceSelectedItem || serviceSelectedItem.id !== dataItem.id) {
      newItem = dataItem
      openSidePanel()
    } else {
      newItem = null
      closeSidePanel()
    }

    setServiceSelectedItem(newItem)
  }

  const labels = [
    <Label key="planning-view" title={I18n.t('planning.view')} />
  ]

  const useProgressServiceProps = {
    handleCloseSidePanel: closeSidePanel,
    serviceSelectedItem,
    filtered: false,
    onClick: onProgressServiceRowClick,
    showConfirmation,
    labels,
    serviceModel: planningModel,
    requestIdPin
  }

  const [
    serviceGrid,
    serviceSidePanel,
    serviceTemplate,
    setServiceTemplate,
  ] = useProgressServices(useProgressServiceProps)

  const onTemplateChange = (title) => {
    const selectedTemplate = templates.find((template) => template.title === title)
    setTemplateTitle(title)
    setServiceTemplate(selectedTemplate)
    closeSidePanel()
    setServiceSelectedItem()
  }

  useEffect(() => {
    setPlanningModel((model) => {
      model.setStatuses(progressServiceStatuses)
      return model
    })
  }, [progressServiceStatuses])

  return (
    <React.Fragment>
      {renderConfirmation()}
      <TabsWrapper tabType="progress_service" onTemplateChange={onTemplateChange} />
      <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
        <div className="shadowed-page">
          {(templates.length > 0 && selectedTemplate) &&
            (
              <React.Fragment key={selectedTemplate.id}>
                { serviceGrid(selectedTemplate) }
              </React.Fragment>
            )}
        </div>
        {serviceSelectedItem ? serviceSidePanel() : (<div />) }
      </LayoutPanels>
    </React.Fragment>
  )
}
