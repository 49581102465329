/* eslint-disable max-lines-per-function */
import React from 'react'
import { GridColumn as Column } from '@progress/kendo-react-grid'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

const useRowsSelection = (onDataSource) => {

  const onSelectChange = (e, dataSource) => {
    const newDataSource = { ...dataSource }

    const i = newDataSource.data.findIndex((i) => i.id === e.dataItem.id)
    newDataSource.data[i].row_selected = !e.dataItem.row_selected
    onDataSource(newDataSource)

    const selectedItems = newDataSource.data.filter((item) => item.row_selected)

    dispatch({ type: BusEvents.SELECTED_ROWS, payload: selectedItems })
  }

  const onHeaderSelectionChange = (e, dataSource) => {
    const selectedCount = dataSource.data.filter((dataItem) => dataItem.row_selected).length
    const haveItemsChecked = selectedCount > 0
    const isChecked = !haveItemsChecked
    const newDataSource = { ...dataSource }
    newDataSource.data = dataSource.data.map((item) => {
        item.row_selected = isChecked
        return item
      }
    )
    onDataSource(newDataSource)
    const selectedItems = newDataSource.data.filter((item) => item.row_selected)

    dispatch({ type: BusEvents.SELECTED_ROWS, payload: selectedItems })
    e.value = isChecked
  }

  const getHeaderSelectionColumn = (dataSource) => {
    const selectedCount = dataSource.data.filter((dataItem) => dataItem.row_selected).length

    const dataSize = dataSource.data.length
    const isChecked = (dataSize === 0) ? (false) : (selectedCount === dataSize)
    return (
      <Column
        key="row_selected"
        field="row_selected"
        width="80px"
        sortable={false}
        filterable={false}
        resizable={false}
        headerCell={() => (
          <input
            data-testid="grid-input-check"
            type="checkbox" 
            className="k-checkbox"
            checked={isChecked}
            // eslint-disable-next-line no-param-reassign
            ref={(el) => el && (el.indeterminate = (selectedCount > 0 && selectedCount < dataSource.data.length))}
            onChange={(e) => onHeaderSelectionChange(e, dataSource)}
          />
        )}
      />
    )
  }
  
  return {
    onSelectChange,
    getHeaderSelectionColumn
  }
}

export default useRowsSelection
