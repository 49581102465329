import React from 'react'
import PropTypes from 'prop-types'
import { BiCommentError } from 'react-icons/bi'
import { Tooltip } from "@progress/kendo-react-tooltip"

export default function ErrorColumn({ dataItem }) {
  const { inError, to_be_deleted } = dataItem

  const iconClass = to_be_deleted ? 'delete-error-highlight' : 'mandatory-highlight'
  const icon = inError && <BiCommentError className={iconClass} />

  return (
    <td className='error-cell'>
      <Tooltip anchorElement="target" align="left" parentTitle>
        <span title={inError || undefined}>{icon}</span>
      </Tooltip>
    </td>
  )
}

ErrorColumn.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

ErrorColumn.defaultProps = {
  dataItem: {}
}
