import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function ScopesCountColumn({ dataItem, scopes }) {
  const scopesCount = (
    scopes.filter((scope) => scope.label === dataItem.label).length
    - scopes.filter((scope) => scope.label !== dataItem.label).length
    + dataItem.scopes_count
  )

  return (
    <div className="count">
      {I18n.t('scopes.count')}
      <span className="labeled">{scopesCount}</span>
    </div>
  )
}

ScopesCountColumn.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  scopes: PropTypes.oneOfType([PropTypes.array])
}

ScopesCountColumn.defaultProps = {
  scopes: []
}
