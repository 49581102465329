import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import EnvironmentSwitchItem from './environment_switch_item'
import SearchItemOnPopup from '/src/ui/core/popups/search_item_on_popup'
import I18n from '/src/utils/translations'

export default function EnvironmentSwitchList ({ items, loading, handleItemChange }) {
  const [unfilteredItems, setItems] = useState(items)
  const [filteredItems, setFilteredItems] = useState()

  useEffect(() => {
    setItems(items)
  }, [items])

  useEffect(() => {
    if (loading) {
      setFilteredItems((<span className="list-info">{I18n.t('search.loading')}</span>))
    } else if (unfilteredItems.length === 0) {
      setFilteredItems((<span className="list-info">{I18n.t('search.no_result_found')}</span>))
    } else {
      setFilteredItems(unfilteredItems.map((item) =>
        <EnvironmentSwitchItem item={item} key={item.id} handleItemChange={handleItemChange} />
      ))
    }
  }, [unfilteredItems, loading])

  return (
    <div data-testid="environment-popup-body" className="project-switch-list-container">
      <SearchItemOnPopup setItems={setItems} items={items} />
      <div className="project-list">
        {filteredItems}
      </div>
    </div>
  )
}

EnvironmentSwitchList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired
  })),
  loading: PropTypes.bool.isRequired,
  handleItemChange: PropTypes.func.isRequired
}

EnvironmentSwitchList.defaultProps = {
  items: null
}
