import React, { useState } from 'react'
import { MdInsertChart } from 'react-icons/md'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import useSyncBatch from '/src/hooks/api/sync_batch'
import SideBarCenterItem from '/src/ui/core/menus/sidebar_center_item'
import ReportItem from '/src/ui/domain/reports/report_item'

// eslint-disable-next-line max-lines-per-function
export default function SideBarReportButton() {
  const [opened, setOpened] = useState(false)
  const onClick = () => setOpened(!opened)

  const [reportTemplates, setReportTemplates] = useState()
  const [reportTemplatesRoles, setReportTemplatesRoles] = useState()
  const [userRole] = useStore('user_role')

  const batchedEntities = {
    report_templates_roles: { get: reportTemplatesRoles, set: setReportTemplatesRoles },
    report_templates: { get: reportTemplates, set: setReportTemplates },
  }

  useSyncBatch(batchedEntities)

  const getReportItemList = () => {
    let newReportTemplates = []
    if (userRole === undefined) return

    const role = Object.values(userRole)[0]

    if (role.name === 'admin') newReportTemplates = Object.values(reportTemplates)
    else if (reportTemplatesRoles) {
      Object.values(reportTemplatesRoles).forEach((rt) => {
        if (rt.role_id === role.id) {
          const report = reportTemplates[rt.report_template_id]
          if (report) newReportTemplates.push(report)
        }
      })
    }

    return newReportTemplates.map((report) => <ReportItem report={report} key={report.id} />)
  }

  return (
    <SideBarCenterItem
      icon={<MdInsertChart />}
      title={I18n.t('side_menu.report')}
      opened={opened}
      onClick={onClick}
    >
      {reportTemplates && getReportItemList()}
    </SideBarCenterItem>
  )
}
