import I18n from '/src/utils/translations'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'

// eslint-disable-next-line max-lines-per-function
export default function useRequestConfirmationPopup(showConfirmation) {
  const showActionConfirmation = (dataItem, requestParams, opts) => {
    let actionButtonText = I18n.t(`actions.${opts.actionName}`)
    let cancelButtonText = I18n.t('actions.cancel')
    if (opts.actionText) actionButtonText = opts.actionText
    if (opts.cancelText) cancelButtonText = opts.cancelText

    const actionSuccess = () => {
      const defaultSuccessMessage = I18n.t('notification.action_success', {
        action: I18n.t(`actions.past.${opts.actionName}`),
        model: I18n.t('requests.title'),
        id: dataItem.id
      })
      notifySuccess(opts.customSuccessMessage || defaultSuccessMessage)

      return dispatch(BusEvents.RELOAD_GRID)
    }

    const actionParams = {
      title: I18n.t(`requests.confirmation_modal.${opts.actionName}_title`),
      description: I18n.t(`requests.confirmation_modal.${opts.actionName}_description`),
      actionButtonText,
      cancelButtonText,
      buttonColor: opts.buttonColor ? opts.buttonColor : '#B33939',
      onSuccess: () => actionSuccess,
      requestParams,
    }

    return showConfirmation(actionParams)
  }

  return showActionConfirmation
}
