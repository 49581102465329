import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdFunctions } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { formulaCategory } from '/src/models/concerns/formula_categories'
import InputSearch from '/src/ui/core/inputs/input_search'
import FieldFactory from '/src/ui/core/fields/field_factory'
import useFormulaModal from '/src/ui/core/popups/formula_modal'
import I18n from '/src/utils/translations'
import '/src/static/css/core/inputs/input_drop_formula.css'

/**
 *  It will create a drop_formula input responsible to select
 *  a drop_formula_id and open the formula modal with that formula
 *
 * @param inputProps
 * @return {*}
 * @constructor
 */
// eslint-disable-next-line max-lines-per-function
export default function InputControlledDropFormula({ inputProps }) {
  const { id, onChange, dataItem, column, formData, modalSimpleTitle,
          closeModalButtonText, appendModalToBody, applyOverlayToModal } = inputProps

  const idKey = `${column.description}_id`
  const variablesKey = `${column.description}_variables`
  const resultKey = column.description

  const get = (object, key, defaultValue) => object && object[key] ? object[key] : defaultValue

  const [formula, setFormula] = useState(dataItem ? column.formulas[dataItem[idKey]] : {})
  const [variables, setVariables] = useState(get(dataItem, variablesKey, {}))
  const [result, setResult] = useState(get(dataItem, resultKey, ''))

  const location = useLocation()

  const useFormulaModalProps = {
    closeButtonText: closeModalButtonText || I18n.t('history_popup.back'),
    appendToBody: appendModalToBody !== false,
    applyOverlay: applyOverlayToModal || false,
    simpleTitle: modalSimpleTitle,
    columnDescription: column.description,
  }

  const [showFormulaModal, renderFormulaModal] = useFormulaModal(useFormulaModalProps)

  const searchQuery = () => {
    const query = {
      where: {
        eav_template_id: column.eav_template_id,
        category: formulaCategory.drop
      }
    }

    if (column.metadata) query.where.id = JSON.parse(column.metadata)

    return query
  }

  const updateVariables = (formulaParam) => {
    const normalFields = formulaParam.fields.map((f) => f.description).filter((d) => !d.includes('.'))

    const newVariables = Object.fromEntries(
      Object.entries(variables).filter(([key]) => normalFields.includes(key))
    )

    setVariables(newVariables)
  }

  const onFormulaChange = (_, idParam) => {
    const { formulas } = column
    updateVariables(formulas[idParam])
    setFormula(formulas[idParam])
    if (formulas[idParam].id !== dataItem[idKey]) setResult(null)
    else setResult(dataItem[resultKey])
  }

  const searchProps = {
    id,
    onChange: onFormulaChange,
    searchRoute: 'formulas',
    searchExtraQuery: searchQuery(),
    searchFields: ['description'],
    textDisplayFields: ['description'],
    placeholder: (() => { return I18n.t('form.inputs.drop_formula.formula_select') })(),
    value: get(dataItem, idKey, 0)
  }

  const onSave = ({ id: idParam, variables: variablesParam, result: resultParam }) => {
    setFormula(column.formulas[idParam])
    setVariables(variablesParam)
    setResult(resultParam)

    const historyFound = location.pathname.match(/\/history/)
    let dropFormulaVariables

    if (historyFound) {
      dropFormulaVariables = variablesParam
    } else {
      dropFormulaVariables = Object.fromEntries(
        Object.entries(variablesParam).filter(([variableName]) => !variableName.includes('.'))
      )
    }

    onChange(idKey, idParam)
    onChange(variablesKey, dropFormulaVariables)
    onChange(resultKey, resultParam)
  }

  const showFormulaOnClick = () => {
    const formulaParams = {
      item: { ...dataItem, ...formData },
      formula,
      variables,
      onSave: () => onSave
    }

    showFormulaModal(formulaParams)
  }

  return (
    <div className="drop-formula-field">
      {renderFormulaModal()}
      <InputSearch inputProps={searchProps} />
      <button
        type="button"
        className="epsilon-button"
        onClick={() => showFormulaOnClick()}
      >
        <div className="epsilon"><MdFunctions /></div>
      </button>
      <div id="drop-formula-result" className="drop-formula-result">
        <FieldFactory value={result} type="decimal" />
      </div>
    </div>
  )
}

InputControlledDropFormula.propTypes = {
  inputProps: PropTypes.oneOfType([PropTypes.object]).isRequired
}
