import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { RiErrorWarningLine } from 'react-icons/ri'
import classNames from 'classnames'
import FieldFactory from '/src/ui/core/fields/field_factory'
import ColumnInput from '/src/ui/core/inputs/column_input'
import useCellClick from '/src/ui/core/grid/cell_click'
import { byString } from '/src/utils/object'
import UnformattedCell from '/src/ui/core/grid/unformatted_cell'
import ConditionalFormatIcon from '/src/ui/core/icons/conditional_format_icon'
import { isBlankOrFalse } from '/src/utils/boolean_refinements'
import '/src/static/css/core/grid/editable_grid.css'

export default function CustomizeCell({
  cell, column, columnCellFactory, columns, onClick, inBulkEdit, cellVisibility,
  cellConditionalFormat
}) {
  const [updatedValue, setUpdatedValue] = useState()

  const onCellClick = useCellClick()
  const onCustomizeCellClick = (e) => onCellClick(e, cell.dataItem, onClick)

  const cellValue = (changeValue) => {
    let changeValueCopy = changeValue

    if (cell.dataItem.inEdit !== column.description) changeValueCopy = undefined

    const fieldToDisplay = column.dataItemFieldToDisplay || column.description
    let newValue = changeValueCopy || cell.dataItem[fieldToDisplay]
    if (newValue && typeof newValue === 'object') newValue = byString(cell.dataItem, cell.field)

    return newValue
  }

  const cellFactory = columnCellFactory || <UnformattedCell />

  const change = (id, keyValue, foreignAttributeValue) => {
    setUpdatedValue(keyValue)

    const setForeignAttributeValue = (opts) => {
      if (!column.foreignAttribute) return opts

      cell.onChange({
        dataIndex: 0,
        dataItem: cell.dataItem,
        field: column.dataItemFieldToDisplay || column.description,
        value: foreignAttributeValue
      })

      return  { ...opts, field: column.foreignAttribute }
    }

    if (cell.onChange) {
      const opts = { dataIndex: 0, dataItem: cell.dataItem, field: cell.field, value: keyValue }
      cell.onChange(setForeignAttributeValue(opts))
    }
  }

  const renderConditionalFormat = () => {
    const { type, message } = cellConditionalFormat() || {}
    return <ConditionalFormatIcon type={type} message={message} />
  }

  if (cellVisibility() === false) {
    return (
      <td>
        ------
      </td>
    )
  }

  if (cell.dataItem && cell.dataItem.inEdit && cell.dataItem.inEdit === column.description) {
    return (
      <td className="input-cell">
        <ColumnInput
          column={column}
          onChange={change}
          dataItem={cell.dataItem}
        />
      </td>
    )
  }

  if ((!inBulkEdit || column.hideOnForm || column.readOnly) && !column.eav_template_id) {
    const newElementProps = {
      field: cell.field,
      dataItem: cell.dataItem,
      value: cellValue(),
      columns
    }

    return (
      <td data-testid={cell.field} onClick={onCustomizeCellClick}>
        {React.cloneElement(cellFactory, newElementProps)}
      </td>
    )
  }

  const hasAnyError = cell.dataItem.requiredAndEmpty &&
    cell.dataItem.requiredAndEmpty.includes(column.description) &&
    isBlankOrFalse(updatedValue)

  const useStringField = column.column_type && column.column_type.useStringField
  const columnType = column.column_type ? column.column_type.description : column.type

  const notEditableClick = inBulkEdit ? null : onCustomizeCellClick

  return (
    <td
      onClick={column.editable ? onClick : notEditableClick}
      className={classNames({ 'cell-editable': column.editable })}
    >
      <FieldFactory
        value={cellValue(updatedValue)}
        type={useStringField ? 'string' : columnType}
        opts={column}
      />
      {renderConditionalFormat()}
      {hasAnyError && <RiErrorWarningLine className="mandatory-highlight" />}
    </td>
  )
}

CustomizeCell.propTypes = {
  cell: PropTypes.shape({
    field: PropTypes.string.isRequired,
    dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
    foreignAttribute: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  column: PropTypes.shape({
    description: PropTypes.string,
    foreignAttribute: PropTypes.string,
    field: PropTypes.string,
    editable: PropTypes.bool,
    column_type: PropTypes.shape({
      description: PropTypes.string
    }),
    eav_template_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  columnCellFactory: PropTypes.element,
  columns: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  inBulkEdit: PropTypes.bool,
  cellVisibility: PropTypes.func,
  cellConditionalFormat: PropTypes.func
}

CustomizeCell.defaultProps = {
  columnCellFactory: <UnformattedCell />,
  columns: [],
  onClick: () => {},
  inBulkEdit: false,
  cellVisibility: () => true,
  cellConditionalFormat: () => {}
}
