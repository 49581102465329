import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@progress/kendo-react-tooltip'
import '/src/static/css/core/tooltips/general_tooltip.css'

export default function GeneralTooltip({ children, content, anchor }) {
  return (
    <Tooltip
      anchorElement={anchor}
      className="general-tooltip"
      showCallout={false}
      content={content}
    >
      {children}
    </Tooltip>
  )
}

GeneralTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]).isRequired,
  content: PropTypes.func,
  anchor: PropTypes.string
}

GeneralTooltip.defaultProps = {
  content: (props) => props.title,
  anchor: 'target'
}

