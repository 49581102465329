import React, { useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { useQueryParams, NumberParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'
import EnvironmentSwitchList from '/src/ui/core/menus/environment_switch_list'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { dispatch } from '/src/hooks/bus/bus'
import useFetchAPI from '/src/hooks/api/fetch_api'
import BusEvents from '/src/hooks/bus/bus_events'
import useClearBatchEntities from '/src/hooks/clear_batch_entities'
import I18n from '/src/utils/translations'
import { sortArrayOfObjectsByString } from '/src/utils/object'

// eslint-disable-next-line max-lines-per-function
export default function SubprojectSwitch({ openSubproject, setOpenSubproject, animatePopup }) {
  const [forceOpen, setForceOpen] = useState(false)
  const [items, setItems] = useState([])
  const [selectedSubproject, setSelectedSubproject] = useState()
  const [user] = useStore('user')
  const [globalProject] = useStore('project')
  const [globalSubproject, setGlobalSubproject] = useStore('subproject')
  const [subproject, setSubproject] = useState(globalSubproject.description ||
                                      I18n.t('environment.subproject.select_subproject'))
  const getSubprojects = useFetchAPI('users')
  const storePreference = useFetchAPI('users')
  const popupButtonRef = useRef(null)
  const history = useHistory()

  const clearBatchEntities = useClearBatchEntities()

  const queryParams = {}
  const undefineParams = {}
  const urlParams = new URLSearchParams(history.location.search)
  for(const key of urlParams.keys()) {
    queryParams[key] = NumberParam
    undefineParams[key] = undefined
  }
  const [query, setQuery] = useQueryParams(queryParams)

  const handleSubprojectChange = (item) => {
    setSelectedSubproject(item)
    if (history.location.pathname != '/eav_templates') setQuery(undefineParams)
  }

  useEffect(() => {
    if (!globalSubproject || !globalSubproject.description) return
    setSubproject(globalSubproject.description)
  }, [globalSubproject])

  useEffect(() => {
    if (selectedSubproject) {
      const data = { user: { subproject_id: selectedSubproject.id } }
      const params = {
        requestAction: 'UPDATE',
        httpAction:'put',
        resourceId: user.id,
        additionalResource: { path: 'store_preferences' },
        data
      }
      storePreference.fetchAPI(params)
    }
  }, [selectedSubproject])

  useEffect(() => {
    const { status } = storePreference
    if (status === 'NOT_STARTED') return
    if (status === 'FETCHING') dispatch(BusEvents.SHOW_LOADING_DIALOG)
    else {
      dispatch(BusEvents.HIDE_DIALOG)
      if (status === 'SUCCESS') {
        setSubproject(selectedSubproject.description)
        setGlobalSubproject(selectedSubproject)
        clearBatchEntities()
      }
      if (status === 'ERROR') {
        setSubproject(I18n.t('environment.subproject.select_subproject'))
        setGlobalSubproject({})
      }
      setForceOpen(false)
      setOpenSubproject(false)
    }
  }, [storePreference.status])

  const onOpen = () => {
    if (globalProject.id) {
      const params = {
        requestAction: 'READ',
        httpAction:'get',
        resourceId: user.id,
        additionalResource: { path: 'subprojects' }
      }
      getSubprojects.fetchAPI(params)
    }
  }

  useEffect(() => {
    const { data } = getSubprojects.responseData
    if(!data || data.length === 0) return
    const filteredItems = data.filter((subp) => {
      return subp.project_id === globalProject.id
    })
    setItems(sortArrayOfObjectsByString(filteredItems, 'description'))
  },[getSubprojects.responseData.data])

  const forceOpenPopup = () => {
    if (openSubproject) {
      setForceOpen(false)
      setOpenSubproject(false)
    } else {
      setForceOpen(!forceOpen || openSubproject)
    }
  }

  const popupButtonClass = () => {
    let className = 'subproject-switch switch-button-text'
    if (forceOpen || openSubproject) className += ' active'
    return className
  }

  return (
    <PopupAnchored
      body={
        (
          <EnvironmentSwitchList
            items={items}
            loading={getSubprojects.loading}
            handleItemChange={handleSubprojectChange}
          />
        )
      }
      forceOpen={forceOpen || openSubproject}
      popupButtonRef={popupButtonRef}
      setForceOpen={() => {
        setForceOpen(false)
        setOpenSubproject(false)
      }}
      onClick={() => forceOpenPopup()}
      onOpen={() => onOpen()}
      anchorAlign={{ horizontal: "right", vertical: "bottom" }}
      popupAlign={{ horizontal: "right", vertical: "top" }}
      animatePopup={animatePopup}
    >
      <button
        type="button"
        className={popupButtonClass()}
        ref={popupButtonRef}
        data-testid="subproject-switch-button"
      >
        <CustomTooltip maxLength={35}>{subproject}</CustomTooltip>
        <span className="subproject-switch-collapser"><MdKeyboardArrowDown /></span>
      </button>
    </PopupAnchored>
  )
}

SubprojectSwitch.propTypes = {
  setOpenSubproject: PropTypes.func.isRequired,
  openSubproject: PropTypes.bool.isRequired,
  animatePopup: PropTypes.bool
}

SubprojectSwitch.defaultProps = {
  animatePopup: true
}
