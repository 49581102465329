// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class InspectedTemplateModel {
  name = I18n.t('inspections.inspected_detail')

  paramName = 'inspection'

  route = 'inspections'

  constructor(dataItem) {
    this.template_types = {
      'Scope': 5,
      'Progress': 6
    }
    this.columns = [
      {
        id: 1,
        description: 'inspected_template',
        title: I18n.t('inspections.template'),
        foreignKey: 'eav_templates',
        textField: 'title',
        required: true,
        query: { where: { template_type: this.template_types[dataItem.inspected_type] } },
        foreignAttribute: 'inspected_template',
        filterable: false,
        type: 'drop',
      }
    ]
  }
}
