/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Layout from '/src/ui/core/layouts/layout'
import useModelController from '/src/hooks/model_controller'
import ProgressServiceForm from '/src/ui/domain/progress_services/progress_service_form'
import { useQueryParam, NumberParam, BooleanParam, StringParam } from 'use-query-params'
import { buildBackURL } from '/src/utils/url'

export default function ProgressServiceFormPage({ match }) {
  const history = useHistory()
  const [progressServiceTemplateId] = useQueryParam('eav_template_id', NumberParam)
  const [itemTemplateId] =  useQueryParam('item_template_id', NumberParam)
  const [disciplineId] = useQueryParam('discipline_id', NumberParam)
  const [progressId] = useQueryParam('progress_id', NumberParam)
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [selectServiceId] = useQueryParam('select_service_id', NumberParam)
  const [path] = useQueryParam('path', StringParam)
  const backRoute = path || 'performances'

  const backURLConfig = () => ({
    eavTemplateId: itemTemplateId,
    selectItemId: progressId,
    selectServiceId,
    requestId
  })

  const { dataItem } = useModelController({
    modelType: 'progress_services',
    dataItemId: match.params.id,
    onBack: () => history.push(`/${backRoute}?${buildBackURL(backURLConfig())}`),
    onFinish: () => history.push(`/${backRoute}?${buildBackURL(backURLConfig())}`),
  })

  return (
    <Layout key={dataItem} showProjectSwitcher={false}>
      <ProgressServiceForm
        dataItem={dataItem}
        progressId={progressId}
        templateId={progressServiceTemplateId}
        disciplineId={disciplineId}
        type={match.params.collection}
        path={path}
      />
    </Layout>
  )
}

ProgressServiceFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
      collection: PropTypes.string
    })
  })
}

ProgressServiceFormPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined,
      collection: undefined
    })
  })
}
