import React, { useState, useEffect, useRef } from 'react'
import { useStore } from 'react-context-hook'
import { NumericTextBox } from '@progress/kendo-react-inputs'
import deNumbers from 'cldr-numbers-full/main/de/numbers.json'
import { IntlProvider, load } from '@progress/kendo-react-intl'
import PropTypes from 'prop-types'
import '/src/static/css/inputs.css'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'

load(deNumbers)

export default function InputNumeric({ inputProps, step, format }) {
  const { id, className, name, placeholder, value, min,
          title, required, onChange, onBlur, onFocus, readOnly } = inputProps

  const [wrapperClass, setWrapperClass] = useState('form-input-numeric-wrapper')
  const [project] = useStore('project')
  const inputChangeProps = { id, value: parseFloat(value), onChange }
  const [inputValue, onInputChange] = useInputChange(inputChangeProps)
  const error = useInputError({ inputValue, title, required })
  const locale = project.number_format_type === 'COMMA' ? 'de' : 'en'
  const inputRef = useRef(null)

  const onFocusInput = (e) => {
    if(onFocus) onFocus(e)
    e.target.element.current.select()
    setWrapperClass('form-input-numeric-wrapper active')
  }

  const onBlurInput = (e) => {
    if(onBlur) onBlur(e)
    setWrapperClass('form-input-numeric-wrapper')
  }

  const inputClass = () => {
    const classes = ['form-input-numeric']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  const handleWheel = (e) => e.stopPropagation()

  useEffect(() => {
    inputRef.current.element.current.addEventListener('wheel', handleWheel)
    return () => {
      inputRef.current.element.current.removeEventListener('wheel', handleWheel)
    }
  }, [])

  return (
    <div className={wrapperClass}>
      <IntlProvider locale={locale}>
        <NumericTextBox
          id={id}
          className={inputClass()}
          name={name}
          value={inputValue}
          min={min}
          placeholder={placeholder}
          step={step}
          format={format}
          onChange={onInputChange}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          disabled={readOnly}
          ref={inputRef}
        />
        <div className="error-label">{error}</div>
      </IntlProvider>
    </div>
  )
}

InputNumeric.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    numberOfDigits: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.oneOfType([
      PropTypes.bool, PropTypes.func
    ]),
    readOnly: PropTypes.bool
  }).isRequired,
  format: PropTypes.string.isRequired,
  step: PropTypes.number
}

InputNumeric.defaultProps = {
  step: 1
}
