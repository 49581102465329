import { useStore } from 'react-context-hook'

export default function useEntitiesCache(additionalEntities) {
  const [disciplines] = useStore('disciplines')
  const [units] = useStore('units')
  const [servicePackages] = useStore('service_packages')
  const [areas] = useStore('areas')
  const [subareas] = useStore('subareas')
  const [requestStatuses] = useStore('request_statuses')
  const [inspectStatuses] = useStore('inspect_statuses')
  const [formulas] = useStore('formulas')
  const [employees] = useStore('employees')

  const returnEntities = {
    disciplines,
    units,
    servicePackages,
    areas,
    subareas,
    requestStatuses,
    inspectStatuses,
    formulas,
    employees
  }

  if (additionalEntities) {
    additionalEntities.forEach((entity) => {
      const [get] = useStore(entity.foreignKey)
      returnEntities[entity.foreignKey] = get
    })
  }

  return returnEntities
}
