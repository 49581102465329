import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MdViewStream } from 'react-icons/md'
import { useQueryParams, NumberParam } from 'use-query-params'
import queryString from 'query-string'

export default function ClusteredIcon() {
  const [queryParams] = useQueryParams({ eav_template_id: NumberParam, request_id: NumberParam })
  const [visible, setVisible] = useState(false)
  const [highlight, setHighlight] = useState(false)
  const [redirect, setRedirect] = useState("")
  const [requestId, setRequestId] = useState()
  const url = useParams()

  useEffect(() => {
    if (url.resource !== 'scopes') return

    setRequestId(queryParams.request_id)
    setVisible(true)
    url.collection === 'clusters' ? setHighlight(true) : setRedirect('/clusters')
  }, [])

  return (
    visible && requestId ? (
      <Link to={`/scopes${redirect}?${queryString.stringify(queryParams)}`} className={`clustered, btn-icon ${highlight ? "highlight" : ""}`}>
        <MdViewStream />
      </Link>
    ) : null
  )
}
