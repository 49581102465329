import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function LabelColumn({ value }) {
  let className = 'label'
  let stringValue = value

  if (!value) {
    className = 'other-label'
    stringValue = I18n.t('grid.other')
  }

  return <CustomTooltip className={className} maxLength={12}>{stringValue}</CustomTooltip>
}

LabelColumn.propTypes = {
  value: PropTypes.string
}

LabelColumn.defaultProps = {
  value: null
}
