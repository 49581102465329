import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SidePanelField from '/src/ui/core/panels/side_panel_field'
import I18n from '/src/utils/translations'
import { byString } from '/src/utils/object'
import '/src/static/css/core/grid/grid.css'

export default function ContractItemBlock({ dataItem, templateType }) {
  const [fields, setFields] = useState([])

  const field = {
    type: 'string',
    sidePanelFull: true,
    labeled: true
  }

  useEffect(() => {
    if (!dataItem) return

    setFields([
      { value: byString(dataItem, 'service_package.description'), description: 'service_package' },
      { value: byString(dataItem, 'contract_service.description'), description: 'contract_service' },
      { value: byString(dataItem, 'unit.description'), description: 'unit' },
      { value: dataItem.factor, description: 'factor', type: 'decimal' },
      { value: byString(dataItem, 'discipline.description'), description: 'discipline' }
    ])
  }, [dataItem])

  return (
    <React.Fragment>
      <div className='detail-row'>
        {fields && fields.map((f) => (
          <SidePanelField
            field={{ ...field, ...f, title: I18n.t(`${templateType}.${f.description}`) }}
            key={f.description}
          />
        ))}
      </div>
    </React.Fragment>
  )
}

ContractItemBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  templateType: PropTypes.string.isRequired
}

ContractItemBlock.defaultProps = {
  dataItem: {}
}
