import React from 'react'
import PropTypes from 'prop-types'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import '/src/static/css/core/icons/spinner_icon.css'

export default function SpinnerIcon({ className }) {
  return (
    <AiOutlineLoading3Quarters
      className={`spinner-icon ${className}`}
    />
  )
}

SpinnerIcon.propTypes = {
  className: PropTypes.string
}

SpinnerIcon.defaultProps = {
  className: ''
}
