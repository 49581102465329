import React from 'react'
import PropTypes from 'prop-types'

export default function IconButton({ icon, title, onClick, disabled }) {
  return (
    <div className="btn-text-icon">
      <button onClick={onClick} type="button" disabled={disabled}>
        {icon}
        <span>{title}</span>
      </button>
    </div>
  )
}

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

IconButton.defaultProps = {
  disabled: false,
  onClick: null
}
