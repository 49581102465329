import { useState, useEffect } from 'react'
import { isPresent } from '/src/utils/boolean_refinements'

export default function useInputChange({ id, value, onChange, valueFormatter }) {
  const getValue = (val) => isPresent(val) ? val : null

  const [inputValue, setValue] = useState(getValue(value))

  useEffect(() => { setValue(getValue(value)) }, [id, value])

  const sanitizeValue = (targetValue) => {
    let newValue = targetValue
    if (valueFormatter) newValue = valueFormatter(newValue)
    if (newValue === null) return newValue
    const isStringEmpty = typeof newValue === 'string' && newValue.replace(/\s/g, '').length === 0
    if (isStringEmpty || newValue === undefined || newValue.length === 0) newValue = null
    return newValue
  }

  const onInputChange = (e) => {
    const sanitizedValue = sanitizeValue(e.target.value)
    setValue(sanitizedValue)
    if (onChange) onChange(id, sanitizedValue, e.target.all_values)
  }

  return [inputValue, onInputChange]
}
