import React from 'react'
import PropTypes from 'prop-types'
import InputNumeric from './input_numeric'

export default function InputInteger({ inputProps }) {
  return <InputNumeric format="n" inputProps={inputProps} />
}

InputInteger.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    min: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired
}
