import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import ContractItemBlock from '/src/ui/domain/blocks/contract_item_block'
import GeneralTooltip from '/src/ui/core/tooltips/general_tooltip'
import EstimateServicePricesBlock from '/src/ui/domain/estimate_services/estimate_service_prices_block'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import EstimateServicesTrackBlock from '/src/ui/domain/estimate_services/estimate_services_track_block'
import useSyncBatch from '/src/hooks/api/sync_batch'
import '/src/static/css/model_side_panel.css'

export default function ScopingServicesSidePanel({ dataItem, onClose,
                                                    sections, contextMenuItems }) {
  const sectionBlocks = useSectionBlock({ sections, dataItem })

  const [contractItemBlock, setContractItemBlock] = useState({
    component: <React.Fragment key="contract_service_block" />,
    title: I18n.t('estimate_services.contract_service'),
    type: 'general'
  })
  const [contractServices, setContractServices] = useState()
  const [reload, setReload] = useState(false)
  const [units, setUnits] = useState()
  const query = { where: { id: dataItem.contract_service_id } }

  const batchedEntities = {
    contract_services: { get: contractServices, set: setContractServices, query },
    units: { get: units, set: setUnits }
  }

  const { loading } = useSyncBatch(batchedEntities, reload)

  useEffect(() => {
    if (loading || !contractServices || !units || !dataItem) return
    const contractService = contractServices[dataItem.contract_service_id]

    if(!contractService) {
      setReload(true)
      return
    }

    setReload(false)

    const newDataItem = {
      ...dataItem,
      contract_service: contractService,
      unit: units[contractService.unit_id]
    }

    setContractItemBlock({
      component: <ContractItemBlock dataItem={newDataItem} templateType='estimate_services' />,
      title: I18n.t('estimate_services.contract_service'),
      type: 'general'
    })
  }, [loading, contractServices, units, dataItem])

  const getPricesBlock = () => {
    const title = (
      <React.Fragment>
        <span>{I18n.t('estimate_services.prices')}</span>
        <GeneralTooltip>
          <div className="info-icon" title={I18n.t('estimate_services.automatically_prices')}>i</div>
        </GeneralTooltip>
      </React.Fragment>
    )

    return {
      component: <EstimateServicePricesBlock key="prices_block" dataItem={dataItem} />,
      title,
      type: 'prices'
    }
  }

  const buildSummary = () => ({
    component: (
      <EstimateServicesTrackBlock
        dataItem={dataItem}
        contextMenuItems={contextMenuItems}
      />
    ),
    title: '',
    type: 'track',
    key: 'scope-track-block'
  })

  return (
    <div className="model-side-panel">
      <BlocksSidePanel
        title={I18n.t('estimate_services.estimate_services_detail.side_panel_title')}
        closable
        onClose={onClose}
        blocks={[buildSummary(), contractItemBlock, getPricesBlock(), ...sectionBlocks]}
      />
    </div>
  )
}

ScopingServicesSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.shape({
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    remove: PropTypes.oneOfType([PropTypes.object]).isRequired
  }).isRequired
}

ScopingServicesSidePanel.defaultProps = {
  dataItem: undefined
}
