import React, { useEffect } from 'react'
import { StringParam } from 'use-query-params'
import Layout from '/src/ui/core/layouts/layout'
import EmbedIframe from '/src/ui/core/layouts/embed_iframe'
import I18n from '/src/utils/translations'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

const allowedTemplateType = ['contract_service', 'request', 'scoping', 'estimate_service',
                             'progress', 'progress_service', 'inspection', 'asset',
                             'datasheet', 'report']

export default function EavTemplatePage() {
  const templateType = useQueryParamWithPrevious('template_type', StringParam)

  useEffect(() => {
    if(templateType && !allowedTemplateType.includes(templateType)) {
      notifyError(I18n.t('notification.page_denied'))
    }
  }, [templateType])

  const url = () => {
    if(templateType === 'report') {
      return `${SERVER_URL}/report_templates`
    }

    return `${SERVER_URL}/eav_templates?query[where][template_type]=${templateType}`
  }

  return (
    <Layout key={templateType}>
      {templateType ? (
        <EmbedIframe url={url()} allowedUrls={['eav_templates', 'report_templates']} />
      ) : <ThreeDotsLoader />}
    </Layout>
  )
}
