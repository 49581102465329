import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetchAPI from '/src/hooks/api/fetch_api'
import EstimateServiceModel from '/src/models/estimate_service'
// eslint-disable-next-line max-len
import ScopingServiceCellFactory from '/src/ui/domain/estimate_services/scoping_service_cell_factory'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import useExcelExportUrl from '/src/hooks/api/export_excel_url'
import useGridFilterSelection from '/src/hooks/grid_filter_selection'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import GridFilterIcon from '/src/ui/core/icons/grid_filter_icon'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import {
  bulkEditingItem,
  importMenuItem,
  exportMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import '/src/static/css/core/grid/grid.css'

// eslint-disable-next-line max-lines-per-function
export default function EstimateServicesGrid({ dataItem, selectedItem, onRowClick, onTemplate,
                                               bulkEditItemVisible, onBulkEditItemClick,
                                               onDataSource, onGridColumns, contextMenuItems }) {
  const [filter, setFilter] = useState()
  const [template, setTemplate] = useState()
  const [openImport, setOpenImport] = useState(false)
  const getTemplate = useFetchAPI('eav_templates')
  const estimateServiceModel = new EstimateServiceModel()
  const exportParams = { entity: 'estimate_services',
                         templateId: template ? template.id : '', isApi: true }
  const { linkExport, setExportFilters } = useExcelExportUrl(exportParams)

  const fetchTemplate = () => {
    const params = {
      requestAction: 'READ',
      httpAction:'get',
      query: {
        where: { parent_template_id: dataItem.eav_template_id, template_type: 'estimate_service' }
      }
    }

    getTemplate.fetchAPI(params)
  }

  const moreActionsMenuItems = template ? ([
    bulkEditingItem(
      () => onBulkEditItemClick('bottom'),
      () => bulkEditItemVisible('bottom')
    ),
    importMenuItem(setOpenImport),
    exportMenuItem(linkExport)
  ]) : []

  const onFilterSelection = useGridFilterSelection(setFilter)

  const icons = [
    <MoreActionsIcon items={moreActionsMenuItems} />,
    <GridFilterIcon template={template} model='EstimateService' onFilterSelection={onFilterSelection} />
  ]

  const onClosePopupImport = () => {
    setOpenImport(false)
    dispatch(BusEvents.RELOAD_GRID)
  }

  useEffect(() => {
    if (!dataItem) {
      setFilter(undefined)
      return
    }

    fetchTemplate()
  }, [dataItem])

  useEffect(() => {
    const { data } = getTemplate.responseData
    if(!data || data.length === 0 || !data[0] || !data[0].id) return
    setTemplate(data[0])
    onTemplate(data[0])
  }, [getTemplate.responseData.data])

  useEffect(() => {
    if (!dataItem) return
    setFilter([{ type: 'where', column: 'scope_id', value: dataItem.id }])
  }, [dataItem])

  return (
    filter ? (
      <div className="subgrid" data-testid="scoping-service-grid">
        { openImport && template && (
          <PopupImportFile
            modelRoute={estimateServiceModel.route}
            templateId={template.id}
            onClose={onClosePopupImport}
          />
        )}
        <SimpleGrid
          loadFlexColumns
          model={estimateServiceModel}
          sort={[{ field: 'id', dir: 'desc' }]}
          contextMenuItems={contextMenuItems}
          filter={filter}
          gridTitle={estimateServiceModel.name}
          templateId={template ? template.id : undefined}
          columnCellFactory={<ScopingServiceCellFactory />}
          selectedItem={selectedItem}
          onRowClick={onRowClick}
          onDataSource={onDataSource}
          onGridColumns={onGridColumns}
          selectFiltering={false}
          icons={icons}
          onFilterUpdate={setExportFilters}
        />
      </div>
    ) : null
  )
}

EstimateServicesGrid.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  onRowClick: PropTypes.func,
  onBulkEditItemClick: PropTypes.func.isRequired,
  onGridColumns: PropTypes.func.isRequired,
  onDataSource: PropTypes.func.isRequired,
  bulkEditItemVisible: PropTypes.func.isRequired,
  onTemplate: PropTypes.func,
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ).isRequired
}

EstimateServicesGrid.defaultProps = {
  dataItem: undefined,
  selectedItem: undefined,
  onRowClick: () => {},
  onTemplate: () => {}
}
