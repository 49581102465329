// eslint-disable-next-line
import I18n from '/src/utils/translations'
// eslint-disable-next-line
import { normalizeDateToString } from '/src/utils/project_formatter'

export default class ScopeModel {
  name = I18n.t('scopes.title')

  singularName = I18n.t('scopes.description')

  paramName = 'scope'

  templateType = 'scoping'

  route = 'scopes'

  url = 'scopes'

  flexible = true

  footer = true

  parentModels = ['request']

  columns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('scopes.id'),
      orderOnForm: 1,
      hideOnForm: true,
      hideOnGrid: true,
      editable: false,
      type: 'decimal'
    },
    {
      id: 11,
      description: 'request_id',
      title: I18n.t('requests.id'),
      orderOnForm: 2,
      hideOnForm: true,
      editable: false,
      type: 'integer'
    },
    {
      id: 2,
      description: 'request',
      title: I18n.t('requests.id'),
      orderOnForm: 3,
      foreignKey: 'requests',
      foreignAttribute: 'request_id',
      hideOnGrid: true,
      columnSize: 'large',
      required: true,
      searchFields: ['id', 'reason', 'comments'],
      searchExtraQuery: { where: {
        '[request_statuses][i18n_id]': [
          'confirmed',
          'in_scoping',
          'clustered'
        ]
      } },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'comments'],
      textField: 'reason',
      type: 'search'
    },
    {
      id: 3,
      description: 'label',
      title: I18n.t('scopes.label'),
      orderOnForm: 4,
      hideOnForm: true,
      editable: false,
      type: 'string'
    },
    {
      id: 4,
      description: 'number',
      title: '#',
      orderOnForm: 5,
      hideOnForm: true,
      editable: false,
      width: 200,
      type: 'string'
    },
    {
      id: 5,
      description: 'created_at',
      title: I18n.t('scopes.date'),
      orderOnForm: 6,
      editable: false,
      default: normalizeDateToString(new Date()),
      readOnly: true,
      hideOnGrid: true,
      hideOnForm: true,
      width: 200,
      type: 'date'
    },
    {
      id: 6,
      description: 'team_target_hours',
      title: I18n.t('scopes.hours'),
      orderOnForm: 7,
      editable: false,
      filterable: false,
      width: 200,
      hideOnForm: true,
      type: 'numeric',
      footer: true
    },
    {
      id: 7,
      description: 'quantity',
      title: I18n.t('scopes.quantity'),
      orderOnForm: 8,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 8,
      description: 'crews',
      title: I18n.t('scopes.crews'),
      orderOnForm: 9,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 9,
      description: 'crew_size',
      title: I18n.t('scopes.crew_size'),
      orderOnForm: 10,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 10,
      description: 'working_hours',
      title: I18n.t('scopes.working_hours'),
      orderOnForm: 11,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    }
  ]
}
