import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { MdEdit } from 'react-icons/md'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import FilterStepsModal from '/src/ui/core/popups/filter_steps_modal'
import RequiredMark from '/src/ui/core/alerts/required_mark'
import useInputError from '/src/ui/core/inputs/input_error'
import { isJson } from '/src/utils/string'
import '/src/static/css/core/inputs/input_filter.css'

export default function InputDatasheetFilter({ inputProps }) {
  const { template, filters, tag, onChange } = inputProps

  const isRequired = filters[0] && filters[0].required
  const visibleOnWeb = filters[0] && filters[0].visible_on_web
  const title = filters[0] && filters[0].title

  const [open, setOpen] = useState(false)
  const [stepFilters, setStepFilters] = useState(filters)
  const [currentValues, setCurrentValues] = useState({})
  const error = useInputError({
    inputValue: currentValues,
    title,
    required: isRequired,
    type: 'object'
  })
  const [showCard, setShowCard] = useState(filters[0] ? (isRequired || visibleOnWeb) : true)

  const { status, responseData, fetchAPI } = useFetchAPI('eav_columns')

  useEffect(() => {
    if (isRequired) {
      setShowCard(true)
      return
    }

    const formulaVisibilities = filters.filter((f) => f.visibility_by_formula)
    if (formulaVisibilities.length === 0) {
      setShowCard(visibleOnWeb)
      return
    }

    setShowCard(formulaVisibilities[formulaVisibilities.length-1].visible_on_web)
  }, [filters])

  useEffect(() => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: filters.map((f) => f.datasheet.datasheet_column_id) } }
    }

    fetchAPI(params)
  }, [])

  useEffect(() => {
    if (status !== 'SUCCESS') return

    const newStepFilters = filters.map((f) => {
      const column = responseData.data.find((c) => c.id === f.datasheet.datasheet_column_id)
      return {
        ...f,
        multiple: f.column_type.description === 'multiple_datasheet_filter',
        filterAttribute: column.description,
        query: { where: { eav_template_id: f.datasheet.datasheet_template_id } }
      }
    })

    updateCurrentValues(newStepFilters)
    setStepFilters(newStepFilters)
  }, [status, responseData])

  useEffect(() => {
    updateCurrentValues(stepFilters)
  }, [stepFilters])

  const updateCurrentValues = () => {
    let newCurrentValues = {}
    stepFilters.forEach((f) => {
      let val = f.value
      if (!val) {
        onChange(f.description, val)
        return
      }

      if (!f.multiple && Array.isArray(val)) val = f.value[0]
      let currentValue = val

      if (f.multiple && Array.isArray(val)) {
        val = val.map((v) => v && v.computed_text_field || v)
        currentValue = val
      } else if (f.multiple && typeof val === 'string') {
        val = JSON.parse(val)
        currentValue = val.map((v) => ({ [f.filterAttribute]: v, computed_text_field: v }))
      }

      onChange(f.description, val)
      newCurrentValues = { ...newCurrentValues, [f.filterAttribute]: currentValue }
    })

    setCurrentValues(newCurrentValues)
  }

  const onDone = (values) => {
    const newStepFilters = stepFilters.map((f) => {
      return { ...f, value: values[f.filterAttribute] }
    })

    setStepFilters(newStepFilters)
    setOpen(false)
  }

  const renderValues = (value) => {
    if (Array.isArray(value) && value[0] === null) return I18n.t('fields.na')
    if (isJson(value)) return JSON.parse(value).join(", ")

    return Array.isArray(value) ? value.map((v) => v.computed_text_field || v).join(", ") : value
  }

  const renderFilters = () => {
    return stepFilters.map((filter) => {
      const { id, title, value } = filter
      return (
        <div key={id}>
          {`${title}: ${value ? renderValues(value) : I18n.t('fields.na')}`}
        </div>
      )
    })
  }

  return (
    <React.Fragment>
      <div className={classNames({ 'filter': true, 'no-display': !showCard, 'input-error': error })}>
        <div className="filter-header">
          <div className="filter-group-title">
            <div className="filter-title">
              <RequiredMark required={isRequired} />
              {template.title}
            </div>
            <div className="filter-tag">{tag}</div>
          </div>
          <div className="filter-edit" onClick={() => { updateCurrentValues(stepFilters); setOpen(true) }}>
            <MdEdit />
          </div>
        </div>
        <div className="filter-body">
          <div className="filters">
            {renderFilters()}
          </div>
        </div>
      </div>
      <div className="error-label">{error}</div>
      {open && (
        <FilterStepsModal
          filters={stepFilters}
          route="datasheets"
          resultTitle={template.title}
          tag={tag}
          onClose={() => setOpen(false)}
          currentValues={currentValues}
          onDone={onDone}
        />
      )}
    </React.Fragment>
  )
}

InputDatasheetFilter.propTypes = {
  inputProps: PropTypes.shape({
    tag: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })),
    template: PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  }).isRequired
}
