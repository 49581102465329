import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import ScopesTrackBlock from './scopes_track_block'
import '/src/static/css/model_side_panel.css'

// eslint-disable-next-line max-lines-per-function
export default function ScopesSidePanel({ dataItem, onClose, sections, contextMenuItems }) {
  const [blocks, setBlocks] = useState()
  const sectionBlocks = useSectionBlock({ sections, dataItem })

  useEffect(() => {
    if (!dataItem) onClose()
    else if (!dataItem.request_id) setBlocks([...sectionBlocks])
    else setBlocks([buildSummary(), ...sectionBlocks])
  }, [dataItem])

  const buildSummary = () => ({
    component: <ScopesTrackBlock dataItem={dataItem} contextMenuItems={contextMenuItems} />,
    title: '',
    type: 'track',
    key: 'scope-track-block'
  })

  return (
    <div className="model-side-panel">
      <BlocksSidePanel
        title={I18n.t('scopes.scopes_detail.side_panel_title')}
        closable
        onClose={onClose}
        blocks={blocks}
      />
    </div>
  )
}

ScopesSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.shape({
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    remove: PropTypes.oneOfType([PropTypes.object]).isRequired
  }).isRequired
}

ScopesSidePanel.defaultProps = {
  dataItem: undefined
}
