import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/layout.css'

export default function Tabs({ tabsList, onTabChange, initialTabId, filterTabs }) {
  const [active, setActive] = useState(initialTabId)

  useEffect(() => {
    if (active) onTabChange(active)
  }, [active])

  useEffect(() => {
    if (initialTabId) setActive(initialTabId)
  }, [initialTabId])

  const isActive = (tab) => active === tab

  const tabContent = (tab) => (
    <div onClick={() => setActive(tab.id)} role="presentation">
      {tab.title}
    </div>
  )

  const listTabs = () => {
    let tabs = tabsList
    if (filterTabs) tabs = tabsList.filter((tab) => isActive(tab.id))

    return tabs.map((tab) => (
      <li key={tab.id} className={isActive(tab.id) ? 'active' : ''}>
        {tabContent(tab)}
      </li>
      ))
  }

  return tabsList && tabsList.length > 0 ? (
    <ul className="tabs" data-testid="list-tabs">
      {listTabs()}
    </ul>
  ) : null
}

Tabs.propTypes = {
  tabsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string
    })
  ).isRequired,
  onTabChange: PropTypes.func,
  initialTabId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filterTabs: PropTypes.bool
}

Tabs.defaultProps = {
  onTabChange: () => {},
  initialTabId: null,
  filterTabs: undefined
}
