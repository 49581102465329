/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryParams, NumberParam, BooleanParam } from 'use-query-params'
import Layout from '/src/ui/core/layouts/layout'
import EstimateServicePackeageableForm from '/src/ui/domain/estimate_services/estimate_service_packageable_form'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useModelController from '/src/hooks/model_controller'
import useGetEstimate from '/src/ui/domain/estimate_services/get_estimate_hook.jsx'

export default function EstimateServicePackageableFormPage() {
  const history = useHistory()
  const [queryParams] = useQueryParams({
    eav_template_id: NumberParam,
    request_id: NumberParam,
    scope_id: NumberParam,
    discipline_id: NumberParam,
    scope_template_id: NumberParam,
    filtered_by_request: BooleanParam
  })

  const estimate = useGetEstimate()

  const requestFilters = () => {
    if (!queryParams.filtered_by_request) return ''
      return `&request_id=${queryParams.request_id}`
  }

  const [queryString] = useState(`?eav_template_id=${queryParams.scope_template_id}${requestFilters()}`)

  const { dataItem, loading } = useModelController({
    modelType: 'estimate_services',
    onBack: () => {history.push(`/scopes${queryString}`)},
    onFinish: () => {history.push(`/scopes${queryString}`)},
  })

  useEffect(() => {
    estimate.read(queryParams.request_id)
  }, [queryParams.request_id])

  return (
    <Layout key={dataItem} showProjectSwitcher={false}>
      {loading || !estimate.id ? (
        <ThreeDotsLoader />
      ) : (
        <EstimateServicePackeageableForm
          scopeId={queryParams.scope_id}
          templateId={queryParams.eav_template_id}
          disciplineId={queryParams.discipline_id}
          estimateId={estimate.id}
        />
      )}
    </Layout>
  )
}
