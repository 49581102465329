import React, { useState } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useUpdateEffect from '/src/hooks/update_effect'
import I18n from '/src/utils/translations'
import Modal from '/src/ui/core/popups/modal'
import '/src/static/css/core/popups/confirmation_modal.css'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

export default function useConfirmationModal() {
  const colors = { gray: '#607d8b' }
  const [opened, setOpened] = useState(false)
  const [title, setTitle] = useState(I18n.t('confirmation_modal.title'))
  const [description, setDescription] = useState(I18n.t('confirmation_modal.description'))
  const [actionButtonText, setActionButtonText] = useState(I18n.t('confirmation_modal.ok'))
  const [cancelButtonText, setCancelButtonText] = useState(I18n.t('confirmation_modal.cancel'))
  const [buttonColor, setButtonColor] = useState(colors.gray)
  const [onSuccess, setOnSuccess] = useState(() => {})
  const [onCancel, setOnCancel] = useState()
  const [onFailure, setOnFailure] = useState(() => {})
  const [requestParams, setRequestParams] = useState()

  const fetchAPI = useFetchAPI()

  useUpdateEffect(() => {
    if (fetchAPI.loading && !fetchAPI.errors) return dispatch(BusEvents.SHOW_LOADING_DIALOG)
    dispatch(BusEvents.HIDE_DIALOG)
    if (fetchAPI.loading || fetchAPI.errors) return
    setOpened(false)
    onSuccess(fetchAPI.responseData)
  }, [fetchAPI.loading, fetchAPI.errors])

  const actionClick = () => {
    if (requestParams) {
      fetchAPI.fetchAPI(requestParams)
      return
    }

    setOpened(false)
    onSuccess()
  }

  const cancelClick = () => {
    if (onCancel) onCancel()
    setOpened(false)
  }

  const renderConfirmation = () => {
    if (!opened) return null

    return (
      <div className="confirmation-modal">
        <Modal height={42} width={20}>
          <React.Fragment>
            <div id="confirmation-title" className="confirmation-title">
              {title}
            </div>

            <div id="confirmation-description" className="confirmation-description">
              {description}
            </div>

            <div id="confirmation-action-button" className="confirmation-button">
              <button
                type="button"
                aria-label={`${actionButtonText} button`}
                style={{ backgroundColor: buttonColor }}
                onClick={() => actionClick()}
              >
                {actionButtonText}
              </button>
            </div>

            <div id="confirmation-cancel-button" className="confirmation-button">
              <button
                type="button"
                onClick={() => cancelClick()}
                aria-label={`${cancelButtonText} button`}
                >
                {cancelButtonText}
              </button>
            </div>
          </React.Fragment>
        </Modal>
      </div>
    )
  }

  const showConfirmation = (params) => {
    if (params.title) setTitle(params.title)
    if (params.description) setDescription(params.description)
    if (params.actionButtonText) setActionButtonText(params.actionButtonText)
    if (params.cancelButtonText) setCancelButtonText(params.cancelButtonText)
    if (params.buttonColor) setButtonColor(params.buttonColor)
    if (params.onSuccess) setOnSuccess(params.onSuccess)
    if (params.onCancel) setOnCancel(params.onCancel)
    if (params.onFailure) setOnFailure(params.onFailure)
    if (params.requestParams) setRequestParams(params.requestParams)

    setOpened(true)
  }

  return [showConfirmation, renderConfirmation]
}
