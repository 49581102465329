import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { isPresent } from '/src/utils/refinements'

export default function RequestColumn({ request, field }) {
  return (request ? request[field] : '')
}

RequestColumn.propTypes = {
  request: PropTypes.shape({
    reason: PropTypes.string,
    comments: PropTypes.string
  }),
  field: PropTypes.string.isRequired
}

RequestColumn.defaultProps = { request: undefined }
