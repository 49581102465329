import I18n from '/src/utils/translations'

const shortcuts = (id) => {
  return [
    {
      title: I18n.t('shortcuts.users'),
      shortcuts: [
        {
          text: I18n.t('shortcuts.users'),
          path: 'users'
        },
        {
          text: I18n.t('shortcuts.background_jobs'),
          path: 'background_jobs'
        },
        {
          text: I18n.t('shortcuts.routes'),
          path:`users/${id}`
        }
      ]
    },
    {
      title: I18n.t('shortcuts.preferences'),
      shortcuts: [
        {
          text: I18n.t('shortcuts.mobile_details'),
          path: 'visible_fields'
        },
        {
          text: I18n.t('shortcuts.columns_renaming'),
          path: 'translations'
        }
      ]
    },
    {
      title: I18n.t('shortcuts.clients_info'),
      shortcuts: [
        {
          text: I18n.t('shortcuts.clients'),
          path: 'customers'
        },
        {
          text: I18n.t('shortcuts.client_sites'),
          path: 'customer_sites'
        },
        {
          text: I18n.t('shortcuts.areas_subareas'),
          path: 'areas'
        },
        {
          text: I18n.t('shortcuts.assets'),
          path: 'project_assets'
        }
      ]
    },
    {
      title: I18n.t('shortcuts.projects_contracts'),
      shortcuts: [
        {
          text: I18n.t('shortcuts.contracts'),
          path: 'project_contracts'
        },
        {
          text: I18n.t('shortcuts.contract_services'),
          path: 'contract_services'
        },
        {
          text: I18n.t('shortcuts.projects_subprojects'),
          path: 'projects'
        },
        {
          text: I18n.t('shortcuts.position_names'),
          path: 'work_positions'
        },
        {
          text: I18n.t('shortcuts.staff'),
          path: 'employees'
        },
        {
          text: I18n.t('shortcuts.disciplines'),
          path: 'disciplines'
        },
        {
          text: I18n.t('shortcuts.specialities'),
          path: 'specialities'
        },
        {
          text: I18n.t('shortcuts.equipments'),
          path: 'equipaments'
        },
        {
          text: I18n.t('shortcuts.materials'),
          path: 'materials'
        }
      ]
    },
    {
      title: I18n.t('shortcuts.location'),
      shortcuts: [
        {
          text: I18n.t('shortcuts.companies'),
          path: 'companies'
        },
        {
          text: I18n.t('shortcuts.regions'),
          path: 'regions'
        },
        {
          text: I18n.t('shortcuts.countries'),
          path: 'countries'
        },
        {
          text: I18n.t('shortcuts.branches'),
          path: 'branches'
        },
        {
          text: I18n.t('shortcuts.states_provinces'),
          path: 'provinces'
        },
        {
          text: I18n.t('shortcuts.cities'),
          path: 'cities'
        }
      ]
    },
    {
      title: I18n.t('shortcuts.measures'),
      shortcuts: [
        {
          text: I18n.t('shortcuts.formulas'),
          path: 'formulas'
        },
        {
          text: I18n.t('shortcuts.matrices'),
          path: 'matrices'
        }
      ]
    },
  ]
}

export default shortcuts
