import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'
import useSyncBatch from '/src/hooks/api/sync_batch'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import EstimateModel from '/src/models/estimate'
import EstimateServicesList from '/src/ui/domain/estimates/estimate_services_list'
import Form from '/src/ui/core/forms/form'
import EstimateFormButtons from '/src/ui/domain/estimates/estimate_form_buttons'
import Information from '/src/ui/core/alerts/information'

export default function EstimateForm({ requestId, estimate }) {
  const [estimateServices, setEstimateServices] = useState()
  const [estimateStatuses, setEstimateStatuses] = useState()
  const estimateModel = new EstimateModel()
  const cannotBeAccepted = ['on_hold', 'pending_authorization', 'authorized', 'done']
  const requestIdWhere = { request_id: requestId }
  const queryService = { where: { estimates: requestIdWhere } }

  const batchedEntities = {
    estimate_services: { get: estimateServices, set: setEstimateServices, query: queryService },
    estimate_statuses: { get: estimateStatuses, set: setEstimateStatuses }
  }

  useSyncBatch(batchedEntities)

  const showButtonsOrInfo = () => {
    if (!estimate) return null

    const statusId = estimate.estimate_status_id
    const isEstimateEmpty = Object.keys(estimate).length === 0

    if (isEstimateEmpty || cannotBeAccepted.includes(estimateStatuses[statusId].i18n_id)) {
      const color = isEstimateEmpty ? 'yellow' : 'blue'
      const messageKey = isEstimateEmpty ? 'estimate.no_estimate_message': 'estimate.info_message'

      return (
        <div className="information">
          <Information color={color} message={I18n.t(messageKey)} />
        </div>
      )
    }

    return <EstimateFormButtons estimate={estimate} />
  }

  return estimate && estimateServices ? (
    <FormWrapper
      title={I18n.t('estimate.title')}
      subtitle={
        I18n.t('estimate.description', { number: Object.values(estimateServices).length })
      }
      model={estimateModel}
      classes="estimate-form"
      backText={I18n.t('estimate.back')}
      label={requestId}
    >
      { Object.keys(estimate).length > 0 && (
        <div className="form-list-wrapper">
          <Form
            model={estimateModel}
            sections={[]}
            sectionable={false}
            dataItem={estimate}
            type="edit"
          />
          <div className="estimate-list-on-page">
            <EstimateServicesList
              requestId={requestId}
              estimateServices={Object.values(estimateServices)}
            />
          </div>
        </div>
      )}
      {showButtonsOrInfo()}
    </FormWrapper>
    ): <ThreeDotsLoader />
}

EstimateForm.propTypes = {
  requestId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  estimate: PropTypes.oneOfType([PropTypes.object])
}

EstimateForm.defaultProps = {
  estimate: undefined
}
