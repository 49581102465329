import I18n from '/src/utils/translations'
import { notifyWarning, notifySuccess } from '/src/ui/core/dialogs/notifications'

export const ALLOWED_COLUMN_TYPES = [
  'boolean', 'date', 'decimal', 'integer', 'string', 'drop', 'percentage',
  'time', 'lookup', 'link', 'datasheet_filter', 'search', 'cascade_drop',
  'multiline_text', 'date_time'
]

export const onSave = (status, quantity) => {
  if (status === 'error') {
    return notifyWarning({
      title: I18n.t('notification.error'),
      body: I18n.t('grid.editable.update.error', { quantity })
    })
  }

  if (status === 'success') {
    return notifySuccess({
      title: I18n.t('notification.success'),
      body: I18n.t('grid.editable.update.success', { quantity })
    })
  }

  return null
}

export const attributesToRemove = (columns) => {
  const propertiesToRemove = [
    'dirty', 'inEdit', 'inError', 'inputProps', 'virtualKey', 'requiredAndEmpty'
  ]

  const foreignAttributeToRemove = columns
    .filter((column) => column.foreignAttribute && column.foreignAttribute !== column.description)
    .map((column) => column.description)

  return [...propertiesToRemove, ...foreignAttributeToRemove]
}

