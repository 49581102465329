import React from 'react'
import PropTypes from 'prop-types'
import RequestStatusLabel from './request_status_label'
import IdCell from '/src/ui/core/grid/id_cell'
import RequestModel from '/src/models/request'
import FieldFactory from '/src/ui/core/fields/field_factory'
import SubRequestBadge from './sub_request_badge'

export default function RequestCellFactory({ field, dataItem, value }) {
  const { columns } = new RequestModel()
  const column = columns.find((c) => c.description === field)

  switch (field) {
    case 'request_status':
      return <RequestStatusLabel dataItem={dataItem} />
    case 'id':
      return <IdCell id={dataItem[field]} />
    case 'sub':
      return <SubRequestBadge show={dataItem.parent_id != null} />
    default:
      return (
        <FieldFactory value={value || dataItem[field]} type={column ? column.type : 'text'} />
      )
  }
}


RequestCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.shape({
    parent_id: PropTypes.number
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape()
  ])
}

RequestCellFactory.defaultProps = {
  field: '',
  dataItem: {},
  value: undefined
}
