export default class Refinement {
  define (object, method, callback) {
    if (!object[method]) {
      // Object.definedProperty is used here to avoid problems when iterating with "for .. in .."
      // https://stackoverflow.com/questions/948358/adding-custom-functions-into-array-prototype
      Object.defineProperty(object, method, {
        value: callback
      })
    } else {
      // TODO: Should call Sentry Here
    }
  }

  install() {
    this.define(Object, 'byString', function (o, s) {
      s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
      s = s.replace(/^\./, '') // strip a leading dot
      const a = s.split('.')
      for (let i = 0, n = a.length; i < n; i += 1) {
        const k = a[i]
        if (k in o) {
          o = o[k]
        } else {
          return
        }
      }
      return o
    })
  }
}

export const isPresent = (value) => {
  return value !== false && value !== null && !Number.isNaN(value) &&
    value !== 0 && value !== '' && value !== undefined
}
