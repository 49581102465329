import React from 'react'
import '/src/static/css/core/grid/grid.css'
import PropTypes from 'prop-types'
import GridHeader from '/src/ui/core/grid/grid_header'

export default function GridWrapper({ children, page, take, total, onPageChange, gridTitle,
                                      optionsEnabled, icons, overlayHeaderColumns,
                                      selectedItem, labels }) {

  const wrapperClass = () => {
   return optionsEnabled ? 'entity-grid-wrapper' : 'entity-grid-wrapper-no-shadow'
  }

  return (
    <div className={wrapperClass()}>
      <GridHeader
        page={page}
        total={total}
        onPageChange={onPageChange}
        take={take}
        gridTitle={gridTitle}
        selectedItem={selectedItem}
        icons={icons}
        labels={labels}
        overlayHeaderColumns={overlayHeaderColumns}
      />
      {children}
    </div>
  )
}

GridWrapper.propTypes = {
  children: PropTypes.element,
  page: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  gridTitle: PropTypes.string.isRequired,
  optionsEnabled: PropTypes.bool,
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  icons: PropTypes.oneOfType([PropTypes.array]),
  labels: PropTypes.oneOfType([PropTypes.array]),
  overlayHeaderColumns: PropTypes.element
}

GridWrapper.defaultProps = {
  children: <React.Fragment />,
  selectedItem: {},
  icons: ['more'],
  labels: [],
  optionsEnabled: true,
  overlayHeaderColumns: undefined
}
