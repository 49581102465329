import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import PopupMenuItem from '/src/ui/core/popups/popup_menu_item'

// eslint-disable-next-line max-lines-per-function
export default function PopupMenu({ items, children, isHoverable, onOpen, onClose,
                                    handleMenuOpen, setActive, popupButtonRef, selectable,
                                    onSelection, disabled }) {

  const [selected, setSelected] = useState()
  const menu = items.map((item) => {
    if (selectable) {
      item.onClick = () => {
        item.selected && item.text ? setSelected(null) : setSelected(item.text)
      }
    }
    item.selected = selected === item.text
    const popupMenuItem = <PopupMenuItem item={item} key={item.text} />
    if (item.link) return <a href={item.link} key={item.link}>{popupMenuItem}</a>
    return popupMenuItem
  })

  useEffect(() => {
    if (selected === undefined) return

    onSelection(selected)
  }, [selected, onSelection])

  return (
    <PopupAnchored
      body={menu}
      popupButtonRef={popupButtonRef}
      handlePopupOpen={handleMenuOpen}
      setActive={setActive}
      onOpen={onOpen}
      onClose={onClose}
      isHoverable={isHoverable}
      disabled={disabled}
    >
      {children}
    </PopupAnchored>
  )
}

PopupMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.element,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    visible: PropTypes.func
  })).isRequired,
  children: PropTypes.element.isRequired,
  isHoverable: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  popupButtonRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleMenuOpen: PropTypes.func,
  setActive: PropTypes.func,
  selectable: PropTypes.bool,
  onSelection: PropTypes.func,
  disabled: PropTypes.bool
}

PopupMenu.defaultProps = {
  isHoverable: false,
  onOpen: () => {},
  onClose: () => {},
  handleMenuOpen: () => {},
  setActive: () => {},
  selectable: false,
  onSelection: () => {},
  disabled: false
}
