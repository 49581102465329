import initialValue from './initial_store.json'
import { getEnvironment, setEnvironment } from './environment'
import { getUser, setUser } from './user'
import { getLanguage, setLanguage } from './language'
import { getGridSettings, setGridSettings } from './grid_settings'
import { getTemplateFilters, setTemplateFilters } from './template_filters'
import { getBatchEntities, setBatchEntities } from './batch_entities'
import { getLastSync, setLastSync } from './last_sync'

function manipulateInitialValue() {
  let initState = { }
  initState = {
    ...initialValue,
    ...getEnvironment(),
    ...getUser(),
    ...getLanguage(),
    ...getGridSettings(),
    ...getBatchEntities(),
    ...getLastSync(),
    ...getTemplateFilters()
  }
  return initState
}

export default function useInitialState() {
  const initState = manipulateInitialValue()
  const config = {
    listener: (state) => {
      setEnvironment(state)
      setUser(state)
      setLanguage(state)
      setBatchEntities(state)
      setLastSync(state)
      setGridSettings(state)
      setTemplateFilters(state)
    }
  }

  return [ initState, config ]
}
