import React from 'react'
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa'
import { useStore } from 'react-context-hook'
import useCellClick from '/src/ui/core/grid/cell_click'

/**
 * @param templateType - string 'progress' or 'progress_service'
 * @returns {function(*, *): {children: *, style: *}} - return row render function to be passed
 * to the grid and to style or change grid row structure on grid mount
 */
export default function useRowRenderPerformanceGrids(templateType) {
  const [statuses] = useStore(`${templateType}_statuses`)
  const onCellClick = useCellClick()

  const renderCheckMark = (isDone, dataItem, onRowClick) => {
    const onTdClick = (e) => onCellClick(e, dataItem.dataItem, onRowClick)
    const classNames = ['checkmark']

    if (isDone) classNames.push('checkmark-green')

    return (
      <td
        key={`${templateType}_${dataItem.dataItem.id}`}
        className={classNames.join(' ')}
        onClick={onTdClick}
      >
        {isDone ? <FaCheckCircle /> : <FaRegCheckCircle />}
      </td>
    )
  }

  return (trElement, dataItem, onRowClick) => {
    let checkMark = renderCheckMark(false, dataItem, onRowClick)
    let style = { ...trElement.props.style }

    if (dataItem.dataItem[`${templateType}_status_id`] && statuses &&
        statuses[dataItem.dataItem[`${templateType}_status_id`]] &&
        statuses[dataItem.dataItem[`${templateType}_status_id`]].i18n_id &&
        statuses[dataItem.dataItem[`${templateType}_status_id`]].i18n_id === 'done') {
      style = { backgroundColor: '#E5FFF1' }
      checkMark = renderCheckMark(true, dataItem, onRowClick)
    }

    const { children } = trElement.props
    children.splice(1, 1, checkMark)

    return { style, children }
  }
}
