import React from 'react'
import PropTypes from 'prop-types'
import { MdAlarmOn, MdHelp, MdError, MdDirectionsRun } from 'react-icons/md'
import classNames from 'classnames'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import { formattedLocaleDate } from '/src/utils/project_formatter'
import { IntlService } from '@progress/kendo-react-intl'
import '/src/static/css/domain/progress_services/planning_timeline_block.css'

export default function PlanningTimelineBlock({ dataItem }) {
  const [project] = useStore('project')
  const {
    progress_service_summary: summary,
    start_date: startDate,
    end_date: endDate
  } = dataItem

  const defaultOpts = { project, parser: new IntlService() }
  const optsStartDate = { ...defaultOpts, value: startDate }
  const optsEndDate = { ...defaultOpts, value: endDate }
  const responsible = dataItem.responsible ? dataItem.responsible.full_name : I18n.t('fields.na')
  const startDateValue = startDate ? formattedLocaleDate(optsStartDate) : I18n.t('fields.na')
  const endDateValue = endDate ? formattedLocaleDate(optsEndDate) : I18n.t('fields.na')

  const statusIcon = (() => {
    if (summary.delta_end_date === null) return <MdHelp className="icon md-help" />
    if (summary.delta_end_date < 0) return <MdError className="icon md-error" />
    if (summary.delta_end_date > 0) return <MdDirectionsRun className="icon md-directions-run" />

    return <MdAlarmOn className="icon md-alarm-on" />
  })()

  const remainingDaysValue = (() => {
    if (summary.remaining_days === null) return '---'
    const value = summary.remaining_days
    if (value === 1) return `${value} ${I18n.t('progress_services.planning_timeline.day')}`

    return `${value} ${I18n.t('progress_services.planning_timeline.days')}`
  })()

  const forecastedDaysValue = (() => {
    if (summary.delta_end_date === null) return '---'
    if (summary.delta_end_date === 0) return I18n.t('progress_services.planning_timeline.on_time')
    const value = Math.abs(summary.delta_end_date)
    let substringI18nTitle = value === 1 ? 'day' : 'days'
    substringI18nTitle += summary.delta_end_date > 0 ? '_advance' : '_delayed'
    substringI18nTitle = I18n.t(`progress_services.planning_timeline.${substringI18nTitle}`)

    return `${value} ${substringI18nTitle}`
  })()

  const forecastedValueClass = classNames({
    'forecasted-value': true,
    'md-directions-run': summary.delta_end_date > 0,
    'md-error': summary.delta_end_date < 0,
    'md-alarm-on': summary.delta_end_date === 0,
    'md-help': summary.delta_end_date === null
  })

  const responsibleElement = (
    <div className="responsible-wrapper">
      <span className="responsible-label">
        {I18n.t('progress_services.responsible')}
      </span>
      <span className="responsible-value">{responsible}</span>
    </div>
  )

  const startDateElement = (
    <div className="start-date-wrapper">
      <span className="start-date-label">
        {I18n.t('progress_services.planning_timeline.start_date')}
      </span>
      <span className="start-date-value">{startDateValue}</span>
    </div>
  )

  const endDateElement = (
    <div className="end-date-wrapper">
      <span className="end-date-label">
        {I18n.t('progress_services.planning_timeline.end_date')}
      </span>
      <span className="end-date-value">{endDateValue}</span>
    </div>
  )

  const forecastedElement = (
    <div className="forecasted-wrapper">
      <span className="forecasted-label">
        {I18n.t('progress_services.planning_timeline.forecasted')}
      </span>
      <span className={forecastedValueClass}>{forecastedDaysValue}</span>
    </div>
  )

  const remainingDaysElement = (
    <div className="remaining-days-wrapper">
      <span className="remaining-days-label">
        {I18n.t('progress_services.planning_timeline.remaining_days')}
      </span>
      <span className="remaining-days-value">{remainingDaysValue}</span>
    </div>
  )

  return (
    <section className="planning-timeline-block-wrapper">
      <div className="first-half">
        <div className="header">
          <h1 className="title">{I18n.t('progress_services.planning_timeline.title')}</h1>
          <span className="header-icon">{statusIcon}</span>
        </div>
        {responsibleElement}
        {startDateElement}
        {endDateElement}
      </div>
      <div className="second-half">
        {forecastedElement}
        {remainingDaysElement}
      </div>
    </section>
  )
}

PlanningTimelineBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object])
}

PlanningTimelineBlock.defaultProps = {
  dataItem: {}
}
