import { useEffect, useState } from 'react'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { isBlank, isBlankOrFalse }  from '/src/utils/boolean_refinements'
import { isEmpty } from '/src/utils/object'
import I18n from '/src/utils/translations'

function moveToInput(element) {
  if (element && element.nodeName === 'INPUT') {
    element.focus()
    return true
  }

  return false
}

export function moveScreenToError() {
  let errorElement = document.querySelector('.input-error')
  if (moveToInput(errorElement)) return

  errorElement = document.querySelector('.input-error input')
  if (moveToInput(errorElement)) return

  errorElement = document.querySelector('.input-error .filter-header')
  if (errorElement) errorElement.scrollIntoView()
}

/**
 * It will keep track and let the input know that are some error on the field
 *
 * @param inputValue - the value of the input
 * @param title - the title of the field to custom error message
 * @param required - boolean if or not the field is required
 * @param type - the type of the field e.g: date, date_range, drop and so on  (optional)
 * @returns custom error message
 */
export default function useInputError({ inputValue, title, required, type }) {
  const [error, setError] = useState(null)
  const isRequiredFunction = typeof required === 'function'

  useEffect(() => {
    if (isRequiredFunction) return
    setError(null)
  }, [inputValue, required, isRequiredFunction])

  const requiredCheck = () => {
    let isRequiredAndEmpty

    const isRequired = isRequiredFunction ? required() : required

    if (inputValue && type === 'date_range')
      isRequiredAndEmpty = isRequired && (isBlank(inputValue.start) || isBlank(inputValue.end))
    else if (inputValue && type === 'object')
      isRequiredAndEmpty = isRequired && isEmpty(inputValue)
    else
      isRequiredAndEmpty = isRequired && isBlankOrFalse(inputValue)

    const requiredMsg =  I18n.t('form.inputs.errors.mandatory', { field: title })
    setError(isRequiredAndEmpty ? requiredMsg : null)
  }

  useBus(BusEvents.INPUT_VALIDATE, requiredCheck, [inputValue, required])

  return error
}
