import React from 'react'
import PropTypes from 'prop-types'
import InputText from '/src/ui/core/inputs/input_text'
import InputMultiline from '/src/ui/core/inputs/input_multiline'
import InputInteger from '/src/ui/core/inputs/input_integer'
import InputPercentage from '/src/ui/core/inputs/input_percentage'
import InputDecimal from '/src/ui/core/inputs/input_decimal'
import InputBoolean from '/src/ui/core/inputs/input_boolean'
import InputDate from '/src/ui/core/inputs/input_date'
import InputMultipleDrop from '/src/ui/core/inputs/input_multiple_drop'
import InputDrop from '/src/ui/core/inputs/input_drop'
import InputLookup from '/src/ui/core/inputs/input_lookup'
import InputGPS from '/src/ui/core/inputs/input_gps'
import InputFormula from '/src/ui/core/inputs/input_formula'
import InputControlledFormula from '/src/ui/core/inputs/input_controlled_formula'
import InputFormulaLink from '/src/ui/core/inputs/input_formula_link'
import InputCascadeDrop from '/src/ui/core/inputs/input_cascade_drop'
import InputConditionalCascadeDrop from '/src/ui/core/inputs/input_conditional_cascade_drop'
import InputModuleFilter from '/src/ui/domain/inspections/inputs/input_module_filter'
import InputSearch from '/src/ui/core/inputs/input_search'
import InputDateRange from '/src/ui/core/inputs/input_date_range'
import InputAttachment from '/src/ui/core/inputs/input_attachment'
import InputDatasheetFilter from '/src/ui/core/inputs/input_datasheet_filter'
import InputSingleDatasheetFilter from '/src/ui/core/inputs/input_single_datasheet_filter'
import InputContractService from '/src/ui/core/inputs/input_contract_service'
import InputDropFormula from '/src/ui/core/inputs/input_drop_formula'
import InputControlledDropFormula from '/src/ui/core/inputs/input_controlled_drop_formula'
import InputDateTime from '/src/ui/core/inputs/input_date_time'
import InputTime from '/src/ui/core/inputs/input_time'
import InputLink from '/src/ui/core/inputs/input_link'
import InputUpload from '/src/ui/core/inputs/input_upload'
import InputMaskedTextBox from '/src/ui/core/inputs/input_masked_text_box'
import { isPresent } from '/src/utils/boolean_refinements'
import { MAX_ATTACHMENT_SIZE_MB, MAX_PICTURE_SIZE_MB } from '/src/models/concerns/attachments_sizes'

// eslint-disable-next-line max-lines-per-function
export default function ColumnInput({ column, dataItem, onChange, control,
                                      disableColumnEdition }) {
  const {
    id,
    description,
    formatter,
    default: defaultValue,
    metadata,
    searchExtraQuery,
    allowedExtensions,
    searchFields,
    searchRoute,
    required,
    title,
    dependency,
    matchCondition,
    filterAttribute,
    column_type: columnType,
    textDisplayFields,
    readOnly,
    number_of_digits: numberOfDigits,
    textField,
    formula,
    formula_result,
    min,
    maxLength,
    multiple,
    autoUpload,
    saveUrl,
    removeUrl,
    forceRadioButton,
    calculate,
    valueFormatter,
    forceValue,
    useStringInput,
    mask,
    defaultValueFormatter,
    appendButton,
    onBlur
  } = column

  let { type } = column

  type = columnType ? columnType.description : type

  const dataItemInputProps = dataItem && dataItem.inputProps ? dataItem.inputProps : {}

  let inputProps = {
    ...dataItemInputProps,
    id: description,
    required,
    formatter,
    title,
    readOnly,
    dependency,
    allowedExtensions,
    onChange,
    control,
    textField,
    dataItem,
    formula,
    formula_result,
    searchExtraQuery,
    matchCondition,
    filterAttribute,
    searchFields,
    searchRoute,
    textDisplayFields,
    min,
    maxLength,
    multiple,
    autoUpload,
    saveUrl,
    removeUrl,
    type,
    forceRadioButton,
    calculate,
    valueFormatter,
    defaultValueFormatter,
    mask,
    appendButton,
    onBlur
  }

  if (!readOnly) inputProps.readOnly = disableColumnEdition(column)

  const isNew = () =>  (!dataItem || dataItem.id === undefined)

  if (defaultValue && typeof defaultValue === 'function') {
    inputProps.value = defaultValue(dataItem)
  } else if (forceValue && isPresent(defaultValue)) {
    inputProps.value = defaultValue
  } else if (dataItem && isPresent(dataItem[description])) {
    inputProps.value = dataItem[description]
  } else if (isNew() && isPresent(defaultValue)) {
    inputProps.value = defaultValue
  }

  if (formatter) inputProps.value = formatter(dataItem)

  const renderReadOnly = () => {
    const hasValue = dataItem && dataItem[description]
    const isString = hasValue && typeof dataItem[description] !== 'object'
    if (isString) return dataItem[description]
    return ''
  }

  switch (type) {
    // fixed column inputs
    case 'search':
      inputProps.forceValue = forceValue
      return <InputSearch inputProps={inputProps} />
    case 'cascade_drop':
      inputProps = { ...inputProps, options: JSON.parse(metadata) }
      return <InputCascadeDrop inputProps={inputProps} />
    case 'conditional_cascade_drop':
      inputProps = { ...inputProps, options: JSON.parse(metadata) }
      return <InputConditionalCascadeDrop inputProps={inputProps} />
    case 'module_filter':
      return <InputModuleFilter inputProps={inputProps} />
    case 'date_range':
      return <InputDateRange inputProps={inputProps} />
    case 'contract_service':
      return <InputContractService inputProps={inputProps} />
    case 'masked_text_box':
      return <InputMaskedTextBox inputProps={inputProps} />

    // flexible column inputs
    case 'string':
      return <InputText inputProps={inputProps} />
    case 'multiline_text':
      if (useStringInput) return <InputText inputProps={inputProps} />
      return <InputMultiline inputProps={inputProps} />
    case 'integer':
      return <InputInteger inputProps={inputProps} />
    case 'percentage':
      inputProps = { ...inputProps, numberOfDigits }
      return <InputPercentage inputProps={inputProps} />
    case 'decimal':
      return <InputDecimal inputProps={inputProps} />
    case 'date':
      return <InputDate inputProps={inputProps} />
    case 'datetime':
      return <b>{renderReadOnly()}</b>
    case 'boolean':
      return <InputBoolean inputProps={inputProps} />
    case 'gps':
      return <InputGPS inputProps={inputProps} />
    case 'drop':
      inputProps = {
        ...inputProps,
        options: JSON.parse(metadata),
        forceCombobox: inputProps.forceCombobox,
        isDynamicDrop: column.is_dynamic_drop
      }
      return <InputDrop inputProps={inputProps} />
    case 'lookup':
      inputProps = { ...inputProps, columnId: id }
      return <InputLookup inputProps={inputProps} />
    case 'multiple_drop':
      inputProps = {
        ...inputProps,
        options: JSON.parse(metadata),
        value: !inputProps.value ? inputProps.value : JSON.parse(inputProps.value),
        isDynamicDrop: column.is_dynamic_drop
      }
      return <InputMultipleDrop inputProps={inputProps} />
    case 'formula':
      inputProps = { ...inputProps, isInteger: metadata && JSON.parse(metadata).integer }
    case 'formula_service':
      return <InputFormula inputProps={inputProps} />
    case 'controlled_formula':
      return <InputControlledFormula inputProps={inputProps} />
    case 'picture':
      inputProps = { ...inputProps, dataItem, columnId: id }
      inputProps.allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg']
      inputProps.maxFileSize = MAX_PICTURE_SIZE_MB * 1000000
      inputProps.isPictureInput = true
      return <InputAttachment inputProps={inputProps} />
    case 'upload':
      inputProps = { ...inputProps, dataItem, columnId: id }
      return <InputUpload inputProps={inputProps} />
    case 'attachment':
      inputProps = { ...inputProps, dataItem, columnId: id }
      inputProps.maxFileSize = MAX_ATTACHMENT_SIZE_MB * 1000000
      if(column && column.metadata) {
        column.metadata = column.metadata.replace(/"jpeg_jpg"/, '"jpeg","jpg"')
        inputProps.allowedExtensions = JSON.parse(column.metadata)
      }
      return <InputAttachment inputProps={inputProps} />
    case 'datasheet_filter':
      return (column.column_type && column.column_type.single
        ? <InputSingleDatasheetFilter inputProps={inputProps} />
        : <InputDatasheetFilter inputProps={column} />
      )
    case 'drop_formula':
      inputProps = { ...inputProps, column, dataItem }
      return <InputDropFormula inputProps={inputProps} />
    case 'controlled_drop_formula':
      inputProps = { ...inputProps, column, dataItem }
      return <InputControlledDropFormula inputProps={inputProps} />
    case 'date_time':
      return <InputDateTime inputProps={inputProps} />
    case 'time':
      return <InputTime inputProps={inputProps} />
    case 'link':
      if (formula) return <InputFormulaLink inputProps={inputProps} />
      return <InputLink inputProps={inputProps} />
    default:
      return <span>{renderReadOnly()}</span>
  }
}

ColumnInput.propTypes = {
  column: PropTypes.shape({
    description: PropTypes.string,
    column_type: PropTypes.shape({
      description: PropTypes.string.isRequired,
      single: PropTypes.bool
    }),
    eav_lookup: PropTypes.shape({
      model_description: PropTypes.string.isRequired,
      model_field: PropTypes.string.isRequired,
    }),
    type: PropTypes.string,
    required: PropTypes.oneOfType([
      PropTypes.bool, PropTypes.func
    ]),
    default: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.func
    ]),
    formatter: PropTypes.func,
    title: PropTypes.string,
    metadata: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    number_of_digits: PropTypes.number,
    readOnly: PropTypes.bool,
    dependency: PropTypes.string,
    watch: PropTypes.func,
    calculate: PropTypes.func,
    valueFormatter: PropTypes.func,
    formula: PropTypes.oneOfType([PropTypes.object]),
    forceRadioButton: PropTypes.bool
  }).isRequired,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  control: PropTypes.oneOfType([PropTypes.object]),
  disableColumnEdition: PropTypes.func,
  onChange: PropTypes.func
}

ColumnInput.defaultProps = {
  dataItem: null,
  control: {},
  disableColumnEdition: () => {},
  onChange: () => {}
}
