import React, { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import processColumn from '/src/ui/domain/formulas_services/columns_processor'
import PropTypes from 'prop-types'
import { guid } from '@progress/kendo-react-common'
import useFetchSections from '/src/hooks/api/fetch_sections'
import useFormulasServices from '/src/hooks/formulas_services'
import includeGeneralSection from '/src/ui/domain/eav_sections/include_general_section'
import ScopeModel from '/src/models/scope'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import FormButtons from '/src/ui/core/forms/form_buttons'
import Form from '/src/ui/core/forms/form'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'

// eslint-disable-next-line max-lines-per-function
export default function ScopeForm({ dataItem, templateId, requestId, type }) {
  const [scopeModel, setScopeModel] = useState(new ScopeModel())
  const [subproject] = useStore('subproject')
  const [includeOnForm, setIncludeOnForm] = useState({ subproject_id: subproject.id, uuid: guid() })
  const sections = useFetchSections(templateId)
  const isEditMode = () => (type === 'edit')

  const formulasServices = useFormulasServices(templateId, true)

  const [formulasStore] = useStore('formulas')

  const includeTemplateAndRequestOnForm = () => {
    setIncludeOnForm({
      ...includeOnForm,
      eav_template_id: templateId,
      request_id: requestId,
    })
  }

  useEffect(() => { if (!isEditMode()) includeTemplateAndRequestOnForm() }, [templateId, requestId])

  useEffect(() => {
    if (!requestId && !isEditMode()) return
    const { columns } = scopeModel
    const request = columns.find((a) => a.description === 'request')
    request.default = requestId
    request.readOnly = true
    setScopeModel(scopeModel)
  }, [requestId])

  const duplicationCustomAction = {
    requestAction: 'CREATE',
    httpAction: 'post',
    resourceId: dataItem ? dataItem.id : {},
    additionalResource: { path: 'duplicate_hierarchy' }
  }

  const duplicationCustomError = (errors) => {
    const partialSuccess = I18n.t('api_internal_messages.only_scope_copy')
    const messages = errors.join().match(`${partialSuccess}\n(.*)`)
    const requestError = (messages ? messages[1] : null)
    if (requestError === '') {
      const causingError = errors.join().match(`(.*\n.*)\n{2}${partialSuccess}`)[1]
      const renderedError = {
        title: I18n.t(`notification.error`),
        body: causingError,
        status: 'error',
        closable: true,
        closeTimeout: 10
      }
      return renderedError
    }
  }

  if (!templateId) return (<ThreeDotsLoader />)

  return (
    <FormWrapper
      model={scopeModel}
      dataItem={dataItem}
      label={isEditMode() && dataItem && dataItem.request_id}
      type={type}
      sidePanelSections={includeGeneralSection(sections)}
    >
      <React.Fragment>
        <Form
          model={scopeModel}
          sections={sections}
          dataItem={dataItem}
          includeOnForm={includeOnForm}
          templateId={templateId}
          type={type}
          submitParams={type === 'duplicate' ? duplicationCustomAction : undefined}
          disableColumnEdition={type === 'duplicate' ? (column) => (column.title === 'DPMSID') : undefined}
          errorHandler={type === 'duplicate' ? duplicationCustomError : undefined}
          hasFormulasServices
          formulasServices={formulasServices}
          processColumn={(column) => {
            return processColumn(column, formulasServices, formulasStore, ['scope', 'scoping'])
          }}
        />
        <FormButtons model={scopeModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

ScopeForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  templateId: PropTypes.number,
  requestId: PropTypes.number,
  type: PropTypes.string.isRequired
}

ScopeForm.defaultProps = {
  dataItem: null,
  requestId: undefined,
  templateId: undefined
}
