import React, { useState, useEffect, useRef } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import Modal from '/src/ui/core/popups/modal'
import Button from '/src/ui/core/buttons/button'
import InputFormulaModal from '/src/ui/core/inputs/input_formula_modal'
import GeneralTooltip from '/src/ui/core/tooltips/general_tooltip'
import FieldFactory from '/src/ui/core/fields/field_factory'
import {
  buildEquation,
  splitFormulaVariable,
  getFormulaVariableValue,
  isSelfVariable
} from '/src/models/concerns/formula'
import { byString , filter } from '/src/utils/object'
import { MdInfo, MdKeyboardArrowLeft } from 'react-icons/md'
import { variableType } from '/src/models/concerns/variable_types'
import { isBlank }  from '/src/utils/boolean_refinements'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import '/src/static/css/core/popups/formula_modal.css'

const DEFAULT_FIELD_VALUE = 0

/**
 * Custom hook reponsible to render and show a formula modal for drop formula inputs
 * You can call this hook by: const [showFormulaModal, renderFormulaModal] = useFormulaModal()
 * Then, place the renderFormulaModal() somewhere on html and call
 * @param closeButtonText (string) - close form button it will most likely be 'cancel' or 'back'
 * @param appendToBody (bool) - true if the modal should be appended to
 * @param applyOverlay (bool) = true if it should be applied a overlay when modal is open
 * @param simpleTitle (bool) = true if it should display only the formula description as title or
 * the element with buttons and the title
 * the body and false for it to appended to its parent
 *
 * the showFormulaModal() with these parameters:
 *
 * @param item (Object) - The item object. Used to fetch the formula values.
 * @param formula (Object) - The formula object. Used to access the equation and fields.
 * @param variables (Object) - The drop formula variables => { variableX: valueX }
 * @param onSave (Function) - The callback. It will be called when save button is clicked.
 */
// eslint-disable-next-line max-lines-per-function
export default function useFormulaModal({ closeButtonText, appendToBody, applyOverlay, simpleTitle, columnDescription }) {
  const [opened, setOpened] = useState(false)
  const [formula, setFormula] = useState({})
  const [variables, setVariables] = useState({})
  const [onSave, setOnSave] = useState(() => {})
  const [result, setResult] = useState()
  const [fields, setFields] = useState({})
  const [title, setTitle] = useState()
  const formulaId = useRef(null)

  const calculateAPI = useFetchAPI('formulas/eval_equation')
  const saveAPI = useFetchAPI('formulas/eval_equation')

  const closeModal = () => setOpened(false)

  useEffect(() => {
    if (calculateAPI.status !== 'SUCCESS') return

    setResult(calculateAPI.responseData.response)
  }, [calculateAPI.status, calculateAPI.responseData])

  useEffect(() => {
    if (saveAPI.status !== 'SUCCESS') return

    const { responseData: { response } } = saveAPI
    onSave({ id: formula.id, result: response, variables })
    setResult(response)
    closeModal()
  }, [saveAPI.status, saveAPI.responseData])

  const lookupValues = () => {
    const fieldsVariables = { }
    const variableTypes = Object.fromEntries(formula.fields.map((field) => {
      return [field.description, variableType[field.variable_type_id]]
    }))

    formula.fields.forEach((field) => {
      fieldsVariables[field.description.replace(/.*\./, '')] = {
        description: field.description,
        value: fields[field.description] || DEFAULT_FIELD_VALUE,
        type: variableTypes[field.description]
      }
    })

    return fieldsVariables
  }

  const showEmptyErrors = (equationVariables) => {
    const emptyFields = Object.keys(
      filter(equationVariables, ([, data]) => isBlank(data.value))
    )

    notifyError(I18n.t('formula_modal.mandatory', { fields: emptyFields.join(', ') }))
  }

  const calculate = (apiCall) => {
    const equationVariables = lookupValues()
    const hasEmptyField = Object.values(equationVariables).some((v) => { return isBlank(v.value) })
    if (hasEmptyField) {
      showEmptyErrors(equationVariables)
      return
    }

    const equation = buildEquation(formula, equationVariables)
    const params = { requestAction: 'CREATE', httpAction: 'post', data: { equation } }
    apiCall.fetchAPI(params)
  }

  const calculateButton = {
    id: 1,
    text: I18n.t('formula_modal.calculate'),
    class: 'calculate-button',
    onClick: () => calculate(calculateAPI)
  }

  const actionButtons = [
    {
      id: 2,
      text: closeButtonText,
      class: 'cancel-button',
      onClick: () => {
        setResult()
        closeModal()
      }
    },
    {
      id: 3,
      text: I18n.t('formula_modal.ok'),
      class: 'action-button',
      onClick: () => calculate(saveAPI)
    }
  ]

  const setField = (key, value) => {
    const newPair = { [key]: value }

    setFields({ ...fields, ...newPair })
    setVariables({ ...variables, ...newPair })
  }

  const filterLookupFields = (itemParam, formulaParam) => {
    const lookupKeys = formulaParam.fields.map((f) => f.description).filter((f) => f.includes('.'))
    const lookupFieldValues = {}

    lookupKeys.forEach((lKey) => {
      const [variableOwner, variableName] = splitFormulaVariable(lKey)
      const { entity: ownerName } = formulaParam
      const isOwnVar = isSelfVariable(ownerName, variableOwner)
      const key = `${variableOwner}.${variableName}`
      const { _parentItems: parentItems } = itemParam

      lookupFieldValues[lKey] = isOwnVar ? itemParam[variableName] : byString(parentItems, key)
    })

    return lookupFieldValues
  }

  const initVariables = (params) => {
    const formulaParam = params.formula
    const variablesParam = params.variables
    if (!formulaParam) return

    const normalVariables = formulaParam.fields.map((f) => f.description).filter((f) => !f.includes('.'))
    const defaultVariables = Object.fromEntries(normalVariables.map((f) => [f, 0]))

    params.variables = { ...defaultVariables, ...variablesParam }
    setVariables(params.variables)
  }

  const initResult = (item) => {
    const calcResult = item[columnDescription]
    if (!calcResult || item[`${columnDescription}_id`] !== formulaId.current) return
    setResult(calcResult)
  }

  const initFields = ({ item: itemParam, formula: formulaParam, variables: variablesParam }) => {
    if (!formulaParam) {
      setFields(variablesParam)
      return
    }

    const lookupFieldValues = filterLookupFields(itemParam, formulaParam)

    setFields({ ...lookupFieldValues, ...variablesParam })
  }

  const titleElement = (formulaTitle, equation) => {
    if (simpleTitle) return <div className="simple-title">{formulaTitle}</div>
    return (
      <div className="history-popup-drop-formula-title">
        <div className="title-actions">
          <button
            type="button"
            className="back-button"
            onClick={() => closeModal()}
          >
            <div className="back-icon"><MdKeyboardArrowLeft /></div>
            <div className="back-text">{I18n.t('formula_modal.back_button_text')}</div>
          </button>
          <GeneralTooltip>
            <div className="info-icon" title={equation}>i</div>
          </GeneralTooltip>
        </div>
        <div className="title">{formulaTitle}</div>
      </div>
    )
  }

  const showFormulaModal = (params) => {
    const lookupVariablesWithValue = { }

    if (params.formula) {
      formulaId.current = params.formula.id
      const newFormula = { ...params.formula }

      if(newFormula.fields) {
        newFormula.fields = newFormula.fields.map((field) => {
          const value = getFormulaVariableValue(newFormula.entity, field, params)
          lookupVariablesWithValue[field.description] = value

          return { ...field, value }
        })
      }

      setFormula(newFormula)
    }

    if (params.onSave) setOnSave(params.onSave)
    const formulaTitle = params.formula.description
    setTitle(titleElement(formulaTitle, params.formula.equation))
    initVariables({ ...params, variables: { ...params.variables, ...lookupVariablesWithValue } })
    initFields({ ...params, variables: { ...params.variables, ...lookupVariablesWithValue } })
    if (params.item && columnDescription) initResult(params.item)
    setOpened(true)
  }

  const modalButtons = () => {
    return actionButtons.map((button) => (
      <Button
        key={button.id}
        text={button.text}
        type="button"
        className={button.class}
        onClick={button.onClick}
      />
    ))
  }

  const footer = () => {
    return (
      <div className="drop-formula-modal-footer">
        <div className="calculate-button-wrapper">
          <div id="calculate-result" className="calculate-result">
            <FieldFactory value={result} type="decimal" />
          </div>
          <Button
            key={calculateButton.id}
            text={calculateButton.text}
            type="button"
            className={calculateButton.class}
            onClick={calculateButton.onClick}
          />
        </div>
        <div className="action-buttons-wrapper">
          {modalButtons()}
        </div>
      </div>
    )
  }

  const renderFormulaModalInputs = () => {
    return formula && formula.fields && formula.fields.map((field) => (
      <InputFormulaModal
        key={field.id}
        field={field}
        onChange={setField}
      />
    ))
  }

  const renderFormulaModal = () => {
    if (!opened) return null

    return (
      <Modal
        width={50}
        height={75}
        title={title}
        className="formula-modal"
        footer={footer()}
        appendToBody={appendToBody}
        applyOverlay={applyOverlay}
      >
        <div className="formula-inputs">
          {renderFormulaModalInputs()}
        </div>
      </Modal>
    )
  }

  return [showFormulaModal, renderFormulaModal]
}
