import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useTabs from '/src/ui/core/layouts/template_tabs_hook'
import TemplateTabs from '/src/ui/core/layouts/template_tabs'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import ContentLoader from 'react-content-loader'
import I18n from '/src/utils/translations'
import '/src/static/css/core/layouts/tabs_wrapper.css'

export default function TabsWrapper({ tabType, onTemplateChange }) {
  const [tabsList, setTabsList] = useState([])
  const { responseData, status, loading } = useTabs(tabType)

  const loadingDiv = (
    <ContentLoader speed={2} height={37} width="100%">
      <rect x="5" y="0" rx="10" ry="10" width="100" height="10" />
      <rect x="125" y="0" rx="10" ry="10" width="50" height="10" />
      <rect x="200" y="0" rx="10" ry="10" width="150" height="10" />
    </ContentLoader>
  )
  useEffect(() => {
    if(status !== 'SUCCESS') return
    
    const { data } = responseData
    if(!data || data.length === 0 || !data[0] || !data[0].id)
      notifyError({ body: I18n.t('templates.no_template'), closeTimeout: 10 })

    setTabsList(data)
  }, [responseData])

  return tabsList.length === 0 ? loadingDiv : (
    <TemplateTabs tabs={tabsList} onTemplateChange={onTemplateChange} />
  )
}

TabsWrapper.propTypes = {
  tabType: PropTypes.string.isRequired
}
