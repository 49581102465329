import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { useQueryParam, NumberParam } from 'use-query-params'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import CellFactory from '/src/ui/core/grid/column_cell_factory/cell_factory'
import '/src/static/css/templatable_grid.css'

export default function TemplatableGrid({ model, filter, icons, contextMenuItems, onRowClick,
                                          gridTitle, labels, selectedItem, onTemplateChange,
                                          selectFiltering, selecting, onFilterUpdate,
                                          onDataSource, onGridColumns, onRowRender }) {
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const [filters, setFilters] = useState()
  const sort = [{ field: 'id', dir: 'desc' }]

  useEffect(() => {
    if (templateId) {
      setFilters([ ...filter, { type: 'where', column: 'eav_template_id', value: templateId }])
      dispatch({ type: BusEvents.SELECTED_ROWS, payload: [] })
      if (onTemplateChange) onTemplateChange(templateId)
    }
  }, [templateId, filter])

  return (
    filters ? (
      <div className="templatable-grid">
        <SimpleGrid
          key={templateId}
          labels={labels}
          icons={icons}
          model={model}
          sort={sort}
          filter={filters}
          contextMenuItems={contextMenuItems}
          gridTitle={gridTitle}
          loadFlexColumns
          templateId={templateId}
          columnCellFactory={<CellFactory type={model.paramName} />}
          onRowClick={onRowClick}
          onRowRender={onRowRender}
          selectedItem={selectedItem}
          onFilterUpdate={onFilterUpdate}
          onDataSource={onDataSource}
          onGridColumns={onGridColumns}
          selectFiltering={selectFiltering}
          selecting={selecting}
        />
      </div>
  )
    : null
  )
}

TemplatableGrid.propTypes = {
  contextMenuItems: PropTypes.arrayOf(PropTypes.object),
  model: PropTypes.shape({
    name: PropTypes.string.isRequired,
    paramName: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired
  }).isRequired,
  gridTitle: PropTypes.string,
  filter: PropTypes.oneOfType([PropTypes.array]),
  labels: PropTypes.oneOfType([PropTypes.array]),
  icons: PropTypes.oneOfType([PropTypes.array]),
  selectedItem: PropTypes.object,
  selectFiltering: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowRender: PropTypes.func,
  onTemplateChange: PropTypes.func,
  selecting: PropTypes.bool,
  onFilterUpdate: PropTypes.func,
  onGridColumns: PropTypes.func,
  onDataSource: PropTypes.func
}

TemplatableGrid.defaultProps = {
  contextMenuItems: null,
  templates: [],
  labels: [],
  gridTitle: undefined,
  filter: [],
  icons: ['more'],
  selectedItem: undefined,
  onRowClick: undefined,
  onRowRender: undefined,
  onTemplateChange: undefined,
  selectFiltering: true,
  selecting: false,
  onFilterUpdate: undefined,
  onGridColumns: undefined,
  onDataSource: undefined
}
