import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function InspectionStatusLabel({ dataItem }) {
  const [status, setStatus] = useState()
  const [statuses] = useStore('inspect_statuses')
  const pendingStatus = 'pending'

  useEffect(() => {
    if (!statuses || statuses.length === 0) return
    setStatus(statuses[dataItem.inspect_status_id])
  }, [statuses, dataItem])

  const isScheduled = () => ((status.i18n_id === pendingStatus) && dataItem.recurrency_uuid)

  const scheduledText = I18n.t('inspections.scheduled_exception')

  const scheduleContent = () => (
    <span className="label workflow-status scheduled">
      <img src="/static/svg/scheduled.svg" alt={scheduledText} />
      {scheduledText}
    </span>
  )

  const description = () => {
    if (isScheduled()) return scheduleContent()
    return (
      <CustomTooltip
        maxLength={14}
        className={`label workflow-status ${status.i18n_id.replace('_', '-')}`}
      >
        {I18n.t(`inspections.statuses.${status.i18n_id}`)}
      </CustomTooltip>
    )
  }

  return (
    <React.Fragment>
      {status && description()}
    </React.Fragment>
  )
}

InspectionStatusLabel.propTypes = {
  dataItem: PropTypes.shape({
    inspect_status_id: PropTypes.number,
    recurrency_uuid: PropTypes.string
  }).isRequired
}
