import React, { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import processColumn from '/src/ui/domain/formulas_services/columns_processor'
import PropTypes from 'prop-types'
import { isPresent }  from '/src/utils/boolean_refinements'
import useFetchSections from '/src/hooks/api/fetch_sections'
import useFormulasServices from '/src/hooks/formulas_services'
import useFetchById from '/src/hooks/api/fetch_by_id'
import ProgressModel from '/src/models/progress'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import FormButtons from '/src/ui/core/forms/form_buttons'
import Form from '/src/ui/core/forms/form'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import I18n from '/src/utils/translations'

// eslint-disable-next-line max-lines-per-function
export default function ProgressForm({ dataItem, templateId, requestId, type }) {
  const [progressModel, setProgressModel] = useState(new ProgressModel())
  const [includeOnForm, setIncludeOnForm] = useState()
  const sections = useFetchSections(templateId)
  const entity = I18n.t('performances_wip.performance_item')
  const formulasServices = useFormulasServices(templateId, true)
  const [formulasStore] = useStore('formulas')
  const dataItemRequestId = dataItem && dataItem.request_id
  const request = useFetchById('requests', requestId || dataItemRequestId)
  const [progressStatuses] = useStore('progress_statuses')

  const isFetchingRequest = ((requestId || dataItemRequestId) && !request)

  const formTypes = {
    new: 'form.create_entity',
    edit: 'form.update_entity',
    duplicate: 'form.duplicate_entity'
  }

  useEffect(() => {
    if (!isEditMode()) includeTemplateAndRequestOnForm()
  }, [requestId, templateId])

  useEffect(() => {
    if (!(requestId || dataItemRequestId) && !isEditMode()) return

    setProgressModel((model) => {
      const { columns } = model
      const requestColumn = columns.find((col) => (
        (col.description === 'request_id' || col.description === 'request')
        && col.type === 'search'
      ))
      requestColumn.readOnly = true
      requestColumn.forceValue = true

      requestColumn.default = defaultRequestColumn(request, requestColumn)

      return model
    })
  }, [request, requestId, dataItemRequestId])

  const defaultRequestColumn = (request, column) => {
    if (!request) return

    const fieldsToDisplay = column.textDisplayFields.map((f) => request[f])
    const computed_text_field = fieldsToDisplay.filter((y) => isPresent(y)).join(' - ')
    return { ...request, computed_text_field }
  }

  const isEditMode = () => (type === 'edit')

  const includeTemplateAndRequestOnForm = () => {
    setIncludeOnForm({
      ...includeOnForm,
      eav_template_id: templateId,
      request_id: requestId,
    })
  }

  const duplicationCustomAction = {
    requestAction: 'CREATE',
    httpAction: 'post',
    resourceId: dataItem ? dataItem.id : {},
    additionalResource: { path: 'duplicate_hierarchy' }
  }

  const duplicationCustomError = (errors) => {
    const partialSuccess = I18n.t('api_internal_messages.only_progress_copy')
    const messages = errors.join().match(`${partialSuccess}\n(.*)`)
    const requestError = (messages ? messages[1] : null)
    if (requestError === '') {
      const causingError = errors.join().match(`(.*\n.*)\n{2}${partialSuccess}`)[1]
      const renderedError = {
        title: I18n.t(`notification.error`),
        body: causingError,
        status: 'error',
        closable: true,
        closeTimeout: 10
      }
      return renderedError
    }
  }

  if (!templateId || isFetchingRequest) return (<ThreeDotsLoader />)

  const itemStatusI18n = dataItem && progressStatuses && progressStatuses[dataItem.progress_status_id].i18n_id
  
  const disableColumns = () => {
    if(isEditMode())
     return progressModel.canEditRowStatuses.includes(itemStatusI18n) ? (column) => (column.available_on_formula) : undefined 
    
    return type === 'duplicate' ? (column) => (column.title === 'DPMSID') : undefined
  }

  return (
    <FormWrapper
      model={progressModel}
      dataItem={dataItem}
      label={isEditMode() && dataItemRequestId}
      type={type}
      subtitle={I18n.t(`form.subtitle`, { entity })}
      title={I18n.t(formTypes[type], { entity })}
    >
      <React.Fragment>
        <Form
          model={progressModel}
          sections={sections}
          dataItem={dataItem}
          includeOnForm={includeOnForm}
          templateId={templateId}
          type={type}
          disableColumnEdition={disableColumns()}
          submitParams={type === 'duplicate' ? duplicationCustomAction : undefined}
          errorHandler={type === 'duplicate' ? duplicationCustomError : undefined}
          hasFormulaServices
          formulasServices={formulasServices}
          processColumn={
            (column) => processColumn(column, formulasServices, formulasStore, 'progress')
          }
        />
        <FormButtons model={progressModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

ProgressForm.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  templateId: PropTypes.number,
  requestId: PropTypes.number,
  type: PropTypes.string.isRequired
}

ProgressForm.defaultProps = {
  dataItem: null,
  requestId: undefined,
  templateId: undefined
}
