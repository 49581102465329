import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Modal from '/src/ui/core/popups/modal'
import ChooseButton from '/src/ui/core/buttons/choose_button'
import '/src/static/css/core/popups/choose_modal.css'

// eslint-disable-next-line max-lines-per-function
export default function ChooseFormModal({ onClose, opts }) {
  const history = useHistory()
  const { buttons, title } = opts

  const chooseButtons = () => (
    buttons.map(({ route, action, query, title, subtitle, icon }) => (
      <ChooseButton
        key={title}
        onClick={() => history.push(`${route}/${action}?${query}`)}
        title={title}
        subtitle={subtitle}
        icon={icon()}
      />
    ))
  )

  return (
    <div className="confirmation-modal choose-modal">
      <Modal
        height={45}
        width={28}
        title={title}
        onClose={onClose}
        closable
      >
        <div className="body">
          {chooseButtons()}
        </div>
      </Modal>
    </div>
  )
}

ChooseFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  opts: PropTypes.shape({
    buttons: PropTypes.arrayOf(PropTypes.shape({
      action: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      icon: PropTypes.func.isRequired
    })).isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
}
