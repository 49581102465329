import React, { useEffect } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import AuthRequired from './auth_required'
import I18n from '/src/utils/translations'

export default function Routers({ child, mock }) {
  const [locale] = useStore('language')

  I18n.locale = locale || 'en'

  useEffect(() => { I18n.locale = locale || 'en' }, [locale])

  const cloneElement = (match) => {
    const newChildrenProps = { ...child.props }
    Object.keys(child.props).forEach( (key) => {
      newChildrenProps[key] = match.params[child.props[key]] || newChildrenProps[key]
    })
    return React.cloneElement(child, newChildrenProps)
  }

  const renderPage = (match) => {
    if (mock) { return cloneElement(match) }
    return <AuthRequired match={match} />
  }

  return (
    <Switch>
      <Route
        exact
        path='/'
        render={({ match }) => renderPage(match)}
      />
      <Route
        exact
        path='/:resource/:collection?'
        render={({ match }) => renderPage(match)}
      />
      <Route
        exact
        path='/:resource/:id/:collection?'
        render={({ match }) => renderPage(match)}
      />
    </Switch>
  )
}

Routers.propTypes = {
  child: PropTypes.element,
  mock: PropTypes.bool,
}

Routers.defaultProps = {
  child: null,
  mock: false
}
