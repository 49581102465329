/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import DecimalColumn from '/src/ui/core/grid/flexible_column/decimal'

export default function MovingScopes({ label }) {
  const [interactiveScopeList, setInteractiveScopeList] = useStore('interactive_scope_list')

  const handleClick = (id) => {
    setInteractiveScopeList(interactiveScopeList.filter((scope) => scope.id !== id))
  }

  return (
    interactiveScopeList.filter((scope) => scope.label === label).map((scope) => (
      <tr className="selected" onClick={() => handleClick(scope.id)} key={scope.id}>
        <td width={460} className="detail-scope">
          <span className="detail-scope-index">
            <input className="k-checkbox" type="checkbox" id={scope.id} name="scales" defaultChecked />
          </span>
          <span className="detail-scope-id">{scope.id}</span>
          {label && <span className="label">{label}</span>}
        </td>
        <td className="detail-column large"><DecimalColumn value={scope.team_target_hours} /></td>
        <td className="detail-column large"><DecimalColumn value={scope.quantity} /></td>
      </tr>
    ))
  )
}

MovingScopes.propTypes = {
  label: PropTypes.string
}

MovingScopes.defaultProps = {
  label: null
}
