/* eslint-disable max-lines-per-function */
import { useEffect, useRef } from 'react'
import { useStore } from 'react-context-hook'
import { buildEquation, buildVariables } from '/src/models/concerns/formula'
import { objectEquals, byString, isEmpty } from '/src/utils/object'
import useFetchAPI from '/src/hooks/api/fetch_api'

/**
 *  Hook designed watch form change and calculate formulas to update form fields visibilities
 */
export default function useFieldsControl(watch, formulasControlFields, eavColumns, onColumnsUpdated, fixedFields, parentItems) {
  const postEquation = useFetchAPI('formulas/eval_equations', true)
  const [variableTypes] = useStore('variable_types')
  const prevWatchFields = useRef()
  const prevParentItems = useRef()
  const timer = useRef(null)

  const fieldsToWatch = [
    ...Object.keys(eavColumns).map((key) => eavColumns[key].description),
    ...(fixedFields ? fixedFields.map((item) => item.description) : [])
  ]
  const watchFields = watch(fieldsToWatch).reduce((map, value, index) => {
    map[fieldsToWatch[index]] = value
    return map
  }, {})

  const calculate = (formulas) => {
    if (!formulas || isEmpty(formulas)) return
    const equations = {}

    formulas.forEach((formula) => {
      const fieldsVariables = buildVariables(
        { ...formula, parents: parentItems },
        watchFields,
        variableTypes
      )
      equations[formula.id] = [buildEquation(formula, fieldsVariables), {}]
    })

    const params = { requestAction: 'CREATE', httpAction: 'post', data: { formulas: equations } }
    postEquation.fetchAPI(params)
  }

  useEffect(() => {
    if (postEquation.status !== "SUCCESS") return

    const result = postEquation.responseData
    const actualEavColumns = eavColumns
    const formulas = (
      formulasControlFields &&
      formulasControlFields.reduce((obj, item) => ((obj[item.id] = item), obj), {})
    )

    Object.keys(result).forEach((key) => {
      const formula = formulas[key]
      if (formula && actualEavColumns[formula.eav_column_id] && result[key].status === 'success') {
        actualEavColumns[formula.eav_column_id][formula.eav_column_field] = result[key].response
      }
    })

    onColumnsUpdated({ ...actualEavColumns })
  }, [postEquation.status, postEquation.responseData])

  if (!Object.keys(watchFields).length) return
  if (objectEquals(watchFields, prevWatchFields.current) && objectEquals(parentItems, prevParentItems.current)) return

  prevWatchFields.current = watchFields
  prevParentItems.current = parentItems

  clearTimeout(timer.current)
  timer.current = setTimeout(() => calculate(formulasControlFields), 500)
}
