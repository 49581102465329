export const fieldSizeByType = {
  // Column Types
  boolean: 'medium',
  string: 'medium',
  text: 'medium',
  integer: 'medium',
  decimal: 'medium',
  numeric: 'medium',
  percentage: 'medium',
  date: 'medium',
  date_time: 'medium',
  gps: 'medium',
  lookup: 'medium',
  link: 'medium',
  drop: 'medium',
  multiple_drop: 'medium',
  time: 'medium',
  picture: 'large',
  multiline_text: 'large',
  formula: 'large',
  attachment: 'large',
  datasheet_filter: 'large',
  drop_formula: 'large',

  // Special Types
  date_range: 'medium',
  cascade_drop: 'medium',
  conditional_cascade_drop: 'medium',
  search: 'medium',
  module_filter: 'large',
  formula_service: 'large',
  upload: 'large',
  contract_service: 'large',
  controlled_formula: 'large',
  controlled_drop_formula: 'large',
}

export const fieldWidthByType = (type) => {
  switch (type) {
    case 'date_time':
      return 250
    default:
      return 150
  }
}
