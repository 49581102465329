import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useStore } from 'react-context-hook'
import SidebarItems from '/src/ui/core/menus/sidebar_items'
import '/src/static/css/core/menus/sidebar.css'

export default function SideBar({ showNewButton }) {
  const [globalSubproject] = useStore('subproject')

  return (
    <div className="sidebar">
      <div className="dpms-logo">
        <Link to="/home">
          <img className="dpms-logo-collapsed" src="/static/svg/logo-collapsed.svg" alt="" />
          <img className="dpms-logo-full" src="/static/svg/logo-full.svg" alt="" />
        </Link>
      </div>
      <SidebarItems showNewButton={showNewButton} key={globalSubproject.id} />
      <div className="kaefer-copyright">
        <div className="copyright-text">
          {' '}
          ©
          {(new Date().getFullYear())}
          {' '}
          - kaefer.com
        </div>
      </div>
    </div>
  )
}

SideBar.propTypes = {
  showNewButton: PropTypes.bool
}

SideBar.defaultProps = {
  showNewButton: false
}
