
export const dailyHours = (item) => {
  const { crews, crew_size: crewSize, working_hours: workingHours } = item
  return (crews && crewSize && workingHours) ? (crews * crewSize * workingHours) : 0
}

export const numberOfWorkers = (item) => {
  const { crews, crew_size: crewSize } = item
  return (crews && crewSize) ? (crews * crewSize) : 0
}
