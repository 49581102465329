import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import TrackBlock from '/src/ui/domain/requests/track_block'
import useFetchById from '/src/hooks/api/fetch_by_id'
import TrackBlockGeneralButtons from '/src/ui/core/buttons/track_block_general_buttons'
import '/src/static/css/core/blocks/track_block.css'

export default function ScopesTrackBlock({ dataItem, contextMenuItems }) {
  const buttons = (
    <TrackBlockGeneralButtons
      dataItem={dataItem}
      contextMenuItems={contextMenuItems}
    />
  )

  return (!dataItem || !dataItem.request) ? <ThreeDotsLoader /> : (
    <TrackBlock detail={dataItem.request} actions={buttons}>
      <div className="scope-track-block-children">
        <div className="summary-item">
          {`${I18n.t('scopes.item')} ${dataItem.number}`}
        </div>
      </div>
    </TrackBlock>
  )
}

ScopesTrackBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  contextMenuItems: PropTypes.shape({
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    remove: PropTypes.oneOfType([PropTypes.object]).isRequired
  })
}

ScopesTrackBlock.defaultProps = {
  dataItem: undefined,
  contextMenuItems: {
    edit: {},
    duplicate: {},
    remove: {}
  },
}
