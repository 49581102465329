import React, { useState, useEffect } from 'react'
import useFetchAPI from '/src/hooks/api/fetch_api'
import PropTypes from 'prop-types'
import { Dialog } from '@progress/kendo-react-dialogs'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import I18n from '/src/utils/translations'
import '/src/static/css/dialogs.css'
import '/src/static/css/edit_field_dialog.css'

export default function EditFieldDialog({ open, onClose, label, field, onCancel,
                                          onSuccess, resource, object }) {
  const [textValue, setTextValue] = useState()
  const [start, setStart] = useState(false)

  const updateResource = useFetchAPI(resource)
  const updateResourceQueryParams = {
    httpAction: 'put',
    resourceId: object.id
  }

  const onChange = (event) => {
    setTextValue(event.target.value)
  }

  const handleCancel = () => {
    onClose()
    onCancel()
  }

  const handleSave = () => {
    if (!textValue)
      return

    updateResourceQueryParams.data = { ...object, [field]: textValue }
    updateResource.fetchAPI(updateResourceQueryParams)
    setStart(true)
    onClose()
  }

  useEffect(() => {
    if (updateResource.loading) {
      dispatch(BusEvents.SHOW_LOADING_DIALOG)
      return
    }

    if (updateResource.errors) {
      setStart(false)
      dispatch(BusEvents.SHOW_FAILURE_DIALOG)
      return
    }

    if (start) {
      setStart(false)
      dispatch(BusEvents.HIDE_DIALOG)
      object[field] = textValue
      onSuccess(textValue)
    }
  }, [updateResource.loading, updateResource.errors])

  return (
    <React.Fragment>
      {open && (
        <Dialog
          className='edit-field-dialog'
          title={`${I18n.t('actions.edit')} ${label}`}
          closeIcon={false}
          onClose={onCancel}
          width={380}
          height={273}
        >
          <div className="section-field">
            <label className="column-label" htmlFor={field}>{label}</label>
            <input
              role="edit-field-input"
              placeholder={I18n.t('form.inputs.placeholder', { field })}
              onChange={onChange}
              className="form-input-text"
              type="text"
            />
          </div>
          <div className="actions-row">
            <button
              className="k-button cancel-button"
              onClick={handleCancel}
            >
              {I18n.t('actions.cancel')}
            </button>
            <button
              className="k-button edit-button"
              onClick={handleSave}
            >
              {I18n.t('actions.save')}
            </button>
          </div>
        </Dialog>
      )}
    </React.Fragment>
  )
}

EditFieldDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
}

EditFieldDialog.defaultProps = {
  onCancel: () => {},
  onSuccess: (t) => {}
}
