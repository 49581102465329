import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MdCallSplit } from 'react-icons/md'
import { useQueryParams, NumberParam } from 'use-query-params'
import PropTypes from 'prop-types'

export default function SpliterClusterIcon({ selectedItem }) {
  const [queryParams] = useQueryParams({ eav_template_id: NumberParam, request_id: NumberParam })
  const [visible, setVisible] = useState(false)
  const url = useParams()

  useEffect(() => {
    if (url.collection === 'clusters' && selectedItem && selectedItem.label) return setVisible(true)
    
    setVisible(false)
  }, [selectedItem])

  return (
    visible ? (
      <Link 
        id="split-request"
        to={`/requests/${queryParams.request_id}/sub_requests?eav_template_id=${queryParams.eav_template_id}&label=${selectedItem.label}`}
        className="btn-icon"
      >
        <MdCallSplit />
      </Link>
    ) : null
  )
}

SpliterClusterIcon.propTypes = {
  selectedItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}
