import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useTemplatesCardsBlocks from '/src/ui/domain/estimates/templates_cards_blocks'
import useEstimateServicesCardsBlocks from '/src/ui/domain/estimates/estimate_services_cards_blocks'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import ExpandableCardList from '/src/ui/core/cards/expandable_card_list'
import CardList from '/src/ui/core/cards/card_list'
import useSyncBatch from '/src/hooks/api/sync_batch'
import '/src/static/css/domain/estimates/estimate_services_list.css'

export default function EstimateServicesList({ requestId, estimateServices }) {
  const [templates, setTemplates] = useState([])
  const [units, setUnits] = useState([])
  const [estimateServicesHash, setEstimateServicesHash] = useState()
  const [templatesBuffer, setTemplatesBuffer] = useState([])
  const [scopes, setScopes] = useState([])
  const [blocks, setBlocks] = useState()

  const queryTemplates = { where: { template_type: 4 } }
  const queryScopes = { where: { request_id: requestId } }

  const batchedEntities = {
    eav_templates: { get: templatesBuffer, set: setTemplatesBuffer, query: queryTemplates },
    scopes: { get: scopes, set: setScopes, query: queryScopes },
    units: { get: units, set: setUnits }
  }

  const { loading } = useSyncBatch(batchedEntities)
  const servicesCardsParts = useEstimateServicesCardsBlocks(estimateServicesHash)
  const templatesCardsParts = useTemplatesCardsBlocks(templates)

  useEffect(() => {
    const unitsEntries = Object.entries(units)
    if (loading || !unitsEntries.length) return
    setDataOnEstimateServices()
  }, [loading, units, scopes, estimateServices])

  useEffect(() => {setServicesOnTemplate()}, [servicesCardsParts, templatesBuffer])

  useEffect(() => {setBodyOnTemplateCards()}, [templatesCardsParts])

  const setDataOnEstimateServices = () => {
    const newServices = estimateServices.map((service) => {
      const newService = { ...service }
      const scopeId = service.scope_id
      const { contract_service: contractService } = newService

      newService.unit = contractService ? units[contractService.unit_id] : { description: '' }
      newService.scopeNumber = 0
      if (scopeId && scopes[scopeId]) newService.scopeNumber = scopes[scopeId].number
      return newService
    })

    setEstimateServicesHash(newServices)
  }

  const setServicesOnTemplate = () => {
    if (servicesCardsParts.length > 0) {
      Object.values(templatesBuffer).forEach((tb) => tb.items = [])

      servicesCardsParts.forEach((cardParts) => {
        if (!cardParts) return
        const template = templatesBuffer[cardParts.templateId]

        template.items.push(cardParts)
      })
    }

    setTemplates(Object.values(templatesBuffer))
  }

  const setBodyOnTemplateCards = () => {
    if (templatesCardsParts.length === 0) {
      setBlocks([])
    } else {
      templatesCardsParts.forEach((cardParts) => {
        const { items, key } = cardParts
        if (items && items.length > 0) {
          cardParts.body = <CardList blocksCollection={items} key={key} />
        }
      })

      setBlocks(templatesCardsParts)
    }
  }

  return !blocks ?
    <ThreeDotsLoader />
  :(
    <div className="estimate-service-list">
      <ExpandableCardList blocksCollection={blocks} />
    </div>
  )
}

EstimateServicesList.propTypes = {
  requestId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  estimateServices: PropTypes.arrayOf(PropTypes.object).isRequired
}
