import React, { useState } from 'react'
import '@brainhubeu/react-carousel/lib/style.css'
import ScrollContainer from 'react-indiana-drag-scroll'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Image from '/src/ui/core/fields/image'
import ImageModal from '/src/ui/core/popups/image_modal'

export default function Picture({ images }) {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [selected, setSelected] = useState(undefined)

  const printImages = () => {
    if (!images || images.length === 0) return I18n.t('fields.na')
    return (
      images.map((image) => (
        <div key={image.id} role="button">
          <Image image={image} key={image.id} onClick={setSelected} />
        </div>
      ))
    )
  }

  return (
    <div className="detail-field-picture">
      {selected && <ImageModal image={selected} onClose={() => setSelected(undefined)} />}
      <ScrollContainer className="scroll-container" hideScrollbars={false}>
        <div className="side-panel-carousel-wrapper">
          {printImages()}
        </div>
      </ScrollContainer>
    </div>
  )
}

Picture.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object)
}

Picture.defaultProps = {
  images: []
}
