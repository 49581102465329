import { useEffect, useState, useReducer, useMemo } from 'react'
import useFetchAPI, { MAX_PAGE_SIZE } from '/src/hooks/api/fetch_api'

const MAX_RETRIES = 3

/**
 * This custom hook will fetch multiple paginated pre defined entities data
 * @param batchEntities
 * @return {[*, *]}
 */

const initialState = {
  loading: true,
  status: { loading: true , pagesLeft: 0 },
}

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: true,
        status: { ...state.status, loading: true },
      }
    case "pageUpdate":
      return {
        ...state,
        status: { ...state.status, ...action.payload },
      }
    case "nextPage":
      return {
        ...state,
        status: { ...state.status, pagesLeft: state.status.pagesLeft - 1 },
      }
    case "done":
      return {
        ...state,
        loading: false,
        status: { ...state.status, loading: false },
      }
    default:
      throw Error()
  }
}

export default function useSyncBatch(batchEntities, reload = false, paginateInactives = false) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [skip, setSkip] = useState(0)
  const [mounted, setMounted] = useState(false)
  const [retries, setRetries] = useState(0)
  const batchCall = useFetchAPI('sync')
  const routes = Object.keys(batchEntities).map((key) => {
    return { name: key, query: batchEntities[key].query }
  })

  const isEmptyEntities = useMemo(() => (
    Object.keys(batchEntities).length === 0
  ), [batchEntities])
  const isDisabled = isEmptyEntities

  const routesData = { routes }

  useEffect(() => {
    if (isDisabled) return

    callAPI()
    setMounted(true)
  }, [isDisabled])

  useEffect(() => {
    if (isDisabled) return

    if (mounted && skip > 0 && !reload) callAPI()
  }, [mounted, skip, reload, isDisabled])

  useEffect(() => {
    if (isDisabled) return

    if (reload && batchCall.status === 'SUCCESS') callAPI()
  }, [batchEntities, reload, isDisabled])

  useEffect(() => {
    if (batchCall.status === 'SUCCESS')
      treatAPIResponse()
    else if (batchCall.status === 'ERROR')
      treatAPIError()
  }, [batchCall.status, batchCall.responseData])

  useEffect(() => { paginate() }, [state.status.pagesLeft])

  useEffect(() => { if (retries > 0) callAPI() }, [retries])

  const callAPI = () => {
    const batchQueryParams = {
      requestAction: 'READ',
      httpAction: 'post',
      dataOptions: { paging: { pageSize: MAX_PAGE_SIZE, skip } },
      additionalResource: { path: 'batch' },
      data: routesData
    }

    batchCall.fetchAPI(batchQueryParams)
  }

  const treatAPIError = () => {
    setRetries((retries) => (retries + 1) % (MAX_RETRIES + 1))
  }

  const treatAPIResponse = () => {
    const { data } = batchCall.responseData

    const hasData = data.some((item) => {
      const hasItemData = item.data && item.data.length > 0
      const hasItemInactives = item.inactives && item.inactives.length > 0
      return hasItemData || (paginateInactives && hasItemInactives)
    })

    if (hasData) {
      storeEntities(data)
      skip === 0 ? setTotalPagesLeft(data) : dispatch({ type: 'nextPage' })
    } else if (mounted) {
      dispatch({ type: 'done' })
    }
  }

  const paginate = () => {
    if (state.status.pagesLeft > 0) {
      setSkip(skip + 100)
    } else if (skip > 0) {
      dispatch({ type: 'done' })
    }
  }

  const setTotalPagesLeft = (entities) => {
    let totalPages = 0

    entities.forEach((entity) => {
      const verifyInactives = paginateInactives && entity.inactives_total
      const inactivesTotal = verifyInactives ? entity.inactives_total : 0

      const maxItems = Math.max(entity.total, inactivesTotal)
      const entityTotalPages = Math.ceil(maxItems / MAX_PAGE_SIZE)

      if (entityTotalPages > totalPages) totalPages = entityTotalPages
    })

    dispatch({ type: 'pageUpdate', payload: { totalPages, pagesLeft: totalPages - 1 } })

    if (totalPages === 1) dispatch({ type: 'done' })
  }

  const storeEntities = (entities) => {
    entities.forEach((entity) => {
      const { data: entityData, inactives: entityInactives } = entity
      if (!batchEntities[entity.name]) return

      const { get, set, onPageFetch, key } = batchEntities[entity.name]
      const hasNoInactives = entityInactives && entityInactives.length === 0

      if (entityData.length === 0 && hasNoInactives) {
        set(get || [])
        return
      }

      const result = entityData.reduce((map, obj) => {
        map[obj[key || 'id']] = obj
        return map
      }, {})

      const newEntityData = { ...get, ...result }

      if (onPageFetch)
        onPageFetch(entity.name, newEntityData, entityData, entityInactives)
      else
        set(newEntityData)
    })
  }

  if (isDisabled) return false

  return { loading: state.loading, status: state.status }
}
