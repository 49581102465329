import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { isPresent } from '/src/utils/boolean_refinements'
import { moveScreenToError } from '/src/ui/core/inputs/input_error'

export default function useGroupFormStep(groupedFields, groupOnNextClick) {

  const groupAndDispatchFields = (data, requireds = []) => {
    const allGiven = requireds.every((e) => isPresent(data[e]))
    if (!allGiven) {
      moveScreenToError()
      return
    }
    const updatedItem = { ...groupedFields, ...data }
    groupOnNextClick(updatedItem)
    dispatch({ type: BusEvents.FORM_SUBMITTED, payload: updatedItem })
  }

  useBus(BusEvents.STEPS_NEXT_BUTTON_CLICKED, () => dispatch(BusEvents.FORM_SUBMIT), [])

  return [groupAndDispatchFields]
}
