/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { useQueryParam, NumberParam } from 'use-query-params'
import PropTypes from 'prop-types'
import RequestModel from '/src/models/request'
import I18n from '/src/utils/translations'
import RequestCellFactory from './request_cell_factory'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import RequestStatusFilterIcon from '/src/ui/domain/requests/request_status_filter_icon'
import ScheduleRequestPopup from './schedule_request_popup'
import AuthorizationRequestPopup from './authorization_request_popup'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import useRequestContextMenu from '/src/ui/domain/requests/request_context_menu'
import LabelFilter from '/src/ui/core/buttons/label_filter'
import useFetchById from '/src/hooks/api/fetch_by_id'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import { bulkEditingItem , importMenuItem, exportMenuItem } from '/src/ui/core/grid/context_menu_entries'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import useExcelExportUrl from '/src/hooks/api/export_excel_url'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/panels/side_panel.css'

export default function RequestsGrid({
  onRowClick, onRowRender, selectedRequest, editableGridFunctions
}) {
  const model = new RequestModel()
  const [filter, setFilter] = useState()
  const [gridTitle, setGridTitle] = useState(I18n.t('grid.all'))
  const [scheduleDataItem, setScheduleDataItem] = useState({})
  const [authorizeDataItem, setAuthorizeDataItem] = useState({})
  const [openImport, setOpenImport] = useState(false)
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [subproject] = useStore('subproject')
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const request = useFetchById('requests', requestId)
  const labels = []
  const requestContextMenu = useRequestContextMenu({ setAuthorizeDataItem,
                                                     setScheduleDataItem,
                                                     showConfirmation })
  const templateId = subproject.request_eav_template_id
  const exportParams = { entity: 'requests', templateId, isApi: true }
  const { linkExport, setExportFilters } = useExcelExportUrl(exportParams)

  const { setInEditMode, onGridColumns, editableGridDataSource } = editableGridFunctions

  const moreActionsMenuItems = [
    bulkEditingItem(() => setInEditMode('top'), () => (
      editableGridDataSource && editableGridDataSource.get && !editableGridDataSource.get.loading
    )),
    importMenuItem(setOpenImport),
    exportMenuItem(linkExport)
  ]

  const icons = [
    <RequestStatusFilterIcon
      key='request-status-filter-icon'
      filter={filter}
      onFilter={setFilter}
    />,
    <MoreActionsIcon items={moreActionsMenuItems} />
  ]

  if (requestId) {
    labels.push(
      <LabelFilter
        key={requestId}
        link="/requests"
        text={requestId.toString()}
      />
    )
  }

  const updateFilterState = () => {
    const filterArray = [{ type: 'where', column: 'id', value: parseInt(requestId, 10) }]
    setFilter(requestId ? filterArray : undefined)
  }

  useEffect(() => { updateFilterState() }, [requestId])

  useEffect(() => {
    if(request && requestId) setGridTitle(`${request.reason}`)
  }, [request])

  useEffect(() => { if (!requestId) setGridTitle(I18n.t('grid.all')) }, [requestId])

  const onClosePopupImport = () => {
    setOpenImport(false)
    dispatch(BusEvents.RELOAD_GRID)
  }

  return (
    <React.Fragment>
      <AuthorizationRequestPopup dataItem={authorizeDataItem} />
      {renderConfirmation()}
      <ScheduleRequestPopup dataItem={scheduleDataItem} />
      { openImport && (
        <PopupImportFile
          modelRoute={model.route}
          templateId={templateId}
          onClose={onClosePopupImport}
        />
      )}
      <SimpleGrid
        labels={labels}
        icons={icons}
        model={model}
        onGridColumns={onGridColumns}
        onFilterUpdate={setExportFilters}
        sort={[{ field: 'id', dir: 'desc' }]}
        filter={filter}
        contextMenuItems={requestContextMenu}
        gridTitle={gridTitle}
        loadFlexColumns
        templateId={templateId}
        onDataSource={editableGridDataSource && editableGridDataSource.set}
        columnCellFactory={<RequestCellFactory />}
        selectedItem={selectedRequest}
        selectFiltering={false}
        onRowClick={onRowClick}
        onRowRender={onRowRender}
      />
    </React.Fragment>
  )
}

RequestsGrid.propTypes = {
  onRowClick: PropTypes.func,
  onRowRender: PropTypes.func,
  selectedRequest: PropTypes.object,
  editableGridFunctions: PropTypes.oneOfType([PropTypes.object])
}

RequestsGrid.defaultProps = {
  onRowClick: () => {},
  onRowRender: () => {},
  selectedRequest: undefined,
  editableGridFunctions: {}
}
