import React, { useState , useEffect } from 'react'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import Block from '/src/ui/core/grid/side_panel/block'

export default function BlocksSidePanel({ blocks, closable, onClose, title }){
  const closePanel = () => { 
    onClose()
    dispatch(BusEvents.SIDE_PANEL_CLOSED)
  }

  const [blocksToRender, setBlocksToRender] = useState([])

  useEffect(() => {
    if (!blocks || blocks.length === 0) return

    setBlocksToRender(blocks.map((block) => <Block block={block} key={block.key || block.title} />))
  }, [blocks])

  return (
    <React.Fragment>
      <aside className="side-panel" role="side-panel">
        <div className="side-panel-header">
          {(closable || title) && (
          <div className="side-panel-header-default">
            {title &&
            <div className="side-panel-name" role="side-panel-title">{title}</div>}
            {closable && (
            <div className="side-panel-close">
              <a onClick={closePanel} role="side-panel-close">
                <MdClose />
              </a>
            </div>
                )}
          </div>
            )}
        </div>
        <div className="side-panel-content">
          {blocksToRender}
        </div>
      </aside>
    </React.Fragment>
  )
}

BlocksSidePanel.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      border: PropTypes.bool,
      component: PropTypes.oneOfType([PropTypes.object]).isRequired,
      radius: PropTypes.bool,
      type: PropTypes.string.isRequired
    })
  ),
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string
}

BlocksSidePanel.defaultProps = {
  closable: true,
  onClose: () => {},
  title: null,
  blocks: null
}
