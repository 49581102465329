import React, { useRef, useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import EnvironmentSwitchList from './environment_switch_list'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import { sortArrayOfObjectsByString } from '/src/utils/object'

// eslint-disable-next-line max-lines-per-function
export default function ProjectSwitch({ setOpenSubproject }) {
  const [forceOpen, setForceOpen] = useState(false)
  const [globalProject, setGlobalProject] = useStore('project')
  const [user] = useStore('user')
  const [project, setProject] = useState(globalProject.description ||
                                         I18n.t('environment.project.select_project'))

  const getProjects = useFetchAPI('users')
  const popupButtonRef = useRef(null)

  useEffect(() => {
    if (!globalProject || !globalProject .description) return
    setProject(globalProject .description)
  }, [globalProject ])

  const handleProjectChange = (item) => {
    setProject(item.description)
    setGlobalProject(item)
    setForceOpen(false)
    setOpenSubproject(true)
  }

  const forceOpenPopup = () => {
    const params = {
      requestAction: 'READ',
      httpAction:'get',
      resourceId: user.id,
      additionalResource: { path: 'projects' }
    }

    getProjects.fetchAPI(params)
    setForceOpen(!forceOpen)
    setOpenSubproject(false)
  }

  const popupButtonClass = () => {
    let className = 'project-switch switch-button-text'
    if (forceOpen) className += ' active'
    return className
  }

  return (
    <PopupAnchored
      body={
        (
          <EnvironmentSwitchList
            items={sortArrayOfObjectsByString(getProjects.responseData.data, 'description')}
            loading={getProjects.loading}
            handleItemChange={handleProjectChange}
          />
        )
      }
      forceOpen={forceOpen}
      popupButtonRef={popupButtonRef}
      setForceOpen={setForceOpen}
      onClick={() => forceOpenPopup()}
    >
      <button
        type="button"
        className={popupButtonClass()}
        ref={popupButtonRef}
        data-testid="project-switch-button"
      >
        <CustomTooltip maxLength={35}>{project}</CustomTooltip>
      </button>
    </PopupAnchored>
  )
}

ProjectSwitch.propTypes = {
  setOpenSubproject: PropTypes.func.isRequired
}
