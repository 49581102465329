import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import classNames from 'classnames'
import { MdCheck, MdFormatIndentIncrease } from 'react-icons/md'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import { createSingleDateRangeFilter, createWeekDateRangeFilter } from '/src/utils/calendar'

export default function ProgressServicesDateFilter({ filter, onFilter, onSelectedOptionChange }){
  const [selectedOption, setSelectedOption] = useState('all')
  const popupButtonRef = useRef(null)

  const cleanFilter = () => filter.filter((f) => !['start_date', 'end_date'].includes(f.column))

  const changeFilter = (filterOption) => {
    let filters = []
    const today = new Date()

    switch (filterOption) {
      case 'yesterday': {
        const yesterday = new Date(today.getTime())
        yesterday.setDate(today.getDate() - 1)
        filters = createSingleDateRangeFilter(yesterday)
        break
      }
      case 'today':
        filters = createSingleDateRangeFilter(today)
        break
      case 'tomorrow': {
        const tomorrow = new Date(today.getTime())
        tomorrow.setDate(today.getDate() + 1)
        filters = createSingleDateRangeFilter(tomorrow)
        break
      }
      case 'week':
        filters = createWeekDateRangeFilter(today)
        break
      default:
        filters = []
    }

    setSelectedOption(filterOption)
    onSelectedOptionChange(I18n.t(`progress_services.date_filter.${filterOption}`))
    onFilter((f) =>  [...cleanFilter(f), ...filters])
  }

  const items = ['all', 'week', 'yesterday', 'today', 'tomorrow'].map((option) => ({
    text: I18n.t(`progress_services.date_filter.${option}`),
    className: classNames({ 'date-filter-item': true, selected: selectedOption === option }),
    icon: selectedOption === option ? (<MdCheck />) : <span />,
    onClick: () => changeFilter(option)
  }))

  return (
    <PopupMenu items={items} popupButtonRef={popupButtonRef}>
      <button
        id="open-filter-popup-button"
        type="button"
        className="date-filter-icon"
        ref={popupButtonRef}
      >
        <MdFormatIndentIncrease />
      </button>
    </PopupMenu>
  )
}

ProgressServicesDateFilter.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.object),
  onFilter: PropTypes.func.isRequired,
  onSelectedOptionChange: PropTypes.func.isRequired
}

ProgressServicesDateFilter.defaultProps = {
  filter: []
}
