import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { MdMoreHoriz } from 'react-icons/md'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import '/src/static/css/popup_anchored.css'

export default function GridRowContextMenu({ dataItem, data, items }) {
  const [dataItemIndex, setDataItemIndex] = useState(null)
  const [active, setActive] = useState(false)
  const popupButtonRef = useRef(null)

  const itemsMapped = items.map((item) => {
    return { ...item, ...{ dataItem: { ...dataItem, dataItemIndex } } }
  })

  const itemsVisible = itemsMapped.filter((item) => {
    if (item.visible) return item.visible(item.dataItem)
    return true
  })

  const handleMenuOpen = (e) => {
    setDataItemIndex(data.findIndex((p) => (p.id === dataItem.id)))
  }

  const contextMenuButtonClassName = () => {
    const classes = ['context-menu-button']

    if (active) classes.push('active')
    if (itemsVisible.length === 0) classes.push('disabled')

    return classes.join(' ')
  }

  return (
    <td>
      <PopupMenu
        items={itemsVisible}
        handleMenuOpen={handleMenuOpen}
        setActive={setActive}
        popupButtonRef={popupButtonRef}
        disabled={itemsVisible.length === 0}
      >
        <button
          ref={popupButtonRef}
          type="button"
          data-testid="grid-context-menu-button"
          className={contextMenuButtonClassName()}
          disabled={itemsVisible.length === 0}
        >
          <MdMoreHoriz />
        </button>
      </PopupMenu>
    </td>
  )
}

GridRowContextMenu.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.element,
    onClick: PropTypes.func.isRequired,
    visible: PropTypes.func
  })).isRequired
}
