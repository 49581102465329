/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useRef } from 'react'
import axios from 'axios'
import { store } from 'react-context-hook'
import useCookie from '/src/hooks/cookie'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import buildUrl from '/src/hooks/api/query_builder'
import { dispatch } from '/src/hooks/bus/bus'
import handleError from '/src/hooks/api/errors'

export const MAX_PAGE_SIZE = 100

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

const notifyErrors = (errors) => {
  if (Array.isArray(errors)) {
    errors.forEach((body) => {
      notifyError({ body, closeTimeout: 10 })
    })
  } else {
    notifyError({ body: errors, closeTimeout: 10 })
  }
}

const onFetchFailed = (response, dialogMessages) => {
  const errors = handleError(response.request)
  dialogMessages ? dispatch(BusEvents.SHOW_FAILURE_DIALOG) : notifyErrors(errors)
}

const useFetch = (hideErrors = false) => {
  const componentMounted = useRef(true)
  const [getToken] = useCookie('authentication_token')
  const source = axios.CancelToken.source()

  useEffect(() => {
    return () => {
      source.cancel()
      componentMounted.current = false
    }
  }, [])

  const getUrlParams = (path, params) => {
    return {
      ...params,
      resourcePath: path,
      serverUrl: SERVER_URL,
      globalSubproject: store.get('subproject'),
      globalProject: store.get('project')
    }
  }

  const fetch = useCallback((path, params, { onSuccess, onError }) => {
    const authToken = getToken()
    const headers = { 'Content-Type': 'application/json; charset=utf-8' }
    if (authToken) headers['Authorization'] = `Token ${authToken}`

    axios({
      cancelToken: source.token,
      method: params.httpAction,
      url: buildUrl(getUrlParams(path, params)),
      data: params.data,
      responseType: 'text',
      withCredentials: true,
      headers
    })
      .then((response) => {
        if (componentMounted.current && onSuccess){
          let responseData

          try {
            responseData = JSON.parse(response.data)
          } catch (e) {
            responseData = response.data
          }

          onSuccess({ data: responseData }, params)
        }
      })
      .catch((response) => {
        if (!axios.isCancel(response)) {
          if (!hideErrors) onFetchFailed(response, params.dialogMessages)
          if (onError) onError(response, params.dialogMessages)
        }
      })
  }, [])

  return { fetch }
}

export default useFetch
