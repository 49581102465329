import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdGesture } from 'react-icons/md'
import I18n from '/src/utils/translations'
import ImageModal from '/src/ui/core/popups/image_modal'
import '/src/static/css/core/fields/signature_field.css'

export default function SignatureField({ value }) {
  const [opened, setOpened] = useState(false)
  const image = { file_path: `/api/v1/pictures/${value}` }

  return (
    <React.Fragment>
      {opened && <ImageModal image={image} onClose={() => setOpened(false)} />}
      {value ? (
        <div className="link-signature">
          <button type="button" className="signature-pic" onClick={() => setOpened(true)}>
            <span className="signature-icon"><MdGesture /></span>
            {I18n.t('fields.signature')}
          </button>
        </div>
      ) : I18n.t('fields.na')}
    </React.Fragment>
  )
}

SignatureField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
