import React, { useState } from 'react'
import { useStore } from 'react-context-hook'
import { BiError } from 'react-icons/bi'
import PropTypes from 'prop-types'
import useCookie from '/src/hooks/cookie'
import I18n from '/src/utils/translations'
import Modal from '/src/ui/core/popups/modal'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import '@brainhubeu/react-carousel/lib/style.css'
import '/src/static/css/core/popups/image_modal.css'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function ImageModal({ height, width, image, onClose }) {
  const { uuid, file_path: filePath } = image
  const [getToken] = useCookie('authentication_token')
  const [project] = useStore('project')
  const [subproject] = useStore('subproject')
  const [imageFound, setImageFound] = useState(true)

  const subprojectQuery = `&subproject_id=${subproject.id}&project_id=${project.id}`
  const src = `${SERVER_URL+filePath}?remember_token=${getToken()}${subprojectQuery}`

  const onError = () => {
    notifyError(I18n.t('fields.image_not_found'))

    setImageFound(false)
  }

  return (
    <Modal
      onClose={onClose}
      width={width}
      height={height}
      closable
    >
      {imageFound ? (
        <div className="selected-image-wrapper">
          <img
            id="modal-image"
            className="selected-image"
            src={src}
            onError={onError}
            alt={uuid || filePath}
          />
        </div>
        ) : (
          <div className="not-found-placeholder-wrapper">
            <div className="not-found-placeholder-icon">
              <BiError />
            </div>
            <div className="not-found-message">
              {I18n.t('fields.image_not_found')}
            </div>
          </div>
      )}
    </Modal>
  )
}

ImageModal.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  image: PropTypes.shape({
    file_path: PropTypes.string.isRequired,
    uuid: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func
}

ImageModal.defaultProps = {
  height: 90,
  width: 90,
  onClose: () => {}
}
