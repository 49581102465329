import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import classNames from 'classnames'
import { MdMoreHoriz } from 'react-icons/md'
import '/src/static/css/popup_anchored.css'

export default function HistoriesFeedItemActions({ actions, buttonInactive }) {
  const [active, setActive] = useState(false)
  const popupButtonRef = useRef(null)

  const getClassnames = () => {
    return buttonInactive ?
      classNames('context-menu-button-inactive', { active }) :
      classNames('context-menu-button', { active })
  }

  return (
    <PopupMenu
      items={actions}
      setActive={setActive}
      popupButtonRef={popupButtonRef}
    >
      <button
        ref={popupButtonRef}
        type="button"
        className={getClassnames()}
      >
        <MdMoreHoriz />
      </button>
    </PopupMenu>
  )
}

HistoriesFeedItemActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttonInactive: PropTypes.bool
}
