import React, { useState } from 'react'
import ProjectSwitch from './project_switch'
import SubprojectSwitch from './subproject_switch'
import '/src/static/css/environment_switch.css'

export default function EnvironmentSwitch() {
  const [openSubproject, setOpenSubproject] = useState(false)

  return (
    <div className="project-switch-container">
      <ProjectSwitch setOpenSubproject={setOpenSubproject} />
      <div className="project-switch-divisor">/</div>
      <SubprojectSwitch openSubproject={openSubproject} setOpenSubproject={setOpenSubproject} />
    </div>
  )
}
