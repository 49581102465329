/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'
import lodash from 'lodash'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import useFetchAPI from '/src/hooks/api/fetch_api'
import Layout from '/src/ui/core/layouts/layout'
import TemplatableGrid from '/src/ui/core/grid/templatable_grid'
import LabelFilter from '/src/ui/core/buttons/label_filter'
import DatasheetModel from '/src/models/datasheet'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import useExcelExportUrl from '/src/hooks/api/export_excel_url'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import {
  bulkEditingItem,
  editMenuItem,
  deleteMenuItem,
  importMenuItem,
  exportMenuItem,
  duplicateMenuItem,
  clearRecordsMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import DatasheetsSidePanel from '/src/ui/domain/datasheets/datasheets_side_panel'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import '/src/static/css/core/layouts/shadowed_page.css'

export default function DatasheetsPage() {
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const model = new DatasheetModel()
  const [datasheetId] = useQueryParam('id', NumberParam)
  const templateId = useQueryParamWithPrevious('eav_template_id')
  const [filterQuery] = useQueryParam('filter', StringParam)
  const url = `/datasheets/new?eav_template_id=${templateId}&filter=${filterQuery}`
  const [openImport, setOpenImport] = useState(false)
  const [filter, setFilter] = useState()
  const exportParams = { entity: 'datasheets', templateId, isApi: false }
  const { linkExport, setExportFilters } = useExcelExportUrl(exportParams)
  const { status, responseData, fetchAPI } = useFetchAPI('eav_sections')
  const [sectionsColumns, setSectionsColumns] = useState([])
  const [selectedItem, setSelectedItem] = useState()
  const [gridColumns, setGridColumns] = useState()

  const history = useHistory()
  useBus(BusEvents.SIDEBAR_ADD_BUTTON_CLICKED,
    () => {
      history.push(url)
    }, [templateId])

  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)

  const datasheetCategory = (filterQuery && filterQuery.split(':')[0] === 'datasheet_category_id') && filterQuery

  const editableGridProps = {
    allowCreate: true,
    recoverSettings: () => selectedItem,
    topEntity: { model }
  }

  const [
    editableGrid,
    setInEditMode,
    editableGridColumns,
    editableGridDataSource
  ] = useEditableGrid(editableGridProps)

  const menuItems = {
    edit: editMenuItem(history, model.route, () => true, {
      urlParams: datasheetCategory && `filter=${datasheetCategory}`
    }),
    duplicate: duplicateMenuItem(history, model.route, () => true, {
      urlParams: datasheetCategory && `filter=${datasheetCategory}`
    }),
    remove: deleteMenuItem(history, model, showConfirmation),
  }

  const moreActionsMenuItems = [
    bulkEditingItem(() => setInEditMode('top'), () => (
      editableGridDataSource && editableGridDataSource.get && !editableGridDataSource.get.loading
    )),
    importMenuItem(setOpenImport),
    exportMenuItem(linkExport),
    clearRecordsMenuItem(model, templateId, showConfirmation)
  ]

  const icons = [<MoreActionsIcon items={moreActionsMenuItems} />]

  const handleCloseSidePanel = () => {
    setSelectedItem()
    dispatch(BusEvents.SIDE_PANEL_CLOSED)
    setColumnStyles(SIDE_PANEL_CLOSED)
  }

  const onRowClick = useCallback((e) => {
    setSelectedItem((prevSelectedItem) => {
      const isSidePanelOpened = prevSelectedItem && prevSelectedItem.id === e.dataItem.id
      const newItem = isSidePanelOpened ? undefined : e.dataItem

      return newItem
    })

    setColumnStyles(SIDE_PANEL_OPENED)
  }, [])

  const onGridColumns = (e) => {
    setGridColumns(e)
    editableGridColumns.set(e)
  }

  const onGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    const newSelectedItem = selectedItem && dataSource.data.filter((item) => (
      item.id === selectedItem.id
    ))[0]

    editableGridDataSource.set(dataSource)

    if (!isEqual(selectedItem, newSelectedItem)) {
      if (!newSelectedItem) handleCloseSidePanel()
      else {
        setSelectedItem(newSelectedItem)
        setColumnStyles(SIDE_PANEL_OPENED)
      }
    }
  }

  const labels = useMemo(() => {
    if (!datasheetId || !templateId) return []

    const link = [`/datasheets?eav_template_id=${templateId}`]
    if (filterQuery) {
      const categoryFilter = encodeURIComponent(filterQuery)
      link.push(`filter=${categoryFilter}`)
    }

    return [
      <LabelFilter
        key={datasheetId}
        link={link.join('&')}
        text={datasheetId.toString()}
      />
    ]
  }, [datasheetId, templateId, filterQuery])

  const updateFilterState = () => {
    const filterArray = [{ type: 'where', column: 'id', value: parseInt(datasheetId, 10) }]
    setFilter(datasheetId ? filterArray : undefined)
  }

  const onClosePopupImport = () => {
    setOpenImport(false)
    dispatch(BusEvents.RELOAD_GRID)
  }

  useEffect(() => { updateFilterState() }, [datasheetId])

  useEffect(() => {
    if (!templateId) return

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: templateId } }
    }

    fetchAPI(params)
    handleCloseSidePanel()
  }, [templateId])

  useEffect(() => {
    if (status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(responseData.data[0])
    setSectionsColumns(newSectionsColumns)
  }, [status, responseData])

  useBus(BusEvents.EXITING_EDITABLE_MODE, ({ payload }) => {
    const item = payload()
    if (!item) return

    setSelectedItem({ ...item })
    setColumnStyles(SIDE_PANEL_OPENED)
  }, [])

  return (
    <Layout showNewButton={!!templateId}>
      <React.Fragment>
        {renderConfirmation()}
        {openImport && (
          <PopupImportFile
            modelRoute={model.route}
            templateId={templateId}
            onClose={onClosePopupImport}
            useLegacyPath
          />
        )}
        {editableGrid(
          <React.Fragment>
            <TabsWrapper tabType={model.templateType} />
            <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
              <TemplatableGrid
                contextMenuItems={Object.values(menuItems)}
                model={model}
                icons={icons}
                labels={labels}
                filter={filter}
                selectedItem={selectedItem}
                selectFiltering={false}
                onFilterUpdate={setExportFilters}
                onRowClick={onRowClick}
                onGridColumns={onGridColumns}
                onDataSource={onGridDataSource}
              />
              <DatasheetsSidePanel
                dataItem={selectedItem}
                sections={sectionsColumns}
                columns={gridColumns}
                onClose={handleCloseSidePanel}
                contextMenuItems={menuItems}
              />
            </LayoutPanels>
          </React.Fragment>
      )}
      </React.Fragment>
    </Layout>
  )
}
