/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import FormSidePanel from '/src/ui/core/forms/form_side_panel'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import '/src/static/css/core/layouts/page_form.css'

export default function FormWrapper({ model, label, badge, children, classes, type, title,
                                      backText, subtitle, showSidePanel, sidePanelSections }) {
  const formTypes = {
    new: 'form.create_entity',
    edit: 'form.update_entity',
    duplicate: 'form.duplicate_entity'
  }

  const formTitle = formTypes[type]
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const [visibleSections, setVisibleSections] = useState(sidePanelSections)

  const completeBack = () => {
    dispatch(BusEvents.FORM_BACK_BUTTON_CLICKED)
  }

  const completeCancel = () => {
    dispatch(BusEvents.FORM_CANCEL_BUTTON_CLICKED)
  }

  const staticParams = {
    title: I18n.t('discard_modal.title'),
    description: I18n.t('discard_modal.description'),
    actionButtonText: I18n.t('discard_modal.ok'),
    cancelButtonText: I18n.t('discard_modal.cancel'),
    buttonColor: '#B33939'
  }

  const getConfirmationParams = (eventName) => {
    const events = {
      'back': completeBack,
      'cancel': completeCancel
    }

    return {
      ...staticParams,
      onSuccess: () => events[eventName]
    }
  }

  const canShowSidePanel = () => {
    return sidePanelSections && showSidePanel.includes(type)
  }

  const getSidePanelCssClass = () => {
    return canShowSidePanel() ? classes.concat('full') : classes
  }

  const updateVisibleSections = (section) => {
    let visibles = visibleSections
    if (!visibles || visibles.length === 0)
      visibles = sidePanelSections

    if (!visibles) return
    const index = visibles.findIndex((sec) => sec.id === section.id)
    if (index === -1) return
    const newVisibleSections = visibles.map((x) => x)
    newVisibleSections[index].hide = !section.shouldDisplay
    setVisibleSections(newVisibleSections)
  }

  useEffect(() => {
    if (!visibleSections || visibleSections.length == 0) return

    const newVisibleSections = visibleSections.map((section) => {
      if (section.id === "general") return section

      const columns = section.eav_columns || section.columns
      if (!columns) return { ...section, hide: true }

      const visible = columns.filter((x) => {
        let visibility = false
        if (x.filters) {
          const visibilities = x.filters.filter((f) => f.visibility_by_formula)
          if (visibilities.length > 0)
            visibility = visibilities[0].visible_on_web
        }
  
        return visibility || x.visible_on_web
      })
  
      const display = visible.length > 0

      return { ...section, hide: !display }
    })

    setVisibleSections(newVisibleSections)
  }, [sidePanelSections])

  useBus(
    BusEvents.FORM_DISCARD_CHANGES_CONFIRM,
    () => showConfirmation(getConfirmationParams('cancel')),
    [showConfirmation, getConfirmationParams]
  )

  useBus(
    BusEvents.FORM_SECTION_DISPLAY_UPDATE,
    ({ payload }) => updateVisibleSections(payload),
    [updateVisibleSections]
  )

  return (
    <div className={`form-wrapper ${getSidePanelCssClass()}`}>
      {renderConfirmation()}
      <button
        type="button"
        className="back-button"
        data-testid="back-button-form"
        onClick={() => showConfirmation(getConfirmationParams('back'))}
      >
        <div className="back-icon"><MdKeyboardArrowLeft /></div>
        <div className="back-text">{backText || I18n.t('form.back', { entity: model.name })}</div>
      </button>
      <div className="form-wrapper-header">
        <div className="form-title-badge-wrapper">
          <div className="form-wrapper-title" data-testid="form-title">
            {title || I18n.t(formTitle, { entity: model.singularName })}
          </div>
          {label && <div className="form-wrapper-label">{label}</div>}
          <span id="badge" className="badge">{badge}</span>
        </div>
        <div className="form-wrapper-subtitle">
          {subtitle || model.singularName &&
          I18n.t(`form.subtitle`, { entity: model.singularName.toLowerCase() })}
        </div>
      </div>
      <div className="form-wrapper-body">
        <div className="form" style={{ flexBasis: canShowSidePanel() ? '65%' : '100%' }}>{children}</div>
        { visibleSections && canShowSidePanel() && <FormSidePanel steps={visibleSections} />}
      </div>
    </div>
  )
}

FormWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]),
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  backText: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  badge: PropTypes.element,
  classes: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showSidePanel: PropTypes.arrayOf(PropTypes.string),
  sidePanelSections: PropTypes.arrayOf(PropTypes.object)
}

FormWrapper.defaultProps = {
  label: undefined,
  badge: undefined,
  backText: undefined,
  classes: '',
  type: 'new',
  title: undefined,
  subtitle: undefined,
  showSidePanel: ['new', 'edit', 'duplicate'],
  sidePanelSections: undefined
}
