import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdAttachment } from 'react-icons/md'
import { useStore } from 'react-context-hook'
import { tableize } from 'inflected'
import useCookie from '/src/hooks/cookie'
import useSyncBatch from '/src/hooks/api/sync_batch'
import { titleLimiter } from '/src/utils/string'
import I18n from '/src/utils/translations'
import '/src/static/css/core/fields/attachment_field.css'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

// eslint-disable-next-line max-lines-per-function
export default function AttachmentField({ value }) {
  const [project] = useStore('project')
  const [subproject] = useStore('subproject')
  const [getToken] = useCookie('authentication_token')

  const [fileCategories, setFileCategories] = useState([])
  const batchedEntities = { file_categories: { get: fileCategories, set: setFileCategories } }
  const { loading } = useSyncBatch(batchedEntities)
  const titleLenghtLimit = 20

  const buildUrl = (attach) => {
    const type = tableize(attach.attachable_type)
    const filePath = `/api/v1/${type}/${attach.id}/download_attachment`
    const subprojectQuery = `&subproject_id=${subproject.id}&project_id=${project.id}`
    return `${SERVER_URL+filePath}?remember_token=${getToken()}${subprojectQuery}`
  }

  const buildAttachment = (attachment, index) => {
    const url = buildUrl(attachment)
    const title = titleLimiter(attachment.description, titleLenghtLimit)
    return (
      <div className="link-attachment" key={`${index}_${attachment.uuid}`}>
        <a href={url} target='_blank' rel='noopener noreferrer' className="attachment-href">
          <span className="attachment-icon"><MdAttachment /></span>
          {!loading && `${fileCategories[attachment.file_category_id].description} - ${title}`}
        </a>
      </div>
    )
  }

  const buildAttachmentsList = (attachments) => {
    if (!attachments || !attachments.length) return I18n.t('fields.na')
    return (
      <React.Fragment>
        {attachments.map((attachment, index) => buildAttachment(attachment, index))}
      </React.Fragment>
    )
  }

  return buildAttachmentsList(value)
}

AttachmentField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array])
}

AttachmentField.defaultProps = {
  value: null
}
