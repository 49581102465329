/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react'
import ColumnMenuVisibility from '/src/ui/core/grid/column_menu_visibility'
import ColumnMenuFilter from '/src/ui/core/grid/column_menu_filter'
import '/src/static/css/core/grid/column_menu.css'

export function ColumnMenu(props) {
  const [opened, setOpened] = useState('filter')

  return (
    <div className="column-menu-items">
      <ColumnMenuFilter {...props} opened={opened === 'filter'} onExpand={()=> setOpened('filter')} />
      <ColumnMenuVisibility {...props} opened={opened === 'visibility'} onExpand={()=> setOpened('visibility')} />
    </div>
  )
}
