import React from 'react'
import { GridColumnMenuFilter } from '@progress/kendo-react-grid'
import ColumnMenuFilterUI from '/src/ui/core/grid/column_menu_filter_ui'
import PropTypes from 'prop-types'

export default function ColumnMenuFilter(props) {
  const { column, columns } = props

  const onFilterChange = (f, e) => {
    props.onFilterChange(f, { ...e, column: props.column.field })
  }

  const onFilterExpandChange = (value) => {
    if (props.onExpand) props.onExpand()
  }

  const getColumnType = (columnField, gridColumns) => {
    const columnFiltered = gridColumns.filter((gridColumn) => {
      return gridColumn.description === columnField
    })[0]

    if (columnFiltered)
      return columnFiltered.type ? columnFiltered.type : columnFiltered.column_type.description

    return 'text'
  }

  const filterUI = (uiProps) => {
    return (
      <ColumnMenuFilterUI
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...uiProps}
        columnType={getColumnType(column.field, columns)}
      />
    )
  }

  // TODO: This is necessary just to disable the "Is not equal" filter operator
  // in a date column with datetime values. This feature will be fully implemented
  // in a future user story
  const getFilterOperators = () => {
    const operators = { ...props.filterOperators }

    if (props.columnIsDateTime) {
      operators.date = operators.date.slice(0, 1)
    }

    const neqUnableColumns = ['norm_hours', 'team_target_hours', 'budget_target_hours']
    if (props.flexible || neqUnableColumns.includes(column.field)) {
      Object.keys(operators).forEach((key) => {
        operators[key] = operators[key].filter((op) => op.operator !== 'neq')
      })
    }

    return operators
  }

  return (
    <div className={`column-menu-filter ${props.opened ? 'selected' : ''}`}>
      <GridColumnMenuFilter
        {...props}
        filterOperators={getFilterOperators()}
        hideSecondFilter
        onExpandChange={onFilterExpandChange}
        onFilterChange={onFilterChange}
        expanded={props.opened}
        filterUI={filterUI}
      />
    </div>
  )
}

ColumnMenuFilter.propTypes = {
  column: PropTypes.oneOfType([PropTypes.object]).isRequired,
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  props: PropTypes.oneOfType([PropTypes.object])
}

ColumnMenuFilter.defaultProps = {
  props: undefined
}
