import React from 'react'
import { MdAdd } from 'react-icons/md'
import I18n from '/src/utils/translations'
import SideBarCenterItem from '/src/ui/core/menus/sidebar_center_item'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/core/menus/sidebar.css'

export default function SideBarNewButton() {

  const onClick = () => dispatch(BusEvents.SIDEBAR_ADD_BUTTON_CLICKED)

  return (
    <div className="new-btn">
      <SideBarCenterItem icon={<MdAdd />} title={I18n.t('side_menu.new')} onClick={onClick} />
    </div>
  )
}
