// Return the parent models on a string
// E.g.: getParents('estimate.request')
//       ['estimate', 'request']
export const getParents = (string) => {
    return string.split('.') // [parentModel, grandparentModel]
}

// Return if the parent model string is a grandparent
// E.g.: isGranparentModel('estimate.request')
//       true
export const isGranparentModel = (string) => {
    return string.includes('.')
}
