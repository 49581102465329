import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export default function Block({ block }){
  const [className, setClassName] = useState('')
  const [style, setStyle] = useState({})

  const { border, component, maxHeight, radius, title, type } = block

  const buildClass = () => {
    let newClassName = `side-panel-block ${type}-block`
    newClassName += radius === false ? '' : ' block-radius'
    newClassName += border === false ? '' : ' block-border'
    newClassName += maxHeight ? ' block-scroll-y' : ''

    return newClassName
  }

  const buildStyle = () => {
    let newStyle = { ...style }
    const maxHeightObj = maxHeight ? { maxHeight, overflowY: 'auto' } : { maxHeight: undefined }

    newStyle = { ...newStyle, ...maxHeightObj }

    return newStyle
  }

  useEffect(() => {
    if (!block) return

    setClassName(buildClass())
    setStyle(buildStyle())
  }, [block])

  return (
    <div className={className} style={style}>
      {title && <div className="block-title">{title}</div>}
      {component}
    </div>
  )
}

Block.propTypes = {
  block: PropTypes.shape({
    border: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.object]).isRequired,
    maxHeight: PropTypes.string,
    radius: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    type: PropTypes.string.isRequired
  })
}

Block.defaultProps = {
  block: PropTypes.shape({
    border: true,
    maxHeight: null,
    radius: true
  })
}
