import React from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/core/grid/grid.css'
import { MdTrendingFlat } from 'react-icons/md'
import { Link } from 'react-router-dom'

export default function ShortcutBlock({ items }) {
  return (
    <React.Fragment>
      {items.map((item, index) => (
        <div key={`shortcut-block-item-${index}`} className="shortcut-block-item">
          <Link to={`/settings/edit?page=${item.path}`}>{item.text}</Link>
          <MdTrendingFlat />
        </div>
      ))}
    </React.Fragment>
  )
}

ShortcutBlock.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
}
