/* eslint-disable max-lines-per-function */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params'
import { buildBackURL } from '/src/utils/url'
import Layout from '/src/ui/core/layouts/layout'
import ProgressServicePackeageableForm from '/src/ui/domain/progress_services/progress_service_packageable_form'
import useModelController from '/src/hooks/model_controller'

export default function ProgressServicePackageableFormPage() {
  const history = useHistory()
  const [progressServiceTemplateId] = useQueryParam('eav_template_id', NumberParam)
  const [itemTemplateId] =  useQueryParam('item_template_id', NumberParam)
  const [disciplineId] = useQueryParam('discipline_id', NumberParam)
  const [progressId] = useQueryParam('progress_id', NumberParam)
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [selectServiceId] = useQueryParam('select_service_id', NumberParam)
  const [filterByRequest] = useQueryParam('filtered_by_request', BooleanParam)

  const backURLConfig = () => ({
    eavTemplateId: itemTemplateId,
    selectItemId: progressId,
    selectServiceId,
    requestId: (requestId && filterByRequest) ? requestId : null
  })

  const { dataItem } = useModelController({
    modelType: 'progress_services',
    onBack: () => history.push(`/performances?${buildBackURL(backURLConfig())}`),
    onFinish: () => history.push(`/performances?${buildBackURL(backURLConfig())}`),
  })

  return (
    <Layout key={dataItem} showProjectSwitcher={false}>
      <ProgressServicePackeageableForm
        progressId={progressId}
        templateId={progressServiceTemplateId}
        disciplineId={disciplineId}
      />
    </Layout>
  )
}
