import React from 'react'
import { MdSearch } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function SearchItemOnPopup({ items, setItems }) {
  const searchItem = (value) => {
    const itemsFiltered = []

    items.forEach((item) => {
      const checkString = typeof(item) === 'string' &&
        !item.toLowerCase().includes(value.toLowerCase())

      const checkObject = typeof(item) === 'object' &&
        item.hasOwnProperty('description') &&
        !item.description.toLowerCase().includes(value.toLowerCase())

      if (checkString || checkObject) return
      itemsFiltered.push(item)
    })

    setItems(itemsFiltered)
  }

  return (
    <div className="popup-search">
      <span className="search-box-icon"><MdSearch /></span>
      <input
        className="search-box-popup"
        type="text"
        onChange={(e) => searchItem(e.target.value)}
        placeholder={I18n.t('search.placeholder')}
        data-testid="search-box-popup"
      />
    </div>
  )
}

SearchItemOnPopup.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string.isRequired
    }))
  ]).isRequired,
  setItems: PropTypes.func.isRequired
}
