import { useState, useEffect } from 'react'

export default function usePaging(pageSize, onChange) {
  const [paging, setPaging] = useState({ skip: 0, pageSize })

  useEffect(() => {
    setPaging({ skip: 0, pageSize })
  }, [pageSize])

  const onPageChange = (event, offset) => {
    if (onChange) onChange()
    const newSkip = offset < 1 ? 0 : (offset - 1) * pageSize
    setPaging((prevState) => { return { ...prevState, skip: newSkip } })
  }

  return { paging, onPageChange }
}
