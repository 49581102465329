import React from 'react'
import PropTypes from 'prop-types'
import { isPresent } from '/src/utils/boolean_refinements'
import getFirstStringOrFormula from '/src/ui/domain/eav_columns/get_first_string_or_formula'

export default function DescriptionColumn({ data, columns }) {
  if (!Object.values(data).length || !columns.length) return null

  const firstColumn = getFirstStringOrFormula(columns)

  return (
    <React.Fragment>
      {isPresent(firstColumn) && data[firstColumn.description]}
    </React.Fragment>
  )
}

DescriptionColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.oneOfType([PropTypes.array])
}

DescriptionColumn.defaultProps = {
  data: {},
  columns: []
}
