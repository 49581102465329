import { useEffect, useState } from 'react'
import useSyncBatch from '/src/hooks/api/sync_batch'

/**
 *  Hook designed to fetch formulas and add the formula entity name
 * @return {(*|(function(*): *))[]} function callback to send form data to API
 */
// eslint-disable-next-line max-lines-per-function
export default function useSetupFormulas(templateId, paramName) {
  const [formulasBatch, setFormulasBatch] = useState()
  const [formulas, setFormulas] = useState()
  const [formulasControlField, setFormulasControlField] = useState()
  const formulaQuery = { where: { eav_template_id: templateId } }
  let entityName = paramName
  if (entityName === 'scope') entityName = ['scope', 'scoping']

  const batchedEntities = {
    formulas: { get: formulasBatch, set: setFormulasBatch, query: formulaQuery },
  }

  const { loading } = useSyncBatch(batchedEntities)

  useEffect(() => {
    if (!loading && formulasBatch) {
      const formulaValues = Object.values(formulasBatch)
      setFormulas(
        formulaValues
          .filter((formula) => !formula.eav_column_field)
          .map((formula) => ({ ...formula, entity: entityName }))
      )
      setFormulasControlField(treatColumnControlFormulas(formulaValues))
    }
  }, [loading, formulasBatch])

  const treatColumnControlFormulas = (data) => {
    if (!data) return 
    const columnControlFormulas = data.filter((formula) => formula.eav_column_field)

    if (columnControlFormulas) {
      columnControlFormulas.forEach((formula) => {
        formula.entity = entityName
      })
    }
    return columnControlFormulas
  }

  const getColumnType = (col) => col.column_type ? col.column_type.description : col.type

  const treatSimpleFormulaFields = ({ fields, formulasMap, results, onValueCalculated }) => {
    fields.forEach((field) => {
      const allowedTypes = ['formula', 'link', 'formula_service']

      if (!field.formula_id || !allowedTypes.includes(getColumnType(field))) return
      
      const formula = formulasMap[field.formula_id]
      let formulaResult = null

      if (results && results[field.formula_id] && results[field.formula_id].status === 'success')
        formulaResult = results[field.formula_id].response

      if (formula) {
        formula.entity = entityName
        field.formula = formula
        field.formula_result = formulaResult
        onValueCalculated(field.description, formulaResult)
      }
    })
  }

  const treatDropFormulaFields = (fields, formulasMap) => {
    fields.forEach((field) => {
      if (getColumnType(field) !== 'drop_formula') return

      const formulasIds = JSON.parse(field.metadata)
      field.formulas = {}

      if (formulasIds) {
        formulasIds.forEach((id) => {
          const formula = formulasMap[id]
          if (formula) {
            formula.entity = entityName
            field.formulas[id] = formula
          }
        })
      } else {
        Object.values(formulasMap).forEach((formula) => {
          formula.entity = entityName
          field.formulas[formula.id] = formula
        })
      }
    })
  }

  const treatFormulaFields = (fields, results, onValueCalculated) => {
    if (!formulas) return
    const formulasMap = {}
    formulas.forEach((formula) => formulasMap[formula.id] = formula)

    const treatSimpleFormulasProps = { fields, formulasMap, results, onValueCalculated }
    treatSimpleFormulaFields(treatSimpleFormulasProps)
    treatDropFormulaFields(fields, formulasMap)

    return fields
  }

  return [formulas, formulasControlField, loading, treatFormulaFields]
}
