import React from 'react'
import { MdAddCircleOutline, MdCloudDownload } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations.js'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/empty_state_grid.css'

export default function GridEmptyState({ onCreate, modelName, buttons, modelRoute, 
                                        templateId, onPopupImportClose }) {
  const modelNameLower = modelName.toLowerCase()

  const printButtons = () => (
    <React.Fragment>
      {buttons && (
        <div className="empty-buttons">
          {templateId !== undefined && (
            <PopupImportFile
              button={(
                <button type="button" className="import-from-xls empty-button">
                  <MdCloudDownload />
                  <span>{I18n.t('actions.import')}</span>
                </button>
              )}
              modelRoute={modelRoute}
              templateId={templateId}
              onClose={onPopupImportClose}
            />
          )}
          <button type="button" className="create-entity empty-button" onClick={onCreate}>
            <MdAddCircleOutline />
            <span>{`${I18n.t('actions.create')} ${modelName}`}</span>
          </button>
        </div>
      )}
    </React.Fragment>
  )

  return (
    <div className="empty-state-wrapper">
      <div className="empty-state">
        <div className="empty-state-svg">
          <img
            className="dpms-logo-collapsed"
            src="/static/svg/empty-state-workflow.svg"
            alt="empty-requests"
          />
        </div>
        <div className="empty-description">
          <div className="empty-title">
            {I18n.t('empty_state.empty', { model: modelNameLower })}
          </div>
          <div className="empty-subtitle">
            {I18n.t('empty_state.not_created', { model: modelNameLower })}
          </div>
        </div>
        {printButtons()}
      </div>
    </div>
  )
}

GridEmptyState.propTypes = {
  modelName: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  buttons: PropTypes.bool,
  modelRoute: PropTypes.string,
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPopupImportClose: PropTypes.func
}

GridEmptyState.defaultProps = {
  onCreate: undefined,
  buttons: true,
  modelRoute: '',
  templateId: undefined,
  onPopupImportClose: undefined
}
