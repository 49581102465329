import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from './routes/app_router_react'
import I18n from '/src/utils/translations'
import '/src/static/css/app.css'

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('root'),
)

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/#hot-module-replacement
if (import.meta.hot) {
  import.meta.hot.accept()
}
