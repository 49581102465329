import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import I18n from '/src/utils/translations'
import { formatNumberWithPrecision, formattedLocaleDate } from '/src/utils/project_formatter'
import { IntlService } from '@progress/kendo-react-intl'
import { Group, Path } from '@progress/kendo-drawing'
import usePlanningChartData from '/src/ui/domain/progress_services/planning_chart_data'
import PlanningChartLegend from '/src/ui/domain/progress_services/planning_chart_legend'
import { LIGHT_GRAY } from '/src/utils/constants/chart_colors'
import '/src/static/css/domain/progress_services/planning_chart.css'

export default function PlanningChart({ dataItem, unit, project }) {
  const formatDate = (date) => {
    const opts = { project }
    opts.parser = new IntlService()
    opts.value = date

    return formattedLocaleDate(opts)
  }

  const tooltip = (point, paramName) => {
    const paramNameQtyStr = I18n.t(`progress_services.planning_chart.${paramName}_qty`)
    const date = I18n.t('progress_services.planning_chart.date')
    return (
      <span>
        {`${paramNameQtyStr}: ${formatNumberWithPrecision(point.dataItem, project)}${unit}`}
        <br />
        {`${date}: ${formatDate(point.category)}`}
      </span>
    )
  }

  const [seriesData, dateCategories] = usePlanningChartData(dataItem, tooltip)

  const [series, setSeries] = useState([])

  useEffect(() => {
    if (!seriesData) return

    setSeries(seriesData)
  }, [seriesData])

  const renderRedLine = (args) => {
    const chart = args.target.chartInstance

    if (!chart) return

    const valueAxis = chart.findAxisByName('valueAxis')
    const categoryAxis = chart.findAxisByName('categoryAxis')
    const valueSlot = valueAxis.slot(valueAxis.options.seriesMax)
    const range = categoryAxis.range()
    const endDateIndex = dateCategories.indexOf(dataItem.end_date)
    const categorySlot = categoryAxis.slot(range.min, endDateIndex)
    const drawPathOptions = { stroke: { color: 'red', width: 1.5 } }
    const lineToDraw = new Path(drawPathOptions)
      .moveTo(categorySlot.topRight().x, valueSlot.origin.y)
      .lineTo(categorySlot.topRight().x, categorySlot.bottomRight().y)

    const group = new Group()
    group.append(lineToDraw)
    chart.surface.draw(group)
  }

  const toggleSeriesVisibility = (seriesItem) => {
    const newSeries = series.map((item) => {
      const newItem = { ...item }
      if (newItem.name === seriesItem.name) newItem.visible = !newItem.visible

      return newItem
    })

    setSeries(newSeries)
  }

  const renderDashedLineOnForecast = (forecastedSeriesLine) => (
    <ChartSeriesItem
      key={`${forecastedSeriesLine.name}line`}
      type="line"
      data={forecastedSeriesLine.data}
      color={LIGHT_GRAY}
      dashType="dash"
      zIndex={forecastedSeriesLine.zIndex}
      missingValues="gap"
      name={`${forecastedSeriesLine.name}line`}
      visible={forecastedSeriesLine.visible}
      markers={{ visible: false }}
    >
      <ChartSeriesItemTooltip visible={false} />
    </ChartSeriesItem>
  )

  const renderChartSeries = () => series.map((s) => (
    <ChartSeriesItem
      key={s.name}
      type="area"
      data={s.data}
      color={s.color}
      line={s.name !== I18n.t('progress_services.planning_chart.forecasted') ? s.line : undefined}
      zIndex={s.zIndex}
      missingValues="gap"
      name={s.name}
      visible={s.visible}
      markers={{ visible: false }}
    >
      <ChartSeriesItemTooltip
        render={s.tooltip}
        background={s.line.color}
      />
    </ChartSeriesItem>
  ))

  const renderChart = () => {
    const forecastedSeriesLine = series.find((s) => {
      return s.name === I18n.t('progress_services.planning_chart.forecasted')
    })

    return (
      <Chart style={{ height: 120 }} onRender={renderRedLine}>
        <ChartLegend visible={false} />
        <ChartTooltip />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            name="categoryAxis"
            visible={false}
            categories={dateCategories}
            majorGridLines={{ visible: false }}
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem
            name="valueAxis"
            visible={false}
            majorGridLines={{ visible: false }}
          />
        </ChartValueAxis>
        {forecastedSeriesLine ? (
          <ChartSeries>
            { [...renderChartSeries(), renderDashedLineOnForecast(forecastedSeriesLine)] }
          </ChartSeries>
        ) : (
          <ChartSeries>
            {renderChartSeries()}
          </ChartSeries>
        )}
      </Chart>
    )
  }

  return (
    <div className="chart">
      {renderChart()}
      <PlanningChartLegend
        series={series}
        onClick={toggleSeriesVisibility}
      />
    </div>
  )
}

PlanningChart.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  unit: PropTypes.string.isRequired,
  project: PropTypes.oneOfType([PropTypes.object])
}

PlanningChart.defaultProps = {
  project: {}
}
