import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import GeneralFlagColumn from '/src/ui/core/grid/general_flag_column'
import { isPresent, isBlankOrZero } from '/src/utils/boolean_refinements'

export default function FlagColumn({ data }) {
  let flag = ''
  let className = ''

  if (isBlankOrZero(data.scope_id)) {
    flag = I18n.t('progresses.extra')
    className = 'extra'
  } else if (data.scope_revision === 'revised') {
    flag = I18n.t('progresses.revised')
    className = 'revised'
  }

  return isPresent(className) && (!data.request || !data.request.skip_scoping) && (
    <GeneralFlagColumn flag={flag} className={className} />
  )
}

FlagColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object])
}

FlagColumn.defaultProps = {
  data: {}
}
