import React, { useState } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import FormButtons from '/src/ui/core/forms/form_buttons'
import Form from '/src/ui/core/forms/form'
import useFetchSections from '/src/hooks/api/fetch_sections'
import DatasheetModel from '/src/models/datasheet'
import '/src/static/css/domain/inspections/inspection_form.css'

export default function DatasheetForm({ dataItem, templateId, type }) {
  const [subproject] = useStore('subproject')
  const sections = useFetchSections(templateId)

  // TODO: This is a provisory solution. After the refactor of the Model's options, to alow
  // using functions to specificate if a field should be hidden, we
  // shall be able to remove this function and use a option to hide the number column on creation.
  const filteredDatasheetModel = (model) => {
    if (type === 'edit') return model

    const { columns } = model
    const number = columns.find((column) => column.description === 'number')
    number.hideOnForm = true
    return model
  }

  const [datasheetModel] = useState(() => filteredDatasheetModel(new DatasheetModel()))

  const [includeOnForm] = useState({
    subproject_id: subproject.id,
    eav_template_id: templateId
  })

  const readOnlyTypes = ['attachment', 'picture', 'signature']
  const isDuplicate = type === 'duplicate'
  const isCreation = type === 'new'

  const disabledColumns = (column) => {
    let result = false
    if (isDuplicate || isCreation) {
      result = readOnlyTypes.includes(column.type)
    }
    return result
  }

  const labelId = () => {
    if (type === 'new') return
    return dataItem && dataItem.id
  }

  const formType = () => isDuplicate ? 'new' : type

  return (
    <FormWrapper
      model={datasheetModel}
      dataItem={dataItem}
      label={labelId()}
      type={type}
      sidePanelSections={sections}
    >
      <React.Fragment>
        <Form
          model={datasheetModel}
          sections={sections}
          dataItem={dataItem}
          includeOnForm={includeOnForm}
          templateId={templateId}
          type={formType()}
          disableColumnEdition={disabledColumns}
        />
        <FormButtons model={datasheetModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

DatasheetForm.propTypes = {
  dataItem: PropTypes.object,
  templateId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
}

DatasheetForm.defaultProps = {
  dataItem: undefined
}
