import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
} from 'react-icons/md'
import '/src/static/css/core/menus/sidebar.css'

export default function SideBarCenterItem({ icon, title, link, onClick, opened, children }) {
  const arrowIcon = () => opened ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />

  const item = (
    <button id={`sidebar-${title}`} type="button" className="side-menu-item" onClick={onClick}>
      <div>
        <span>
          {icon}
          <span className="side-menu-label">{title}</span>
        </span>
        {children && arrowIcon()}
      </div>
    </button>
  )

  if (link) return <NavLink to={`/${link}`}>{item}</NavLink>

  if (children)
    return (
      <div className="side-menu-subitems">
        {item}
        {opened && <ul className="side-menu-subitem">{children}</ul>}
      </div>
    )

  return item
}

SideBarCenterItem.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  opened: PropTypes.bool
}

SideBarCenterItem.defaultProps = {
  title: '',
  link: null,
  onClick: () => {},
  opened: false
}
