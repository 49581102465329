/* eslint-disable import/prefer-default-export */
/* eslint-disable max-lines-per-function */
import React, { useState } from 'react'
import {
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent
} from '@progress/kendo-react-grid'
import I18n from '/src/utils/translations'
import { isBlankOrFalse } from '/src/utils/boolean_refinements'

export default function ColumnMenuVisibility(props) {
  const [columns, setColumns] = useState(props.columns)

  const onToggleColumn = (id) => {
    setColumns(
      columns.map((column, idx) => {
        return idx === id ? { ...column, hide: !column.hide } : column
      })
    )
  }

  const onSubmit = (event, value) => {
    if (event) {
      event.preventDefault()
    }
    const test = value || columns
    props.onColumnsSubmit(test)
    if (props.onCloseMenu) props.onCloseMenu()
  }

  const onReset = (event) => {
    event.preventDefault()
    const allColumns = props.columns.map((col) => {
      return {
        ...col,
        hide: false
      }
    })

    setColumns(allColumns)
    onSubmit(event, allColumns)
  }

  const onMenuItemClick = () => {
    if (props.onExpand) props.onExpand()
  }

  const oneVisibleColumn = () => {
    const hideOnGrid = columns.filter((c) => c.hideOnGrid).length
    const visibleColumns = columns.filter((c) => isBlankOrFalse(c.hide)).length
    return (visibleColumns - hideOnGrid) === 1
  }
    

  return (
    <div className={`column-menu-visibility ${props.opened ? 'selected' : ''}`}>
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem 
          title={I18n.t('grid.menu.visibility')}
          iconClass="k-i-columns"
          onClick={onMenuItemClick}
        />
        <GridColumnMenuItemContent show={props.opened}>
          <div className="k-column-list-wrapper">
            <form onSubmit={(e) => onSubmit(e)} onReset={onReset}>
              <div className="k-column-list">
                {columns.map(
                  (column, idx) =>
                    !column.hideOnGrid && (
                      <div key={idx} className="column-item">
                        <span>
                          <input
                            id={`column-visiblity-show-${idx}`}
                            className="k-checkbox"
                            type="checkbox"
                            readOnly
                            disabled={isBlankOrFalse(column.hide) && oneVisibleColumn()}
                            checked={isBlankOrFalse(column.hide)}
                            onClick={() => onToggleColumn(idx)}
                          />
                          <label
                            htmlFor={`column-visiblity-show-${idx}`}
                            className="k-checkbox-label"
                            style={{ userSelect: 'none' }}
                          >
                            {column.title}
                          </label>
                        </span>
                      </div>
                    )
                )}
              </div>
              <div className="k-columnmenu-actions">
                <button type="reset" className="k-button">{I18n.t('actions.reset')}</button>
                <button type="submit" className="k-button k-primary">{I18n.t('actions.save')}</button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  )
}
