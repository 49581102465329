import React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import PropTypes from 'prop-types'

export default function EnvironmentSwitchItem({ item, handleItemChange }) {
  return (
    <button
      type="button"
      data-testid="environment-switch-button"
      className="project-item"
      onClick={() => handleItemChange(item)}
    >
      <span className="project-title">{item.description}</span>
      <span className="project-switch-icon"><MdKeyboardArrowRight /></span>
    </button>
  )
}

EnvironmentSwitchItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string.isRequired
  }).isRequired,
  handleItemChange: PropTypes.func.isRequired
}
