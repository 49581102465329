import React from 'react'
import PropTypes from 'prop-types'
import LabelFilter from '/src/ui/core/buttons/label_filter'
import '/src/static/css/core/grid/grid.css'

export default function SimpleGridLabels({
  gridColumns, labels, dynamicFilters, clearColumnFilter
}) {
  const hasDynamicFilters = typeof dynamicFilters !== 'undefined' && dynamicFilters.filter
  const gridFilters = hasDynamicFilters ? dynamicFilters.filter.filters : []

  const chipsData = gridFilters && gridFilters.map((f) => {
    const { field, value, operator } = f.filters[0]
    const text = gridColumns.find((col) => [col.field, col.description].includes(field))
    if (!text) return undefined

    return (
      <LabelFilter
        key={field}
        value={value}
        operator={operator}
        text={text.title}
        onClick={() => clearColumnFilter(field)}
        suppressLink
        classes="filter-badge"
      />
    )
  })

  return labels.concat(chipsData)
}

SimpleGridLabels.propTypes = {
  gridColumns: PropTypes.array,
  labels: PropTypes.array,
  dynamicFilters: PropTypes.oneOfType([PropTypes.object]),
  clearColumnFilter: PropTypes.func.isRequired
}

SimpleGridLabels.defaultProps = {
  gridColumns: [],
  labels: [],
  dynamicFilters: undefined,
  clearColumnFilter: () => {}
}
