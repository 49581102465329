import React from 'react'
import PropTypes from 'prop-types'
import { BiLock } from 'react-icons/bi'

export default function GridHeaderCell ({ title, children, column, isColumnEditable }) {
  return (
    <div className="header-cell">
      {!column.hideLockIcon && !isColumnEditable(column) && (
        <div className="lock-icon">
          <BiLock />
        </div>
      )}
      <div className="header-title">{title}</div>
      {children}
    </div>
  )
}

GridHeaderCell.propTypes = {
  title: PropTypes.string.isRequired,
  column: PropTypes.shape({
    hideLockIcon: PropTypes.bool,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  isColumnEditable: PropTypes.func.isRequired,
}

GridHeaderCell.defaultProps = {
  children: undefined
}
