import React, { useState, useRef } from 'react'
import { MdMoreHoriz } from 'react-icons/md'
import PropTypes from 'prop-types'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import '/src/static/css/core/icons/popup_icon.css'

export default function MoreActionsIcon({ items }) {
  const [active, setActive] = useState(false)
  const popupButtonRef = useRef(null)

  return (
    <React.Fragment>
      {items && items.length > 0 && (
        <div className="popup-icon" id="more-icon-id">
          <PopupMenu
            items={items}
            setActive={setActive}
            popupButtonRef={popupButtonRef}
          >
            <button
              ref={popupButtonRef}
              id="more-button-id"
              type="button"
              className={`open-icon-popup${active ? ' active-button' : ''}`}
            >
              <MdMoreHoriz />
            </button>
          </PopupMenu>
        </div>
      )}
    </React.Fragment>
  )
}

MoreActionsIcon.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array])
}

MoreActionsIcon.defaultProps = {
  items: null
}
