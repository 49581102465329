import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import { formatCurrency, formatNumberWithPrecision } from '/src/utils/project_formatter'
import TitledHashList from '/src/ui/core/lists/titled_hash_list'
import GeneralTooltip from '/src/ui/core/tooltips/general_tooltip'
import '/src/static/css/domain/estimates/estimate_service_values_tooltip.css'

export default function EstimateServiceValuesTooltip({ totalPrice, estimateService }) {
  const [hashList, setHashlist] = useState()
  const [project] = useStore('project')
  const {
    application_price: applicationPrice,
    materials_price: materialsPrice,
    equipment_price: equipmentPrice,
    norm_hours: normHours,
    budget_target_hours: budgetHours,
    other_price: otherPrice,
    team_target_hours: teamTargetHours
  } = estimateService

  useEffect(() => { if (project) setNewHashList() }, [project])

  const formatHour = (value) => `${formatNumberWithPrecision(value, project)}h`

  const setNewHashList = () => {
    const newHashList = {
      [I18n.t('estimate.norm_hours')]: formatHour(normHours),
      [I18n.t('estimate.team_target_hours')]: formatHour(teamTargetHours),
      [I18n.t('estimate.budget_hours')]: formatHour(budgetHours),
      [I18n.t('estimate.tooltip.application')]: formatCurrency(applicationPrice, project),
      [I18n.t('estimate.tooltip.material')]: formatCurrency(materialsPrice, project),
      [I18n.t('estimate.tooltip.equipment')]: formatCurrency(equipmentPrice, project),
      [I18n.t('estimate.tooltip.others')]: formatCurrency(otherPrice, project),
    }

    setHashlist(newHashList)
  }

  const tooltipContent = () => hashList && (
    <TitledHashList
      title={I18n.t('estimate.service_details')}
      hashValues={hashList}
    />
  )

  return (
    <div className="estimate-total-price">
      <GeneralTooltip content={tooltipContent}>
        <div title="tooltip">{totalPrice}</div>
        <div className="info-icon" title="tooltip">i</div>
      </GeneralTooltip>
    </div>
  )
}

EstimateServiceValuesTooltip.propTypes = {
  estimateService: PropTypes.shape({
    application_price: PropTypes.number,
    materials_price: PropTypes.number,
    equipment_price: PropTypes.number,
    norm_hours: PropTypes.number,
    budget_target_hours: PropTypes.number,
    other_price: PropTypes.number,
    team_target_hours: PropTypes.number
  }).isRequired,
  totalPrice: PropTypes.string.isRequired
}
