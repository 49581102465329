import React from 'react'
import PropTypes from 'prop-types'
import InputNumeric from './input_numeric'

export default function InputPercentage({ inputProps }) {
  const { numberOfDigits } = inputProps
  const formatTemplate = numberOfDigits ? `p${numberOfDigits}` : 'p'

  // TODO: There is a bug on the percentage input:
  // When you try to copy and paste values into the field
  // two or more zeros will be added to the field
  // we opened a support ticket
  // (https://www.telerik.com/account/support-tickets/view-ticket/1472266)
  return <InputNumeric format={formatTemplate} inputProps={inputProps} step={0.01} />
}

InputPercentage.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    numberOfDigits: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired
}
