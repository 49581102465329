import React from 'react'
import PropTypes from 'prop-types'
import { MdLink } from 'react-icons/md'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'
import '/src/static/css/core/inputs/input_link.css'

export default function InputLink({ inputProps })   {
  const { id, className, testId, name, placeholder, title,
          value, onChange, onBlur, onFocus, required, readOnly } = inputProps

  const [inputValue, onInputChange] = useInputChange({ id, value, onChange })
  const error = useInputError({ inputValue, title, required })

  const inputClass = () => {
    const classes = ['form-input-link']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  return (
    <React.Fragment>
      <label className="input-type-link"> 
        <input
          id={id}
          className={inputClass()}
          name={name}
          placeholder={placeholder}
          value={inputValue || ''}
          onChange={onInputChange}
          onBlur={onBlur}
          onFocus={onFocus}
          data-testid={testId}
          disabled={readOnly}
          type="text"
        />
        {inputValue && (
        
        <a 
          href={inputValue} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="link-icon"
          label={name}
        >
          <MdLink /> 
        </a>
)}
      </label>
      <div className="error-label">{error}</div>
    </React.Fragment>

  )
}

InputLink.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    testId: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.oneOfType([
      PropTypes.bool, PropTypes.func
    ]),
    readOnly: PropTypes.bool
  }).isRequired
}
