import React from 'react'
import PropTypes from 'prop-types'

export default function LayoutPanels({ wrapperClass, columnStyles, children }){

  return (
    <div
      className={`layout-panels-wrapper ${wrapperClass}`}
      data-testid="layout-panels-wrapper"
    >
      {React.Children.map(children, (child, index) => {
        return (
          <div className='layout-panel' style={columnStyles[index]}>
            {React.cloneElement(child, child.props)}
          </div>
        )
      })}
    </div>
  )
}

LayoutPanels.propTypes = {
  wrapperClass: PropTypes.string,
  columnStyles: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}

LayoutPanels.defaultProps = {
  wrapperClass: '',
  columnStyles: [],
  children: []
}

