import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import { MdError, MdBorderColor } from 'react-icons/md'
import { BsFillTagFill } from 'react-icons/bs'
import '/src/static/css/domain/progress_services/cells.css'

// eslint-disable-next-line max-lines-per-function
export default function FlagColumn({ dataItem, request }) {
  const [statuses] = useStore('progress_service_statuses')

  if (!statuses || !request) return <React.Fragment />

  let flag = ''
  let className = ''
  let additionalIcon
  let isDone = false
  let isCanceled = false
  const {
    estimate_service: estimateService,
    quantity,
    progress_service_status_id: statusId,
    responsible_id: responsibleId = null,
    start_date: startDate = null,
    end_date: endDate = null
  } = dataItem

  const notSkipScoping = request && !request.skip_scoping
  const isExtra = (!estimateService || estimateService.quantity === null) && notSkipScoping
  const isRevised = estimateService && estimateService.quantity !== quantity
  const isPlanned = (responsibleId && startDate && endDate)

  if (statusId && statuses[statusId] && statuses[statusId].i18n_id) {
    isDone = (statuses[statusId].i18n_id) === 'done'
    isCanceled = (statuses[statusId].i18n_id) === 'canceled'
  }

  if (isPlanned) {
    className = 'planned'
    flag = I18n.t('progress_services.planned')
  } else {
    className = 'not-planned'
    flag = I18n.t('progress_services.not_planned')
  }

  // These are supposed to overwrite whatever the flags above set
  if (isCanceled) {
    className = 'canceled'
    flag = I18n.t('progress_services.canceled')
  } else if (isDone) {
    className = 'done'
    flag = I18n.t('progress_services.statuses.done')
  }

  if (isExtra) {
    additionalIcon = <div className="ps-extra"><MdError /></div>
  } else if (isRevised) {
    additionalIcon = <div className="ps-revised"><MdBorderColor /></div>
  }

  return (
    <div className={`flag-cell ${className}`}>
      <div className="progress-service-flag">
        <div className="icons">
          <div className="tag-icon">
            <BsFillTagFill />
          </div>
          {additionalIcon && <div className="second-icon">{additionalIcon}</div>}
        </div>
        <div className="text">{flag}</div>
      </div>
    </div>
  )
}

FlagColumn.propTypes = {
  dataItem: PropTypes.shape({
    quantity: PropTypes.number,
    progress_service_status_id: PropTypes.number,
    estimate_service: PropTypes.shape({
      quantity: PropTypes.number
    })
  }).isRequired,
  request: PropTypes.shape({
    skip_scoping: PropTypes.bool
  })
}
