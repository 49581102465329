import getPictureColumnImages from '/src/models/concerns/picture'
import getAttachmentFiles from '/src/models/concerns/attachment'

export default function useEavColumnsFields(eavColumns, dataItem) {
  const hideFlexColTypes = []

  const getFlexibleFields = () => {
    const fieldsObj = {}
    eavColumns.forEach((column) => {
      if (hideFlexColTypes.includes(column.column_type.description) || !dataItem) return
      const newColumn = column
      newColumn.value = dataItem[column.description]
      newColumn.type = column.column_type.description
      if (column.column_type.description === 'picture') {
        const pictureObjects = column.value ? column.value.slice(0, 6) : []
        newColumn.value = getPictureColumnImages(dataItem, pictureObjects)
      }
      if (column.column_type.description === 'attachment') {
        const attachmentObjects = column.value ? column.value : []
        newColumn.value = getAttachmentFiles(dataItem, attachmentObjects)
      }

      fieldsObj[column.description] = newColumn
    })

    return fieldsObj
  }

  return getFlexibleFields()
}
