import React from 'react'
import { MdTraffic, MdViewStream, MdMoreHoriz } from 'react-icons/md'
import PropTypes from 'prop-types'
import ClusteredIcon from '/src/ui/core/icons/clustered_icon'
import LabelIcon from '/src/ui/domain/scopes/label_icon'
import SpliterClusterIcon from '/src/ui/core/icons/spliter_cluster_icon'
import BatchAssignerIcon from '/src/ui/core/icons/batch_assigner_icon'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/buttons/buttons.css'

export default function GridHeaderIcons({ icons, selectedItem }) {
  const includes = (iconName) => icons.includes(iconName)

  const buildButton = (testId, icon) => (
    <button type="button" className="btn-icon" key={testId} data-testid={testId}>
      {icon}
    </button>
  )

  const spliterClusterIcon = () => includes('split') && <SpliterClusterIcon selectedItem={selectedItem} />
  const labelIcon = () => includes('label') && <LabelIcon />
  const clusteredIcon = () => includes('clustered') && <ClusteredIcon />
  const batchAssignerIcon = () => includes('batch_assigner') && <BatchAssignerIcon />
  const clusterIcon = () => includes('cluster') && buildButton('cluster-icon', <MdViewStream />)
  const filterIcon = () => includes('filter') && buildButton('filter-icon', <MdTraffic />)
  const moreIcon = () => includes('more') && buildButton('more-icon', <MdMoreHoriz />)
  const renderComponentIcons = () => icons.filter((icon) => typeof icon === 'object').map((v, i) => {
    return { ...v, key: i }
  })

  return (
    <div data-testid="group-icons" className="group-icons">
      {spliterClusterIcon()}
      {labelIcon()}
      {clusteredIcon()}
      {batchAssignerIcon()}
      {clusterIcon()}
      {filterIcon()}
      {moreIcon()}
      {renderComponentIcons()}
    </div>
  )
}

GridHeaderIcons.propTypes = {
  icons: PropTypes.oneOfType([PropTypes.array]).isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.object])
}

GridHeaderIcons.defaultProps = {
  selectedItem: undefined
}
