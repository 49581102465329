/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useQueryParam, NumberParam, BooleanParam } from 'use-query-params'
import useGetEstimate from '/src/ui/domain/estimate_services/get_estimate_hook.jsx'
import Layout from '/src/ui/core/layouts/layout'
import EstimateServiceForm from '/src/ui/domain/estimate_services/estimate_service_form'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useModelController from '/src/hooks/model_controller'
import { buildBackURL } from '/src/utils/url'
import I18n from '/src/utils/translations'

export default function EstimateServiceFormPage({ match }) {
  const history = useHistory()
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const [itemTemplateId] = useQueryParam('scope_template_id', NumberParam)
  const [selectItemId] = useQueryParam('select_item_id', NumberParam)
  const [selectServiceId] = useQueryParam('select_service_id', NumberParam)
  const [requestId] = useQueryParam('request_id', NumberParam)
  const [filterByRequest] = useQueryParam('filtered_by_request', BooleanParam)

  const [scopeId] = useQueryParam('scope_id', NumberParam)
  const [disciplineId] = useQueryParam('discipline_id', NumberParam)
  const estimate = useGetEstimate()

  const backURLConfig = () => ({
    eavTemplateId: itemTemplateId || templateId,
    selectItemId,
    selectServiceId,
    requestId: (filterByRequest) ? requestId : null
  })

  const { dataItem, loading } = useModelController({
    modelType: 'estimate_services',
    dataItemId: match.params.id,
    onBack: () => { history.push(`/scopes?${buildBackURL(backURLConfig())}`) },
    onFinish: () => { history.push(`/scopes?${buildBackURL(backURLConfig())}`) },
  })

  useEffect(() => {
    estimate.read(requestId)
  }, [requestId])

  return (
    <Layout key={dataItem} showProjectSwitcher={false}>
      {loading || (!estimate.id && !dataItem) ? (
        <ThreeDotsLoader />
      ) : (
        <EstimateServiceForm
          dataItem={dataItem}
          scopeId={scopeId}
          templateId={templateId}
          disciplineId={disciplineId}
          estimateId={estimate.id}
          type={match.params.collection}
        />
      )}
    </Layout>
  )
}

EstimateServiceFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  })
}

EstimateServiceFormPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined
    })
  })
}
