import React, { useMemo } from 'react'
import lodash from 'lodash'
import { GridColumn as Column } from '@progress/kendo-react-grid'
import DeleteColumn from '/src/ui/core/grid/column_cell_factory/delete_column'

const useEditableGridRowsDeletion = (newDataSource, setNewDataSource, isRowEditable, updateEditedItems) => {
  const { omit } = lodash

  const isHeaderChecked = useMemo(() => (
    newDataSource.length > 0 &&
    newDataSource.filter((item) => isRowEditable(item) && !item.to_be_deleted).length === 0
  ), [newDataSource, isRowEditable])  

  const onSelectionChange = (cellItem) => {
    const { dataIndex, dataItem } = cellItem

    if (!isRowEditable(dataItem)) return

    setNewDataSource((prevDataSource) => {
      const nextDataSource = prevDataSource.map((item, index) => {
        if (dataIndex === index) {
          if (item.to_be_deleted) return omit(item, [ 'to_be_deleted', 'inError' ])
          return { ...item, to_be_deleted: true }
        }
        return item
      })

      updateEditedItems(nextDataSource)

      return nextDataSource
    })
  }

  const onHeaderSelectionChange = (event) => {
    const checkboxElement = event.target
    const { checked } = checkboxElement
    setNewDataSource((prevDataSource) => {
      let nextDataSource = []

      if (!checked) {
        nextDataSource = prevDataSource.map((item) => omit(item, [ 'to_be_deleted', 'inError' ]))
      } else {
        nextDataSource = prevDataSource.map((item) => isRowEditable(item) ? (
          { ...item, to_be_deleted: true }
        ) : item)
      }

      updateEditedItems(nextDataSource)

      return nextDataSource
    })
  }

  const renderDeletionColumn = () => {
    return (
      <Column
        key="to_be_deleted"
        width={80}
        sortable={false}
        filterable={false}
        resizable={false}
        headerCell={() => (
          <DeleteColumn
            toBeDeleted={isHeaderChecked}
            onChange={(event) => onHeaderSelectionChange(event)}
            header
          />
        )}
        cell={(cellItem) => (
          <DeleteColumn
            toBeDeleted={cellItem.dataItem.to_be_deleted}
            onChange={() => onSelectionChange(cellItem)}
          />
        )}
      />
    )
  }

  return renderDeletionColumn
}

export default useEditableGridRowsDeletion
