const sanitizeFilterValue = (value) => {
  if (value === true) return 1
  if (value === false) return 0
  return encodeURIComponent(value)
}

export const filterToQuery = (filter) => {
  switch (filter.type) {
    case 'between': {
      const value1 = sanitizeFilterValue(filter.value[0] || filter.value.start)
      const value2 = sanitizeFilterValue(filter.value[1] || filter.value.end)
      return `query[where][${filter.column}]=${value1}..${value2}`
    }

    case 'less_than_or_equal_to': {
      const lowerValue = typeof filter.value === 'string' ? '1970-01-01' : -2147483648
      return `query[where][${filter.column}]=${lowerValue}..${sanitizeFilterValue(filter.value)}`
    }

    case 'more_than_or_equal_to': {
      const maxValue = typeof filter.value === 'string' ? '3000-12-31' : 2147483647
      return `query[where][${filter.column}]=${sanitizeFilterValue(filter.value)}..${maxValue}`
    }

    case 'where_distinct': {
      return `query[where_distinct]=${encodeURIComponent(filter.column)}`
    }

    case 'sync_from': {
      return `query[sync_from]=${encodeURIComponent(filter.value)}`
    }

    default: {
      if (filter.value === null) {
        return `query[${filter.type}][${filter.column}]`
      }
      return `query[${filter.type}][${filter.column}]=${sanitizeFilterValue(filter.value)}`
    }
  }
}

const parseKeyValueQuery = (name, filters, queries) => {
  Object.keys(filters).forEach((column) => {
    queries.push(filterToQuery({
      type: name,
      column,
      value: filters[column]
    }))
  })
}

const pushSimpleQuery = (name, filter, queries) => {
  queries.push(`query[${name}]=${encodeURIComponent(filter)}`)
}

const parseSimpleQuery = (name, filters, queries) => {
  filters.forEach((filter) => pushSimpleQuery(name, filter, queries))
}

const parseQueryFilters = ({ where, whereEq, contains, containsOr, not, order, distinct,
                             blank, syncFrom, lessThanOrEqualTo, moreThanOrEqualTo,
                             between }, queries) => {
  if (where) parseKeyValueQuery('where', where, queries)
  if (whereEq) parseKeyValueQuery('where_eq', whereEq, queries)
  if (contains) parseKeyValueQuery('contains', contains, queries)
  if (containsOr) parseKeyValueQuery('contains_or', containsOr, queries)
  if (not) parseKeyValueQuery('where_not', not, queries)
  if (order) parseKeyValueQuery('order', order, queries)
  if (lessThanOrEqualTo) parseKeyValueQuery('less_than_or_equal_to', lessThanOrEqualTo, queries)
  if (moreThanOrEqualTo) parseKeyValueQuery('more_than_or_equal_to', moreThanOrEqualTo, queries)
  if (between) parseKeyValueQuery('between', between, queries)
  if (syncFrom) pushSimpleQuery('sync_from', syncFrom, queries)
  if (distinct) parseSimpleQuery('where_distinct', distinct, queries)

  if (blank) {
    blank.forEach((column) => {
      queries.push(`query[where][${column}]`)
    })
  }
}

const parseProjectFilters = ({ token, projectId, subprojectId, paging }, queries) => {
  if (token) queries.push(`remember_token=${token}`)
  if (projectId) queries.push(`project_id=${projectId}`)
  if (subprojectId) queries.push(`subproject_id=${subprojectId}`)

  if (paging) {
    if (paging.size) queries.push(`page_size=${paging.size}`)
    if (paging.skip) queries.push(`skip=${paging.skip}`)
  }
}

export const query = (params) => {
  const queries = []

  parseQueryFilters(params, queries)
  parseProjectFilters(params, queries)

  if (queries.length === 0) return ''

  return `?${queries.join('&')}`
}
