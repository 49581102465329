import React from 'react'
import PropTypes from 'prop-types'
import { MdCheck } from 'react-icons/md'

export default function Itemize({ type, index, children, disabled }) {

  const print = (content = '', className = '') => (
    <div className={`d-flex ${className} ${disabled && 'disabled'}`}>
      <div className={`section-number ${className}`}>{content}</div>
      {children}
    </div>
  )

  switch (type) {
    case 'checked':
      return print(<MdCheck />, 'checked')
    case 'number':
      return print(index)
    default:
      return print()
  }
}

Itemize.propTypes = {
  children: PropTypes.element.isRequired,
  type: PropTypes.string,
  index: PropTypes.number,
  disabled: PropTypes.bool
}

Itemize.defaultProps = {
  type: null,
  index: null,
  disabled: null
}
