import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import FieldFactory from '/src/ui/core/fields/field_factory'
import { isBlank, isPresent } from '/src/utils/boolean_refinements'

export default function SidePanelField({ field }) {
  const { type, field: fieldName, value, title, labeled, sidePanelFull  } = field

  const fullColumnTypes = ['signature', 'multiline_text', 'attachment', 'picture']

  const printValue = () => {
    if (isBlank(value)) return I18n.t('fields.na')
    let fieldValue = value
    const isNotNullObject = fieldValue && typeof fieldValue === 'object'
    if (fieldName && isNotNullObject) {
      const [, property] = fieldName.split('.')
      if (property) fieldValue = value[property]
    }

    return <FieldFactory value={fieldValue} type={type} opts={field} />
  }

  return (
    <div className={`detail-field${fullColumnTypes.includes(type) || sidePanelFull ? ' full' : ''}`}>
      <span className="detail-field-title">{title}</span>
      <div className={labeled && isPresent(value) ? 'labeled' : ''}>
        {printValue()}
      </div>
    </div>
  )
}

SidePanelField.propTypes = {
  field: PropTypes.shape({
    description: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    labeled: PropTypes.bool,
    sidePanelFull: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array
    ]),
  }).isRequired,
}
