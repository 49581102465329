import React, { useState } from 'react'
import BlockingDialog from '/src/ui/core/dialogs/blocking_dialog'
import ServiceDialog from '/src/ui/core/dialogs/service_dialog'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import I18n from '/src/utils/translations.js'

export default function HandlerDialog() {
  const [dialogType, setDialogType] = useState()
  const [serviceDialogProps, setServiceDialogProps] = useState({})

  useBus(BusEvents.SHOW_LOADING_DIALOG, () => { setDialogType('load') }, [])
  useBus(BusEvents.SHOW_SUCCESS_DIALOG, () => { setDialogType('success') }, [])
  useBus(BusEvents.SHOW_FAILURE_DIALOG, () => { setDialogType('failure') }, [])
  useBus(BusEvents.SHOW_SERVICE_IN_PROGRESS_DIALOG, ({ payload }) => {
    setupServiceDialog(payload) 
  }, [])
  useBus(BusEvents.HIDE_DIALOG, () => { setDialogType() }, [])

  const closeDialog = () => {
    setDialogType()
  }

  const setupServiceDialog = (payload) => {
    setDialogType('service')
    setServiceDialogProps(payload)
  }

  const dialogTypes = {
    load: {
      title: I18n.t('main_dialog.loading_title'),
      closeIcon: false,
      icon: 'loadIcon',
      message: I18n.t('main_dialog.loading_message')
    },
    success: {
      title: I18n.t('main_dialog.success_title'),
      closeIcon: true,
      onClose: closeDialog,
      icon: 'successIcon',
      message: I18n.t('main_dialog.success_message')
    },
    failure: {
      title: I18n.t('main_dialog.failure_title'),
      closeIcon: true,
      onClose: closeDialog,
      icon: 'failureIcon',
      message: I18n.t('main_dialog.failure_message')
    },
    service: {
      title: I18n.t('main_dialog.service_title'),
      closeIcon: true,
      onClose: closeDialog,
      icon: 'serviceBar',
      message: I18n.t('main_dialog.loading_message'),
      serviceDialogProps
    }
  }

  return (
    <React.Fragment>
      {dialogType === 'service'
        ? <ServiceDialog { ...dialogTypes[dialogType]} />
        : dialogType && <BlockingDialog {...dialogTypes[dialogType]} />}
    </React.Fragment>
  )
}
