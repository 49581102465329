const useCookie = (key) => {
  function setCookie(value, expireDays = 7) {
    const date = new Date()
    date.setDate(date.getDate() + expireDays)
    const expires = `expires=${date.toUTCString()}`
    document.cookie = ` ${key}=${value};${expires};SameSite=Lax;path=/`
  }

  function getCookie() {
    const name = `${key}=`
    const ca = decodeURIComponent(document.cookie).split(';')
    for (let i = 0; i < ca.length; i+=1) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  function clearCookie() {
    setCookie('', 0)
  }

  return [
    getCookie,
    setCookie,
    clearCookie
  ]
}

export default useCookie
