import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import useTranslations from '/src/hooks/api/translations'
import Layout from '/src/ui/core/layouts/layout'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import RequestForm from '/src/ui/domain/requests/request_form'
import useModelController from '/src/hooks/model_controller'
import { useQueryParam, NumberParam } from 'use-query-params'

export default function RequestFormPage({ match }) {
  const history = useHistory()
  const [requestId] = useQueryParam('request_id', NumberParam)
  const loadingTranslations = useTranslations()
  const { dataItem, loading } = useModelController({
    modelType: 'requests',
    dataItemId: match.params.id,
    onBack: () => {history.push(`/requests${backURLRequest()}`)},
    onFinish: () => {history.push(`/requests${backURLRequest()}`)},
  })

  const backURLRequest = () => {
    return requestId ? `?request_id=${requestId}` : ''
  }

  return (
    <Layout key={dataItem} showProjectSwitcher={false}>
      {loading || loadingTranslations ? (
        <ThreeDotsLoader />
       ) : (
         <RequestForm requestDataItem={dataItem} type={match.params.collection} />
       )}
    </Layout>
  )
}

RequestFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

RequestFormPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined
    })
  })
}
