/* eslint-disable max-lines-per-function */
import { useCallback, useEffect } from 'react'

// eslint-disable-next-line max-lines-per-function
/**
 * This will add a listener to the editable_grid so it can be navigatable
  */
export default function useEditableGridKeyboardNavigation(isLoadingEditableGrid) {

  const cellClickAndMove = useCallback((cell) => {
    if (!cell || cell.nodeType !== 1) return
    if (typeof cell.scrollIntoView === 'function')
      cell.scrollIntoView({ block: 'start', inline: 'center' })

    cell.click()
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    if (isLoadingEditableGrid) return
    cellClickAndMove(document.querySelector('.cell-editable'))
  }, [isLoadingEditableGrid, cellClickAndMove])

  const getCloseSibling = (elem, selector, direction) => {
    let sibling = elem[`${direction}Sibling`]

    if (!selector) return sibling

    while (sibling) {
      if (sibling.matches(selector)) return sibling
      sibling = sibling[`${direction}Sibling`]
    }
  }

  const moveHorizontallyOnGrid = (direction) => {
    const cell = document.querySelector('td.input-cell')

    let sibling = getCloseSibling(cell, '.cell-editable', direction)
    if (!sibling && cell.closest('tr')[`${direction}Sibling`]) {
      const { firstChild, lastChild } = cell.closest('tr')[`${direction}Sibling`]
      sibling = direction === 'next' ? firstChild : lastChild

      if (sibling.className !== 'cell-editable')
        sibling = getCloseSibling(sibling, '.cell-editable', direction)

    }

    if (sibling) cellClickAndMove(sibling)
  }

  const moveVerticallyOnGrid = (direction) => {
    const child = document.querySelector('td.input-cell')
    const parent = child.closest('tr')
    if (!parent) return
    const index = Array.prototype.indexOf.call(parent.children, child)
    if (parent[`${direction}Sibling`]) {
      const cell = parent[`${direction}Sibling`].children[index]
      if(cell) cell.click()
    }
  }

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === 'Enter') document.querySelector('.button-action').click()
    const input = document.querySelector('td.input-cell input')

    if(input === null) return

    const activeElementName = document.activeElement.tagName
    if(activeElementName === 'INPUT'){
      if (e.key === 'Tab') moveHorizontallyOnGrid('next')
      else if (e.key === 'Escape') input.blur()
    } else {
      // eslint-disable-next-line default-case
      switch (e.key) {
        case 'ArrowLeft':
          moveHorizontallyOnGrid('previous')
          break
        case 'Tab':
        case 'ArrowRight':
          moveHorizontallyOnGrid('next')
          break
        case 'ArrowUp':
          moveVerticallyOnGrid('previous')
          break
        case 'ArrowDown':
          moveVerticallyOnGrid('next')
          break
        case 'Enter':
          input.focus()
          break
      }
    }
  }
}
