import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'
import formatBytes from '/src/utils/converter'
import useFetchAPI from '/src/hooks/api/fetch_api'
import I18n from '/src/utils/translations'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'

// eslint-disable-next-line max-lines-per-function
export default function DeleteAttachment({ file, fileCategory, onDelete, removeUrlParams, route }) {
  const deleteAttachment = useFetchAPI(route)

  const handleDelete = () => deleteAttachment.fetchAPI(removeUrlParams)

  useEffect(() => {
    if (deleteAttachment.status === 'SUCCESS') {
      notifySuccess(I18n.t('form.inputs.attachment.remove_success'))
      if (onDelete) onDelete(file)
    }
  }, [deleteAttachment.status, file, onDelete])

  return (
    <div className="d-flex">
      <div className="left">
        <p>{fileCategory}</p>
      </div>
      <div className="form-input-combobox-wrapper">
        <input
          disabled
          className="form-input-text"
          value={`${file.name} ${formatBytes(file.size)}`}
          type="text"
        />
      </div>
      {removeUrlParams && (
        <span
          className="icon"
          onClick={() => handleDelete()}
          role="delete-attachment"
        >
          <MdClose />
        </span>
      )}
    </div>
  )
}

DeleteAttachment.propTypes = {
  fileCategory: PropTypes.string,
  file: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    uuid: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number
  }).isRequired,
  onDelete: PropTypes.func,
  removeUrlParams: PropTypes.shape({
    requestAction: PropTypes.string,
    httpAction: PropTypes.string,
    resourceId: PropTypes.number,
    additionalResource: PropTypes.oneOfType([PropTypes.object]),
    data: PropTypes.oneOfType([PropTypes.object]),
    query: PropTypes.oneOfType([PropTypes.object])
  }),
  route: PropTypes.string.isRequired
}

DeleteAttachment.defaultProps = {
  fileCategory: null,
  onDelete: null,
  removeUrlParams: null
}
