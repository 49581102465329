// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export default class InspectedItemModel {
  name = I18n.t('inspections.inspected_detail')

  paramName = 'inspection'

  route = 'inspections'

  constructor(dataItem) {
    const { request_id: requestId, inspected_type: inspectedType,
            inspected_template: inspectedTemplate } = dataItem
    const searchRoute = {
      'Scope': 'scopes',
      'Progress': 'progresses'
    }
    this.columns = [
      {
        id: 1,
        description: 'inspected_id',
        title: I18n.t('inspections.inspected'),
        required: true,
        searchFields: ['number'], // fetch eav_columns from inspected_template ordered by position and find this name as the first column
        searchExtraQuery: { where: { request_id: requestId, eav_template_id: inspectedTemplate } },
        searchRoute: searchRoute[inspectedType],
        textDisplayFields: ['number'],
        textField: 'number',
        type: 'search'
      }
    ]
  }
}
