import { useEffect, useState } from 'react'
import { camelize, pluralize } from 'inflected'
import lodash from 'lodash'
import useUpdateEffect from '/src/hooks/update_effect'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useBatchEntities from '/src/hooks/api/batch_entities'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import forbiddenTypesOnGrid from '/src/ui/core/grid/forbidden_types'
import usePaginatedFetch from '/src/hooks/api/paginated_fetch'

/* eslint-disable max-lines-per-function */

export default function useGridCRUD({ model, paging, filtering, sorting,
                                      tabbing, flexible, templateId, additionalEntities }) {
  const [loading, setLoading] = useState()
  const [richData, setRichData] = useState({ data: [], total: 0 })
  const [flexibleColumnsData, setFlexibleColumnsData] = useState([])
  const loadingBatch = useBatchEntities(additionalEntities)
  const batchedEntities = useEntitiesCache()
  const { responseData, errors, fetchAPI, requestAction } = useFetchAPI(model.route)

  const { uniqBy } = lodash
  const [flexibleColumns, fetchFlexibleColumns] = usePaginatedFetch(
    'eav_columns',
    (unfilteredData) =>
      uniqBy(unfilteredData.filter((item) => item.eav_template_id === templateId), 'id')
  )

  useUpdateEffect(() => {
    const { data } = responseData
    data.forEach((entity) => {
      Object.keys(entity).forEach((key) => {
        const foreignKeyEnds = '_id'
        if (key.endsWith(foreignKeyEnds)) {
          const equivalent = { responsibles: 'employees' }
          const foreignEntity = key.substr(0, key.length - foreignKeyEnds.length)
          const batchKey = camelize(pluralize(foreignEntity), false)
          const batchOrEquivalent = batchedEntities[batchKey] || batchedEntities[equivalent[batchKey]]
          const isEntityUndefined = !batchOrEquivalent
          if (isEntityUndefined || Object.keys(batchOrEquivalent).length === 0) return
          if (batchOrEquivalent[entity[key]]) entity[foreignEntity] = batchOrEquivalent[entity[key]]
        }
      })
    })

    setLoading(false)
    setRichData(responseData)

  }, [responseData])

  useEffect(() => {
    if (!flexibleColumns || flexibleColumns.length === 0) return
    filterAllowedFlexibleColumnsByType(flexibleColumns)
  }, [flexibleColumns])

  const setFilters = () => {
    const filters = [{ type: 'where', column: 'visible_on_web', operator: 'eq', value: 1 }]

    if (templateId) {
      filters.push({ type: 'where', column: 'eav_template_id', operator: 'eq', value: templateId })
    }

    return filters
  }

  const filterAllowedFlexibleColumnsByType = (flexibleCols) => {
    let flexibleColsFiltered = flexibleCols.filter((fc) => {
      if(!fc.column_type) return false
      const type = fc.column_type.description
      return !forbiddenTypesOnGrid.includes(type)
    })

    flexibleColsFiltered = flexibleColsFiltered.map((col) => ({ ...col, flexible: true }))
    setFlexibleColumnsData(flexibleColsFiltered)
  }


  const fetchColumns = () => {
    const sort = [{ field: 'position', dir: 'asc' }]
    const dataOptions = { sorting: sort, tabbing, filtering: { filter: { logic: 'and', filters: setFilters() } } }
    fetchFlexibleColumns(true, dataOptions)
  }

  const read = () => {
    setLoading(true)

    setRichData({ data: [], total: 0 })
    setFlexibleColumnsData([])

    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      dataOptions: { paging, filtering, sorting, tabbing }
    }

    if (flexible) fetchColumns()
    fetchAPI(params)
  }

  const create = (dataItem) => {
    // normalizeDateFields(dataItem, model.fields)
    const data = {}
    data[model.paramName] = dataItem
    const params = { requestAction: 'CREATE', httpAction: 'post', data }
    fetchAPI(params)
  }

  const update = (dataItem) => {
    // normalizeDateFields(dataItem, model.fields)
    const data = {}
    data[model.paramName] = dataItem
    const params = { requestAction: 'UPDATE', httpAction: 'put', data, resourceId: dataItem.id }
    fetchAPI(params)
  }

  const destroy = (dataItem) => {
    const params = { requestAction: 'DESTROY', httpAction: 'delete', resourceId: dataItem.id }
    fetchAPI(params)
  }

  useEffect(() => {
    if (!loadingBatch) read()
  }, [paging, sorting, filtering, tabbing, loadingBatch])

  return {
    loading,
    responseData: richData,
    flexibleColumnsData,
    errors,
    requestAction,
    read,
    create,
    update,
    destroy
  }
}
