import React from 'react'
import PropTypes from 'prop-types'
import { MdError, MdBorderColor } from 'react-icons/md'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function GeneralFlagColumn({ flag, className }) {
  return (
    <CustomTooltip className={className} maxLength={12}>
      <div className="cell-wrapper">
        <div className={className} title={flag}>
          <div className="cell-icon">{className === 'extra' ? <MdError /> : <MdBorderColor />}</div>
          <div className="cell-name">{flag}</div>
        </div>
      </div>
    </CustomTooltip>
  )
}

GeneralFlagColumn.propTypes = {
  flag: PropTypes.string.isRequired,
  className: PropTypes.oneOf(['revised', 'extra']).isRequired,
}
